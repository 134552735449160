<script>
    export let fill;
    export let height = 19;
    export let width = 19;

    $: if (!fill) {
        fill = '#666666';
    }
</script>

<svg
    {width}
    {height}
    viewBox="0 0 {width}
    {height}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M0 9.5C0 4.52944 4.02944 0.5 9 0.5C13.9706 0.5 18 4.52944 18 9.5C18 14.4706 13.9706 18.5 9 18.5C4.02944 18.5 0 14.4706 0 9.5ZM1 9.5C1 13.9183 4.58172 17.5 9 17.5C11.1217 17.5 13.1566 16.6571 14.6569 15.1569C16.1571 13.6566 17 11.6217 17 9.5C17 5.08172 13.4183 1.5 9 1.5C4.58172 1.5 1 5.08172 1 9.5ZM9 15.5C9.55228 15.5 10 15.0523 10 14.5C10 13.9477 9.55228 13.5 9 13.5C8.44771 13.5 8 13.9477 8 14.5C8 15.0523 8.44771 15.5 9 15.5ZM6 6.73C6.13321 5.00861 7.62728 3.71523 9.35 3.83C10.2047 3.78916 11.0435 4.07115 11.7 4.62C12.2934 5.13805 12.6266 5.89247 12.61 6.68C12.5698 7.84134 11.8425 8.86741 10.76 9.29C10.452 9.4209 10.1706 9.60734 9.93 9.84C9.83155 10.0055 9.78944 10.1985 9.81 10.39V12.22H8.31V11.97C8.31 11.5021 8.34475 10.7996 8.36844 10.3206C8.3807 10.0728 8.39 9.88478 8.39 9.82C8.41112 9.61159 8.46866 9.40851 8.56 9.22C8.63569 9.04138 8.74424 8.87857 8.88 8.74C9.11614 8.54367 9.37459 8.37585 9.65 8.24C9.92971 8.09138 10.1972 7.92085 10.45 7.73C10.7561 7.4545 10.9306 7.06183 10.93 6.65C10.9511 6.26119 10.7789 5.88702 10.47 5.65C10.1433 5.39204 9.73609 5.25749 9.32 5.27C8.32 5.27 7.72 5.85 7.49 7.04V7.29L6 6.97V6.73Z"
        {fill}
    />
</svg>
