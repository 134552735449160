<script>
    export let viewable = null;
    export let file = null;
    export let canDownload;

    import UnsupportedIcon from '../../icons/unsupported.svelte';
    import config from 'app.config';
    import AssetDownloadComponent from '../../components/folder.content/asset.download.svelte';
    import { i18n } from '../../i18n';
</script>

<div class="viewer has-all-space has-centered-content" data-cy="not-supported">
    <div class="is-relative">
        <UnsupportedIcon />
    </div>
    <div class="is-gap" />
    <div class="is-size-4">{i18n.t('App.Viewing_Actions.Unsupported_Msg')}</div>
    <div class="is-size-7">
        {@html i18n.t(
            'App.Viewing_Actions.Learn_More_Viewable_File_Type_Link',
            {
                HTML_START:
                    '<a href="' +
                    config.links.learnMoreViewableFileTypes +
                    '" target="_blank">',
                HTML_END: '</a>',
            }
        )}
        {i18n.t('App.Viewing_Actions.Learn_More_Viewable_File_Type_Verbose')}
    </div>
    <div class="is-gap is-large" />
    {#if canDownload}
        <AssetDownloadComponent assets={[file]} open={false} {viewable}>
            <button
                class="ga-unsupported-download button is-primary"
                slot="download-folder-trigger"
            >
                {i18n.t('App.Primary_Buttons.Download_Btn')}
            </button>
        </AssetDownloadComponent>
    {/if}
</div>

<style>
    .viewer {
        flex-direction: column;
    }
</style>
