<script>
    export let id;
    export let type = 'info'; // info/success/warning/danger
    export let title = '';
    export let body = '';
    export let bodyParams = ''; // any parameters needing passing to fill the body i18n string
    export let learnMoreLinkHref = ''; // URL for a "learn more" link, if needed.
    export let viewAllAction = null;

    import { i18n } from '../../i18n';
    import { learnMoreLink } from '../../providers/utils';
    import { notificationsStore } from './notifications.store.js';
    import ClearNotificationIcon from '../../icons/clear.notification.svelte';

    // info/success/warning/danger > Info/Success/Warning/Danger
    const alt = type[0].toUpperCase() + type.substr(1);

    const dataCy = `notification-panel-${type}`;
</script>

<!--
    Notification component based upon Bulma notification component:
    https://bulma.io/documentation/elements/notification/
    Type should be one of: info/success/warning/danger
-->
<article class="message is-{type}" data-cy={dataCy}>
    <div class="notification message-body is-white">
        <span class="icon notification-icon">
            <img {alt} src="DRIVE_ASSETS/images/{type}.svg" />
        </span>
        <span
            class="icon delete-icon"
            data-cy="{dataCy}-close"
            on:click={notificationsStore.close(id)}
        >
            <ClearNotificationIcon />
        </span>
        <div class="notification-body">
            {#if title}
                <div class="notification-title has-text-weight-bold">
                    {@html i18n.t(title)}
                </div>
            {/if}
            {@html i18n.t(body, bodyParams)}
            {#if learnMoreLinkHref}
                {@html learnMoreLink(learnMoreLinkHref)}
            {/if}
            {#if viewAllAction}
                <div
                    class="view-all-action has-pointer-cursor is-size-7 has-text-primary"
                    data-cy="{dataCy}-view-all"
                    on:click={viewAllAction}
                >
                    {i18n.t('App.Upload_Actions.View_All')}
                </div>
            {/if}
        </div>
    </div>
</article>

<style>
    .view-all-action:hover {
        font-weight: 700;
    }
</style>
