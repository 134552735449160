<script>
    import Spinner from '../components/spinner.svelte';
    import { i18n } from '../i18n';
    import ModalComponent from '../components/elements/modal.svelte';
    import { gDriveInfoResponseTime } from '../providers/state/user';

    const dataCy = 'account-setup';
    const waitTimeThreshold = 3; // Seconds
</script>

<ModalComponent footerModifier="is-hidden" hasMinHeight={true} {dataCy}>
    <div
        class="columns has-all-space is-size-5 is-vertical-center is-marginless"
        slot="title"
    >
        <div class="column is-paddingless is-full">
            {i18n.t('App.New_Account.Setting_Up')}
        </div>
    </div>
    <div slot="content">
        {#if $gDriveInfoResponseTime <= waitTimeThreshold}
            <div>{i18n.t('App.New_Account.First_Wait_Msg')}</div>
        {:else}
            <div>
                {i18n.t('App.New_Account.Second_Wait_Msg')}
            </div>
        {/if}
        <div class="is-gap is-xlarge" />
        <div class="has-centered-content content">
            <Spinner name="create-drive-spinner" size="is-large" />
        </div>
        <div class="is-gap is-large" />
    </div>
</ModalComponent>
