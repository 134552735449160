<script>
    export let fill = '#666666';
    export let size = 26;
    const width = size;
    const height = size;
</script>

<svg
    {width}
    {height}
    viewBox="7 7 {width}
    {height}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 16H19V12L23 16ZM20 24.7V23.7V21V20H21H23V17H18V12H12C11.4 12 11
        12.4 11 13V27C11 27.6 11.4 28 12 28H22C22.6 28 23 27.6 23
        27V24.7H21H20ZM21 21V23.7H24V25.8L28.9 22.3L24 18.8V21H21Z"
        {fill}
    />
</svg>
