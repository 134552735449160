<script>
    export let fileId; // Encoded

    import FileIcon from '../icons/file.svelte';
    import Spinner from '../components/spinner.svelte';
    import Viewer from '../components/file.viewer/viewer.svelte';
    import SomethingWentWrong from '../components/something.wrong.svelte';
    import AssetDownloadComponent from '../components/folder.content/asset.download.svelte';
    import { gDriveInfo } from '../providers/state/user';
    import {
        trackEvent,
        trackPage,
        pages,
        categories,
        actions,
    } from '../providers/analytics/ga';
    import { i18n } from '../i18n';
    import { FileFactory } from '../rest.sdks';
    import { getExtension } from '../rest.sdks/utils.js';

    const dataCy = 'file-viewer';

    let file, getMetadata, canDownload, getViewable, collectionId;

    trackPage({
        page: pages.viewingPage,
    });

    const trackViewable = (viewable) => {
        trackEvent({
            category: categories.viewing,
            action: actions.viewing,
            label: getExtension(viewable.name),
        });
    };

    const trackViewingError = (error) => {
        trackEvent({
            category: categories.viewing,
            action: actions.viewingError,
            label: error.message || error,
        });
    };

    // File Ingestion Pattern
    const setupFileAsset = async (id) => {
        file = FileFactory(id);
        await file.setup(); // Call when we might need metadata
        const metadata = file.itemInfo;

        canDownload = file.canDownload;

        getViewable = file.viewable();
        getViewable.then(trackViewable, trackViewingError);

        return metadata;
    };

    $: if ($gDriveInfo && fileId && (!file || fileId !== file.id)) {
        collectionId = $gDriveInfo.collectionId;
        getMetadata = setupFileAsset(fileId);
    }
</script>

<div class="section" data-cy={dataCy}>
    {#if $gDriveInfo}
        {#await getMetadata}
            <div class="has-centered-content has-all-space">
                <Spinner size="is-large" />
            </div>
        {:then metadata}
            {#if metadata}
                <div class="section-head">
                    <div
                        class="container file-name-header is-fluid columns is-marginless
                has-bottom-border is-strong-border is-mobile"
                    >
                        <div
                            class="column is-vertical-center is-paddingless
                    has-ellipsis"
                        >
                            <span class="icon">
                                <FileIcon type={file.fileType} />
                            </span>
                            <span
                                class="title is-6 has-tooltip-right has-tooltip-arrow has-tooltip-hidden-mobile"
                            >
                                {metadata.name}
                            </span>
                        </div>
                        <div class="column is-narrow is-vertical-center">
                            {#if canDownload}
                                <AssetDownloadComponent
                                    assets={[file]}
                                    open={false}
                                >
                                    <button
                                        class={`ga-${dataCy}-download button is-outlined is-primary is-small`}
                                        slot="download-folder-trigger"
                                    >
                                        {i18n.t(
                                            'App.Primary_Buttons.Download_Btn'
                                        )}
                                    </button>
                                </AssetDownloadComponent>
                            {/if}
                        </div>
                    </div>
                </div>
                <div class="section-body">
                    {#if getViewable}
                        {#await getViewable}
                            <div class="has-centered-content has-all-space">
                                <Spinner size="is-large" />
                            </div>
                        {:then viewable}
                            <Viewer
                                {collectionId}
                                {file}
                                {viewable}
                                {canDownload}
                            />
                        {:catch error}
                            <SomethingWentWrong
                                {error}
                                source={`${dataCy}-get-viewable`}
                            />
                        {/await}
                    {/if}
                </div>
            {:else}
                <!-- DriveMS doesn't throw any error 
                    if trying to access the non-existing file, so custom error. -->
                <SomethingWentWrong
                    error={new Error(`File not found - ${fileId}.`)}
                    assetType="file"
                />
            {/if}
        {:catch error}
            <SomethingWentWrong
                {error}
                source={`${dataCy}-get-metadata`}
                assetType="file"
            />
        {/await}
    {/if}
</div>

<style>
    .content-area {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }
    .content-box {
        flex: 1;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        position: relative;
    }

    .file-name-header {
        min-height: 60px;
    }

    .sub-header {
        display: flex;
        margin-top: 1px;
        min-height: 45px;
        height: 45px;
        border-bottom: 1px solid #c2c4c6;
        background-color: white;
        align-items: center;
        padding: 0 32px 5px 20px;
    }

    .sub-header.loading {
        border-bottom: 1px solid transparent;
    }
</style>
