<script>
    export let content = {};
    export let assetActions = {};

    import FolderListItem from './folder.list.item.svelte';
    import FileListItem from './file.list.item.svelte';
    import { i18n } from '../../../i18n';

    const dataCy = 'folder-content';
</script>

<div class="section">
    <div
        class="section-head columns is-mobile is-vcentered is-marginless
        has-bottom-border is-strong-border"
    >
        <div class="column is-one-third-desktop has-text-weight-semibold">
            {i18n.t('App.Folder_Content_List.Name_Heading')}
        </div>
        <div
            class="column is-narrow is-2 has-text-weight-semibold
            is-hidden-touch"
        >
            {i18n.t('App.Folder_Content_List.Last_Updated_Heading')}
        </div>
        <div
            class="column is-narrow is-2 has-text-weight-semibold
            is-hidden-touch"
        >
            {i18n.t('App.Folder_Content_List.Created_By_Heading')}
        </div>
        <div
            class="column is-narrow is-1 has-text-weight-semibold
            is-hidden-touch"
        >
            {i18n.t('App.Folder_Content_List.Size_Heading')}
        </div>
        <div
            class="column is-narrow is-1 has-text-weight-semibold
            is-hidden-touch"
        >
            {i18n.t('App.Folder_Content_List.Version_Heading')}
        </div>
        <div class="column is-narrow is-2 has-text-weight-semibold" />
    </div>
    <div class="section-body folder-content-section-body" data-cy={dataCy}>
        {#each content.folders as folder (folder.idDecoded)}
            <FolderListItem {folder} {assetActions} />
        {/each}
        {#each content.files as file (file.idDecoded)}
            <FileListItem {file} {assetActions} />
        {/each}
    </div>
</div>

<style>
    .section-body.folder-content-section-body {
        min-height: 300px;
    }
</style>
