<script>
    export let fill = '';
    export let height = 20;
    export let width = 20;
    $: if (!fill) {
        fill = '#666666';
    }
</script>

<svg
    {width}
    {height}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M16.2254 1.08072C15.9908 0.159502 15.2676 0.00585938 14.9397
        0.00585938H4.65402C4.32616 0.00585938 3.60295 0.159502 3.3683
        1.08072C3.20373 1.72807 0.796875 9.18007 0.796875
        10.2916H18.7969C18.7969 9.18007 16.39 1.72807 16.2254 1.08072ZM0.796875
        16.7201C0.796875 17.5031 1.44488 18.0059 2.08259 18.0059H17.5112C18.1489
        18.0059 18.7969 17.5031 18.7969
        16.7201V11.5773H0.796875V16.7201ZM14.9397 12.863C15.6501 12.863 16.2254
        13.4384 16.2254 14.1487C16.2254 14.8591 15.6501 15.4344 14.9397
        15.4344C14.2294 15.4344 13.654 14.8591 13.654 14.1487C13.654 13.4384
        14.2294 12.863 14.9397 12.863Z"
        {fill}
    />
</svg>
