import { actions, categories, trackEvent } from '../../providers/analytics/ga';

// For consistent handling of events when showing a Modal or Dropdown
//
// Modal
//  Close events are not propagated
// Dropdown
//  Close events are propagated, such as directly selecting another item
//
// There are 3 patterns:
//  1)  They are present in the DOM always and visibility is controlled
//      via 'openModal'
//  2)  They are added and removed from the DOM - caught in
//      onMount()/onDestroy()
//  3)  A combination of the above two
//
export const manageDialogEvents = (() => {
    let dialogs = [];
    let current;

    const handleMouse = (event) => {
        if (!current) {
            return;
        }

        // Do not allow the system context menu when a dialog is open
        if (event.type === 'contextmenu') {
            event.preventDefault(); // Don't show the system context menu anywhere
        }

        if (current.nodes.every((node) => !node.contains(event.target))) {
            // Don't close on 'mousedown', rather wait for 'click'/'contextmenu'
            // so we can stop propagation of those.
            if (event.type !== 'mousedown') {
                // If its a close mouse event outside, close ourselves
                current.closeFcn();
            }

            if (current.stopPropagation) {
                event.stopPropagation();
            }
        }
    };

    const handleKeyboard = (event) => {
        if (!current) {
            return;
        }

        // Close on Escape key anywhere on the page
        if (event.keyCode === 27) {
            current.closeFcn();
        }
    };

    // Events outside we should react to
    document.addEventListener('mousedown', handleMouse, true);
    document.addEventListener('click', handleMouse, true);
    document.addEventListener('contextmenu', handleMouse, true);

    document.addEventListener('keydown', handleKeyboard);

    return (source, capture, node, closeFcn) => {
        if (!node) {
            return; // Ignore events without an attached DOM element
        }

        const tabSelector = 'button, input, a[href]';
        const dataCy = node.getAttribute('data-cy');

        // Push or pop dialog nesting
        if (capture) {
            // Ignore redundant calls
            if (dialogs.find((dialog) => dialog.dataCy === dataCy)) {
                trackEvent({
                    category: categories.errorDrive,
                    action: actions.dialogState,
                    label: `${source}: ${dataCy} already registered`,
                });
                return;
            }

            // Gather details of nodes to watch
            const isModal = node.classList.contains('modal');
            const nodes = isModal
                ? [node.querySelector('.modal-card')]
                : Array.from(node.children);

            // Maintain legacy behavior that events outside the Asset Actions dropdown
            // are propagated. This provides single click to change the selection, etc.
            const stopPropagation = !dataCy.startsWith('actions-dropdown-');

            // Disable tabbing underneath for the first modal
            if (isModal && !dialogs.length) {
                document.querySelectorAll(tabSelector).forEach((item) => {
                    if (!node.contains(item)) {
                        item.tabIndex = -1;
                    }
                });
            }

            dialogs.push({ dataCy, nodes, stopPropagation, closeFcn });
        } else {
            // During app initialization, we may get 'release' calls for dialogs that persist in
            // the DOM and we are not yet watching. This is expected, so ignore them here.
            // Typically 'actions-dropdown-XXX', 'upload-dropdown' or 'new-folder'.
            const lastDialog = dialogs[dialogs.length - 1];
            if (lastDialog) {
                if (lastDialog.dataCy === dataCy) {
                    dialogs.pop();
                }
            }

            if (!dialogs.length) {
                document
                    .querySelectorAll(tabSelector)
                    .forEach((item) => item.removeAttribute('tabIndex'));
            }
        }

        current = dialogs[dialogs.length - 1]; // [-1] returns undefined
    };
})();
