<script>
    export let fill;
    export let height = 23;
    export let width = 23;

    $: if (!fill) {
        fill = '#666666';
    }
</script>

<svg
    {width}
    {height}
    viewBox="0 0 {width}
    {height}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M11.4414 22.2578C9.26582 22.2578 7.13908 21.6127 5.33014
        20.404C3.5212 19.1953 2.1113 17.4773 1.27874 15.4673C0.446172 13.4573
        0.228336 11.2456 0.652773 9.11182C1.07721 6.97803 2.12486 5.01802
        3.66324 3.47964C5.20161 1.94127 7.16162 0.893617 9.29542
        0.469179C11.4292 0.044742 13.6409 0.262579 15.6509 1.09514C17.6609
        1.92771 19.3789 3.3376 20.5876 5.14654C21.7963 6.95548 22.4414 9.08222
        22.4414 11.2578C22.4384 14.1743 21.2785 16.9704 19.2163 19.0327C17.154
        21.0949 14.3579 22.2548 11.4414 22.2578ZM11.4414 1.95012C9.60052 1.95012
        7.80097 2.49601 6.27033 3.51875C4.73969 4.5415 3.5467 5.99516 2.84222
        7.69592C2.13775 9.39668 1.95342 11.2681 2.31256 13.0737C2.6717 14.8792
        3.55817 16.5376 4.85988 17.8393C6.16158 19.1411 7.82005 20.0275 9.62557
        20.3867C11.4311 20.7458 13.3026 20.5615 15.0033 19.857C16.7041 19.1525
        18.1577 17.9595 19.1805 16.4289C20.2032 14.8982 20.7491 13.0987 20.7491
        11.2578C20.7461 8.79018 19.7645 6.42447 18.0196 4.67958C16.2748 2.9347
        13.909 1.95311 11.4414 1.95012ZM12.2876 16.8988V10.1296C12.2876 9.9052
        12.1984 9.68997 12.0397 9.53129C11.881 9.37261 11.6658 9.28346 11.4414
        9.28346C11.217 9.28346 11.0018 9.37261 10.8431 9.53129C10.6844 9.68997
        10.5953 9.9052 10.5953 10.1296V16.8988C10.5953 17.1233 10.6844 17.3385
        10.8431 17.4972C11.0018 17.6558 11.217 17.745 11.4414 17.745C11.6658
        17.745 11.881 17.6558 12.0397 17.4972C12.1984 17.3385 12.2876 17.1233
        12.2876 16.8988ZM11.4414 5.61679C11.2183 5.61679 11.0001 5.68296 10.8146
        5.80693C10.6291 5.9309 10.4845 6.1071 10.3991 6.31325C10.3137 6.5194
        10.2914 6.74625 10.3349 6.9651C10.3784 7.18395 10.4859 7.38497 10.6436
        7.54276C10.8014 7.70054 11.0025 7.80799 11.2213 7.85152C11.4402 7.89505
        11.667 7.87271 11.8732 7.78732C12.0793 7.70193 12.2555 7.55733 12.3795
        7.37179C12.5034 7.18626 12.5696 6.96813 12.5696 6.745C12.5696 6.44578
        12.4508 6.15881 12.2392 5.94723C12.0276 5.73566 11.7406 5.61679 11.4414
        5.61679Z"
        {fill}
    />
</svg>
