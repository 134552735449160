<script>
    export let fill = '#666666';
    export let size = 26;
    const width = size;
    const height = size;
</script>

<svg
    {width}
    {height}
    viewBox="7 7 {width}
    {height}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 20.18L23.54 17.64L24.96 19.05L20.01 24L15.06 19.05L16.47 17.64L19
        20.16V12H21V20.18ZM26 26V23H28V28H12V23H14V26H26Z"
        {fill}
    />
</svg>
