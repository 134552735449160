<script>
    export let fill = '#808080';
    export let height = 14;
    export let width = 14;
    export let viewBox = '0 0 14 14';
</script>

<svg {width} {height} {viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 6.99994C5.62 6.99994 6.86 5.23994 6.86 3.60994C6.9125 1.95675 5.64986 0.557253
        4 0.439941C3.19172 0.456525 2.4262 0.806345 1.88464 1.40661C1.34308 2.00687 1.07361
        2.80422 1.14 3.60994C1.14 5.57994 2.38 6.99994 4 6.99994ZM0 12.1899C0.07 8.83994
        1.39 7.59994 3.72 7.48994V7.49994H4.37C6.8 7.59994 8 8.85994 8 12.4999V15.9999H0V12.1899ZM11.33
        8.99994H11.86C13.88 9.04994 14.87 10.0899 14.87 13.0999V15.9999H9V12.4999C9.00806
        11.7626 8.9478 11.0261 8.82 10.2999C9.35749 9.44185 10.319 8.94384 11.33 8.99994ZM13.93
        5.74994C13.93 7.08994 12.9 8.56994 11.56 8.56994C10.867 8.52215 10.2232 8.19482 9.77634
        7.66307C9.32944 7.13131 9.11781 6.44079 9.19 5.74994C9.13468 5.08189 9.35778 4.42065
        9.80653 3.92267C10.2553 3.42469 10.8898 3.13421 11.56 3.11994C12.9266 3.22102 13.9712 4.38027 13.93 5.74994Z"
        {fill}
    />
</svg>
