import { enableOpenOnAutoCAD, systemOutage, testFlag } from './feature.flag';
import config from 'app.config';
import { gDriveInfo } from './state/user';
import { FolderFactory } from '../rest.sdks';

const invalidateFolderContent = (folderId) => {
    const folder = FolderFactory(
        folderId || gDriveInfo.getValue().rootFolderId
    );
    folder.content.invalidate();
};

// Provide a way for our Cypress tests to:
//  - Get at this users feature flags
//  - Directly invalidate folder content, instead of more expensive reloads
//
// See: cypress/support/commands.js
export const exposeCypressHooks = () => {
    // Obscure as an array
    const answers = [
        testFlag,
        config.featureflags.testFlag, // Override in effect?
        systemOutage,
        config.featureflags.systemOutage, // Override in effect?
        enableOpenOnAutoCAD,
        config.featureflags.enableOpenOnAutoCAD, // Override in effect?
        invalidateFolderContent,
    ];

    window.cypressHooks = (which) => answers[which];
};
