<script>
    export let assets = [];
    export let onClose = () => {};

    import { randomHex } from '../../providers/utils';
    import { notificationsStore } from '../../components/elements/notifications.store.js';
    import ModalComponent from '../elements/modal.svelte';
    import SomethingWentWrong from '../something.wrong.svelte';
    import { i18n } from '../../i18n';
    import {
        actions,
        categories,
        trackEventWithDataType,
    } from '../../providers/analytics/ga';

    const id = `delete-asset-${randomHex()}`;
    const dataCy = 'delete-asset';
    const dataCyCancel = `${dataCy}-cancel`;
    const dataCyConfirm = `${dataCy}-confirm`;
    const dataCyWarning = `${dataCy}-warning`;

    let modalInstance;
    let deleteInProgress = false;
    let lastError;

    const options = {
        onCloseEnd: onClose,
    };

    const removeAssets = async () => {
        if (assets) {
            deleteInProgress = true;
            try {
                const response = await Promise.all(
                    assets.map(async (asset) => {
                        return asset.remove();
                    })
                ).catch((err) => {
                    throw err;
                });
                if (response) {
                    notificationsStore.add({
                        type: 'success',
                        body: 'App.Bulk_Actions.Items_Deleted',
                        bodyParams: { count: assets.length },
                        timeout: 3000,
                    });

                    modalInstance.close();

                    // Send GA events
                    if (assets.length === 1) {
                        trackEventWithDataType({
                            category: categories.fileManagement,
                            action: assets[0].isFile
                                ? actions.fileDelete
                                : actions.folderDelete,
                        });
                    } else {
                        trackEventWithDataType({
                            category: categories.fileManagement,
                            action: actions.bulkDelete,
                            value: assets.length, // # of top-level items only
                        });
                    }
                }
            } catch (err) {
                lastError = err;
            }
            deleteInProgress = false;
        }
    };
</script>

<ModalComponent {id} {dataCy} bind:this={modalInstance} {options}>
    <span slot="title">{i18n.t('App.Asset_Actions.Asset_Delete_Title')}</span>
    <div slot="content">
        <div class="subtitle is-6" data-cy={dataCyWarning}>
            {i18n.t('App.Asset_Actions.Delete_Confirm_Msg', {
                count: assets.length,
            })}
            <br />
            <br />
            <b>{i18n.t('App.Asset_Actions.Delete_Is_Permanent')}</b>
        </div>
        {#if lastError}
            <SomethingWentWrong error={lastError} source={dataCy} />
        {/if}
    </div>
    <div slot="footer-right-section">
        <button
            class="button is-outlined"
            on:click={modalInstance.close}
            data-cy={dataCyCancel}
            disabled={deleteInProgress}
        >
            {i18n.t('App.Primary_Buttons.Cancel_Btn')}
        </button>
        <button
            class="ga-{dataCyConfirm} button is-danger"
            on:click={removeAssets}
            class:is-loading={deleteInProgress}
            data-cy={dataCyConfirm}
        >
            {i18n.t('App.Primary_Buttons.Delete_Btn')}
        </button>
    </div>
    <div slot="trigger">
        <slot name="delete-folder-trigger" />
    </div>
</ModalComponent>
