<script>
    export let fill = '#4d4d4d';
</script>

<svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.25 8.00244H14.2C15.0208 6.65275 14.9704 4.94639 14.0714 3.64749C13.1724 2.34858 11.5931 1.70045 10.0408 1.99333C8.48848 2.28621 7.25394 3.46524 6.89 5.00244H6.75C5.09315 5.00244 3.75 6.34559 3.75 8.00244H3.25C1.86929 8.00244 0.75 9.12173 0.75 10.5024C0.75 11.8832 1.86929 13.0024 3.25 13.0024H14.25C15.6307 13.0024 16.75 11.8832 16.75 10.5024C16.75 9.12173 15.6307 8.00244 14.25 8.00244Z"
        {fill}
    />
</svg>
