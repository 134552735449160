/** Dispatch event on scroll outside of node */
export function scrollOutside(node, callback) {
    const handleScroll = (event) => {
        if (node && !node.contains(event.target) && !event.defaultPrevented) {
            callback();
        }
    };

    document.addEventListener('scroll', handleScroll, true);

    return {
        destroy() {
            document.removeEventListener('scroll', handleScroll, true);
        },
    };
}
