// Methods meant to be called after successful login
import { gDriveInfo, gDriveInfoResponseTime } from '../providers/state/user';
import { getPropValue } from './utils';
import { drive, fusion } from './request';
import DriveError from './drive.error';
import { trackEvent, categories, actions } from '../providers/analytics/ga';
import whilst from 'async/whilst';
import asyncify from 'async/asyncify';

export const getDriveInfo = async (isSetup = false) => {
    let interval;
    try {
        // Set timer only if it's initial user setup.
        if (isSetup) {
            // Start with 1 second
            let timeTakenByGetDriveInfo = 1;
            gDriveInfoResponseTime.set(timeTakenByGetDriveInfo);
            const increment = () => {
                timeTakenByGetDriveInfo++;
                gDriveInfoResponseTime.set(timeTakenByGetDriveInfo);
            };
            interval = setInterval(increment, 1000);
        }

        // Drive_Get_Drive_Info
        const response = await drive();

        // Clear interval if any, after response is received.
        if (interval) {
            clearInterval(interval);
            gDriveInfoResponseTime.set(null);
        }

        return response.data;
    } catch (error) {
        // Clear interval in case of error
        if (interval) {
            clearInterval(interval);
            gDriveInfoResponseTime.set(null);
        }
        throw error;
    }
};

export const search = async (text) => {
    const { collectionId } = gDriveInfo.getValue();
    const searchCountLimit = 30;
    const searchTerm = encodeURIComponent(text);
    const params = `limit=${searchCountLimit}&searchTerm=${searchTerm}`;
    const url = `collections/${collectionId}/search`;
    // Drive_Search
    const response = await drive(`${url}?${params}`);
    return response.data;
};

export const removeAllCollaborators = async (collectionId) => {
    const getSpaceId = async () => {
        const url = `${collectionId}/communities/api/projects?mode=all`;
        // Fusion_Get_Projects
        const responseBody = await fusion(url);
        // Establish success
        const spaceId = getPropValue(responseBody, 'success.body.objects.0.id');

        if (!spaceId) {
            // UNREGISTERED_IDENTITY_USER|INACTIVE_USER|INACTIVE_CORP|... ?
            const errorId =
                getPropValue(responseBody, 'errors.error.id') || '<unknown>';
            // Do not log responseBody, as it may contain PII
            const options = {
                action: `Fusion_Get_Projects: ${errorId}`,
                error: { url },
            };

            trackEvent({
                category: categories.errorDrive,
                action: actions.spaceRemoval,
                label: options,
            });

            throw new DriveError(options);
        }
        return spaceId;
    };

    const listCollaborators = async () => {
        let users = {
            users: [],
            admin: null,
        };
        let hasMore = true;
        let offset = 0;
        let limit = 15;

        // Keeping it consistent with ConnectApi backend response.
        const processUser = (member) => {
            const user = {
                accountId: member.accountId,
                ...member.basicInfo,
            };

            user.userId = user.guid || user.accountId;
            user.emailId = user.primaryEmail;
            return user;
        };

        const processUsers = (data) => {
            let users = getPropValue(data, 'profile.objects');
            if (!users) {
                users = [];
            }
            if (!Array.isArray(users)) {
                users = [users];
            }
            users = users.map((member) => processUser(member));
            const admin = users.filter((profile) => {
                return profile.role === 'ADMIN';
            })[0];
            return {
                users,
                admin,
            };
        };

        const checkHasMore = asyncify(async () => hasMore);
        const getNext = asyncify(async () => {
            const params = `startIndex=${offset}&count=${limit}&sortMode=alphabetical&sortOrder=asc&showInvited=false&showApprovals=false`;
            const url = `${collectionId}/communities/api/projects/${spaceId}/members?${params}`;
            // Fusion_List_Collaborators
            const response = await fusion(url);
            if (response.errors || response.error) {
                throw response;
            }
            const processed = processUsers(response.success.body);

            if (processed.users.length < limit) {
                hasMore = false;
            }
            [].push.apply(users.users, processed.users);
            if (processed.admin) {
                users.admin = processed.admin;
            }
            offset = offset + limit;
        });

        await whilst(checkHasMore, getNext);

        // Filter admin
        const collaborators = users.users.filter(
            (user) => user.guid !== users.admin.guid
        );

        return collaborators;
    };

    const removeCollaborator = async (accountId) => {
        const path = `${collectionId}/communities/api/admin/membership?community=${spaceId}&member=${accountId}`;
        // Fusion_Remove_Collaborator
        let response = await fusion(path, {
            method: 'delete',
        });
        return response;
    };

    const spaceId = await getSpaceId();
    const collaborators = await listCollaborators();
    const promises = collaborators.map((collaborator) =>
        removeCollaborator(collaborator.accountId)
    );

    await Promise.all(promises);

    // Return if we had any collaborators
    return collaborators && collaborators.length > 0;
};
