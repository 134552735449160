<script>
    import config from 'app.config';
    import ModalComponent from '../../elements/modal.svelte';
    import { gEntitled, profile } from '../../../providers/state/user';
    import store from 'store2';
    import { i18n } from '../../../i18n';
    import FolderIcon from '../../../icons/folder.svelte';
    import CloseIcon from '../../../icons/close.svelte';

    const dataCy = 'folder-share-intro';
    const dataCyDismiss = `${dataCy}-dismiss`;
    const localStorageProperty = 'showFolderShareIntro';

    let modalInstance;
    let openModal = false;

    $: if ($profile) {
        const userStorage = store($profile.userId);
        if (
            $gEntitled && // Only if entitled
            userStorage &&
            userStorage.notFirstTimer && // Do not show to new users
            !Object.prototype.hasOwnProperty.call(
                userStorage,
                localStorageProperty
            )
        ) {
            openModal = true;
        }
    }

    const options = {
        onCloseEnd: () => {
            store.add($profile.userId, { [localStorageProperty]: false });
            openModal = false;
        },
    };
</script>

{#if openModal}
    <ModalComponent {dataCy} {openModal} {options} bind:this={modalInstance}>
        <div
            class="columns has-all-space is-vertical-center is-marginless"
            slot="title"
        >
            <div class="column is-paddingless">
                {i18n.t(
                    'App.New_Experience.Folder_Sharing_Feature_Intro_Title'
                )}
            </div>
            <div class="column is-narrow is-paddingless">
                <span
                    class="icon delete-icon is-pulled-right"
                    data-cy={dataCyDismiss}
                    on:click={modalInstance.close}
                >
                    <CloseIcon />
                </span>
            </div>
        </div>
        <div slot="content" class="is-size-6-7">
            <div class="columns">
                <div class="column is-1"><FolderIcon fill="#0696D7" /></div>
                <div class="column">
                    <div>
                        {i18n.t(
                            'App.New_Experience.Folder_Sharing_Feature_Intro_Content'
                        )}
                    </div>
                    <div class="is-gap" />
                    <div>
                        {@html i18n.t(
                            'App.New_Experience.Folder_Sharing_Feature_Intro_Article',
                            {
                                HTML_START:
                                    '<a href="' +
                                    config.links.folderShare +
                                    '" target="_blank">',
                                HTML_END: '</a>',
                            }
                        )}
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer-right-section" class="level-item">
            <button
                class="button is-primary"
                on:click={modalInstance.close}
                data-cy={dataCyDismiss}
            >
                {i18n.t('App.Primary_Buttons.OK_Btn')}
            </button>
        </div>
    </ModalComponent>
{/if}
