<script>
    export let fill = 'white';
    export let height = 20;
    export let width = 20;
</script>

<svg
    {width}
    {height}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.58197 1.30662V15.3066C0.58197 15.8589 1.02969 16.3066 1.58197
        16.3066H7.6033L13.582 10.328L13.582
        5.30662H8.58197V0.306617H1.58197C1.02969 0.306617 0.58197 0.754332
        0.58197 1.30662ZM9.58197 0.306617V4.30662H13.582L9.58197
        0.306617ZM11.1602 17.1623L12.582 15.7405V19.3262H14.582V15.7405L16.0038
        17.1623L17.418 15.7481L13.582 11.9121L9.74594 15.7481L11.1602 17.1623Z"
        {fill}
    />
</svg>
