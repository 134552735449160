<script>
    export let fill = '#666666';
    export let height = 20;
    export let width = 20;
</script>

<svg
    {width}
    {height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.34 3.81641L5.34 2.81641H0.5C0.223858 2.81641 0 3.04026 0
        3.31641V4.81641H5.34L6.34 3.81641ZM16
        12.8164H14V10.8164H12V12.8164H10V14.8164H12V16.8164H14V14.8164H16V12.8164ZM11
        11.8164H9V14.8164H1C0.447715 14.8164 0 14.3687 0 13.8164V5.81641H6L8
        3.81641H13C13.5523 3.81641 14 4.26412 14 4.81641V9.81641H11V11.8164Z"
        {fill}
    />
</svg>
