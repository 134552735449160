<script>
    export let fill = '';
    export let height = 17;
    export let width = 17;
    export let viewBox = '0 0 17 17';
    $: if (!fill) {
        fill = '#4d4d4d';
    }
</script>

<svg {width} {height} {viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.15137 7.26215C8.77137 7.26215 10.0114 5.47215 10.0114 3.85215C10.0529 2.2066 8.79328
        0.8192 7.15137 0.702148C6.37246 0.717876 5.63188 1.04316 5.09333 1.60611C4.55478 2.16906
        4.2626 2.92331 4.28137 3.70215V3.88215C4.29137 5.84215 5.53137 7.26215 7.15137 7.26215ZM11.6514 
        9.26215C9.71837 9.26215 8.15137 10.8292 8.15137 12.7621C8.15137 14.6951 9.71837 16.2621 11.6514 
        16.2621C13.5844 16.2621 15.1514 14.6951 15.1514 12.7621C15.1514 10.8292 13.5844 9.26215 11.6514 
        9.26215ZM9.90137 8.35215C8.09435 9.07124 6.90655 10.8173 6.90137 12.7621C6.90439 14.0987 7.47513 
        15.3711 8.47137 16.2621H2.15137V12.4521C2.22137 9.10215 4.54137 7.86215 6.87137 7.76215H7.52137C8.34863 
        7.77992 9.16159 7.98145 9.90137 8.35215ZM9.15137 13.2621V12.2621H14.1514V13.2621H9.15137Z"
        {fill}
    />
</svg>
