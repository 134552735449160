<script>
    export let fill = '#4d4d4d';
    export let height = 16;
    export let width = 16;
</script>

<svg
    {width}
    {height}
    viewBox="-8 -8 {width}
    {height}"
    style="background-color:#ffffff00"
    x="-8"
    y="-8"
>
    <path
        d="M-8-5V8H2V0h-5v-5h-5zm6 0v4h4l-4-4zm0-3v2h1l4 4v7h5v-8H3v-5h-5zm6
        0v4h4L4-8z"
        {fill}
    />
</svg>
