<script>
    export let fill = '#4d4d4d';
    export let height = 17;
    export let width = 17;
</script>

<svg
    {width}
    {height}
    viewBox="0 0 {width}
    {height}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.84924 9.01093L12.3892 6.47093L13.8092 7.88093L8.85924
        12.8309L3.90924 7.88093L5.31924 6.47093L7.84924
        8.99093V0.830933H9.84924V9.01093ZM14.8492
        14.8309V11.8309H16.8492V16.8309H0.849243V11.8309H2.84924V14.8309H14.8492Z"
        {fill}
    />
</svg>
