<script>
    export let options = {};
    export let id = `modal-${randomHex()}`;
    export let openModal = true;
    export let dataCy = '';
    export let condensedHeader = false;
    export let headerModifier = '';
    export let bodyModifier = '';
    export let footerModifier = '';
    export let hasMinHeight = false;
    export let hideGap = false; // should we hide the gap div below the modal body?
    export let loadingState = false; // is this modal currently loading content?
    export let hasMaxHeight = false;

    import { randomHex } from '../../providers/utils';
    import { manageDialogEvents } from './document.event.listeners.js';
    import { onMount, onDestroy } from 'svelte';
    import Spinner from '../spinner.svelte';

    $: {
        if (openModal && modalInstance) {
            open();
        }
    }

    let modalInstance = null; // holds both the background and card

    export const open = () => {
        if (options.onOpenStart) {
            options.onOpenStart();
        }
        openModal = true;
        if (options.onOpenEnd) {
            options.onOpenEnd();
        }
        const input = modalInstance.querySelector('.is-focus');
        if (input) {
            setTimeout(() => input.focus(), 100);
        }
    };

    export const close = () => {
        if (options.onCloseStart) {
            options.onCloseStart();
        }
        openModal = false;
        if (options.onCloseEnd) {
            options.onCloseEnd();
        }
    };

    onMount(() => {
        // Before, we had on:click|preventDefault, but that was missed when closing the modal.
        // The following works. Eat mouse events too, so the selection code does not kick in.
        // Listeners should be automatically cleaned up in onDestroy
        ['contextmenu', 'click', 'dblclick', 'mousedown', 'mouseup'].forEach(
            (type) =>
                modalInstance.addEventListener(type, (event) =>
                    event.stopPropagation()
                )
        );
    });

    // See also: app/components/elements/dropdown.svelte
    $: manageDialogEvents('openModal', openModal, modalInstance, close);

    onDestroy(() => {
        manageDialogEvents(
            `onDestroy: openModal: ${openModal}`,
            false, // Stop capturing
            modalInstance,
            close
        );
    });
</script>

<div data-target={id} class="modal-trigger" on:click|stopPropagation={open}>
    <slot name="trigger" />
</div>

<div
    class="modal has-overflow"
    class:is-active={openModal}
    data-cy={dataCy}
    bind:this={modalInstance}
>
    <div class="modal-background" on:click={close} />
    <div
        class="modal-card box is-paddingless is-marginless fadeInDown max-width"
        class:min-height={hasMinHeight}
        class:max-height={hasMaxHeight}
    >
        <header
            class="modal-card-head is-size-6 is-vertical-center has-text-weight-bold {headerModifier}"
            data-cy="modal-header"
            class:is-paddingless={condensedHeader}
        >
            <slot name="title" />
        </header>
        {#if !loadingState}
            <section
                class="modal-card-body has-text-left {bodyModifier}"
                class:max-height={hasMaxHeight}
                data-cy="modal-content"
            >
                <slot name="content" />
                <div class="is-gap" class:is-hidden={hideGap} />
            </section>
            {#if $$slots.content2}
                <section
                    class="modal-card-body has-text-left {bodyModifier}"
                    data-cy="modal-content2"
                >
                    <slot name="content2" />
                </section>
            {/if}
        {:else}
            <section class="modal-card-body" data-cy="modal-content">
                <div class="has-centered-content content p-6 m-2">
                    <Spinner name="modal-loading-spinner" size="is-large" />
                </div>
            </section>
        {/if}
        <footer class="modal-card-foot {footerModifier}" data-cy="modal-footer">
            <div class="columns container">
                <div class="column left-section is-narrow-tablet has-text-left">
                    <slot name="footer-left-section" />
                </div>
                <div class="column is-hidden-mobile" />
                <div
                    class="column right-section is-narrow-tablet has-text-right modal-button-section"
                >
                    <slot name="footer-right-section" />
                </div>
            </div>
            <slot name="footer" />
        </footer>
    </div>
    {#if options.showInfoPanel}
        <div
            data-cy="modal-info-panel"
            class="box fadeInDown max-width modal-card mt-3"
        >
            <slot name="info-content" />
        </div>
    {/if}
</div>

<style>
    .modal-card-head {
        text-align: left;
    }
    .left-section,
    .right-section {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .modal-card.min-height {
        min-height: 300px;
    }
    .modal-card .info-panel {
        margin-top: 6px;
    }
    .modal-button-section {
        float: right;
    }
    .modal-card.max-height {
        max-height: 60%;
        overflow-y: auto;
    }
</style>
