<script>
    export let position = 'left';
    export let isWide = false;
    export let dropdownContentOffset = false;
    export let isDisabled = false;
    export let isHiddenOnScroll = false;
    export let openDropdown = false;
    export let dataCyDropdown = '';
    export let hasCustomWidth = false;

    import { manageDialogEvents } from './document.event.listeners.js';
    import { scrollOutside } from './scroll.outside.js';
    import { onDestroy } from 'svelte';

    let dropdown, dropdownMenu;
    export const closeDropdown = () => {
        // Avoid any unnecessary reactions
        if (openDropdown) {
            // Clear any inline styles added
            dropdownMenu.style.display = null;
            dropdownMenu.style.top = null;
            dropdownMenu.style.left = null;

            openDropdown = false;
        }
    };

    const toggleVisibility = () => {
        openDropdown = !openDropdown;
    };

    const handleScrollOutside = () => {
        if (isHiddenOnScroll) {
            openDropdown = false;
        }
    };

    // See also: app/components/elements/modal.svelte
    $: manageDialogEvents(
        'openDropdown',
        openDropdown,
        dropdown,
        closeDropdown
    );

    onDestroy(() => {
        manageDialogEvents(
            `onDestroy: openDropdown: ${openDropdown}`,
            false, // Stop capturing
            dropdown,
            closeDropdown
        );
    });
</script>

<div
    class="dropdown is-flex is-{position}"
    class:is-active={openDropdown}
    bind:this={dropdown}
    use:scrollOutside={handleScrollOutside}
    data-cy={dataCyDropdown}
>
    <div
        class="dropdown-trigger"
        class:disabled-click={isDisabled}
        on:click|stopPropagation={toggleVisibility}
        on:dblclick|stopPropagation
    >
        <slot name="trigger" />
    </div>
    <div
        class="dropdown-menu"
        role="menu"
        class:fadeIn={openDropdown}
        class:fadeOut={!openDropdown}
        class:wide-dropdown-menu={isWide}
        class:dropdown-content-offset={dropdownContentOffset}
        class:is-hidden-on-scroll={isHiddenOnScroll}
        class:custom-width={hasCustomWidth}
        bind:this={dropdownMenu}
        data-cy="dropdown-menu"
        on:contextmenu|stopPropagation
    >
        <div class="dropdown-content" on:click={closeDropdown}>
            <slot name="content" />
        </div>
    </div>
</div>

<style>
    .dropdown-trigger {
        width: 100%;
    }
    .wide-dropdown-menu {
        width: 17rem;
    }
    .dropdown-content-offset {
        padding-top: 1rem;
    }
    .is-hidden-on-scroll {
        position: fixed !important;
        z-index: 20;
    }
    .dropdown-menu.custom-width {
        width: 15rem;
        padding-top: 0;
    }
</style>
