<script>
    export let viewable = null;
    export let file = null;

    import config from 'app.config';
    import { safeJSONParse } from '../../providers/utils';
    import { getOAuth2ThreeLeggedToken } from '../../rest.sdks/request';
    import {
        trackEvent,
        categories,
        actions,
    } from '../../providers/analytics/ga';
    import { onMount, onDestroy } from 'svelte';

    const rcpEnv = config.env === 'production' ? 'prd' : 'stg';
    const tokenExpiry = 3600; // 1 hour
    const src = `${config.servers.recapViewer}/viewer/latest/index.html?file=${file.id}&env=${rcpEnv}&src=drive`;
    let rcpIfr;

    function sendMessage(msg) {
        if (rcpIfr) {
            rcpIfr.contentWindow.postMessage(msg, config.servers.recapViewer);
        }
    }

    const _messageListener = async (event) => {
        if (event.origin !== config.servers.recapViewer) {
            return false;
        }

        let msg = safeJSONParse(event.data);
        if (msg.request == 'startupToken') {
            const token = await getOAuth2ThreeLeggedToken();
            sendMessage(
                JSON.stringify({
                    accesstoken: token,
                    tokenexpiry: tokenExpiry,
                })
            );
        } else if (msg.request == 'refreshToken') {
            const token = await getOAuth2ThreeLeggedToken();
            // Existing token, tokenexpiry is in seconds
            // Recap viewer will send this when the token is expiring with less than 60 seconds left before expiration
            sendMessage(
                JSON.stringify({
                    accesstoken: token,
                    tokenexpiry: tokenExpiry,
                })
            );
        } else {
            trackEvent({
                category: categories.viewing,
                action: actions.viewingError,
                label: `Recap Message not recognized, original message payload: ${event.data}`,
            });
        }
    };

    const _params = ['message', _messageListener];

    onMount(() => {
        rcpIfr = document.getElementById('recap-ifr');
        window.addEventListener(..._params);
    });

    onDestroy(() => {
        window.removeEventListener(..._params);
    });
</script>

<div class="viewer section has-centered-content">
    <iframe
        id="recap-ifr"
        class="viewer-iframe has-all-space is-borderless"
        title={viewable.name}
        data-cy="rcp-viewer"
        {src}
    />
</div>
