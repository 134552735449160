<script>
    import {
        setTrigger,
        uservoiceLoaded,
    } from '../providers/analytics/uservoice';
    import { authenticated } from '../providers/state/user';
    import { onMount } from 'svelte';

    const id = 'uservoice-feedback-trigger';

    let triggerElement;

    onMount(() => {
        setTrigger(triggerElement);
    });
</script>

<div
    class="uservoice-trigger has-pointer-cursor has-background-black"
    {id}
    bind:this={triggerElement}
    data-cy="uservoice-feedback"
    class:is-hidden={!$uservoiceLoaded || !$authenticated}
>
    <img alt="uv" src="DRIVE_ASSETS/images/uservoice.svg" />
</div>

<style>
    .uservoice-trigger {
        right: 4px;
        bottom: 50px;
        padding: 6px 8px 0px 6px;
        position: fixed;
        border-radius: 3px;
        z-index: 9999;
    }
</style>
