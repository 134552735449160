<script>
    export let folder;

    import DropDownComponent from './elements/dropdown.svelte';
    import FileIcon from '../icons/file.svelte';
    import FolderIcon from '../icons/folder.svelte';
    import MoreIcon from '../icons/more.svelte';
    import { droparea } from './upload/files.drag.drop';
    import { goto, goToShared, goToHome } from '../providers/navigation';
    import { gDriveInfo } from '../providers/state/user';

    const dataCy = 'breadcrumb';
    const dataCyDropdown = 'breadcrumb-dropdown';
    const dataCyExpand = `${dataCy}-expand`;

    let hierarchy = [];

    const constructHierarchy = () => {
        let hierarchy = [];
        if (!folder) {
            return hierarchy;
        }

        const path = [...folder.hierarchy, folder];
        const size = path.length;
        let ops = path.map((node, i) => {
            const onClick =
                i === 0
                    ? node.isPrivateShared
                        ? goToShared
                        : goToHome
                    : gotoFolder(node);
            const notLast = i !== size - 1;
            return {
                name: node.itemInfo.name,
                fileType: node.itemInfo.fileType,
                canEdit: node.canEdit,
                canDrop: notLast,
                onClick,
                hasNext: notLast,
                onFilesDrop: filesDrop(node),
            };
        });
        hierarchy.push.apply(hierarchy, ops);
        const middle = hierarchy.slice(1, -1);
        if (middle.length > 1) {
            hierarchy = [
                hierarchy[0],
                {
                    middle: true,
                    children: middle,
                },
                hierarchy[hierarchy.length - 1],
            ];
        }
        return hierarchy;
    };

    $: if ($gDriveInfo && folder) {
        hierarchy = constructHierarchy();
    }

    const gotoFolder = (node) => {
        return () => {
            goto(`/folders/${node.id}`);
        };
    };

    const dropArea = (node, canEdit) => {
        return canEdit ? droparea(node) : {};
    };

    const filesDrop = (targetFolder) => {
        return (event) => {
            targetFolder.uploads.upload(event.detail);
        };
    };
</script>

<nav class="breadcrumb has-no-separator is-marginless" data-cy={dataCy}>
    <ul>
        {#each hierarchy as node}
            {#if node.middle}
                <li>
                    <DropDownComponent isWide="true" {dataCyDropdown}>
                        <button
                            slot="trigger"
                            class="ga-{dataCyExpand} button is-white is-paddingless"
                            data-cy={dataCyExpand}
                        >
                            <span class="icon">
                                <MoreIcon fill="#666666" mode="horizontal" />
                            </span>
                        </button>
                        <div class="section" slot="content">
                            {#each node.children as dnode, index}
                                <button
                                    class="dropdown-item is-fullwidth button
                                            is-white is-leftaligned
                                            is-shadowless"
                                    data-cy="{dataCyExpand}-{index + 1}"
                                    use:dropArea={dnode.canEdit}
                                    on:files={dnode.onFilesDrop}
                                    on:click={dnode.onClick}
                                >
                                    <span class="icon">
                                        <FolderIcon />
                                    </span>
                                    <span class="has-ellipsis">
                                        {dnode.name}
                                    </span>
                                </button>
                            {/each}
                        </div>
                    </DropDownComponent>
                </li>
                <li>
                    <span class="icon"> <span class="arrow right" /> </span>
                </li>
            {:else}
                <li class:is-active={!node.hasNext}>
                    <div class="is-not-middle">
                        {#if !node.hasNext && node.fileType}
                            {#if node.fileType}
                                <span class="icon">
                                    <FileIcon type={node.fileType} />
                                </span>
                            {/if}
                        {/if}
                        {#if node.canDrop}
                            <span
                                href="javascript:;"
                                use:dropArea={node.canEdit}
                                on:files={node.onFilesDrop}
                                on:click={node.onClick}
                            >
                                {node.name}
                            </span>
                        {:else}
                            <span on:click={node.onClick} href="javascript:;">
                                {node.name}
                            </span>
                        {/if}
                    </div>
                </li>
                {#if node.hasNext}
                    <li>
                        <span class="icon">
                            <span class="arrow right" />
                        </span>
                    </li>
                {/if}
            {/if}
        {/each}
    </ul>
</nav>

<style>
    .dropdown-item {
        padding-right: 1rem;
    }

    /* DropDownComponent is taller than sibilings
        https://jira.autodesk.com/browse/DRIVE-1208 */
    .button {
        height: auto;
    }
</style>
