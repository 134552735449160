<script>
    // Component DRYing up shared code used in the title slot modals that operate on assets (i.e. filesystem nodes).
    export let itemName,
        modalInstance,
        dataCy = null;

    import CloseIcon from '../../icons/close.svelte';
    import FileIcon from '../../icons/file.svelte';
</script>

<div
    class="columns has-all-space is-3-mobile is-1-tablet is-marginless is-variable is-vertical-center is-mobile"
>
    <div class="column is-narrow is-paddingless is-vertical-center">
        <span class="icon">
            <slot name="icon">
                <FileIcon />
            </slot>
        </span>
    </div>
    <div class="column is-paddingless has-ellipsis">
        <abbr
            data-cy={`${dataCy}-item-name`}
            class="folder-title ellipsed-asset-name"
            title={itemName}
        >
            {itemName}
        </abbr>
    </div>
    <div class="column is-1 is-paddingless">
        <span
            class="icon delete-icon is-pulled-right ml-2 mr-0"
            data-cy={`${dataCy}-close-cross`}
            on:click={modalInstance.close}
        >
            <CloseIcon />
        </span>
    </div>
</div>
