<script>
    export let fill = '#808080';
    export let height = 16;
    export let width = 16;
</script>

<svg
    {width}
    {height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M5.10915 11L0 6.2491L1.58384 4.6706L5.08077 7.9013L13.0522 0L14.6667 1.5499L5.10915 11Z"
        {fill}
    />
</svg>
