import { fusion } from '../request';
import { waitForJob } from '../jobs';
import { getExtension } from '../utils.js';
import { randomHex } from '../../providers/utils';

const UploadFactory = (file, parentFolder, collectionId) => {
    if (!Array.isArray(file)) {
        file = [file];
    }

    const state = {
        uploadType: 'dropbox',
        file,
        cancelled: false,
        failed: false,
        progress: 0,
        result: null,
        success: false,
        saving: false,
        uploading: false,
        jobId: null,
        startTime: Date.now(),
        get inprogress() {
            return this.uploading || this.saving;
        },
        set inprogress(val) {
            this.uploading = val;
            this.failed = !val;
            this.cancelled = !val;
        },
        parentFolder,
        collectionId,
        get childUploads() {
            return this.file.map((item) => ({
                // ...this,
                name: item.name,
                size: item.bytes,
                fileType: getExtension(item.name),
                path: item.name,
                file: item,
                parentFolder,
            }));
        },
        size: Object.values(file).reduce((total, b) => total + b.bytes, 0),
    };

    const onSuccess = {};
    const onFailed = {};
    const onProgress = {};
    const onStarted = {};

    const triggerSuccess = () => {
        for (let k in onSuccess) {
            onSuccess[k](state.result);
        }
        triggerProgress();
    };
    const triggerFailed = () => {
        for (let k in onFailed) {
            onFailed[k]();
        }
        triggerProgress();
    };
    const triggerProgress = () => {
        for (let k in onProgress) {
            onProgress[k](state);
        }
    };
    const triggerStarted = () => {
        state.inprogress = true;
        for (let k in onStarted) {
            onStarted[k]();
        }
        triggerProgress();
    };

    const saveFile = async function () {
        if (state.cancelled) return state;
        state.uploading = true;
        const payload = {
            source: 'URL',
            target: state.parentFolder.idDecoded,
            files: state.file.map((item) => {
                return {
                    urn: item.urn || item.link,
                    metadata: {
                        fileName: item.name,
                    },
                };
            }),
        };
        // Fusion_Dropbox_Import
        const response = await fusion(`${state.collectionId}/data/api/import`, {
            method: 'post',
            body: JSON.stringify(payload),
        });
        state.jobId = response.success.body.id;
        triggerProgress();
    };

    const markSuccess = () => {
        state.success = true;
        triggerSuccess();
    };

    const waitForImport = async () => {
        state.uploading = false;
        state.saving = true;
        triggerProgress();
        const resp = await waitForJob(
            state.jobId,
            collectionId,
            (percentComplete) => {
                state.progress = Math.floor(percentComplete);
                triggerProgress();
            }
        );
        state.saving = false;
        state.result = resp;
    };

    const save = async () => {
        await saveFile();
        await waitForImport();
        markSuccess();
        return state.result;
    };

    const markFailed = () => {
        state.failed = true;
        triggerFailed();
    };

    const api = {
        uploadType: 'dropbox',
        path: state.file[0].name,
        info() {
            return state.childUploads;
        },
        start: async () => {
            try {
                triggerStarted();
                await save();
                return state.result;
            } catch (ex) {
                markFailed();
                throw ex;
            }
        },
        observe({ success, failed, progress, start }) {
            const key = randomHex();
            if (success) {
                onSuccess[key] = success;
            }
            if (failed) {
                onFailed[key] = failed;
            }
            if (progress) {
                onProgress[key] = progress;
                triggerProgress();
            }
            if (start) {
                onStarted[key] = start;
            }
            return {
                cancel() {
                    delete onSuccess[key];
                    delete onFailed[key];
                    delete onProgress[key];
                    delete onStarted[key];
                },
            };
        },
    };

    return api;
};

export default UploadFactory;
