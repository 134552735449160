<script>
    export let fill = 'rgb(128, 128, 128)';
    export let height = 16;
    export let width = 16;
</script>

<svg {width} {height} {fill} viewBox="0 0 {width} {height}" class="scale">
    <path
        d="M5.34 2L6.34 3L5.34 4H0V2H5.34ZM9 11H11V9H14V3H8L6 5H0V14H9V11ZM10 12V14H12V16H14V14H16V12H14V10H12V12H10Z"
        {fill}
    />
</svg>

<style>
    .scale {
        transform: scale(1.2);
    }
</style>
