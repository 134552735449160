<script>
    export let fill = '#4d4d4d';
    export let height = 17;
    export let width = 15;
</script>

<svg
    {width}
    {height}
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.00005 5.78462L9.71005 2.07462C11.077 0.707712 13.2931 0.707712 14.66 2.07462C16.027 3.44152 16.027 5.65771 14.66 7.02462L11.94 9.72462C11.9873 8.91717 11.8255 8.11118 11.47 7.38462L13.25 5.60462C13.5334 5.32468 13.6929 4.94294 13.6929 4.54462C13.6929 4.14629 13.5334 3.76455 13.25 3.48462C12.6622 2.90516 11.7179 2.90516 11.13 3.48462L7.42005 7.19462C6.84059 7.7825 6.84059 8.72674 7.42005 9.31462C7.51033 9.41382 7.51033 9.56542 7.42005 9.66462L6.19005 10.9446L6.00005 10.7846C5.32562 10.1277 4.94523 9.22612 4.94523 8.28462C4.94523 7.34312 5.32562 6.44156 6.00005 5.78462ZM9.88005 11.7946L6.17005 15.5046H6.22005C4.85314 16.8853 2.62576 16.8965 1.24505 15.5296C-0.135663 14.1627 -0.146855 11.9353 1.22005 10.5546L3.94005 7.84462C3.89102 8.6554 4.05295 9.46506 4.41005 10.1946L2.64005 11.9746C2.35668 12.2546 2.19719 12.6363 2.19719 13.0346C2.19719 13.4329 2.35668 13.8147 2.64005 14.0946C3.22793 14.6741 4.17217 14.6741 4.76005 14.0946L8.46005 10.3846C9.03951 9.79674 9.03951 8.8525 8.46005 8.26462C8.36977 8.16542 8.36977 8.01382 8.46005 7.91462L9.69005 6.72462L9.88005 6.84462C10.539 7.4997 10.9095 8.39048 10.9095 9.31962C10.9095 10.2488 10.539 11.1395 9.88005 11.7946Z"
        {fill}
    />
</svg>
