<script>
    import { i18n } from '../../i18n';
    export let message = i18n.t('App.Folder_Content_List.Upload_Files_Msg');
</script>

<div class="section has-centered-content mt-4" data-cy="empty-folder">
    <svg
        width="212"
        height="135"
        viewBox="0 0 212 135"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.3"
            d="M104.4 123.338L32.0401 133.998L25.7101 118.598L91.1601
            56.6484L103.15 79.1284L104.4 123.338Z"
            fill="#5A7D91"
        />
        <path
            opacity="0.3"
            d="M150.16 130.338L121.97 133.498L149.77 59.168L211.6 94.868L150.16
            130.338Z"
            fill="#5A7D91"
        />
        <path
            d="M121.65 4.32031L178.72 37.4803V53.8803L121.65 87.0003V4.32031Z"
            fill="#E7F0F5"
        />
        <path
            d="M64.2001 37.2703L121.65 4.32031V87.2503L64.2001 53.8803V37.2703Z"
            fill="#CCD9E0"
        />
        <path
            d="M121.65 42.9597L147.34 57.9497L155.36 53.3097L121.65
            33.6797L87.5601 53.1897L95.6101 57.8397L121.65 42.9597Z"
            fill="white"
        />
        <path
            d="M105.92 63.0689L121.65 54.1289L137 63.1289L121.65 72.0689L105.92
            63.0689Z"
            fill="white"
        />
        <path
            d="M59.3201 37.7578L63.6701 38.7778L130.5 76.7478V120.998L121.65
            132.768L59.3201 96.9578V37.7578Z"
            fill="#E7F0F5"
        />
        <path
            d="M103.15 79.1284L91.1601 56.6484L59.3201 86.7784V96.9584L104.38
            122.848L103.15 79.1284Z"
            fill="#CCD9E1"
        />
        <path
            d="M183.58 37.9805L145.65 57.8505L121.65 73.7405V132.77L183.58
            96.9605V37.9805Z"
            fill="#CCD9E1"
        />
        <path
            d="M121.65 73.7383V132.768"
            stroke="#8CA9B8"
            stroke-width="0.66"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M126.96 36.8097L121.65 33.6797L87.5601 53.1897L93.0801
            56.3697L126.96 36.8097Z"
            fill="#CCD9E0"
        />
        <path
            d="M126.96 36.8103L121.65 33.6803V4.32031L126.96 36.8103Z"
            fill="#CCD9E0"
        />
        <path
            d="M123.55 55.3128L121.65 54.1328L105.92 63.0728L108.02
            64.2828L123.55 55.3128Z"
            fill="#CCD9E0"
        />
        <path
            d="M123.55 55.3109L121.65 54.1309V42.9609L123.55 55.3109Z"
            fill="#CCD9E0"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M121.65 42.9597L147.34 57.9497L155.36 53.3097L121.65
            33.6797L87.5601 53.1897L95.6101 57.8397L121.65 42.9597V42.9597Z"
            stroke="#8CA9B8"
            stroke-width="0.66"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M105.92 63.0689L121.65 54.1289L137 63.1289L121.65 72.0689L105.92
            63.0689V63.0689Z"
            stroke="#8CA9B8"
            stroke-width="0.66"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M121.65 33.8003V4.32031"
            stroke="#8CA9B8"
            stroke-width="0.66"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M121.65 54.2986V43.0586"
            stroke="#8CA9B8"
            stroke-width="0.66"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M59.3201 37.76L121.65 2L183.58 38L121.65 73.76L59.3201
            37.76ZM65.1901 37.27L121.65 69.87L177.74 37.48L121.64 4.89L65.1901
            37.27Z"
            fill="white"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M121.64 69.8484L65.2201 37.2684L121.64 4.89844L177.71
            37.4784L121.64 69.8484Z"
            stroke="#8CA9B8"
            stroke-width="0.66"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M59.3201 37.7617L121.65 73.7617L183.58 38.0017"
            stroke="#8CA9B8"
            stroke-width="0.66"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M121.02 0.919062L121.65 0.559062V0.539062L122.27 0.899063L184.21
            36.8991L184.83 37.2591V97.6391L184.21 97.9991L122.25 133.859L121.63
            134.219L121 133.859L58.7001 98.0391L58.0701 97.6791V37.0291L58.7001
            36.6691L121.02 0.919062ZM183.58 37.9991L121.65 1.99906L59.3201
            37.7591V96.9991L121.65 132.809L183.58 96.9991V37.9991Z"
            fill="#5A7D91"
        />
        <path
            d="M40.29 131.417L93.87 61.0266L80.74 48.4766L30.21 111.877L28.25
            126.017L40.29 131.417Z"
            fill="#CCD9E0"
        />
        <path
            d="M68.0201 9.5875L55.9801 4.1875L63.3801 35.1775L81.8201
            55.6275L93.8701 61.0275L68.0201 9.5875Z"
            fill="white"
        />
        <path
            d="M2.40002 74.5775L55.98 4.1875L81.82 55.6275L28.25 126.018L2.40002
            74.5775Z"
            fill="white"
        />
        <path
            d="M55.98 4.1875L81.82 55.6275L28.25 126.018"
            stroke="#8CA9B8"
            stroke-width="0.66"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M81.8201 55.6289L93.8701 61.0289"
            stroke="#8CA9B8"
            stroke-width="0.66"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M55.0001 3.42719L55.6001 2.63719L55.6201 2.61719L56.5301
            3.02719L68.5301 8.41719L68.9401 8.60719L69.1401 8.99719L94.9501
            60.4672L95.3001 61.1572L94.8201 61.7772L41.2501 132.167L40.6501
            132.957L39.7401 132.557L27.7401 127.157L27.3301 126.977L27.1301
            126.577L1.28005 75.1372L0.930054 74.4372L1.41005 73.8172L55.0001
            3.42719ZM68.0001 9.58719L56.0001 4.18719L2.40005 74.5772L28.2901
            126.017L40.2901 131.417L93.8701 60.9972L68.0001 9.58719Z"
            fill="#5A7D91"
        />
        <path
            d="M51.92 67.9992L57.67 60.5592L40.19 52.3392C39.3331 51.9376
            38.3135 52.1587 37.7 52.8792L31.7 59.9392L32.64 59.6192C33.1198
            59.4464 33.6487 59.4716 34.11 59.6892L51.92 67.9992Z"
            fill="#5A7D91"
        />
        <path
            d="M46.43 67L40.68 83.27C40.2032 84.7197 38.7141 85.5804 37.22
            85.27L33.22 84.34L33.85 84.02C34.2524 83.8071 34.596 83.4979 34.85
            83.12L46.43 67Z"
            fill="#5A7D91"
        />
        <path
            d="M37.69 77.0788C37.6977 77.3535 37.6093 77.6223 37.44
            77.8388L33.54 82.8988C33.3448 83.1539 33.0116 83.261 32.7043
            83.1675C32.397 83.0739 32.18 82.7994 32.16 82.4788L31.39
            62.4088C31.3687 61.8642 31.6313 61.3475 32.0839 61.0437C32.5364
            60.74 33.1141 60.6927 33.61 60.9188L37.19 62.5388L37.69 77.0788Z"
            fill="#5A7D91"
        />
    </svg>
    <div class="is-gap" />
    <div class="subtitle is-6">{message}</div>
</div>
