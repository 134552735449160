import {
    slice,
    getSliceRanges,
    fileName,
    isS3SignedUrlExpired,
    maxUploadChunkConcurrency,
    defaultS3LinkExpiry,
    getExpiryInMinutes,
} from './upload.utils';
import asyncify from 'async/asyncify';
import each from 'async/each';
import queue from 'async/queue';
import { drive, drive2, s3Upload } from '../request';
import memoizer from '../memoizer';
import { getExtension } from '../utils.js';
import { getPropValue, randomHex } from '../../providers/utils';
import { FolderFactory } from '..';
import { trackUploadInfo } from '../../providers/analytics/ga';
import { actions, categories, trackEvent } from '../../providers/analytics/ga';

const methodsS3Upload = memoizer.newGroup('s3.upload');

export const resetCreateFolderCache = () => {
    methodsS3Upload.reset();
};

const getCreateFolderByPath = methodsS3Upload.memoize(
    async (fullPath, url, options) => {
        fullPath; // cacheKey is fullPath
        return drive(url, options);
    }
);

// DriveMS does not automatically create intermediate folders, like Fusion Team does, so
// do that serially upfront so the remaining steps can complete.
const createParentFolders = asyncify(async (state) => {
    try {
        let filePath = state.file.name.split('/');
        if (filePath.length > 1) {
            filePath.pop(); // drop filename

            // Now get the path so far to our parent
            let folderPathHere = [];
            for (const folder of state.parentFolder.hierarchy) {
                folderPathHere.push(folder.itemInfo.name);
            }

            // Be sure to add the target directory
            folderPathHere.push(state.parentFolder.itemInfo.name);

            // And drop the root (Drive Project / group-nnnnnnnn)
            folderPathHere.shift();

            const fullPath = folderPathHere.concat(filePath).join('/');

            // Create the necessary folder structure
            const payload = {
                data: {
                    type: 'FolderGetCreateByPathRequestInfo',
                    attributes: {
                        folderPath: fullPath,
                    },
                },
            };
            const options = {
                method: 'post',
                body: JSON.stringify(payload),
            };
            const url = `collections/${state.collectionId}/folders:bypath`;

            // Drive_Get_Create_Folder_by_Path cache
            const response = await getCreateFolderByPath(
                fullPath,
                url,
                options
            );

            const id = getPropValue(response, 'data.id');
            // Update our parentFolder prior to steps 1/3-3/3
            state.parentFolder = FolderFactory(id);
        }
    } catch (error) {
        trackUploadInfo('createParentFolders', error);

        state.failed = true;
        state.error = error; // Provide exception outside of this queue
    }
});

const getS3SignedUrls = async (
    state,
    firstPart,
    uploadParts = 1,
    isRetry = false
) => {
    // Exponential retry
    const linkExpiry = isRetry ? defaultS3LinkExpiry * 2 : defaultS3LinkExpiry;
    const url = `collections/${state.collectionId}/files/${state.storageId}/upload:signed`;
    const params = `uploadKey=${
        state.uploadKey
    }&firstPart=${firstPart}&uploadParts=${uploadParts}&minutesExpiration=${getExpiryInMinutes(
        linkExpiry
    )}`;

    // Drive2_Get_Signed_Upload_URLs
    const response = await drive2(`${url}?${params}`);
    return response;
};

const directS3Upload = async (state, uploadPart, body, isRetry = false) => {
    let response;
    // For first part we already have a signed url from response of Drive2_Upload_File_Start
    // Try to reuse it, but for retry always fetch fresh url
    if (uploadPart === 1 && !isRetry) {
        response = state.tokenObj;
        const existingSignedUrl = getPropValue(
            response,
            'data.attributes.signedUrls'
        )[0];

        // For slower connections (with concurrent file uploads)
        // url received from Drive2_Upload_File_Start might be expired, check expiry here
        if (!existingSignedUrl || isS3SignedUrlExpired(existingSignedUrl)) {
            response = await getS3SignedUrls(state, uploadPart);
        }
    } else {
        // Get s3 signed url on the fly, if we get in advance it adds more complexity
        // and we also need to worry about link expiry and file concurrency issues.
        // Not seeing any performance impact, because of getting url on the fly
        // https://wiki.autodesk.com/display/FRPA/Upload+Performance+Testing%3A+OSS+vs+Direct+S3
        response = await getS3SignedUrls(state, uploadPart, 1, isRetry);
    }

    const { signedUrls, mimeType } = getPropValue(response, 'data.attributes');
    const url = signedUrls[0];

    // S3_Put_Upload
    return s3Upload(
        url,
        {
            method: 'put',
            headers: {
                'Content-Type': mimeType,
            },
            body,
        },
        null,
        true // skip response
    );
};

// Adjust concurrency based on time taken by first part, mostly for slower connections
// for regular speeds concurrency should be at max i.e. 6
const adjustConcurrency = (startTime) => {
    // Add 10% buffer to get most accurate concurrency
    const _time = ((Date.now() - startTime) * 1.1) / uploadQueue.concurrency;
    // Check how many chunks can be uploaded at a time for user's speed
    // without hitting timeout from S3
    const concurrency =
        Math.floor(
            Math.min(defaultS3LinkExpiry / _time, maxUploadChunkConcurrency)
        ) || 1; // minimum 1

    uploadQueue.concurrency = concurrency;
};

// Upload Step 3/4
const uploadChunk = asyncify(async (task) => {
    const state = task.state;
    const range = task.range;
    const isFirstPart = range.uploadPart === 1;
    const logRetry = (error, retrySuccess = true) => {
        const label = { api: 'S3', retrySuccess };
        trackEvent({
            category: categories.warnDrive,
            action: actions.s3Retry,
            label,
            detail: error,
        });
    };

    // Don't bother continuing if there was an error
    if (state.failed) {
        return false;
    }

    // Don't bother continuing if user cancelled the upload
    if (state.cancelled) {
        return false;
    }

    // If file upload timer not started, start it
    if (isFirstPart) {
        state.startTime = Date.now();
    }

    const file = state.file;
    const body = slice(file, range.start, range.end);

    try {
        range.startTime = Date.now();
        await directS3Upload(state, range.uploadPart, body);
    } catch (error) {
        // Adjust concurrency if we're seeing failures
        // Mostly because of timeout for slower connections
        adjustConcurrency(range.startTime);

        // Retry once
        await retryUploadQueue.push({
            state,
            range,
            body,
        });

        // Log retry success/failure
        if (range.failed) {
            // If retry failed, log error from last attempt
            logRetry(state.error, false);
            return;
        } else {
            // If retry succeeded, log error from first attempt
            logRetry(error);
        }
    }

    if (isFirstPart) {
        adjustConcurrency(state.startTime);
    }
    range.success = true;
    range.inprogress = false;
});

// Start with one at a time and based on user's speed gradually increase
const uploadQueue = queue(uploadChunk, 1);

const retryChunkUpload = asyncify(async (task) => {
    const { state, range, body } = task;
    try {
        // TODO: retry with exponential timeout
        await directS3Upload(state, range.uploadPart, body, true);
    } catch (error) {
        // Mark it as failed after retry once
        range.failed = true;
        state.failed = true; // Block any further processing
        state.error = error; // Provide exception outside of this queue
    }
});

// Process retry queue sequentially
const retryUploadQueue = queue(retryChunkUpload, 1);

// Folder creation needs to be serial to avoid contention
const createParentFoldersQueue = queue(createParentFolders, 1);

const S3UploadFactory = (file, parentFolder, collectionId, handlers = {}) => {
    const onProgress = handlers.onProgress || (() => {});
    // TODO: Never passed
    const onSuccess = handlers.onSuccess || (() => {});
    // TODO: handlers.onFailed passed but not used.

    const sliceRanges = getSliceRanges(file.size);
    const name = fileName(file);

    let state = {
        id: `s3-upload-${file.name}-${parentFolder.id}-${randomHex()}`,
        paused: false,
        cancelled: false,
        failed: false,
        error: null, // nested exception if failed
        successCount: 0,
        progress: 0,
        result: 0,
        tokenObj: null,
        success: false,
        size: file.size,
        startTime: null, // Start of first chunk upload
        sliceRanges,
        totalChunks: sliceRanges.length,
        name,
        fileType: getExtension(name),
        path: file.name,
        file: file,
        parentFolder,
        collectionId,
        uploadKey: null,
        storageId: null,
    };

    const methods = memoizer.newGroup(state.id);

    // Upload Step 2/4
    const uploadFileStart2 = methods.memoize(
        async () => {
            const payload = {
                data: {
                    type: 'FileUploadStartV2RequestInfo',
                    attributes: {
                        folderId: state.parentFolder.id,
                        fileName: state.name,
                        fileSize: state.size,
                        uploadParts: 1, // minimum 1 even if it's an empty file
                        minutesExpiration:
                            getExpiryInMinutes(defaultS3LinkExpiry),
                    },
                },
            };
            const options = {
                method: 'post',
                body: JSON.stringify(payload),
            };
            const url = `collections/${state.collectionId}/files/upload:start`;
            // Drive2_Upload_File_Start
            const response = await drive2(url, options);
            return response;
        },
        {
            maxAge: 8.64e7, // 24 hours
        }
    );

    const markSuccess = (range) => {
        if (state.cancelled || state.paused) return;
        range.success = true;
        state.successCount = state.successCount + 1;
        state.progress = Math.floor(
            (state.successCount / state.totalChunks) * 100
        );
        onProgress(state.progress);
    };

    const doUpload = async () => {
        state.tokenObj = await uploadFileStart2();

        // Need storageId and uploadKey to call Drive2_Get_Signed_Upload_URLs
        state.storageId = getPropValue(state, 'tokenObj.data.id');
        state.uploadKey = getPropValue(
            state,
            'tokenObj.data.attributes.uploadKey'
        );

        const processRange = asyncify(async (range) => {
            range.inprogress = true;
            await uploadQueue.push({
                range,
                state,
            });
            if (range.success) {
                markSuccess(range);
            }
        });

        await each(state.sliceRanges, processRange);

        // Special handling for empty file
        if (state.size === 0) {
            await directS3Upload(state, 1, null);
        }

        if (state.successCount === state.totalChunks) {
            state.failed = false;
            state.success = true;
            state.result = state.tokenObj;
            // TODO: Never goes anywhere, as its never passed...
            onSuccess(state.tokenObj);
            return;
        } else if (state.cancelled) {
            throw new Error('Upload was cancelled');
        } else if (state.failed) {
            throw state.error
                ? state.error
                : new Error('S3UploadFactory.doUpload() error 1');
        } else {
            // Should not occur...
            throw new Error('S3UploadFactory.doUpload() error 2');
        }
    };

    const api = {
        uploadType: 's3',
        info() {
            return {
                ...state,
            };
        },
        start: async () => {
            // Upload Step 1/4
            await createParentFoldersQueue.push(state);
            if (state.failed) {
                throw state.error || new Error('createParentFolders failed');
            }

            await doUpload();
            return {
                tokenObj: state.tokenObj,
                parentFolder: state.parentFolder, // Step 4/4 needs the new parent
            };
        },
        pause: () => {
            if (state.paused) return;
            state.paused = true;
        },
        resume: () => {
            if (!state.paused) return;
            state.paused = false;
            api.start();
        },
        cancel: () => {
            state.cancelled = true;
            methods.reset();
        },
    };

    return api;
};

export default S3UploadFactory;
