<script>
    export let folder; // Current folder
    export let onClose = () => {};

    import ListLoader from '../list.loader.svelte';
    import FileIcon from '../../icons/file.svelte';
    import {
        timeAgo,
        readableFileSize,
        emptyImage,
    } from '../../providers/utils';
    import SharedWithComponent from './folder.share/folder.shared.with.svelte';
    import DriveIcon from '../../icons/drive.svelte';
    import FolderIcon from '../../icons/folder.svelte';
    import { gDriveInfo } from '../../providers/state/user';
    import { i18n } from '../../i18n';
    import { gSelection } from '../elements/components.state';

    const dataCyAssetInfo = 'asset-info';

    let asset, metadata, isFile, isRootFolder, isSharedWithMe, thumbnailSrc;

    $: {
        thumbnailSrc = emptyImage; // Smooth transitions
        if ($gSelection.length === 1 && $gSelection[0]) {
            asset = $gSelection[0];
        } else {
            asset = folder;
        }

        metadata = asset.itemInfo;

        isFile = asset.isFile;
        if (isFile) {
            asset.thumbnail().then((src) => (thumbnailSrc = src));
        }
        isSharedWithMe = asset.id === 'shared';
        isRootFolder = asset.id === $gDriveInfo.rootFolderId;
    }

    const onThumbnailError = () => {
        thumbnailSrc = null;
    };
</script>

<div class="section" data-cy={dataCyAssetInfo}>
    {#if !metadata}
        <ListLoader />
    {:else}
        <div
            class="info-header subtitle is-6 is-relative is-vertical-center
            has-bottom-border is-marginless"
            data-cy="info-header"
        >
            <span class="icon is-inline-flex">
                {#if isFile}
                    <FileIcon type={asset.fileType} width={24} height="auto" />
                {:else if isRootFolder || isSharedWithMe}
                    <DriveIcon />
                {:else}
                    <FolderIcon />
                {/if}
            </span>
            {#if isRootFolder}
                <span title={i18n.t('App.My_Data')}
                    >{i18n.t('App.My_Data')}</span
                >
            {:else if isSharedWithMe}
                <span title={i18n.t('App.Shared_with_Me')}
                    >{i18n.t('App.Shared_with_Me')}</span
                >
            {:else}
                <span title={metadata.name}>{metadata.name}</span>
            {/if}
            <span
                class="delete"
                data-cy="info-close"
                on:click|stopPropagation={onClose}
            />
        </div>
        <div class="section-body">
            {#if isFile}
                <div
                    class="figure has-centered-content"
                    class:image={thumbnailSrc}
                    class:has-padding={!thumbnailSrc}
                    data-cy="info-figure"
                >
                    {#if thumbnailSrc}
                        <img
                            on:error={onThumbnailError}
                            alt={asset.fileType}
                            src={thumbnailSrc}
                            data-cy="thumbnail"
                        />
                    {:else}
                        <FileIcon width={64} type={asset.fileType} />
                    {/if}
                </div>
            {/if}
            {#if !isRootFolder && !isSharedWithMe}
                <div
                    class="metadata-section has-bottom-border"
                    data-cy="info-body"
                >
                    <div class="is-size-7 has-text-weight-semibold">
                        {i18n.t('App.Asset_Info_Summary.Asset_Type_Label')}
                    </div>
                    <div class="subtitle is-7">
                        {#if isFile}
                            {i18n.t('App.Asset_Info_Summary.File_Asset_Type')}
                        {:else}
                            {i18n.t('App.Asset_Info_Summary.Folder_Asset_Type')}
                        {/if}
                    </div>

                    <div class="is-size-7 has-text-weight-semibold">
                        {i18n.t('App.Asset_Info_Summary.Last_Modified_Age')}
                    </div>
                    <div class="subtitle is-7">
                        {i18n.t('App.Time_Messages.User_Modified_Time_Ago', {
                            USER_FULLNAME: i18n.displayFullName(
                                metadata.lastModifiedBy
                            ),
                            TIME_AGO: timeAgo(metadata.modifiedDate),
                        })}
                    </div>

                    <div class="is-size-7 has-text-weight-semibold">
                        {i18n.t('App.Asset_Info_Summary.Created_Age')}
                    </div>
                    <div class="subtitle is-7">
                        {i18n.t('App.Time_Messages.User_Uploaded_Time_Ago', {
                            USER_FULLNAME: i18n.displayFullName(
                                metadata.createdBy
                            ),
                            TIME_AGO: timeAgo(metadata.createdDate),
                        })}
                    </div>

                    {#if metadata.size}
                        <div class="is-size-7 has-text-weight-semibold">
                            {i18n.t('App.Asset_Info_Summary.Size_Label')}
                        </div>
                        <div class="subtitle is-7">
                            {readableFileSize(metadata.size)}
                        </div>
                    {/if}

                    {#if metadata.latestVersion}
                        <div class="is-size-7 has-text-weight-semibold">
                            {i18n.t('App.Asset_Info_Summary.Latest_Version')}
                        </div>
                        <div class="subtitle is-7">
                            {i18n.t(
                                'App.Asset_Info_Summary.File_Version_String',
                                {
                                    VERSION: metadata.latestVersion,
                                }
                            )}
                        </div>
                    {/if}
                </div>
            {/if}
            {#if !isFile && !isRootFolder && !isSharedWithMe}
                <SharedWithComponent {asset} createdBy={metadata.createdBy} />
            {/if}
        </div>
    {/if}
</div>

<style>
    .section {
        background-color: #fff;
    }

    .info-header {
        min-height: 68px;
        padding-right: 44px;
        padding-left: 10px;
    }

    .delete {
        border-radius: 3px;
        position: absolute;
        right: 12px;
    }

    .icon {
        min-width: 25px;
    }

    .metadata-section {
        padding: 10px;
    }
</style>
