import { encode, decode, trim, isBase64 } from 'url-safe-base64';

export const getPropValue = (obj, key) =>
    key.split('.').reduce((o, x) => (o == undefined ? o : o[x]), obj);

export const URLSafeBase64 = {
    encode(str) {
        str = encode(btoa(str));
        str = trim(str);
        return str;
    },
    decode(str) {
        str = decode(str);
        str = atob(str);
        return str;
    },
    isEncoded(str) {
        return isBase64(str);
    },
};

export const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return btoa(binary);
};

export const delay = (t) =>
    new Promise((resolve) => {
        setTimeout(resolve, t);
    });

export const getExtension = (file) => file.split('.').pop().toLowerCase();

// Group two legged (2LO) methods with this key. There is no user context,
// therefore responses need not be cleared when login state changes.
export const CONNECT_2LO = 'Connect_2LO';

export default {
    getPropValue,
    URLSafeBase64,
    arrayBufferToBase64,
    delay,
    getExtension,
};
