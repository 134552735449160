import asyncify from 'async/asyncify';
import config from 'app.config';
import memoizer from './memoizer';
import UploadFactory from './uploads/upload';
import DropboxUploadFactory from './uploads/dropbox.upload';
import { resetCreateFolderCache } from './uploads/upload.utils';
import whilst from 'async/whilst';
import { DriveError } from './';
import {
    fusion,
    downloadImage,
    getViewerSupportedFiles,
    drive,
    drive2,
} from './request';
import { sortFolderContent } from './processing.utils';
import { waitForJob } from './jobs';
import { URLSafeBase64, getPropValue, getExtension } from './utils';
import {
    trackEvent,
    categories,
    actions,
    trackEventWithDataType,
    trackUploadInfo,
} from '../providers/analytics/ga';
import {
    addUploadSuccessNotification,
    addUploadCancelledNotification,
    uploadErrorType,
} from '../components/upload/upload.utils';
import { SHARED_WITH_ME_PATH } from '../providers/state/active.record';
import {
    arrayEquals,
    getPermissionsFromRole,
    hasPermission,
    randomHex,
    tipFromFileInfo,
    emptyImage,
} from '../providers/utils';
import {
    addUploadInfo,
    removeUploadInfo,
    uploadStore,
} from '../providers/state/upload.store';
import { gDriveInfo } from '../providers/state/user';
import { i18n } from '../i18n';
import { getViewable } from './viewable.utils';

const driveUrl = config.apps.driveUrl;

// Get an items details, if not provided, and return them plus
// some computed props:
//  {newMetadata, apiAdditions}
const _getDetails = async function (encodedUrn, isFile, metadata) {
    const { userId, rootFolderId, driveLicenseValid, collectionId } =
        gDriveInfo.getValue();

    // Fetch ItemInfo if missing
    if (!metadata) {
        // Our dummy root folder for Shared with Me
        const sharedRootFolder = (userId) => ({
            createdBy: { userId },
            hubOwner: userId,
            lastModifiedBy: { userId },
            name: i18n.t('App.Shared_with_Me'),
            path: [],
            permissions: [],
            type: 'FOLDER',
        });

        if (encodedUrn === SHARED_WITH_ME_PATH) {
            metadata = sharedRootFolder('<shared>');
        } else {
            const params = `itemIds=${encodedUrn}`;
            const url = `collections/${collectionId}/items`;
            // Drive_Get_Items
            const response = await drive(`${url}?${params}`);

            // If an item does not exist, we get a 200, not a 404 (as designed)
            metadata = getPropValue(response, 'data.0.attributes');
            if (!metadata) {
                if (isFile) {
                    // Capture this case, and let our callers know
                    throw new DriveError({
                        error: { Drive_Get_Items: 404 },
                    });
                } else {
                    // Detect if this was a direct navigation to a non-existent folder
                    if (window.location.pathname.includes(encodedUrn)) {
                        throw new DriveError({
                            error: { Drive_Get_Items: 404 },
                        });
                    }
                    // Otherwise, most likely an attempt to elaborate the hierarchy
                    // of a shared folder. Others root folders are not accessible.
                    metadata = sharedRootFolder('<sharer>');
                }
            }
        }
    }

    // Process responses containing ItemData, such as:
    //  - Drive_Get_Items
    //  - Drive_Get_Folder_Items
    //  - Drive_Get_Private_Shared_With_Me
    // Inject fileType and isViewableFileType if appropriate into ItemData.attributes (metadata)
    const apiAdditions = {};
    if (metadata.type === 'FILE') {
        // Add 2 more properties
        apiAdditions.fileType = getExtension(metadata.name);
        apiAdditions.isViewableFileType =
            !config.misc.unviewableFileTypes.includes(apiAdditions.fileType);
    }

    // Add computed properties based on ItemData
    apiAdditions.canEdit = hasPermission(metadata, 'write');
    apiAdditions.canDownload = hasPermission(metadata, 'read');
    apiAdditions.canShare = hasPermission(metadata, 'share');
    apiAdditions.canReshare = hasPermission(metadata, 'share', 'explicit');
    apiAdditions.isPrivateShared = metadata.hubOwner !== userId;
    // Outgoing Sharable Folder
    apiAdditions.isFolderShared =
        apiAdditions.canReshare && !apiAdditions.isPrivateShared;
    apiAdditions.isAssetOwner =
        getPropValue(metadata, 'createdBy.userId') === userId;
    // Create our ancestors in memory and save our immediate parent
    // The root comes first, followed by children
    const promises = [];
    apiAdditions.hierarchy = [];
    // TODO: Should be able to simply process the immediate parent
    for (const parent of metadata.path) {
        const parentAsset = AssetFactory(parent.id, null, false, false);
        promises.push(parentAsset.setup()); // Ensure we've got all the metadata
        apiAdditions.hierarchy.push(parentAsset);
        apiAdditions.parent = parentAsset;
    }
    await Promise.all(promises);

    if (encodedUrn === rootFolderId) {
        metadata.name = i18n.t('App.My_Data'); // Change the name from `group-xxx`
        // [DRIVE-891] Analytics: Log mismatches between driveLicenseValid and rootFolder permissions
        const role = driveLicenseValid ? 'rootEdit' : 'download';
        const expected = getPermissionsFromRole(role);
        if (!arrayEquals(metadata.permissions.effective, expected)) {
            const decodedUrn = URLSafeBase64.decode(encodedUrn);
            trackEvent({
                category: categories.errorDrive,
                action: actions.status,
                label: `driveLicenseValid mismatch: ${collectionId} ${decodedUrn} ${role} ${metadata.permissions.effective}`,
            });
        }
    }

    return { newMetadata: metadata, apiAdditions };
};

const assetCache = new Map();

const AssetFactory = memoizer.instancesGroup.memoize(
    (/* Encoded */ id, metadata, isNewAsset, isFile) => {
        const methods = memoizer.newGroup(id);

        const sharedWithMe = id === SHARED_WITH_ME_PATH;

        const idDecoded = sharedWithMe
            ? SHARED_WITH_ME_PATH
            : URLSafeBase64.decode(id);

        const type = isFile ? 'file' : 'folder';

        const { userId, collectionId } = gDriveInfo.getValue();

        let $folderContent = null;
        let $shareInfo = null;
        let $uploads = []; // All uploads for this folder
        let $uploadSuccessTotal = 0;

        const getFolderContent = methods.memoize(
            async (refreshPoliciesCache = false) => {
                if ($folderContent) {
                    return $folderContent;
                }

                let offset = 0;
                let limit = 100;
                let hasMore = true;

                const items = [];
                const checkHasMore = asyncify(async () => hasMore);

                const getNext = asyncify(async () => {
                    let response;
                    if (sharedWithMe) {
                        const url = `collections/${collectionId}/shares/private/shared-with-me`;
                        // Drive_Get_Private_Shared_With_Me
                        response = await drive(url);
                    } else {
                        const params = `offset=${offset}&limit=${limit}`;
                        const url = `collections/${collectionId}/folders/${id}`;
                        const options = refreshPoliciesCache
                            ? { headers: { 'Refresh-Policies-Cache': true } }
                            : {};
                        // Drive_Get_Folder_Items
                        response = await drive(`${url}?${params}`, options);
                    }

                    const entries = getPropValue(response, 'data');
                    const count = entries.length;
                    if (count < limit) {
                        hasMore = false;
                    }
                    items.push(...entries);

                    offset = offset + limit;
                });

                await whilst(checkHasMore, getNext);

                const content = { folders: [], files: [] };
                const promises = [];
                for (const { id, attributes } of items) {
                    const isFile = attributes.type === 'FILE';
                    const asset = AssetFactory(id, attributes, false, isFile);
                    promises.push(asset.setup());

                    if (isFile) {
                        content.files.push(asset);
                    } else {
                        content.folders.push(asset);
                    }
                }
                await Promise.all(promises);

                content.count = content.folders.length + content.files.length;

                $folderContent = content;

                return $folderContent;
            }
        );

        const hasAsset = (asset) => {
            if ($folderContent) {
                return $folderContent[asset.isFile ? 'files' : 'folders'].some(
                    (item) => item.idDecoded === asset.idDecoded
                );
            }
        };

        let onContentUpdate = {};
        let onMetadataUpdate = {};
        let onShareUpdate = {};
        let onUploadsUpdate = {};

        const triggerOnContentUpdate = () => {
            for (let k in onContentUpdate) {
                onContentUpdate[k]($folderContent);
            }
        };

        const triggerUploadsUpdate = () => {
            for (let k in onUploadsUpdate) {
                onUploadsUpdate[k]($uploads);
            }
        };

        const triggerMetadataUpdate = () => {
            for (let k in onMetadataUpdate) {
                onMetadataUpdate[k]();
            }
        };

        const triggerShareUpdate = () => {
            for (let k in onShareUpdate) {
                onShareUpdate[k]();
            }
        };

        // Fetch and cache metadata
        const details = methods.memoize(async () => {
            const { newMetadata, apiAdditions } = await _getDetails.call(
                api,
                id,
                isFile,
                metadata
            );

            metadata = newMetadata;

            // Add the properties as configurable, enumerable & non-writable
            for (const [key, value] of Object.entries(apiAdditions)) {
                Object.defineProperty(api, key, {
                    value,
                    configurable: true,
                    enumerable: true,
                });
            }

            return metadata;
        });

        const createFileInstanceFromLineageUrn = async (
            /* Encoded */ lineageUrn,
            attributes
        ) => {
            try {
                // Make sure we start fresh
                assetCache.delete(lineageUrn);
                memoizer.clearGroup(lineageUrn);
                // Create a new instance
                const newFile = AssetFactory(
                    lineageUrn,
                    attributes,
                    true,
                    true
                );

                await newFile.setup();
                return newFile;
            } catch (error) {
                // Identify source of exception
                const moreInfo = 'createFileInstanceFromLineageUrn';
                if (error instanceof DriveError) {
                    error.moreInfo = moreInfo;
                    throw error;
                } else {
                    throw new DriveError({ error, moreInfo });
                }
            }
        };

        const reEstablishMetadata = async (newMetadata = null) => {
            metadata = newMetadata;
            memoizer.clear(details);
            await details(); // Establishes metadata
            triggerMetadataUpdate();
        };

        const postTranslate = async (newFile) => {
            try {
                // Connect_Viewer_Configuration
                const supportedFiles = await getViewerSupportedFiles();

                if (supportedFiles.includes(newFile.fileType)) {
                    const options = {
                        method: 'post',
                    };
                    const url = `collections/${collectionId}/translate/files/${newFile.id}`;

                    // Drive_Translate_File
                    const response = await drive(url, options);

                    trackEventWithDataType({
                        category: categories.upload,
                        action: actions.fileTranslatePosted,
                        label: `${newFile.fileType}: ${JSON.stringify(
                            getPropValue(response, 'data.attributes')
                        )}`,
                    });
                }
            } catch (error) {
                // We are calling POST translate only for supported files.
                // If we encounter any error, we might be interested in knowing what went wrong.
                trackEventWithDataType({
                    category: categories.upload,
                    action: actions.fileTranslateFail,
                    label: `${newFile.fileType}: ${
                        error.body || error.message
                    }`,
                });
            }
        };

        const api = {
            id,
            idDecoded,
            get isNew() {
                // Only for first access!
                if (isNewAsset) {
                    isNewAsset = false;
                    return true;
                }
                return false;
            },
            type, // Redundant - type, metadata.type, isFile!
            isFile, // Redundant - type, metadata.type, isFile!
            get itemInfo() {
                return metadata;
            },
            // To be used upon Asset creation
            // Call when we might need metadata
            setup: async () => {
                await details(); // Establishes metadata
                return metadata;
            },
            invalidateMetadata: () => {
                metadata = null;
                memoizer.clear(details);
            },
            updateMetadata(newMetadata) {
                metadata = newMetadata;
            },
            rename: methods.memoize(async (fileName) => {
                // Verify expected collectionId - in shared case too
                const payload = {
                    data: {
                        type: 'ItemUpdateRequestInfo',
                        attributes: {
                            itemName: fileName,
                        },
                    },
                };
                const options = {
                    method: 'put',
                    body: JSON.stringify(payload),
                };
                const url = `collections/${collectionId}/items/${id}`;
                // Drive_Update_Item
                const response = await drive(url, options);

                const { attributes } = getPropValue(response, 'data');

                reEstablishMetadata(attributes);
            }),
            remove: async () => {
                await details(); // Establishes metadata

                const options = { method: 'delete' };
                const params = `itemIds=${id}`;
                const url = `collections/${collectionId}/items`;
                // Drive_Delete_Items
                const response = await drive(`${url}?${params}`, options);

                // TODO: Properly handle non-thrown response errors...

                // TODO: Properly handle JobInfo (both files and folders)
                const jobId = getPropValue(response, 'data.0.id');
                if (jobId) {
                    // await waitForJob()...
                }

                api.parent.content[
                    isFile ? 'childFileRemoved' : 'childFolderRemoved'
                ](api);

                return true;
            },
            // TODO: Update this function when Bulk Download is supported by DriveMS
            bulkDownload: async (assets, progressCallback) => {
                const bulkDownloadWorks = false; // See DRIVE-467 and DRIVE-935
                if (bulkDownloadWorks) {
                    trackEventWithDataType({
                        category: categories.fileManagement,
                        action: actions.bulkDownload,
                        value: assets.length, // # of top-level items only
                    });

                    const itemsListJSON = JSON.stringify({
                        items: assets.map((item) => ({
                            urn: item.idDecoded,
                            type: item.type,
                        })),
                    });
                    // TODO: Change below as per DriveMS API specs
                    // Was Fusion_Download_Items
                    // This is just a placeholder
                    const response = await fusion(
                        `${collectionId}/data/api/download`,
                        {
                            method: 'post',
                            body: itemsListJSON,
                        }
                    );
                    if (response.success) {
                        const jobId = response.success.body.id;
                        const jobResponse = await waitForJob(
                            jobId,
                            collectionId,
                            progressCallback
                        );
                        window.location.href = jobResponse.attributes.value;
                        return true;
                    } else {
                        throw response;
                    }
                }
            },
            openOnDesktop: async () => {
                await details(); // Establishes metadata

                const data = btoa(
                    JSON.stringify({
                        apiVersion: '1.0',
                        userObject: {
                            guid: userId,
                        },
                        hubObject: {
                            url: `${config.servers.fusion}/${collectionId}`,
                        },
                        fileObject: {
                            urn: tipFromFileInfo(
                                idDecoded,
                                metadata.versionNumber
                            ),
                        },
                    })
                );

                const href = `CDX://open?file=${metadata.name}&data=${data}`;

                trackEventWithDataType({
                    category: categories.fileManagement,
                    action: actions.openOnDesktop,
                    label: api.fileType,
                    value: metadata.size,
                });

                /* We cannot reliably catch failures to navigate typically reported as:
                Failed to launch ‘cdx://lala.lala’ because the scheme does not have a registered handler
                so we always show Download Desktop Connector banner */
                window.location.href = href;
            },
            cache: {
                reset: () => {
                    methods.reset();
                    $folderContent = null;
                },
            },
            observe({ content, metadata, share, uploads }) {
                const key = randomHex();
                if (content) {
                    onContentUpdate[key] = content;
                }
                if (metadata) {
                    onMetadataUpdate[key] = metadata;
                }
                if (share) {
                    onShareUpdate[key] = share;
                }
                if (uploads) {
                    onUploadsUpdate[key] = uploads;
                }
                return {
                    cancel() {
                        delete onContentUpdate[key];
                        delete onMetadataUpdate[key];
                        delete onShareUpdate[key];
                        delete onUploadsUpdate[key];
                    },
                };
            },
            uploads: {
                list: () => {
                    return $uploads;
                },
                upload: async (data) => {
                    await details(); // Establishes metadata
                    let parent = api,
                        target;
                    if (isFile) {
                        parent = api.parent;
                        target = api;
                    }
                    let uploadObjects = [];
                    if (['folder', 'files', 'file'].indexOf(data.type) > -1) {
                        if (api.isFile && data.files.length > 1) {
                            // Does this ever occur???
                            throw new Error('Only 1 file should be provided');
                        }
                        data.files.forEach((file) => {
                            if (
                                getPropValue(file, 'errorType') ===
                                uploadErrorType.SHOULD_BE_SILENT
                            ) {
                                return;
                            }
                            const uploadObject = UploadFactory(
                                file,
                                parent,
                                collectionId,
                                target
                            );
                            uploadObjects.push(uploadObject);
                        });
                    } else if (data.type === 'dropbox') {
                        const uploadObject = DropboxUploadFactory(
                            data.files,
                            parent,
                            collectionId
                        );
                        uploadObjects.push(uploadObject);
                    }
                    if (uploadObjects.length > 0) {
                        api.uploads.newUploadAdded(uploadObjects);
                    }
                },
                newUploadAdded: function (uploadObjects) {
                    uploadObjects.forEach((uploadObject) => {
                        const ns = api.uploads;
                        // Define local methods to be called by UploadFactory at key events
                        const handlers = {
                            success: ns.uploadFinished.bind(api, uploadObject),
                            progress: ns.uploadProgressed.bind(
                                api,
                                uploadObject
                            ),
                            cancel: ns.uploadCancelled.bind(api, uploadObject),
                            pause: ns.uploadPaused.bind(api, uploadObject),
                            start: ns.uploadStarted.bind(api, uploadObject),
                            failed: ns.uploadFailed.bind(api, uploadObject),
                        };
                        if (uploadObject.uploadType === 'dropbox') {
                            handlers.success = ns.dropboxImportFinished.bind(
                                api,
                                uploadObject
                            );
                        }
                        // We don't bother saving or calling the returned cancel() method
                        // as the uploadObject is simply released
                        uploadObject.observe(handlers);
                        uploadObject.start();
                        $uploads.push(uploadObject);
                        addUploadInfo(uploadObject);
                    });
                    trackEventWithDataType({
                        category: categories.upload,
                        action: actions.fileUploadRequested,
                        value: uploadObjects.length,
                    });

                    triggerUploadsUpdate();
                },
                uploadFinished: async function (uploadObject, file) {
                    // keep track of successful uploads
                    $uploadSuccessTotal++;
                    const { id: newId, attributes } = getPropValue(
                        file,
                        'data'
                    );
                    const newFile = await createFileInstanceFromLineageUrn(
                        newId,
                        attributes
                    );

                    // Existing folder with content?
                    api.uploads.removeUpload(uploadObject);
                    if (hasAsset(newFile)) {
                        trackEventWithDataType({
                            category: categories.upload,
                            action: actions.fileUploadVersionSuccess,
                            label: file.fileType,
                            value: file.fileSize,
                        });

                        api.content.newChildFileAdded(newFile);
                    } else {
                        trackEventWithDataType({
                            category: categories.upload,
                            action: actions.fileUploadSuccess,
                            label: file.fileType,
                            value: file.fileSize,
                        });

                        // Hierarchy order comes bottom to top.
                        let parents = file.parents;
                        if (!Array.isArray(parents)) {
                            parents = [parents];
                        }
                        parents = parents.reverse();
                        // Checking if the file is inside a subfolder
                        const loc = parents.indexOf(id);
                        let folderPointer = api;
                        const promises = [];
                        for (let i = loc + 1; i < parents.length; i++) {
                            const addedFolder = parents[i];
                            const instance = AssetFactory(
                                addedFolder,
                                null,
                                true,
                                false
                            );
                            // New intermediate folders will need their metadata fetched
                            promises.push(instance.setup());
                            folderPointer.content.newChildFolderAdded(instance);
                            folderPointer = instance;
                        }
                        await Promise.all(promises);

                        folderPointer.content.newChildFileAdded(newFile);
                    }
                    // only show success toast after last upload is complete
                    if ($uploads.length === 0) {
                        addUploadSuccessNotification(
                            $uploadSuccessTotal,
                            api.itemInfo.name
                        );
                        // clear $uploadSuccessTotal for future batch uploads
                        $uploadSuccessTotal = 0;
                    }
                    postTranslate(newFile); // Initiate translation for faster viewing
                },
                dropboxImportFinished: async function (
                    uploadObject /* , file */
                ) {
                    api.uploads.removeUpload(uploadObject);
                    await api.content.invalidate();
                },
                uploadProgressed: function (/* uploadObject, progress */) {
                    // bound to UploadFactory 'progress'
                },
                uploadFailed: async (uploadObject) => {
                    uploadStore.incrementFailed();
                    const uploadInfo = await uploadObject.info();
                    trackEventWithDataType({
                        category: categories.upload,
                        action: actions.fileUploadFail,
                        label: uploadInfo.fileType,
                        value: uploadInfo.size,
                        detail: uploadInfo.error,
                    });
                    api.uploads.removeUpload(uploadObject);
                },
                uploadCancelled: async (uploadObject) => {
                    const uploadInfo = await uploadObject.info();
                    trackEventWithDataType({
                        category: categories.upload,
                        action: actions.fileUploadCancel,
                        label: uploadInfo.fileType,
                        value: uploadInfo.size,
                    });
                    addUploadCancelledNotification(uploadInfo.path);

                    api.uploads.removeUpload(uploadObject);
                },
                removeUpload: function (uploadObject) {
                    const index = $uploads.indexOf(uploadObject);
                    if (index > -1) {
                        $uploads.splice(index, 1);
                        removeUploadInfo(uploadObject);
                    }
                    triggerUploadsUpdate();
                    if ($uploads.length === 0) {
                        resetCreateFolderCache();
                    }
                },
                uploadPaused: function (/* uploadObject */) {
                    triggerUploadsUpdate();
                },
                uploadStarted: async (uploadObject) => {
                    const uploadInfo = await uploadObject.info();
                    const action =
                        uploadObject.uploadType === 'dropbox'
                            ? actions.uploadDropbox
                            : actions.fileUploadStart;
                    trackEventWithDataType({
                        category: categories.upload,
                        action,
                        label: uploadInfo.fileType,
                        value: uploadInfo.size,
                    });

                    // Detect unexpected duplicate starts
                    // 20211109 - None recorded for last 30 days - this can safely be removed:
                    if (uploadObject.uploadType === 'file') {
                        if (uploadInfo.started) {
                            trackUploadInfo(
                                'DUPLICATE_uploadStarted',
                                uploadInfo.path,
                                uploadInfo.size
                            );
                        } else {
                            uploadObject.markStarted();
                        }
                    }

                    triggerUploadsUpdate();
                },
            },
        };

        if (isFile) {
            api.downloadFile = async () => {
                await details(); // Establishes metadata

                trackEventWithDataType({
                    category: categories.fileManagement,
                    action: actions.fileDownloadStart,
                    label: api.fileType,
                    value: metadata.size,
                });

                try {
                    const url = `collections/${collectionId}/files/${id}/download:signed`;
                    const params = 'useCdn=true';
                    // Drive2_Get_Download_File_Url
                    const response = await drive2(`${url}?${params}`);

                    const href = response.data.attributes.downloadUrl;
                    window.location.href = href;

                    trackEventWithDataType({
                        category: categories.fileManagement,
                        action: actions.fileDownloadSuccess,
                        label: api.fileType,
                        value: metadata.size,
                    });

                    return true;
                } catch (error) {
                    trackEventWithDataType({
                        category: categories.fileManagement,
                        action: actions.fileDownloadFail,
                        label: api.fileType,
                        value: metadata.size,
                    });

                    throw error;
                }
            };
            api.getDownloadLink = async () => {
                const downloadUrl = (await api.viewable()).viewingUrl;
                return downloadUrl;
            };

            const createUpdatePublicShare = async (attributes = {}) => {
                const payload = {
                    data: {
                        type: 'PublicShareUpsertRequestInfo',
                        attributes,
                    },
                };
                const options = {
                    method: 'put',
                    body: JSON.stringify(payload),
                };
                const url = `collections/${collectionId}/shares/public/items/${id}`;
                // Drive_Create_Update_Public_Share
                const response = await drive(url, options);
                await updateShareMetadata(response.data);
            };

            const updateShareMetadata = async (newShare) => {
                Object.assign($shareInfo, newShare);
                if (!$shareInfo.accessUrl) {
                    await tackAccessUrl();
                }
                memoizer.clear(api.sharing.info);
                triggerShareUpdate();
            };

            const tackAccessUrl = async () => {
                // TODO: This should be shortened - we do not need the collectionId anymore, right?
                const longUrl = `${driveUrl}/${$shareInfo.attributes.originCollectionId}/shares/${$shareInfo.attributes.shareId}`;
                try {
                    const url = `collections/${collectionId}/shorten?shortenUrl=${longUrl}`;
                    // Drive_Shorten_Url
                    const response = await drive(url);
                    // Tack short URL
                    $shareInfo.accessUrl = response.data[0].attributes.shortUrl;
                } catch (error) {
                    // Else, tack long URL
                    $shareInfo.accessUrl = longUrl;
                    /* Do not throw
                    We don't want to break if this fails. Instead, tack long share URL
                    This error is already logged to GA */
                }
            };

            api.sharing = {
                info: methods.memoize(async () => {
                    if ($shareInfo) {
                        return $shareInfo;
                    }
                    try {
                        const url = `collections/${collectionId}/shares/public/items/${id}`;
                        // Drive_Get_Public_Share
                        const response = await drive(url);
                        $shareInfo = response.data;
                        await tackAccessUrl();
                        return $shareInfo;
                    } catch (error) {
                        // Handle case when public share doesn't exist
                        if (getPropValue(error, 'response.status') === 404) {
                            return false;
                        } else {
                            throw error;
                        }
                    }
                }),
                off: async () => {
                    await details(); // Establishes metadata
                    const options = {
                        method: 'delete',
                    };
                    const url = `collections/${collectionId}/shares/public/items/${id}`;
                    const skipResponse = true;
                    // Drive_Delete_Public_Share
                    await drive(url, options, null, skipResponse);
                    $shareInfo = {};
                    memoizer.clear(api.sharing.info);
                    triggerShareUpdate();
                    trackEventWithDataType({
                        category: categories.publicFileShare,
                        action: actions.publicShareRemove,
                        label: api.fileType,
                    });
                },
                on: async () => {
                    $shareInfo = {};
                    await details(); // Establishes metadata
                    await createUpdatePublicShare({
                        downloadEnabled: !api.isViewableFileType,
                    });
                    trackEventWithDataType({
                        category: categories.publicFileShare,
                        action: actions.publicShareCreate,
                        label: api.fileType,
                    });
                },
                setDownload: async (enable) => {
                    enable = !!enable;
                    await createUpdatePublicShare({
                        downloadEnabled: enable,
                    });
                },
                setPassword: async (password = '') => {
                    await createUpdatePublicShare({ password });
                    trackEventWithDataType({
                        category: categories.publicFileShare,
                        action: actions.publicShareSetPassword,
                    });
                },
            };

            api.thumbnail = methods.memoize(async () => {
                await details(); // Establishes metadata
                if (metadata.thumbnailUrl) {
                    try {
                        // Derivative_Get_Thumbnail
                        const src = await downloadImage(metadata.thumbnailUrl);
                        if (
                            typeof src === 'string' &&
                            src.length > 0 &&
                            src !== 'data:image/png;base64,' &&
                            src != emptyImage
                        ) {
                            return src;
                        }
                    } catch (error) {
                        // If the thumbnail is not ready, we'll get a 404, which is expected
                        // Otherwise, log an error
                        if (error.status !== 404) {
                            DriveError.logError(
                                'Derivative_Get_Thumbnail',
                                error
                            );
                        }
                        // In either case, eat the error, and don't bother the user
                        return null;
                    }
                }

                return false;
            });

            api.viewable = methods.memoize(
                async () => {
                    await details(); // Establishes metadata
                    const viewableInfo = await getViewable(
                        id,
                        collectionId,
                        metadata
                    );
                    return viewableInfo;
                },
                {
                    maxAge: 3000,
                }
            );

            if (metadata) {
                api.isViewableFileType = metadata.isViewableFileType;
            }
        } else {
            // Copy or Move into this Folder
            api.copy = async (asset, move) => {
                const assetId = asset.id;
                const assetParent = asset.parent;
                const assetIsFile = asset.isFile;
                const assetType = assetIsFile ? 'files' : 'folders';
                const op = move ? 'move' : 'copy';

                let response;
                // The File and Folder endpoints are significantly different
                let payload = { data: {} };
                const typeSuffix = `${move ? 'Move' : 'Copy'}RequestInfo`;
                if (assetIsFile) {
                    payload.data = {
                        type: `File${typeSuffix}`,
                        attributes: {
                            destinationFolderId: id,
                            fileAttributes: [
                                {
                                    fileId: assetId,
                                },
                            ],
                        },
                    };
                } else {
                    payload.data = {
                        type: `Folder${typeSuffix}`,
                        attributes: {
                            destinationFolderId: id,
                            folderAttributes: [
                                {
                                    folderId: assetId,
                                },
                            ],
                        },
                    };
                }
                const options = {
                    method: 'post',
                    body: JSON.stringify(payload),
                };
                const url = `collections/${collectionId}/${assetType}/${op}`;
                // Drive_Move_File | Drive_Copy_File
                // Drive_Move_Folder | Drive_Copy_Folder
                response = await drive(url, options);
                // Establish success
                const newId = getPropValue(response, 'data.0.id');
                if (!newId) {
                    // Drive_Move_File & Drive_Copy_File respond differently when the target already exists
                    //  - Drive_Move_File returns 409 plus an error response
                    //  - Drive_Copy_File returns 200 plus {"data": []}
                    // Special case Drive_Copy_File so we know it failed
                    if (url.includes('files/copy')) {
                        throw 'Drive_Copy_File: 409';
                    }
                    throw response;
                }

                const { attributes } = getPropValue(response, 'data.0');

                if (assetIsFile) {
                    const newAsset = await createFileInstanceFromLineageUrn(
                        newId,
                        attributes
                    );
                    api.content.newChildFileAdded(newAsset);
                    if (move) {
                        assetParent.content.childFileRemoved(asset);
                    }
                } else {
                    // FolderMoveJobInfo / FolderCopyJobInfo
                    // We'll get a jobId on longer operations, but for just an empty folder, we won't
                    if (newId) {
                        await waitForJob(newId, collectionId);
                    }
                    if (move) {
                        assetParent.content.childFolderRemoved(asset);

                        // Invalidate metadata of moved folder DRIVE-800
                        asset.invalidateMetadata();
                        // And refetch it - as the response from move / jobs does not contain the new metadata
                        await asset.setup();
                    }
                    await api.content.invalidate();
                }
                return true;
            };

            api.content = {
                sortedByName: async (ascending) => {
                    let content = await getFolderContent();
                    content = sortFolderContent(content, 'name', ascending);
                    return content;
                },
                sortedByLastModified: async (ascending) => {
                    let content = await getFolderContent();
                    content = sortFolderContent(
                        content,
                        'modifiedDate',
                        ascending
                    );
                    return content;
                },
                newChildFolderAdded: async (folder) => {
                    if (hasAsset(folder)) {
                        return; // Nothing to update
                    }
                    let useCache = true;
                    if ($folderContent && $folderContent.folders) {
                        $folderContent.folders = [
                            folder,
                            ...$folderContent.folders,
                        ];
                    } else {
                        useCache = false;
                    }
                    await api.content.invalidate(useCache);
                },
                newChildFileAdded: async (file) => {
                    let useCache = true;
                    if ($folderContent && $folderContent.files) {
                        const index = $folderContent.files.findIndex(
                            (item) => item.idDecoded === file.idDecoded
                        );
                        if (index >= 0) {
                            $folderContent.files.splice(index, 1, file);
                        } else {
                            $folderContent.files = [
                                file,
                                ...$folderContent.files,
                            ];
                        }
                    } else {
                        useCache = false;
                    }
                    await api.content.invalidate(useCache);
                },
                childFolderRemoved: async (folder) => {
                    if ($folderContent && $folderContent.folders) {
                        $folderContent.folders = $folderContent.folders.filter(
                            (item) => item.idDecoded !== folder.idDecoded
                        );
                        await api.content.invalidate(true);
                    }
                },
                childFileRemoved: async (file) => {
                    if ($folderContent && $folderContent.files) {
                        $folderContent.files = $folderContent.files.filter(
                            (item) => item.idDecoded !== file.idDecoded
                        );
                        await api.content.invalidate(true);
                    }
                },
                hasNotViewableFile: async () => {
                    const content = await getFolderContent();
                    const notViewableFile = content.files.some(
                        (file) => !file.isViewableFileType
                    );
                    return notViewableFile;
                },
                invalidate: async (useCache, refreshPoliciesCache) => {
                    memoizer.clear(getFolderContent);
                    if (!useCache) {
                        $folderContent = null;
                        await getFolderContent(refreshPoliciesCache);
                    }

                    const files = $folderContent.files || [];
                    const folders = $folderContent.folders || [];
                    $folderContent.count = files.length + folders.length;

                    triggerOnContentUpdate();
                },
            };

            api.new = {
                folder: async (name) => {
                    const payload = {
                        data: {
                            type: 'FolderCreateRequestInfo',
                            attributes: {
                                folderName: name,
                            },
                        },
                    };
                    const options = {
                        method: 'post',
                        body: JSON.stringify(payload),
                    };
                    const url = `collections/${collectionId}/folders/${id}`;
                    // Drive_Create_Folder
                    const response = await drive(url, options);
                    const { id: newId, attributes } = getPropValue(
                        response,
                        'data'
                    );
                    if (!newId) {
                        throw response;
                    }

                    const folder = AssetFactory(newId, attributes, true, false);
                    await folder.setup();

                    api.content.newChildFolderAdded(folder);
                    return folder;
                },
                file: methods.memoize(() => {}),
            };

            api.downloadFolder = async (progressCallback) => {
                trackEventWithDataType({
                    category: categories.fileManagement,
                    action: actions.folderDownloadStart,
                });

                try {
                    const url = `collections/${collectionId}/folders/${id}/download`;
                    // Drive_Get_Download_Folder_Job_Info
                    const response = await drive(url);

                    const jobId = response.data.id;
                    const jobResponse = await waitForJob(
                        jobId,
                        collectionId,
                        progressCallback
                    );

                    window.location.href = jobResponse.downloadUrl;

                    trackEventWithDataType({
                        category: categories.fileManagement,
                        action: actions.folderDownloadSuccess,
                    });
                    return true;
                } catch (error) {
                    trackEventWithDataType({
                        category: categories.fileManagement,
                        action: actions.folderDownloadFail,
                    });

                    throw error;
                }
            };

            api.sharing = {
                info: methods.memoize(async () => {
                    try {
                        // Call Drive_Get_Private_Share only if folder is shared or Sharee with Editor role
                        // This helps to avoid Drive_Get_Private_Share 404 errors
                        if (api.isFolderShared || api.canReshare) {
                            const url = `collections/${collectionId}/shares/private/items/${id}`;
                            // Drive_Get_Private_Share
                            const response = await drive(url);
                            return getPropValue(
                                response,
                                'data.attributes.shareInfo'
                            );
                        } else {
                            return [];
                        }
                    } catch (error) {
                        // Handle case when private share doesn't exist
                        if (getPropValue(error, 'response.status') === 404) {
                            return [];
                        } else {
                            throw error;
                        }
                    }
                }),
                areValidOxygenUsers: async (users) => {
                    const url = `collections/${collectionId}/shares/private/valid-users`;
                    const params = `emailIds=${users
                        .map((user) => encodeURIComponent(user.emailId))
                        .toString()}`;

                    // Drive_Are_Valid_Oxygen_Users
                    const response = await drive(`${url}?${params}`);

                    response.data.forEach((user) => {
                        let index = users.findIndex(
                            (sharee) =>
                                sharee.emailId.toLowerCase() ===
                                user.id.toLowerCase()
                        );
                        if (index > -1) {
                            users[index].isAutodeskUser =
                                user.attributes.isAutodeskUser;
                        }
                    });

                    return users;
                },
                sendAccountCreationEmail: async (emailId) => {
                    const payload = {
                        data: {
                            type: 'PrivateShareAccountCreationEmailRequestInfo',
                            attributes: {
                                emailId: emailId,
                                itemId: id,
                            },
                        },
                    };

                    const options = {
                        method: 'post',
                        body: JSON.stringify(payload),
                    };
                    const url = `collections/${collectionId}/shares/private/account-create-email`;
                    const skipResponse = true;

                    // Drive_Send_Private_Share_Account_Creation_Email
                    await drive(url, options, 'json', skipResponse);
                },
                upsert: async (changes) => {
                    // Fetch latest
                    api.sharing.invalidate();
                    const shareInfo = await api.sharing.info();
                    let newSharees = [];
                    let isShareeUpdate = false;
                    let isShareeCreate = false;
                    let isShareWithOwner = false;
                    let isNotAutodeskUser = false;
                    const isShareeRemove =
                        changes.length === 1 && changes[0].action === 'remove';

                    // Upsert is full replacement, retain existing shares
                    let existingShares = shareInfo.map((share) => ({
                        emailId: share.emailId,
                        permissions: share.permissions
                            ? share.permissions.explicit.toString()
                            : '',
                    }));

                    const isFirstSharee = existingShares.length === 0;

                    for (let change of changes) {
                        let index = existingShares.findIndex(
                            (share) => share.emailId === change.emailId
                        );
                        if (index > -1) {
                            if (change.action === 'remove') {
                                existingShares.splice(index, 1);
                            } else {
                                existingShares[index] = {
                                    emailId: change.emailId,
                                    permissions: change.permissions,
                                };
                            }
                            if (!isShareeRemove) {
                                isShareeUpdate = true;
                            }
                        } else {
                            // Check if sharing with owner
                            const folderOwner = metadata.createdBy;
                            if (folderOwner.emailId === change.emailId) {
                                isShareWithOwner = true;
                            } else {
                                newSharees.push(change);
                            }
                        }
                    }

                    if (newSharees.length) {
                        // Check for Autodesk users
                        const users = await api.sharing.areValidOxygenUsers(
                            newSharees
                        );

                        for (let user of users) {
                            if (user.isAutodeskUser) {
                                existingShares.push({
                                    emailId: user.emailId,
                                    permissions: user.permissions,
                                });
                                isShareeCreate = true;
                            } else {
                                isNotAutodeskUser = true;
                                // For Non-Autodesk users, send account creation email
                                // No await as it can run async
                                api.sharing.sendAccountCreationEmail(
                                    user.emailId
                                );

                                trackEventWithDataType({
                                    category: categories.privateFolderShare,
                                    action: actions.privateShareSendAccountCreationEmail,
                                });
                            }
                        }
                    }

                    const atLeastOneChange =
                        isShareeCreate || isShareeUpdate || isShareeRemove;
                    // If last sharee is being removed, we need to delete PFS
                    if (existingShares.length === 0 && isShareeRemove) {
                        await api.sharing.delete();
                    } else if (atLeastOneChange) {
                        const payload = {
                            data: {
                                type: 'PrivateShareUpsertRequestInfo',
                                attributes: existingShares,
                            },
                        };

                        const options = {
                            method: 'put',
                            body: JSON.stringify(payload),
                        };
                        const url = `collections/${collectionId}/shares/private/items/${id}`;
                        // Drive_Create_Update_Private_Share
                        await drive(url, options);
                        api.sharing.invalidate(true, isFirstSharee);

                        // DRIVE-780
                        if (isShareeCreate) {
                            trackEventWithDataType({
                                category: categories.privateFolderShare,
                                action: actions.privateShareCreate,
                                value: existingShares.length,
                            });
                        }

                        if (isShareeUpdate) {
                            trackEventWithDataType({
                                category: categories.privateFolderShare,
                                action: actions.privateShareUpdate,
                                value: existingShares.length,
                            });
                        }

                        if (isShareeRemove) {
                            trackEventWithDataType({
                                category: categories.privateFolderShare,
                                action: actions.privateShareRemove,
                                value: existingShares.length,
                            });
                        }
                    }
                    return {
                        isUpsert: atLeastOneChange,
                        isShareWithOwner,
                        isNotAutodeskUser,
                    };
                },
                delete: async () => {
                    const options = {
                        method: 'delete',
                    };
                    const url = `collections/${collectionId}/shares/private/items/${id}`;
                    const skipResponse = true;
                    // Drive_Delete_Private_Share
                    await drive(url, options, null, skipResponse);
                    await api.sharing.invalidate(true, true);
                    trackEvent({
                        category: categories.privateFolderShare,
                        action: actions.privateShareDelete,
                        value: 0, // When PFS deleted, no Sharees
                    });
                },
                leaveFolder: async () => {
                    const options = {
                        method: 'delete',
                    };
                    const url = `collections/${collectionId}/shares/private/shared-with-me/items/${id}`;
                    const skipResponse = true;
                    // Drive_Remove_Private_Share_For_Me
                    await drive(url, options, null, skipResponse);
                    trackEvent({
                        category: categories.privateFolderShare,
                        action: actions.privateShareLeave,
                    });
                },
                invalidate: async (
                    isUpdate = false,
                    refreshMetadata = false
                ) => {
                    memoizer.clear(api.sharing.info);
                    if (isUpdate) {
                        if (refreshMetadata) {
                            // Below is workaround for WIPDM cache issue
                            // Refresh-Policies-Cache when folder is shared/unshared
                            // We can call api.parent.content.invalidate() on parent folder as well,
                            // but we decided to call it for folder on which 'Share' is being performed
                            // because contents of parent folder are already cached at this point,
                            // calling it on current folder may speed up navigation inside the current folder.
                            await api.content.invalidate(false, true);

                            // DriveMS now returns explicit 'share' permissions in Drive_Get_Items response to indicate if folder is shared
                            // Fetch metadata again if first sharee added or last sharee removed.
                            await reEstablishMetadata();
                        }

                        triggerShareUpdate();
                    }
                },
            };
        }

        return api;
    },
    {
        cache: assetCache,
    }
);

export default AssetFactory;
