<script>
    import store from 'store2';
    import config from 'app.config';
    import Banner from '../elements/banner.svelte';
    import { profile } from '../../providers/state/user';
    import { i18n } from '../../i18n';

    const learnMoreLink = config.links.learnMoreAutoCAD;
    const dataCy = 'open-in-autocad-banner';
    const type = 'info';
    const userStorage = store($profile.userId) || {};
    let show = !userStorage.hasSeenAutoCADBanner;

    const options = {
        showBanner: () => {
            show = true;
        },
        hideBanner: () => {
            store.add($profile.userId, { hasSeenAutoCADBanner: true });
            show = false;
        },
    };

    const onLearnMoreClick = () => {
        window.open(learnMoreLink, '_blank');
    };
</script>

<Banner {type} {options} {show} {dataCy}>
    <div slot="title" class="is-vertical-center is-size-7-mobile" id={dataCy}>
        {i18n.t('App.Asset_Actions.Open_In_AutoCAD_Msg')}&nbsp;
        <button
            id="btn-learn-more"
            class="button is-small-mobile is-size-7-mobile mr-0 has-text-grey-dark is-link has-text-weight-semibold"
            on:click={onLearnMoreClick}
        >
            {i18n.t('App.EntitlementCheck.Learn_More_Btn')}
        </button>
    </div>
</Banner>
