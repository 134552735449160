<script>
    export let loginRequired;

    import {
        authenticated,
        gDriveInfo,
        signIn,
        signedOut,
        checkingSignin,
    } from '../providers/state/user';
    import UserContentComponent from '../components/user.content.svelte';
    import CheckEntitlement from '../components/entitlement.check.svelte';
    import { goto } from '../providers/navigation';
    import { i18n } from '../i18n';
    import SearchComponent from '../components/search.svelte';
    import HelpComponent from '../components/help.svelte';
    import { currentFolder } from '../providers/state/active.record';

    const dataCy = 'header';

    const onClick = () => {
        goto($authenticated ? '/home' : '/');
    };

    let isPrivateShared = false;

    $: {
        if ($gDriveInfo && $currentFolder) {
            isPrivateShared = $currentFolder.isPrivateShared;
        }
    }
</script>

<div class="container is-fluid columns is-multiline is-vcentered is-mobile">
    <div class="column is-paddingless is-flex is-vertical-center">
        <img
            on:click={onClick}
            class="ga-{dataCy}-logo drive-logo pl-3 drive-logo-mobile is-hidden-tablet"
            src="DRIVE_ASSETS/images/autodesk-logo-drive-mobile-header.svg"
            data-cy="{dataCy}-logo-mobile"
            alt="logo"
        />
        <img
            on:click={onClick}
            class="ga-{dataCy}-logo drive-logo pl-3 drive-logo-tablet is-hidden-mobile"
            src="DRIVE_ASSETS/images/autodesk-logo-drive.svg"
            data-cy="{dataCy}-logo"
            alt="logo"
        />
    </div>
    <div class="column is-flex is-half-mobile is-rightaligned is-paddingless">
        {#if !isPrivateShared}
            <SearchComponent />
        {/if}
    </div>
    <div
        class="column is-flex is-narrow is-narrow-mobile-custom is-rightaligned pl-2"
    >
        <HelpComponent />
    </div>
    <div
        class="column is-narrow is-flex is-rightaligned is-paddingless
        is-vertical-center mr-2"
    >
        <div class="entitlement-box">
            <CheckEntitlement />
        </div>
        {#if $authenticated}
            <UserContentComponent />
        {:else if loginRequired === false}
            <!-- Don't show on pages where login is required, as we already
            redirect users to login page -->
            {#if $checkingSignin}
                <button
                    id="checking-sign-in-btn"
                    class="ga-{dataCy}-checking-sign-in button is-info"
                    data-cy="{dataCy}-checking-sign-in"
                    disabled
                >
                    {i18n.t('App.Landing_Page.Sign_In_Text')}
                </button>
            {:else if $signedOut}
                <button
                    id="sign-in-btn"
                    class="ga-{dataCy}-sign-in button is-info"
                    data-cy="{dataCy}-sign-in"
                    on:click={signIn}
                >
                    {i18n.t('App.Landing_Page.Sign_In_Text')}
                </button>
            {/if}
        {/if}
    </div>
</div>

<style>
    .container {
        min-height: 60px;
        margin: 2px 0;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    }
    .drive-logo {
        width: auto;
        cursor: pointer;
    }
    .drive-logo-tablet {
        height: 15px;
    }
    .drive-logo-mobile {
        height: 20px;
    }
    .entitlement-box {
        margin-right: 10px;
    }
    #sign-in-btn,
    #checking-sign-in-btn {
        font-weight: normal !important;
        background-color: #06a7df;
    }
    @media screen and (max-width: 768px) {
        .column.is-narrow-mobile-custom {
            -webkit-box-flex: 0;
            -webkit-flex: none;
            -ms-flex: none;
            flex: none;
            padding-right: 2px;
            padding-left: 2px;
        }
    }
</style>
