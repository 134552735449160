<script>
    // Stores a list of input emails including both valid/invalid.
    export let inputEmails = [];
    export let isValid;
    export let folderShareInProgress = false;

    import CloseIcon from '../../icons/close.svelte';
    import { randomHex } from '../../providers/utils';

    const emailSpliters = [13, 32, 186, 188]; // Enter/Space/semicolon/comma
    const emailRegex = /\S+@\S+\.\S+/;
    // Valid email chars including: +, -, _, ~
    const emailInvalidCharRegex = /[^a-zA-Z@\d._+~-]/g;
    const emailListSplitterRegex = /[,;\t\n]/g;
    const dataCy = 'email-field';
    const id = `${dataCy}-${randomHex()}`;
    let emailField;
    let hasInvalidEmails;

    const addShareeEmail = (email) => {
        email = removeSpecial(email);
        if (email) {
            if (emailRegex.test(email)) {
                inputEmails = [...inputEmails, { email: email, isValid: true }];
            } else {
                inputEmails = [
                    ...inputEmails,
                    { email: email, isValid: false },
                ];
            }
        }
    };

    $: {
        // Deterine whether or not there are invalid Email(s)
        hasInvalidEmails = inputEmails.filter((se) => !se.isValid).length > 0;
        if (inputEmails.length === 0) {
            hasInvalidEmails = true;
        }

        isValid = !hasInvalidEmails;
    }

    const removeSpecial = (email) => {
        if (!email) {
            return '';
        }

        // Remove any empty space, non-ascii chars, allow only Ascii/Number/-/_/./@
        return email.replace(emailInvalidCharRegex, '');
    };

    const onShareeEmailEntered = (event) => {
        let inputVal = event.target.value.trim();
        if (!inputVal) {
            return false;
        }

        if (emailSpliters.includes(event.keyCode) || event.type === 'blur') {
            inputVal = removeSpecial(inputVal);
            addShareeEmail(inputVal);
            event.target.value = '';
        }
    };

    const pasteEmails = (event) => {
        const pastedContent = event.clipboardData.getData('text');
        // [,;\t\n] are common splitters for list of emails, we don't remove empty space below
        // while trim space while adding individual email
        const inputList = pastedContent
            .replace(emailListSplitterRegex, ' ')
            .split(' ');
        inputList.forEach((ipt) => {
            addShareeEmail(ipt);
        });
        setTimeout(() => {
            emailField.value = '';
        }, 10);
    };

    const removeEmail = (event) => {
        const emailInput = event.currentTarget.dataset.email;
        if (emailInput && emailInput.trim()) {
            inputEmails.splice(
                [inputEmails.findIndex((se) => se.email === emailInput.trim())],
                1
            );
            inputEmails = inputEmails;
        }
    };
</script>

<div
    name="email-field"
    on:keyup={onShareeEmailEntered}
    class="input is-shadowless email-field"
    class:disabled-click={folderShareInProgress}
    data-cy={dataCy}
>
    {#each inputEmails as { email, isValid }}
        <span
            class="email-label is-vertical-center is-justify-content-start {isValid
                ? 'has-background-grey-lighter'
                : 'invalid has-background-danger-light'}"
        >
            <img
                alt="Invalid"
                src="DRIVE_ASSETS/images/danger.svg"
                class={isValid ? 'is-hidden' : 'invalid-icon'}
            />
            <div class="has-ellipsis">
                {email}
            </div>
            <div class="is-inline is-narrow is-paddingless is-rightaligned">
                <button
                    class="button is-ghost is-shadowless"
                    data-email={email}
                    on:click={removeEmail}
                >
                    <span class="icon">
                        <CloseIcon />
                    </span>
                </button>
            </div>
        </span>
    {/each}
    <input
        bind:this={emailField}
        class="text is-borderless email-field-input is-focus is-size-6"
        type="email"
        name=""
        value=""
        {id}
        data-cy="{dataCy}-input"
        on:paste={pasteEmails}
        on:blur={onShareeEmailEntered}
        required
    />
</div>

<style>
    .email-field {
        padding: 2px 4px;
        min-height: 2.7em;
        height: auto;
        max-height: 150px;
        flex-wrap: wrap;
        overflow-y: auto;
    }
    .email-field .email-field-input {
        width: 100%;
    }
    .email-field .email-field-input:active,
    .email-field .email-field-input:focus {
        box-shadow: 0 0 0 0.25em #9bd5ef;
    }
    .email-field .email-field-input:invalid {
        /* Prevent Firefox enforced red color border for borderless input */
        box-shadow: 0 0 0px white;
    }

    .email-label {
        display: inline-block;
        padding: 2px 0 2px 4px;
        vertical-align: middle;
        min-width: 140px;
        max-width: 240px;
        max-height: 30px;
        margin: 2px;
    }

    .email-label img.invalid-icon {
        margin: 0 10px;
    }
    .email-label .icon:hover {
        stroke: #808080;
        stroke-width: 0.8px;
    }
</style>
