<script>
    export let asset = null;
    export let onClose = () => {};

    import {
        getPropValue,
        randomHex,
        invalidItemName,
    } from '../../providers/utils';
    import ModalComponent from '../elements/modal.svelte';
    import SomethingWentWrong from '../something.wrong.svelte';
    import { i18n } from '../../i18n';
    import {
        actions,
        categories,
        trackEventWithDataType,
    } from '../../providers/analytics/ga';
    import FileIcon from '../../icons/file.svelte';
    import FolderIcon from '../../icons/folder.svelte';
    import AssetModalHeader from './asset.modal.header.svelte';

    const id = `rename-asset-${randomHex()}`;
    const inputId = `asset_rename_${id}`;
    const labelId = `asset_rename_${id}_label`;
    const dataCy = 'rename-asset';
    const dataCyLabel = `${dataCy}-label`;
    const dataCyInput = `${dataCy}-input`;
    const dataCyConfirm = `${dataCy}-confirm`;
    const dataCyError = `${dataCy}-error`;

    let oldName = '';
    let newName = '';
    let ext = '';
    let icon = {};
    let modalInstance;
    let saveInProgress = false;
    let someError;
    let assetType = ''; // Folder/File

    oldName = newName = asset.itemInfo.name;
    if (asset.isFile) {
        // Strip extension (if any) from name
        let nameParts = oldName.split('.');
        if (nameParts.length > 1) {
            ext = `.${nameParts.pop().toLowerCase()}`;
        }
        oldName = newName = nameParts.join('.');
        icon = { component: FileIcon, props: { type: ext.substring(1) } };
        assetType = i18n.t('App.Asset_Info_Summary.File_Asset_Type');
    } else {
        icon = { component: FolderIcon, props: {} };
        assetType = i18n.t('App.Asset_Info_Summary.Folder_Asset_Type');
    }

    const options = {
        onCloseEnd: onClose,
    };

    $: namingIssue = invalidItemName(newName, { padding: ext.length });
    $: disabled = namingIssue || newName === oldName || saveInProgress;

    const renameAsset = async () => {
        someError = null;
        saveInProgress = true;
        try {
            await asset.rename(newName.trim() + ext);
            modalInstance.close();
            trackEventWithDataType({
                category: categories.fileManagement,
                action: asset.isFile
                    ? actions.fileRename
                    : actions.folderRename,
            });
        } catch (err) {
            if (getPropValue(err, 'response.status') === 400) {
                namingIssue = 'InvalidOrReserved'; // Drive_Update_Item - DriveError
            } else if (getPropValue(err, 'response.status') === 409) {
                namingIssue = 'Duplicate'; // Drive_Update_Item - DriveError
            } else {
                someError = err;
            }
        }
        saveInProgress = false;
    };

    const onEnter = (event) => {
        if (event.keyCode == 13 && !disabled) {
            renameAsset();
        }
    };
</script>

<ModalComponent {id} {options} {dataCy} bind:this={modalInstance}>
    <div slot="trigger">
        <slot name="rename-folder-trigger" />
    </div>
    <div
        class="has-text-weight-semibold has-all-space is-vertical-center is-marginless"
        slot="title"
    >
        <AssetModalHeader itemName={oldName} {dataCy} {modalInstance}>
            <svelte:component
                this={icon.component}
                {...icon.props}
                slot="icon"
            />
        </AssetModalHeader>
    </div>

    <div slot="content">
        <label class="field" id={labelId} for={inputId} data-cy={dataCyLabel}>
            <span class="is-size-6">
                {i18n.t('App.Asset_Actions.Rename_Label')}
            </span>
            <div class="is-gap" />
            <div class="control" class:is-loading={saveInProgress}>
                <input
                    class="input is-shadowless is-focus"
                    class:is-danger={namingIssue}
                    class:has-text-danger={namingIssue}
                    id={inputId}
                    data-cy={dataCyInput}
                    type="text"
                    on:keydown={onEnter}
                    bind:value={newName}
                />
            </div>
        </label>
        {#if namingIssue}
            <div class="is-gap" />
            <div class="has-text-grey-light" data-cy={dataCyError}>
                {i18n.t(`App.Naming_Issues.${namingIssue}`, {
                    assetType,
                    assetTypeLC: assetType.toLowerCase(),
                })}
            </div>
        {/if}
        {#if someError}
            <div class="is-gap" />
            <SomethingWentWrong
                error={someError}
                source={dataCy}
                inline={true}
            />
        {/if}
    </div>
    <div slot="footer-right-section">
        <button
            class="button"
            on:click={modalInstance.close}
            data-cy={`${dataCy}-cancel`}
        >
            {i18n.t('App.Primary_Buttons.Cancel_Btn')}
        </button>
        <button
            class="ga-{dataCyConfirm} button is-primary"
            on:click|stopPropagation={renameAsset}
            class:is-loading={saveInProgress}
            data-cy={dataCyConfirm}
            {disabled}
        >
            {i18n.t('App.Primary_Buttons.Save_Btn')}
        </button>
    </div>
</ModalComponent>
