import mem, { memClear } from 'mem';
import { CONNECT_2LO } from './utils';

const clear = (fn) => {
    memClear(fn);
};

const memoized = {};

export const clearGroup = (id) => {
    const calls = memoized[id];
    if (Array.isArray(calls)) {
        calls.forEach((call) => {
            clear(call);
        });
    }
};

const newGroup = mem(function (id, options = {}) {
    memoized[id] = [];
    return {
        memoize: (fn, settings = {}) => {
            const call = mem(fn, {
                maxAge: 5 * 60 * 1000, // 5 minutes
                ...options,
                ...settings,
            });
            memoized[id].push(call);
            return call;
        },
        reset: () => {
            clearGroup(id);
        },
    };
});

// Respond to user logged in state
export const clearCache = () => {
    for (let id in memoized) {
        if (id !== CONNECT_2LO) {
            clearGroup(id);
        }
    }
};

memoized.defaultGroup = newGroup('fusion');
const defaultGroup = memoized.defaultGroup;

memoized.instancesGroup = newGroup('instances', {
    maxAge: 8.64e7, // 24 hours
});
const instancesGroup = memoized.instancesGroup;

export default {
    defaultGroup,
    instancesGroup,
    newGroup,
    clear,
    clearCache,
    clearGroup, // Not consumed (yet)
};
