<script>
    export let asset = null;
    export let onClose = () => {};

    import { randomHex, getPermissionsFromRole } from '../../providers/utils';
    import { notificationsStore } from '../../components/elements/notifications.store.js';
    import ModalComponent from '../elements/modal.svelte';
    import ManageFolderShare from './folder.share/folder.share.manage.svelte';
    import EmailFieldComponent from '../../components/elements/email.field.svelte';
    import AssetModalHeader from './asset.modal.header.svelte';
    import SharePermissionsComponent from './folder.share/folder.share.permissions.svelte';
    import SomethingWentWrong from '../something.wrong.svelte';
    import { i18n } from '../../i18n';
    import FolderIcon from '../../icons/folder.svelte';
    import config from 'app.config';

    const dataCy = 'share-folder';
    const id = `share-folder-${randomHex()}`;

    let modalInstance,
        lastError,
        folderShareInProgress = false;

    const options = {
        onCloseEnd: onClose,
        showInfoPanel: false,
    };

    const name = asset.itemInfo.name;

    let inputEmails = [],
        areEmailsValid;

    $: {
        (async () => {
            options.showInfoPanel = await asset.content.hasNotViewableFile();
        })();
    }

    // Can View has issues - DRIVE-908
    // Can Download - default
    let defaultPermission = {
        action: 'download',
        permissions: getPermissionsFromRole('download').join(),
    };

    const sendInvite = async () => {
        folderShareInProgress = true;
        try {
            handleNewSharees();
            await updatePermissions();
        } catch (error) {
            lastError = error;
        }
        folderShareInProgress = false;
    };

    const removeSharee = async (detail) => {
        folderShareInProgress = true;
        try {
            await asset.sharing.upsert(changes);
            changes = [];

            // Wait for refresh before showing success message
            const shareInfo = await asset.sharing.info();
            notificationsStore.add({
                type: 'success',
                body: 'App.Folder_Sharing.Remove_Sharee_Success_Message',
                bodyParams: { USER_FULLNAME: i18n.displayFullName(detail) },
                timeout: 3000,
            });

            // Auto-close if we removed the last sharee
            if (!shareInfo.length) {
                modalInstance.close();
            }
        } catch (error) {
            lastError = error;
        }
        folderShareInProgress = false;
    };

    const handleNewSharees = (change) => {
        // If user has changed default permission for new sharees
        // then update the default
        if (change) {
            defaultPermission = {
                action: change.action,
                permissions: change.newPermissions,
            };
        }

        if (areEmailsValid) {
            inputEmails.forEach((inputEmail) => {
                let index = changes.findIndex(
                    (change) => change.emailId === inputEmail.email
                );
                if (index > -1) {
                    changes.splice(index, 1);
                }
                changes = [
                    ...changes,
                    {
                        emailId: inputEmail.email,
                        ...defaultPermission,
                    },
                ];
            });
        }
    };

    let changes = [];
    const updatePermissions = async () => {
        if (changes.length > 0) {
            try {
                const { isUpsert, isShareWithOwner, isNotAutodeskUser } =
                    await asset.sharing.upsert(changes);

                const folderOwner = asset.itemInfo.createdBy;
                changes = [];
                if (isShareWithOwner) {
                    notificationsStore.add({
                        type: 'warning',
                        body: 'App.Folder_Sharing.Access_Exist_Message',
                        bodyParams: {
                            EMAIL_WITH_LINK: `<a class="has-text-weight-bold" href="mailto:${folderOwner.emailId}">${folderOwner.emailId}</a>`,
                        },
                    });
                }
                if (isNotAutodeskUser) {
                    notificationsStore.add({
                        type: 'warning',
                        body: 'App.Folder_Sharing.Share_Not_Autodesk_User_Message',
                        learnMoreLinkHref: config.links.folderShare,
                    });
                }
                // Show success message at top
                if (isUpsert) {
                    notificationsStore.add({
                        type: 'success',
                        body: 'App.Folder_Sharing.Share_Success_Message',
                        timeout: 3000,
                    });
                }

                modalInstance.close();
            } catch (error) {
                lastError = error;
            }
        }
    };

    const onChangePermissions = async ({ detail }) => {
        if (detail.isManage) {
            // DRIVE-787
            if (detail.action === 'remove') {
                changes = [
                    {
                        action: detail.action,
                        emailId: detail.emailId,
                        permissions: detail.newPermissions,
                    },
                ];
                return removeSharee(detail);
            }
            let index = changes.findIndex(
                (change) => change.emailId === detail.emailId
            );
            if (index > -1) {
                changes.splice(index, 1);
            }
            changes = [
                ...changes,
                {
                    action: detail.action,
                    emailId: detail.emailId,
                    permissions: detail.newPermissions,
                },
            ];
        } else {
            handleNewSharees(detail);
        }
    };
</script>

<ModalComponent
    {id}
    {dataCy}
    bind:this={modalInstance}
    {options}
    hasMaxHeight={true}
>
    <div class="has-all-space is-marginless" slot="title">
        <AssetModalHeader itemName={name} {modalInstance} {dataCy}>
            <FolderIcon fill="#0696D7" slot="icon" />
        </AssetModalHeader>
    </div>
    <div slot="content">
        <label class="label" for=""
            >{i18n.t('App.Folder_Sharing.Share_With_Label')}</label
        >
        <div class="field is-grouped">
            <p class="control is-expanded">
                <EmailFieldComponent
                    bind:inputEmails
                    bind:isValid={areEmailsValid}
                    {folderShareInProgress}
                />
            </p>
            <p class="control">
                <SharePermissionsComponent
                    on:changePermission={onChangePermissions}
                    {folderShareInProgress}
                />
            </p>
        </div>

        <div class="is-gap" />
        <ManageFolderShare
            {asset}
            {folderShareInProgress}
            on:changePermission={onChangePermissions}
        />
        {#if lastError}
            <SomethingWentWrong
                error={lastError}
                source={dataCy}
                inline={true}
            />
        {/if}
    </div>
    <div slot="footer-right-section" class="level-item">
        <button
            class="button"
            on:click={modalInstance.close}
            data-cy={`${dataCy}-cancel`}
        >
            {i18n.t('App.Primary_Buttons.Cancel_Btn')}
        </button>
        <button
            class="button is-primary"
            on:click={sendInvite}
            disabled={folderShareInProgress ||
                (inputEmails.length && !areEmailsValid) ||
                (!inputEmails.length && !changes.length)}
            class:is-loading={folderShareInProgress}
            data-cy={`${dataCy}-send-invite-btn`}
            data-tooltip={inputEmails.length === 0 && changes.length === 0
                ? i18n.t('App.Forms.Add_At_Least_One_Email')
                : undefined}
        >
            {i18n.t('App.Folder_Content_List.Share_Folder_Action')}
        </button>
    </div>
    <div slot="trigger">
        <slot name="share-folder-trigger" />
    </div>
    <div slot="info-content">
        <h4 class="is-size-6">
            {i18n.t(
                'App.Viewing_Actions.Private_Folder_Share.Not_Viewable_File_Type_Subject'
            )}
        </h4>
        <p class="is-size-7">
            {i18n.t(
                'App.Viewing_Actions.Private_Folder_Share.Not_Viewable_File_Type_Info'
            )}
            {@html i18n.t(
                'App.Viewing_Actions.Learn_More_Viewable_File_Type_Link',
                {
                    HTML_START:
                        '<a href="' +
                        config.links.learnMoreViewableFileTypes +
                        '" target="_blank">',
                    HTML_END: '</a>',
                }
            )}
        </p>
    </div>
</ModalComponent>
