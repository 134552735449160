<script>
    export let assets = null;

    import Desktop from '../../icons/desktop.svelte';
    import { i18n } from '../../i18n';
    import { showOpenOnDesktopBanner } from '../../providers/state/user';

    const dataCyOpenOnDesktop = 'open-on-desktop';

    const openAssetOnDesktop = async () => {
        showOpenOnDesktopBanner.set(true);
        await assets[0].openOnDesktop();
    };

    let enableOpenOnDesktop = false;

    $: {
        enableOpenOnDesktop =
            navigator.platform === 'Win32' &&
            assets.length === 1 &&
            assets[0].isFile;
    }
</script>

{#if enableOpenOnDesktop}
    <div
        class="ga-{dataCyOpenOnDesktop} {dataCyOpenOnDesktop} dropdown-item is-vertical-center can-hover"
        data-cy={dataCyOpenOnDesktop}
        on:click={openAssetOnDesktop}
    >
        <span class="icon">
            <Desktop />
        </span>
        <span>
            {i18n.t('App.Folder_Content_List.Open_On_Desktop')}
        </span>
    </div>
{/if}

<style>
    .open-on-desktop:hover {
        box-shadow: 0 0 0 0.12em #9bd5ef;
        border-radius: 4px;
    }
</style>
