<script>
    export let asset = null;
    export let refreshSharedWithMe = null;
    export let onClose = () => {};

    import { randomHex } from '../../../providers/utils';
    import ModalComponent from '../../elements/modal.svelte';
    import SomethingWentWrong from '../../something.wrong.svelte';
    import { notificationsStore } from '../../../components/elements/notifications.store.js';
    import { i18n } from '../../../i18n';
    import CloseIcon from '../../../icons/close.svelte';

    const dataCy = 'share-folder-leave';
    const id = `${dataCy}-${randomHex()}`;

    let modalInstance;
    let leaveFolderInProgress = false;
    let lastError;

    const options = {
        onCloseEnd: onClose,
    };

    const leaveShareFolder = async () => {
        leaveFolderInProgress = true;
        try {
            await asset.sharing.leaveFolder();
            notificationsStore.add({
                type: 'success',
                body: 'App.Folder_Sharing.Leave_Folder_Success_Message',
                bodyParams: { FOLDER_NAME: asset.itemInfo.name },
                timeout: 3000,
            });
            modalInstance.close();
            refreshSharedWithMe(asset);
        } catch (error) {
            lastError = error;
        }
        leaveFolderInProgress = false;
    };
</script>

<ModalComponent {id} {dataCy} {options} bind:this={modalInstance}>
    <div slot="trigger">
        <slot name="leave-folder-trigger" />
    </div>
    <div
        class="columns has-all-space is-vertical-center is-marginless"
        slot="title"
    >
        <div class="column is-paddingless">
            {i18n.t('App.Folder_Sharing.Leave_Folder_Title')}
        </div>
        <div class="column is-narrow is-paddingless">
            <span
                class="icon delete-icon is-pulled-right"
                data-cy={`${dataCy}-close`}
                on:click={modalInstance.close}
            >
                <CloseIcon />
            </span>
        </div>
    </div>
    <div slot="content">
        {i18n.t('App.Folder_Sharing.Leave_Folder_Msg')}
        {#if lastError}
            <SomethingWentWrong
                error={lastError}
                source={dataCy}
                inline={true}
            />
        {/if}
    </div>
    <div slot="footer-right-section">
        <button
            class="button"
            data-cy="{dataCy}-cancel"
            on:click={modalInstance.close}
        >
            {i18n.t('App.Primary_Buttons.Cancel_Btn')}
        </button>
        <button
            class="ga-{dataCy}-confirm button is-primary"
            class:is-loading={leaveFolderInProgress}
            on:click={leaveShareFolder}
            data-cy="{dataCy}-confirm"
        >
            {i18n.t('App.Folder_Sharing.Leave_Folder_Btn')}
        </button>
    </div>
</ModalComponent>
