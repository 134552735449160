<script>
    export let fill;
    export let height = 24;
    export let width = 24;

    $: if (!fill) {
        fill = '#666666';
    }
</script>

<svg
    {width}
    {height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.8398 18.1L13.0698 3.75002C12.9586 3.56577 12.8016
        3.41338 12.6141 3.3076C12.4267 3.20182 12.2151 3.14624
        11.9998 3.14624C11.7846 3.14624 11.573 3.20182 11.3855
        3.3076C11.1981 3.41338 11.0411 3.56577 10.9298
        3.75002L2.15983 18.1C2.04435 18.2897 1.98149 18.5067
        1.97772 18.7288C1.97395 18.9508 2.0294 19.1699 2.13838
        19.3634C2.24735 19.5569 2.40591 19.7179 2.59774
        19.8298C2.78957 19.9417 3.00775 20.0004 3.22983
        20H20.7698C20.9919 20.0004 21.2101 19.9417 21.4019
        19.8298C21.5938 19.7179 21.7523 19.5569 21.8613
        19.3634C21.9703 19.1699 22.0257 18.9508 22.0219
        18.7288C22.0182 18.5067 21.9553 18.2897 21.8398
        18.1ZM20.9898 18.87C20.9691 18.9102 20.9375 18.9437
        20.8986 18.9667C20.8597 18.9897 20.815 19.0013 20.7698
        19H3.22983C3.18298 19.0032 3.13622 18.9926 3.09533
        18.9695C3.05445 18.9464 3.02125 18.9118 2.99983
        18.87C2.97573 18.8328 2.9629 18.7894 2.9629
        18.745C2.9629 18.7007 2.97573 18.6573 2.99983
        18.62L11.7898 4.27002C11.8106 4.23251 11.841 4.20124
        11.8779 4.17947C11.9149 4.15769 11.957 4.14621 11.9998
        4.14621C12.0427 4.14621 12.0848 4.15769 12.1217
        4.17947C12.1587 4.20124 12.1891 4.23251 12.2098
        4.27002L20.9998 18.62C21.0239 18.6573 21.0368 18.7007
        21.0368 18.745C21.0368 18.7894 21.0239 18.8328 20.9998
        18.87H20.9898ZM12 8C11.7348 8 11.4804 8.10536 11.2929
        8.29289C11.1054 8.48043 11 8.73478 11 9L11.5 13.49C11.5
        13.67 11.61 13.82 11.75 13.82H12.25C12.39 13.82 12.5
        13.67 12.5 13.49L13 9C13 8.73478 12.8946 8.48043 12.7071
        8.29289C12.5196 8.10536 12.2652 8 12 8ZM11.4444
        15.1685C11.6089 15.0586 11.8022 15 12 15C12.2652 15
        12.5196 15.1054 12.7071 15.2929C12.8946 15.4804 13
        15.7348 13 16C13 16.1978 12.9414 16.3911 12.8315
        16.5556C12.7216 16.72 12.5654 16.8482 12.3827
        16.9239C12.2 16.9996 11.9989 17.0194 11.8049
        16.9808C11.6109 16.9422 11.4327 16.847 11.2929
        16.7071C11.153 16.5673 11.0578 16.3891 11.0192
        16.1951C10.9806 16.0011 11.0004 15.8 11.0761
        15.6173C11.1518 15.4346 11.28 15.2784 11.4444 15.1685Z"
        {fill}
    />
</svg>
