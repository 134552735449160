<script>
    export let fill = '#666666';
    export let size = 26;
    const width = size;
    const height = size;
</script>

<svg
    {width}
    {height}
    viewBox="7 7 {width}
    {height}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 17H25V27C25 27.5523 24.5523 28 24 28H16C15.4477 28 15 27.5523 15
        27V17ZM22 14H18V13.5C18 13.2239 18.2239 13 18.5 13H21.5C21.7761 13 22
        13.2239 22 13.5V14ZM14.6 14C14.2686 14 14 14.2686 14 14.6V16H26V14.6C26
        14.2686 25.7314 14 25.4 14H23V13.5C23 12.6716 22.3284 12 21.5
        12H18.5C17.6716 12 17 12.6716 17 13.5V14H14.6Z"
        {fill}
    />
</svg>
