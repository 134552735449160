<script>
    export let fill;
    export let height = 32;
    export let width = 33;
    $: if (!fill) {
        fill = '#808080';
    }
</script>

<svg
    {width}
    {height}
    viewBox="0 0 {width}
    {height}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M9.19141 14.5C8.36141 14.5 7.69141 15.17 7.69141 16C7.69141 16.83
        8.36141 17.5 9.19141 17.5C10.0214 17.5 10.6914 16.83 10.6914 16C10.6914
        15.17 10.0214 14.5 9.19141 14.5ZM9.19141 8.5C8.36141 8.5 7.69141 9.17
        7.69141 10C7.69141 10.83 8.36141 11.5 9.19141 11.5C10.0214 11.5 10.6914
        10.83 10.6914 10C10.6914 9.17 10.0214 8.5 9.19141 8.5ZM9.19141
        20.5C8.36141 20.5 7.69141 21.18 7.69141 22C7.69141 22.82 8.37141 23.5
        9.19141 23.5C10.0114 23.5 10.6914 22.82 10.6914 22C10.6914 21.18 10.0214
        20.5 9.19141 20.5ZM12.1914 23H26.1914V21H12.1914V23ZM12.1914
        17H26.1914V15H12.1914V17ZM12.1914 9V11H26.1914V9H12.1914Z"
        {fill}
    />
</svg>
