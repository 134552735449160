<script>
    import { isMobile, isTablet, isDesktop } from '../../providers/device';

    import { onMount, onDestroy } from 'svelte';

    let hiddenInMobileElement, hiddenInTabletElement, hiddenInDesktopElement;

    const isHidden = (el) => {
        return el.getClientRects && el.getClientRects().length === 0;
    };

    const detect = () => {
        const mobile = isHidden(hiddenInMobileElement);
        const tablet = isHidden(hiddenInTabletElement);
        const desktop = isHidden(hiddenInDesktopElement);

        if ($isMobile !== mobile) {
            isMobile.set(mobile);
        }

        if ($isTablet !== tablet) {
            isTablet.set(tablet);
        }

        if ($isDesktop !== desktop) {
            isDesktop.set(desktop);
        }
    };

    onMount(() => {
        detect();
        window.addEventListener('resize', detect);
    });

    onDestroy(() => {
        window.removeEventListener('resize', detect);
    });
</script>

<!-- https://bulma.io/documentation/modifiers/responsive-helpers/ -->

<span class="is-hidden-mobile" bind:this={hiddenInMobileElement} />
<span class="is-hidden-tablet-only" bind:this={hiddenInTabletElement} />
<span class="is-hidden-desktop" bind:this={hiddenInDesktopElement} />
