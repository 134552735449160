<script>
    export let fill = '#666666';
    export let height = 24;
    export let width = 24;
</script>

<svg xmlns="http://www.w3.org/2000/svg" {width} {height} viewBox="0 0 24 24">
    <path
        {fill}
        d="M11,14.37v1.26a.35.35,0,0,0,.35.35h7.3a.35.35,0,0,0,.35-.35V14.37a.35.35,0,0,0-.35-.35h-7.3A.35.35,0,0,0,11,14.37ZM11.35,21h9.3a.35.35,0,0,0,.35-.35V19.33a.35.35,0,0,0-.35-.35h-9.3a.35.35,0,0,0-.35.35v1.32A.35.35,0,0,0,11.35,21ZM11,9.35v1.32a.35.35,0,0,0,.35.35h5.3a.35.35,0,0,0,.35-.35V9.35A.35.35,0,0,0,16.65,9h-5.3A.35.35,0,0,0,11,9.35Z"
    />
    <path
        {fill}
        d="M7.13,3.15l3.48,3.48a.2.2,0,0,1-.15.35H8V20.71A.27.27,0,0,1,7.7,21H6.27A.27.27,0,0,1,6,20.71V7H3.51a.2.2,0,0,1-.15-.35L6.84,3.15A.21.21,0,0,1,7.13,3.15Z"
    />
</svg>
