<script>
    export let fill;
    export let height = 24;
    export let width = 24;
    $: if (!fill) {
        fill = '#808080';
    }
</script>

<svg
    {width}
    {height}
    viewBox="0 0 17 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.5 0.0875244L17 9.80181H0L8.5 0.0875244Z"
        {fill}
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.5 31.659L8.4925e-07 21.9447L17 21.9447L8.5 31.659Z"
        {fill}
    />
</svg>
