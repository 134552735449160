<script>
    export let fill = '#0696D7';
    export let height = 17;
    export let width = 15;
</script>

<svg
    {width}
    {height}
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 3C8 4.7 6.7 6 5 6C3.3 6 2 4.7 2 3C2 1.3 3.3 0 5 0C6.7 0 8 1.3 8 3ZM11.7 9H9.7V11H7.7V13H9.7V15H11.7V13H13.7V11H11.7V9ZM6.7 10H7.7H8.7V9V8.6C7.7 7.6 6.4 7 5 7C2.2 7 0 9.2 0 12H6.7V11V10Z"
        {fill}
    />
</svg>
