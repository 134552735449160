import { writable } from 'svelte/store';
import { randomHex } from '../../providers/utils';

// use these to add emphasis markers to a notification body
const i18nEmphasisParams = {
    EMPHASIS_START: '<strong>',
    EMPHASIS_END: '</strong>',
    LINE_BREAK: '<br />',
};

export const notificationsStore = (() => {
    const { subscribe, set } = writable([]);

    function _getStore() {
        let items = [];
        subscribe((store) => (items = store))(); // immediately unsubscribe
        return items;
    }

    function add({
        type = 'info',
        title = '',
        body = '-',
        bodyParams = {},
        learnMoreLinkHref = '',
        timeout = 0,
        onlyOne = true, // Only show one with this body at a time
        viewAllAction = null,
    }) {
        const id = `notification-${randomHex()}`;
        const notification = {
            id,
            type,
            title,
            body,
            onlyOne,
            learnMoreLinkHref,
            bodyParams: Object.assign(bodyParams, i18nEmphasisParams),
            viewAllAction,
        };

        const notifications = _getStore();
        // Check for duplicates
        const index = notifications.findIndex(
            (element) =>
                notification.onlyOne && element.body === notification.body // check all notifications only if notification has onlyOne attribute
        );
        if (index === -1) {
            delete notification.onlyOne; // remove onlyOne before notification is rendered
            // Latest notification at top
            set([notification, ...notifications]);
            if (timeout) {
                setTimeout(() => {
                    close(notification.id);
                }, timeout);
            }
        }

        return id;
    }

    const close = (notificationId) => {
        const notifications = _getStore();
        const index = notifications.findIndex(
            (notification) => notification.id === notificationId
        );
        if (index > -1) {
            notifications.splice(index, 1);
            set([...notifications]);
        }
    };

    const clearAll = () => {
        set([]);
    };

    return {
        subscribe,
        add,
        close,
        clearAll,
    };
})();
