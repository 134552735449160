<script>
    export let content = {};
    export let assetActions = {};

    import FolderGridItem from './folder.grid.item.svelte';
    import FileGridItem from './file.grid.item.svelte';
    import { i18n } from '../../../i18n';

    const dataCy = 'folder-content';
</script>

<div class="section">
    <div class="section-body" data-cy={dataCy}>
        {#if content.folders.length > 0}
            <div class="is-gap" />
            <div class="columns is-multiline is-marginless">
                <div class="column is-full">
                    <div class="subtitle is-7 has-text-weight-semibold">
                        {i18n.t(
                            'App.Folder_Content_List.Folders_Thumbnail_Group'
                        )}
                    </div>
                </div>
                {#each content.folders as folder (folder.id)}
                    <FolderGridItem {folder} {assetActions} />
                {/each}
            </div>
        {/if}
        {#if content.files.length > 0}
            <div class="columns is-multiline is-marginless">
                <div class="column is-full">
                    <div class="subtitle is-7 has-text-weight-semibold">
                        {i18n.t(
                            'App.Folder_Content_List.Files_Thumbnail_Group'
                        )}
                    </div>
                </div>
                {#each content.files as file (file.idDecoded)}
                    <FileGridItem {file} {assetActions} />
                {/each}
            </div>
        {/if}
    </div>
</div>

<style>
    .columns {
        padding: 2px;
    }
</style>
