<script>
    export let folder;

    import { goto, goToFile } from '../../providers/navigation';
    import { onDestroy } from 'svelte';
    import {
        isDesktop,
        isTouchDevice,
        isTabletOrDesktop,
    } from '../../providers/device';
    import DropDownComponent from '../elements/dropdown.svelte';
    import DownArrowIcon from '../../icons/down.arrow.svelte';
    import FolderContentList from './list.mode/folder.content.list.svelte';
    import FolderContentGrid from './grid.mode/folder.content.grid.svelte';
    import ListLoader from '../list.loader.svelte';
    import ThumbnailGridIcon from '../../icons/thumbnail.grid.svelte';
    import ListIcon from '../../icons/list.svelte';
    import EmptyFolder from './empty.folder.svelte';
    import SomethingWentWrong from '../something.wrong.svelte';
    import AssetInfoComponent from './asset.info.svelte';
    import InfoIcon from '../../icons/info.svelte';
    import BulkActionsComponent from './bulk.actions.svelte';
    import {
        categories,
        actions,
        trackEventWithDataType,
    } from '../../providers/analytics/ga';
    import { i18n } from '../../i18n';
    import { gActiveInfoPanel, gSelection } from '../elements/components.state';
    import SelectionArea from '../elements/selection.area';
    import {
        isDisplayingSharedFolders,
        SHARED_WITH_ME_PATH,
    } from '../../providers/state/active.record';
    import { gEntitled, gDriveInfo } from '../../providers/state/user';
    import OpenOnDesktopComponent from './open.desktop.svelte';

    const primaryColor = '#0696D7';
    const dataCyFolderContainer = 'folder-container';
    const dataCyDropdown = 'sort-dropdown';
    const dataCySortBy = 'sort-by';
    const dataCySortOrder = 'sort-order';
    const dataCyGridView = 'grid-view';
    const dataCyListView = 'list-view';
    const dataCyInfoPanel = 'info-panel';
    const dataCyInfo = 'info';

    let getContent, content, observers;

    const toggleInfo = () => {
        $gActiveInfoPanel = !$gActiveInfoPanel;
    };

    const canSelect = () => {
        return document.getElementsByClassName('modal is-active').length == 0;
    };

    const selectionOptions = {
        selectableCls: 'selectable-item',
        canSelect,
        keepSelectionFor: [
            'sort-options',
            'view-options',
            'info-panel',
            'bulk-operations',
            'open-on-desktop',
        ],
    };

    // Called whenever the selection changes
    const onSelection = (event) => {
        const newSelection = [];
        event.detail.selection.forEach((element) => {
            const idDecoded = element.id.split(/(-list-item|-grid-item)/g)[0];
            const arr =
                content[
                    idDecoded.includes(':fs.folder:') ? 'folders' : 'files'
                ];
            const item = arr.find((item) => item.idDecoded === idDecoded);
            newSelection.push(item);
        });

        // Now, trigger a reactive update
        gSelection.set(newSelection);
    };

    let gridMode = false;

    const gridModeOn = (mode) => {
        return () => {
            gridMode = mode;
        };
    };

    const nameFilter = {
        id: 'name',
        title: i18n.t('App.Folder_Content_List.Sort_Options.Sort_By_Name'),
        dataCy: 'sort-by-name',
    };

    const lastUpdatedFilter = {
        id: 'lastUpdated',
        title: i18n.t(
            'App.Folder_Content_List.Sort_Options.Sort_By_Last_Updated'
        ),
        dataCy: 'sort-by-updated',
    };

    const filters = [nameFilter, lastUpdatedFilter];

    let activeFilter = nameFilter;
    let ascending = true;

    const getContentForFolder = async (thisFolder) => {
        const sortFcn =
            activeFilter === lastUpdatedFilter
                ? 'sortedByLastModified'
                : 'sortedByName';
        return thisFolder.content[sortFcn](ascending).then((items) => {
            // There can me multiple, concurrent fetches of varying durations,
            // and they can resolve out of order (especially 'Shared with Me').
            // Only update 'content' if its for the current 'folder'.
            // In the markup, only the last resolved value is processed.
            if (thisFolder === folder) {
                content = items;
            }
            trackEventWithDataType({
                category: categories.fileManagement,
                action: actions.folderListContent,
                label: thisFolder.idDecoded,
                value: content.count,
            });
            return items;
        });
    };

    const assignGetContent = () => {
        getContent = getContentForFolder(folder);
    };

    let requestedToDelayRefresh = false;
    let hasContentToRefresh = false;
    let sharedWithMe;
    let folderId;

    $: if ($gDriveInfo && folder && folder.id != folderId) {
        folderId = folder.id;

        if (observers) {
            observers.cancel();
        }
        observers = folder.observe({
            /*  When user is moving/copying file user can create new folders.
                In that case we need to delay refresh. Look for `delayRefresh` function below.
            */
            content: () => {
                if (requestedToDelayRefresh) {
                    hasContentToRefresh = true;
                } else {
                    assignGetContent();
                }
            },
        });
        assignGetContent();

        sharedWithMe = folder.id === SHARED_WITH_ME_PATH;
        if (sharedWithMe) {
            assetActions.refreshSharedWithMe = refreshSharedWithMe;
        }
    }

    const filterBy = (filter) => {
        return () => {
            if (activeFilter) {
                activeFilter.active = false;
            }
            filter.active = true;
            activeFilter = filter;
            assignGetContent();
        };
    };

    const toggleSortOrder = () => {
        ascending = !ascending;
        assignGetContent();
    };

    const gotoEvent = (event) => {
        return $isTouchDevice
            ? event.type === 'click'
            : event.type === 'dblclick';
    };

    const gotoFolder = (folder) => {
        return (event) => {
            if (gotoEvent(event)) {
                goto(`/folders/${folder.id}`);
            }
        };
    };
    const gotoFile = (...args) => {
        return (event) => {
            if (gotoEvent(event)) {
                goToFile(...args);
            }
        };
    };

    // Used by child components to delay the refresh when new content is available.
    const delayRefresh = (delayIt) => {
        requestedToDelayRefresh = delayIt;
        if (!delayIt && hasContentToRefresh) {
            assignGetContent();
        }
    };

    // On Leave PFS refresh the shared with me view.
    const refreshSharedWithMe = (childFolder) => {
        folder.content.childFolderRemoved(childFolder);
        assignGetContent();
    };

    let assetActions = {
        gotoFolder,
        gotoFile,
        delayRefresh,
    };

    onDestroy(() => {
        if (observers) {
            observers.cancel();
        }
    });

    const MultiSelect = (node, options) => {
        return $isTouchDevice ? {} : SelectionArea(node, options);
    };

    $: responsiveIconClass = $gActiveInfoPanel ? '-panel-open' : '';
</script>

<div
    class="columns is-marginless has-all-space is-mobile is-relative"
    class:trim={$gActiveInfoPanel && $isDesktop}
>
    <div class="column is-full is-paddingless folder-content">
        <div class="section">
            {#await getContent}
                <ListLoader />
            {:then content}
                {#if content.count > 0}
                    <div class="section-head is-vertical-center">
                        <div
                            class="filter-bar columns is-marginless
                                has-all-space is-strong-border is-mobile"
                        >
                            <div class="column is-vertical-center is-narrow">
                                <div id="sort-options" class="field has-addons">
                                    <p class="control">
                                        <DropDownComponent {dataCyDropdown}>
                                            <button
                                                class="ga-{dataCySortBy} button has-tooltip-right
                                                    has-tooltip-arrow has-tooltip-hidden-mobile"
                                                slot="trigger"
                                                data-cy={dataCySortBy}
                                                data-tooltip={i18n.t(
                                                    'App.Folder_Content_List.Sort_By_Tooltip'
                                                )}
                                            >
                                                <span>
                                                    {activeFilter.title}
                                                </span>
                                            </button>
                                            <div slot="content">
                                                {#each filters as filter (filter.id)}
                                                    <button
                                                        class="dropdown-item
                                                            is-borderless"
                                                        class:is-selected={filter.active}
                                                        data-cy={filter.dataCy}
                                                        on:click={filterBy(
                                                            filter
                                                        )}
                                                    >
                                                        {filter.title}
                                                    </button>
                                                {/each}
                                            </div>
                                        </DropDownComponent>
                                    </p>
                                    <p class="control">
                                        <button
                                            class="ga-{dataCySortOrder} button has-tooltip-right
                                                has-tooltip-arrow has-tooltip-hidden-mobile"
                                            data-cy={dataCySortOrder}
                                            data-tooltip={i18n.t(
                                                'App.Folder_Content_List.Toggle_Sort_Order_Tooltip'
                                            )}
                                            on:click|stopPropagation={toggleSortOrder}
                                        >
                                            <span
                                                class="icon is-small"
                                                class:up={!ascending}
                                            >
                                                <DownArrowIcon />
                                            </span>
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div
                                class="column is-vertical-center is-pulled-left"
                            >
                                {#if !$isDisplayingSharedFolders}
                                    <div id="bulk-operations">
                                        <BulkActionsComponent
                                            {folder}
                                            assets={$gSelection}
                                        />
                                    </div>
                                {/if}
                            </div>
                            <div
                                id="open-on-desktop"
                                class="column is-vertical-center is-narrow is-rightaligned"
                            >
                                <OpenOnDesktopComponent assets={$gSelection} />
                            </div>
                            <div class="column is-vertical-center is-narrow">
                                <div id="view-options" class="buttons">
                                    {#if gridMode}
                                        <button
                                            class="ga-{dataCyListView}{responsiveIconClass} button is-light
                                                has-tooltip-left has-tooltip-arrow
                                                has-tooltip-hidden-mobile"
                                            data-cy={dataCyListView}
                                            data-tooltip={i18n.t(
                                                'App.Folder_Content_List.List_View_Tooltip'
                                            )}
                                            on:click|stopPropagation={gridModeOn(
                                                false
                                            )}
                                        >
                                            <span class="icon is-small">
                                                <ListIcon
                                                    fill={!gridMode
                                                        ? primaryColor
                                                        : null}
                                                />
                                            </span>
                                        </button>
                                    {:else}
                                        <button
                                            class="ga-{dataCyGridView}{responsiveIconClass} button is-light
                                                has-tooltip-left has-tooltip-arrow
                                                has-tooltip-hidden-mobile"
                                            data-cy={dataCyGridView}
                                            data-tooltip={i18n.t(
                                                'App.Folder_Content_List.Grid_View_Tooltip'
                                            )}
                                            on:click|stopPropagation={gridModeOn(
                                                true
                                            )}
                                        >
                                            <span class="icon is-small">
                                                <ThumbnailGridIcon
                                                    fill={gridMode
                                                        ? primaryColor
                                                        : null}
                                                />
                                            </span>
                                        </button>
                                    {/if}
                                    <button
                                        class="ga-{dataCyInfo}{responsiveIconClass} button is-light has-tooltip-left
                                            is-hidden-mobile
                                            has-tooltip-arrow has-tooltip-hidden-mobile"
                                        data-cy={dataCyInfo}
                                        data-tooltip={i18n.t(
                                            'App.Folder_Content_List.Show_Info_Tooltip'
                                        )}
                                        on:click|stopPropagation={toggleInfo}
                                        class:is-selected={$gActiveInfoPanel}
                                    >
                                        <span class="icon is-small">
                                            <InfoIcon
                                                fill={$gActiveInfoPanel
                                                    ? primaryColor
                                                    : null}
                                            />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="section-body is-size-6-7"
                        id={`${folder.idDecoded}-container`}
                        data-cy={dataCyFolderContainer}
                        use:MultiSelect={selectionOptions}
                        on:selection={onSelection}
                    >
                        {#if gridMode}
                            <FolderContentGrid {content} {assetActions} />
                        {:else}
                            <FolderContentList {content} {assetActions} />
                        {/if}
                    </div>
                {:else}
                    <EmptyFolder
                        message={sharedWithMe
                            ? i18n.t(
                                  'App.Shared_Collections.No_Shared_Data_Msg'
                              )
                            : $gEntitled === false
                            ? i18n.t(
                                  'App.Folder_Content_List.Upload_Disabled_Limited_Access'
                              )
                            : i18n.t(
                                  'App.Folder_Content_List.Upload_Files_Msg'
                              )}
                    />
                {/if}
            {:catch error}
                <SomethingWentWrong
                    {error}
                    source={dataCyFolderContainer}
                    assetType="folder"
                />
            {/await}
        </div>
    </div>
    {#if $gActiveInfoPanel}
        <div
            id={dataCyInfoPanel}
            data-cy={dataCyInfoPanel}
            class="column is-narrow info-panel has-border is-strong-border is-hidden-mobile
                fadeIn has-background-white"
            class:floating={isTabletOrDesktop}
        >
            <AssetInfoComponent {folder} onClose={toggleInfo} />
        </div>
    {/if}
</div>

<style>
    .up {
        transform: rotate(180deg);
    }

    .info-panel {
        width: 300px !important;
    }

    .floating {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .trim .folder-content {
        max-width: calc(100% - 300px);
    }

    .filter-bar {
        padding-top: 15px;
    }

    @media only screen and (max-width: 1142px) {
        .ga-info-panel-open {
            display: none;
        }
    }
    @media only screen and (max-width: 1094px) and (min-width: 1023px) {
        .ga-list-view-panel-open,
        .ga-grid-view-panel-open {
            display: none;
        }
    }
</style>
