<script>
    export let fill;
    export let height = 33;
    export let width = 33;
    $: if (!fill) {
        fill = '#808080';
    }
</script>

<svg
    {width}
    {height}
    viewBox="0 0 {width}
    {height}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7985 17.4007H8.94141V24.2578H15.7985V17.4007ZM15.7985
        8.25781H8.94141V15.115H15.7985V8.25781ZM24.9415
        17.4007H18.0843V24.2578H24.9415V17.4007ZM24.9415
        8.25781H18.0843V15.115H24.9415V8.25781Z"
        {fill}
    />
</svg>
