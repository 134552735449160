import { drive } from './request';
import memoizer from './memoizer';
import whilst from 'async/whilst';
import asyncify from 'async/asyncify';
import { delay, getPropValue } from './utils';
import DriveError from './drive.error';

export const waitForJob = memoizer.defaultGroup.memoize(
    async (jobId, collectionId, progressCallback) => {
        let inprogress = true;
        let count = 0;
        let lastResponse;

        const checkInProgress = asyncify(async () => inprogress);
        const nextRun = asyncify(async () => {
            let response, url, body;
            const params = `jobIds=${jobId}`;
            url = `collections/${collectionId}/jobs`;
            // Drive_Get_Jobs_Info
            response = await drive(`${url}?${params}`);
            // Establish success
            body = getPropValue(response, 'data.0.attributes');
            if (!body || body.status === 'FAILED') {
                const error = {
                    waitForJob: response,
                    url,
                    status: 'Failed',
                };
                throw new DriveError({ error });
            }
            let progress = body.progress * 1;
            if (isNaN(progress)) {
                progress = 0;
            }
            // Fusion returns 'FINISHED', while DriveMS returns 'SUCCESS'
            inprogress = !['FINISHED', 'SUCCESS'].includes(body.status);
            if (inprogress) {
                count += 1;
            }
            if (progressCallback) {
                progressCallback(progress);
            }
            lastResponse = body;
            const interval = Math.min(count * 1500, 8000);
            if (count > 100) {
                const error = {
                    waitForJob: response,
                    url,
                    status: 'Timeout',
                };
                throw new DriveError({ error });
            } else {
                await delay(interval);
            }
        });

        await whilst(checkInProgress, nextRun);
        return lastResponse;
    }
);

export default {
    waitForJob,
};
