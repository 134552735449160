<script>
    export let viewable = null;
    export let file = null;

    import ImageViewer from 'viewerjs';
    import 'viewerjs/dist/viewer.min.css';
    import Spinner from '../spinner.svelte';
    import SomethingWentWrong from '../something.wrong.svelte';
    import { onDestroy } from 'svelte';

    const name = viewable.id;
    const id = `${name}-image`;
    const dataCy = 'image-viewer';
    const dataCyContainer = `${dataCy}-container`;

    let imageLoaded = false;
    let imageNotAvailable = false;
    let thumbnailLoadError = false;

    let getThumbnail;

    if (file.thumbnail) {
        getThumbnail = file.thumbnail();
    }

    let imageViewerInstance;
    const initializeImageViewer = () => {
        const image = document.getElementById(id);
        imageViewerInstance = new ImageViewer(image, {
            inline: true,
            title: false,
            viewed() {
                imageViewerInstance.zoomTo(1);
            },
        });
    };

    const imageLoad = () => {
        imageLoaded = true;
    };

    const thumbnailError = () => {
        thumbnailLoadError = true;
    };

    const imageLoadError = () => {
        imageNotAvailable = true;
    };

    onDestroy(() => {
        if (imageViewerInstance) {
            imageViewerInstance.destroy();
        }
    });
</script>

<div
    class="has-centered-content has-all-space is-relative"
    data-cy={dataCyContainer}
>
    {#if !imageLoaded}
        {#if getThumbnail}
            {#await getThumbnail}
                <Spinner />
            {:then thumbnail}
                {#if !thumbnailError || imageLoadError}
                    <img
                        src={thumbnail}
                        alt={name}
                        on:error={thumbnailError}
                        height="100%"
                        width="auto"
                        data-cy={dataCy}
                        class="image thumbnail"
                    />
                {/if}
            {:catch error}
                <SomethingWentWrong
                    {error}
                    source={`${dataCy}-get-thumbnail`}
                />
            {/await}
        {:else}
            <Spinner />
        {/if}
    {/if}

    {#if !imageNotAvailable}
        <img
            on:click={initializeImageViewer}
            {id}
            src={viewable.viewingUrl}
            on:load={imageLoad}
            on:error={imageLoadError}
            alt={name}
            height="100%"
            width="auto"
            data-cy={`${dataCy}-image`}
            class="image"
        />
    {/if}
</div>

<style>
    .image {
        height: 100%;
        width: auto;
        position: absolute;
        cursor: zoom-in;
    }

    .thumbnail {
        height: 100%;
        width: auto;
    }

    :global(.viewer-navbar, .viewer-prev, .viewer-play, .viewer-next) {
        display: none !important;
    }
</style>
