<script>
    export let fill = 'rgb(128, 128, 128)';
    export let height = 16;
    export let width = 16;
</script>

<svg {width} {height} {fill} viewBox="0 0 {width} {height}" class="scale">
    <path
        d="M0 2.5V4h6.34l1-1-1-1H.5a.5.5 0 00-.5.5zM0 5v8a1 1 0 001 1h13a1 1 0
        001-1V4a1 1 0 00-1-1H9L7 5z"
        {fill}
    />
</svg>

<style>
    .scale {
        transform: scale(1.2);
    }
</style>
