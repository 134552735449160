<script>
    export let file;
    export let assetActions = {};

    import FileIcon from '../../../icons/file.svelte';
    import Spinner from '../../spinner.svelte';
    import { timeAgo, readableFileSize } from '../../../providers/utils';
    import AssetActionsComponent from '../asset.actions.svelte';
    import { onDestroy } from 'svelte';
    import { i18n } from '../../../i18n';
    import { gSelection } from '../../elements/components.state';
    import { locateContextMenu } from '../../utils.js';

    const id = `${file.idDecoded}-list-item`;
    const dataCy = 'file-list-item';

    let metadata = file.itemInfo;
    let activeUploads = [];

    let observers = file.observe({
        // Rename, upload version call back here...
        metadata: () => {
            metadata = file.itemInfo;
        },
        uploads: ($uploads) => {
            activeUploads = $uploads;
        },
    });

    let containerElement;

    onDestroy(() => {
        if (observers) {
            observers.cancel();
        }
    });

    let showContextMenu = false;
    const onContextMenu = (event) => {
        $gSelection = [file];
        locateContextMenu(event, containerElement, `${dataCy}: contextmenu`);
        showContextMenu = true;
    };
</script>

<div
    class="selectable-item file-list-item-row columns is-mobile is-vcentered
        is-marginless has-bottom-border has-text-black-bis can-hover"
    bind:this={containerElement}
    data-cy={dataCy}
    {id}
    on:dblclick={assetActions.gotoFile(file, `${dataCy}: dblclick`)}
    on:click={assetActions.gotoFile(file, `${dataCy}: click`)}
    on:contextmenu|preventDefault={onContextMenu}
    class:is-selected={$gSelection && $gSelection.includes(file)}
>
    {#if !metadata}
        <Spinner />
    {:else}
        <div
            class="column is-one-third-desktop is-10-touch has-text-weight-semibold
                    is-vertical-center file-name-column"
            data-cy="item-name"
        >
            <span class="icon">
                <FileIcon type={file.fileType} />
            </span>
            <abbr class="main-text ellipsed-asset-name" title={metadata.name}>
                {metadata.name}
            </abbr>
        </div>
        <div class="column is-narrow is-2 is-hidden-touch has-ellipsis">
            <span title={timeAgo(metadata.createdDate)}>
                {timeAgo(metadata.modifiedDate)}
            </span>
        </div>
        <div class="column is-narrow is-2 is-hidden-touch has-ellipsis">
            <span title={i18n.displayFullName(metadata.createdBy)}>
                {i18n.displayFullName(metadata.createdBy)}
            </span>
        </div>
        <div class="column is-narrow is-1 is-hidden-touch has-ellipsis">
            {#if metadata.size}{readableFileSize(metadata.size)}{/if}
        </div>
        <div class="column is-narrow is-1 is-hidden-touch has-ellipsis">
            {#if activeUploads && activeUploads.length > 0}
                <Spinner />
            {:else}
                <span class="tag" data-cy="file-version-tag">
                    {i18n.t('App.Asset_Info_Summary.File_Version_String', {
                        VERSION: metadata.versionNumber,
                    })}
                </span>
            {/if}
        </div>
        <div
            class="column is-narrow is-2 is-rightaligned"
            data-cy="list-action"
        >
            <div class="filler" on:click />
            <AssetActionsComponent
                asset={file}
                {assetActions}
                parentDataCy={dataCy}
                bind:openDropdown={showContextMenu}
            />
        </div>
    {/if}
</div>

<style>
    .file-list-item-row {
        height: 51px;
    }
</style>
