<script>
    export let fill = '#4d4d4d';
</script>

<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM9 12H7V7H9V12ZM8 6.25C7.30964 6.25 6.75 5.69036 6.75 5C6.75 4.30964 7.30964 3.75 8 3.75C8.69036 3.75 9.25 4.30964 9.25 5C9.25 5.33152 9.1183 5.64946 8.88388 5.88388C8.64946 6.1183 8.33152 6.25 8 6.25Z"
        {fill}
    />
</svg>
