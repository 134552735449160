<script>
    export let height = 24;
    export let width = 24;
    export let type;

    // Check build.extensions/icons.gatherer.js
    import IconsMap from 'drive.file.icons';

    let icon = 'generic.png'; // default
    if (type) {
        for (const ext in IconsMap) {
            if (IconsMap[ext][type]) {
                icon = `${type}${ext}`;
                break;
            }
        }
    }
    const path = `DRIVE_ASSETS/images/file-icons/${icon}`;
</script>

<img
    src={path}
    alt={type}
    {width}
    {height}
    data-cy="file-icon"
    style="min-width:{width}; min-height:{height}"
/>
