<svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clip-path="url(#clip0_615_26424)">
        <path
            d="M2.50537 0.00500488L13.9116 2.50266H15.146V16.0027H2.2085C1.99301 16.0027 1.78635 15.9171 1.63397 15.7647C1.4816 15.6123 1.396 15.4056 1.396 15.1902V11.7885L2.48141 0.0403314L2.50537 0.00500488Z"
            fill="#78082A"
        />
        <path
            d="M2.50537 0.00256348H13.2241C13.4396 0.00256348 13.6463 0.0881659 13.7986 0.240539C13.951 0.392913 14.0366 0.599575 14.0366 0.815064V11.2526H2.50537V0.00256348Z"
            fill="#E51050"
        />
        <path
            d="M0.0678711 12.7213L2.50537 11.2526V0.00256348L0.0678711 1.47131V12.7213Z"
            fill="#E85984"
        />
        <path
            d="M5.10498 13.8694V13.2146C5.10498 12.6746 5.44586 12.3068 6.06688 12.3068C6.61026 12.3068 6.95114 12.5633 6.96802 13.0594H6.42463C6.41114 12.8535 6.29976 12.732 6.07363 12.732C5.83738 12.732 5.70237 12.8873 5.70237 13.1404V13.9437C5.70237 14.1968 5.83401 14.352 6.07026 14.352C6.31325 14.352 6.41788 14.2272 6.42802 14.001H6.97814C6.94439 14.5309 6.62038 14.7773 6.05338 14.7773C5.46611 14.7773 5.10498 14.4094 5.10498 13.8694Z"
            fill="white"
        />
        <path
            d="M8.54239 14.2069H7.83363L7.702 14.7233H7.11475L7.78976 12.3607H8.57614L9.28827 14.7233H8.68752L8.54239 14.2069ZM8.18126 12.7489L7.94163 13.7681H8.42765L8.18126 12.7489Z"
            fill="white"
        />
        <path
            d="M11.4372 13.3024V13.7377C11.4372 14.3453 11.066 14.7233 10.364 14.7233H9.59106V12.3607H10.445C11.1065 12.3607 11.4372 12.7387 11.4372 13.3024ZM10.8466 13.2821C10.8466 12.9513 10.7116 12.786 10.4281 12.786H10.1817V14.298H10.3775C10.6913 14.298 10.8466 14.1292 10.8466 13.7546L10.8466 13.2821Z"
            fill="white"
        />
        <path
            d="M9.16608 7.39192H7.29575L6.9484 8.7546H5.39868L7.17996 2.52014H9.25514L11.1344 8.7546H9.54904L9.16608 7.39192ZM8.21309 3.54437L7.58075 6.23409H8.86325L8.21309 3.54437Z"
            fill="white"
        />
        <path
            d="M9.16608 7.39192H7.29575L6.9484 8.7546H5.39868L7.17996 2.52014H9.25514L11.1344 8.7546H9.54904L9.16608 7.39192ZM8.21309 3.54437L7.58075 6.23409H8.86325L8.21309 3.54437Z"
            fill="white"
        />
    </g>
    <defs>
        <clipPath id="clip0_615_26424">
            <rect
                width="15.0781"
                height="16"
                fill="white"
                transform="translate(0.0678711 0.00256348)"
            />
        </clipPath>
    </defs>
</svg>
