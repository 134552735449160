<script>
    export let assetActions = {};
    export let folder;

    import FolderIcon from '../../../icons/folder.svelte';
    import SharedFolderIcon from '../../../icons/shared.folder.svelte';
    import { timeAgo, getRoleFromPermissions } from '../../../providers/utils';
    import AssetActionsComponent from '../asset.actions.svelte';
    import { onDestroy } from 'svelte';
    import { droparea } from '../../upload/files.drag.drop';
    import Spinner from '../../spinner.svelte';
    import { i18n } from '../../../i18n';
    import { isDisplayingSharedFolders } from '../../../providers/state/active.record';
    import { gSelection } from '../../elements/components.state';
    import { locateContextMenu } from '../../utils.js';

    let metadata = folder.itemInfo;
    let isFolderShared = folder.isFolderShared;

    let observers = folder.observe({
        metadata: () => {
            metadata = folder.itemInfo;
            isFolderShared = folder.isFolderShared;
        },
    });

    const id = `${folder.idDecoded}-list-item`;
    const dataCy = 'folder-list-item';

    let containerElement;

    onDestroy(() => {
        if (observers) {
            observers.cancel();
        }
    });

    const dropArea = (node) => {
        return folder.canEdit ? droparea(node) : {};
    };

    const onFilesDrop = async (event) => {
        folder.uploads.upload(event.detail);
    };

    let showContextMenu = false;
    const onContextMenu = (event) => {
        $gSelection = [folder];
        locateContextMenu(event, containerElement, `${dataCy}: contextmenu`);
        showContextMenu = true;
    };
</script>

<div
    class="selectable-item folder-list-item-row columns is-mobile is-vcentered
        is-marginless has-bottom-border has-text-black-bis can-hover"
    data-cy={dataCy}
    use:dropArea
    on:files={onFilesDrop}
    bind:this={containerElement}
    {id}
    on:dblclick={assetActions.gotoFolder(folder)}
    on:click={assetActions.gotoFolder(folder)}
    on:contextmenu|preventDefault={onContextMenu}
    class:is-selected={$gSelection && $gSelection.includes(folder)}
>
    {#if !metadata}
        <Spinner />
    {:else}
        <div
            class="column is-one-third-desktop is-10-touch has-text-weight-semibold
                    is-vertical-center folder-name-column"
            data-cy="item-name"
        >
            <span class="icon">
                <FolderIcon />
            </span>
            <abbr class="main-text ellipsed-asset-name" title={metadata.name}>
                {metadata.name}
            </abbr>
            {#if $isDisplayingSharedFolders}
                <span
                    class="icon shared-folder-icon has-tooltip-right has-tooltip-arrow"
                    data-cy="{dataCy}-shared"
                    data-tooltip={i18n.t(
                        'App.Folder_Sharing.Shared_Icon_Tooltip_Sharee',
                        {
                            role: getRoleFromPermissions(
                                metadata.permissions.explicit
                            ),
                        }
                    )}
                >
                    <SharedFolderIcon />
                </span>
            {:else if isFolderShared}
                <span class="icon shared-folder-icon" data-cy="{dataCy}-shared">
                    <SharedFolderIcon />
                </span>
            {/if}
        </div>
        <div class="column is-narrow is-2 is-hidden-touch has-ellipsis">
            <span title={timeAgo(metadata.createdDate)}>
                {timeAgo(metadata.modifiedDate)}
            </span>
        </div>
        <div class="column is-narrow is-2 is-hidden-touch has-ellipsis">
            <span title={i18n.displayFullName(metadata.lastModifiedBy)}>
                {i18n.displayFullName(metadata.createdBy)}
            </span>
        </div>
        <div class="column is-narrow is-1 is-hidden-touch" on:click />
        <div class="column is-narrow is-1 is-hidden-touch" on:click />
        <div
            class="column is-narrow is-2 is-rightaligned"
            data-cy="list-action"
        >
            <div class="filler" />
            <AssetActionsComponent
                asset={folder}
                {assetActions}
                parentDataCy={dataCy}
                bind:openDropdown={showContextMenu}
            />
        </div>
    {/if}
</div>

<style>
    .folder-list-item-row {
        height: 51px;
    }
</style>
