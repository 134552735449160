import config from 'app.config';
import { trackEvent, categories } from '../providers/analytics/ga';
import { getPropValue } from './utils';

const { servers, env, apps } = config;

const regExp = new RegExp(`(${apps.driveUrl}/APP_VERSION/|\n   )`, 'g');

// The following are the unique API calls that we make, with unique ids from 0-99
const serviceCalls = [
    {
        url: [servers.fusion],
        offset: 0,
        paths: [
            /\/communities\/api\/admin\/membership/, // Fusion_Remove_Collaborator
            /\/communities\/api\/projects\/[^/]+\/members/, // Fusion_List_Collaborators
            /\/communities\/api\/projects/, // Fusion_Get_Projects
            /\/data\/api\/download/, // Fusion_Download_Items
            /\/data\/api\/import/, // Fusion_Dropbox_Import
        ],
    },
    {
        url: [servers.connect],
        offset: 10,
        paths: [
            /\/GetOAuth2TwoLeggedToken/, // Connect_2LO_Bearer_Token
            /\/Viewer\/GetConfiguration/, // Connect_Viewer_Configuration
        ],
    },
    {
        url: [servers.authentication],
        offset: 20,
        paths: [
            /\/authorize/, // Authentication_Authorize
            /\/token/, // Authentication_3LO_Bearer_Token
            /\/revoke/, // Authentication_Revoke_Token
            /\/logout/, // Authentication_Logout
        ],
    },
    {
        url: [servers.userProfile],
        offset: 30,
        paths: [
            /\/users\/@me/, // Get_User_Info
        ],
    },
    {
        url: [servers.derivative],
        offset: 35,
        paths: [
            /\/thumbnails\/[^/]+/, // Derivative_Get_Thumbnail
        ],
    },
    {
        url: [servers.s3],
        offset: 45,
        paths: [
            /\/signed-url-uploads\/[^/]+/, // S3_Put_Upload
        ],
    },
    {
        url: [servers.drive],
        offset: 60,
        paths: [
            /\/v1\/collections\/[^/]+\/folders\/move/, // Drive_Move_Folder
            /\/v1\/collections\/[^/]+\/folders\/copy/, // Drive_Copy_Folder
            /\/v1\/collections\/[^/]+\/folders\/[^/]+\/download/, // Drive_Get_Download_Folder_Job_Info
            /\/v1\/collections\/[^/]+\/folders\/[^/]+\/upload:complete/, // Drive_Upload_File_Complete
            /\/v1\/collections\/[^/]+\/folders\/[^/]+\/upload:start/, // Drive_Upload_File_Start
            /\/v1\/collections\/[^/]+\/folders:bypath/, // Drive_Get_Create_Folder_by_Path
            /\/v1\/collections\/[^/]+\/folders\/[^/]+/, // Drive_Get_Folder_Items, Drive_Create_Folder
            /\/v1\/collections\/[^/]+\/files\/move/, // Drive_Move_File
            /\/v1\/collections\/[^/]+\/files\/copy/, // Drive_Copy_File
            /\/v1\/collections\/[^/]+\/files\/[^/]+\/download/, // Drive_Get_Download_File_Url
            /\/v1\/collections\/[^/]+\/items\/[^/]+/, // Drive_Update_Item
            /\/v1\/collections\/[^/]+\/items/, // Drive_Get_Items, Drive_Delete_Items
            /\/v1\/collections\/[^/]+\/jobs\/[^/]+/, // Drive_Get_Job_Info
            /\/v1\/collections\/[^/]+\/shares\/public\/items\/[^/]+/, // Drive_Get_Public_Share, Drive_Create_Update_Public_Share, Drive_Delete_Public_Share
            /\/v1\/collections\/[^/]+\/shorten/, // Drive_Shorten_Url
            /\/v1\/collections\/[^/]+\/translate\/files\/[^/]+/, // Drive_Translate_File
            /\/v1\/collections\/shares\/public\/shares\/[^/]+/, // Drive_Get_Public_Share_By_Id
            /\/v1\/collections\/[^/]+\/shares\/private\/items\/[^/]+/, // Drive_Get_Private_Share, Drive_Create_Update_Private_Share, Drive_Delete_Private_Share
            /\/v1\/collections\/[^/]+\/shares\/private\/valid-users/, // Drive_Are_Valid_Oxygen_Users
            /\/v1\/collections\/[^/]+\/shares\/private\/account-create-email/, // Drive_Send_Private_Share_Account_Creation_Email
            /\/v1\/collections\/[^/]+\/shares\/private\/shared-with-me\/items/, // Drive_Remove_Private_Share_For_Me
            /\/v1\/collections\/[^/]+\/shares\/private\/shared-with-me/, // Drive_Get_Private_Shared_With_Me
            /\/v1\/?$/, // Drive_Get_Drive_Info
            /\/v1\/collections\/[^/]+\/search/, // Drive_Search
            /\/v1\/collections\/[^/]+\/shares\/public\/[^/]+\/access-token/, // Drive_Get_Public_Share_Access_Token
            /\/v2\/collections\/[^/]+\/files\/[^/]+\/download:signed/, // Drive2_Get_Download_File_Url
            /\/v2\/collections\/[^/]+\/files\/[^/]+\/upload:complete/, // Drive2_Upload_File_Complete
            /\/v2\/collections\/[^/]+\/files\/[^/]+\/upload:signed/, // Drive2_Get_Signed_Upload_URLs
            /\/v2\/collections\/[^/]+\/files\/upload:start/, // Drive2_Upload_File_Start
        ],
    },
    {
        url: [servers.forgedm],
        offset: 90,
        paths: [
            /\/project\/v1\/hubs/, // ForgeDM_Get_Hubs
        ],
    },
    {
        url: [servers.identity],
        offset: 95,
        paths: [
            /\/users\/[^/]+\/analytics/, // Identity_Get_AnalyticsId
        ],
    },
    {
        url: 'http', // unknown
        offset: 99,
        paths: [],
    },
];

const getUrlIndex = (url) => {
    const service = serviceCalls.find((service) =>
        // request.url includes the query parameters, so this can be reused in commandsMocks.js
        url.includes(service.url)
    );
    const urlIndex =
        service.paths.findIndex((path) => path.test(url)) + service.offset + 1;

    return urlIndex;
};

// Extract custom error code from backend response
const extractCustomCode = (responseBody) => {
    try {
        responseBody = JSON.parse(responseBody);
        return getPropValue(responseBody, 'errors.0.code');
    } catch (parseError) {
        // Ignore parsing error
    }
};

// prettier-ignore
const failureSource = {
    Unknown:                      0, // Unknown
    Request:                1000000, // fetch() exception with Request
    Response:               2000000, // Response not ok
    Error:                  3000000, // General Error or Object
    PromiseRejectionEvent:  4000000, // PromiseRejectionEvent caught at global scope
};

// prettier-ignore
const fetchMethod = {
    Unknown:                 0, // Unknown
    GET:                100000,
    POST:               200000, // create
    PUT:                300000, // update
    PATCH:              400000, // partial update
    DELETE:             500000,
};

const _waiterMap = new Map(); // Pending wait elements

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
export default class DriveError {
    // One time addition of global error event listeners
    static addEventsListeners() {
        window.addEventListener('error', function () {
            // TODO: https://jira.autodesk.com/browse/DRIVE-246
            // Make sure this is not too verbose...
            // DriveError.logError('Error', event);
        });
        // PromiseRejectionEvent
        window.addEventListener('unhandledrejection', function (event) {
            // Prevent the default handling (such as outputting the
            // error to the console)
            event.preventDefault();

            // Pass the DriveError if its the reason
            DriveError.logError('PromiseRejectionEvent', event.reason || event);
        });
        // Log any outstanding waiters as a warning
        window.addEventListener('visibilitychange', function () {
            if (document.visibilityState === 'hidden') {
                _waiterMap.forEach((lifespan) => {
                    lifespan(categories.warnDrive);
                });
            }
        });
    }

    static addWaiter(key, lifespan) {
        _waiterMap.set(key, lifespan);
    }

    static removeWaiter(key) {
        _waiterMap.delete(key);
    }

    constructor(options = {}) {
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, DriveError);
        }
        // If we're thrown from w/in an asyncify call, we need 'message' for this to survive
        if (!this.message) {
            this.message = '<not set>';
        }

        this.request = options.request; // Request
        this.fetchError = options.fetchError; // fetch() threw this
        this.retryInfo = options.retryInfo; // fetch retry information
        this.response = options.response; // Response
        this.bodyError = options.bodyError; // Getting response body threw this
        this.error = options.error; // Error or Object - mutually exclusive of Request/Response errors
        this.action = options.action; // Override logged GA action for better collation
        this.moreInfo = options.moreInfo; // detail too?

        this.body = options.body; // Caller is responsible for managing this, or asking to get it

        this.source = failureSource.Unknown; // Origination of error (numeric)
        this.method = fetchMethod.Unknown;
        this.url = '';
        this.urlIndex = 0; // unknown from serviceCalls
        this.status = 0; // n/a response code
        this.backendErrorCode;

        this.logged = false; // Avoid duplicate logs

        // If we have a request, get the method, and urlIndex
        if (this.request) {
            this.source = failureSource.Request; // May be updated below...
            this.method =
                fetchMethod[this.request.method.toUpperCase()] ||
                fetchMethod.Unknown;

            this.url = this.request.url;
            this.urlIndex = getUrlIndex(this.url) * 1000;
        }

        // If we have a response, get the status
        if (this.response) {
            this.source = failureSource.Response; // Updated
            this.status = this.response.status;

            // Get the urlIndex if we don't have it yet
            if (!this.request) {
                this.url = this.response.url;
                this.urlIndex = getUrlIndex(this.url) * 1000;
            }

            // Caller is responsible for supplying or requesting the body
        }

        // Lastly, we were passed a generic Error or Object
        if (this.error) {
            this.source = failureSource.Error; // mutually exclusive of Request/Response errors

            // We could extend method, index & status specifically for this source...
        }

        // Ok, compute the specific code - for use in UX
        this.code = this.source + this.method + this.urlIndex + this.status;
    }

    async getBody() {
        if (this.response) {
            this.body = await this.response.text();
            this.backendErrorCode = extractCustomCode(this.body);
        }
    }

    // Format what we know about this error, and log once to Google Analytics
    //
    // Return the error code (for SomethingWentWrong)
    //
    // Two flows into here...
    //  1. SomethingWentWrong
    //  2. PromiseRejectionEvent
    //
    static logError(source, error) {
        let errorCode; // Logged and presented in title
        let action = source; // Default GA action logged

        // Determine error code
        if (error instanceof DriveError) {
            errorCode = error.code; // Computed already
            if (error.action) {
                action = error.action; // Override GA action being logged
            }
        } else if (error instanceof Error) {
            // Filter out known errors we don't care about
            if (error.name === 'AbortError') {
                // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
                if (
                    error.message.startsWith(
                        'The play() request was interrupted by a call to pause()'
                    )
                ) {
                    return;
                }
            }

            errorCode = failureSource.Error;
        } else if (error instanceof PromiseRejectionEvent) {
            errorCode = failureSource.PromiseRejectionEvent;
        } else {
            // Filter out known errors we don't care about
            if (typeof error === 'string') {
                // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/17
                if (error.startsWith('Object Not Found Matching Id:')) {
                    return;
                }
            }

            // prettier-ignore
            // The following will be removed except when running locally
            // eslint-disable-next-line no-constant-condition, no-debugger
            if ('RUNNING_LOCAL') debugger;

            errorCode = failureSource.Unknown;
        }

        // If this error hasn't been marked as logged, log it
        if (!error || !error.logged) {
            // Log the most technical explanation
            // Leave the following as it is, as rollup injects the git hash
            let label = { version: 'APP_VERSION' };
            let detail = {}; // Less collatable information
            if (error instanceof DriveError) {
                label.failureSource = Object.keys(failureSource).find(
                    (key) => failureSource[key] === error.source
                );
                label.code = { [error.code.toString(36)]: error.code };
                label.stack = error.stack;
                if (error.request) {
                    label.request = {
                        url: error.request.url,
                        method: error.request.method,
                    };
                }
                if (error.fetchError) {
                    label.fetchError = {
                        name: error.fetchError.name,
                        message: error.fetchError.message,
                    };
                }
                if (error.response) {
                    label.response = {
                        ok: error.response.ok,
                        status: error.response.status,
                        body: error.body, // We might have collected this
                    };
                }
                if (error.retryInfo) {
                    detail = error.retryInfo; // Let's put this in detail
                    // Should always be true
                    if (error.request) {
                        // Request headers causing TypeError???
                        detail.request = { headers: {} };
                        for (const pair of error.request.headers.entries()) {
                            detail.request.headers[pair[0]] =
                                pair[0] === 'authorization'
                                    ? '<redacted>'
                                    : pair[1];
                        }
                    }
                }
                if (error.bodyError) {
                    label.bodyError = error.bodyError;
                }
                if (error.error) {
                    // Error or Object
                    label.error = error.error; // Additional Error provided
                }
            } else if (error instanceof Error) {
                label.failureSource = 'Error';
                label.name = error.name; // Might be derivation
                label.message = error.message;
                label.stack = error.stack; // available sometimes
            } else if (error instanceof PromiseRejectionEvent) {
                label.failureSource = 'PromiseRejectionEvent';
                label.reason = error.reason;
            } else if (error instanceof Event) {
                label.failureSource = 'Event';
                // Past logging was useless:
                //  {"version":"v2.13.0","failureSource":"Event","error":{"isTrusted":false}}
                // There are no stack traces to log, so log other properties
                // so we can hopefully identify the source...
                label.error = {
                    target: error.target,
                    type: error.type,
                    eventPhase: error.eventPhase,
                    isTrusted: error.isTrusted,
                };
            } else {
                // Unknown
                label.failureSource = 'Unknown';
                label.type = typeof error;
                label.error = error;
            }

            // Make sure the stack is a reasonable size
            if (label.stack) {
                label.stack = label.stack
                    .replaceAll(regExp, '')
                    .substring(0, 200);
            }

            // Optional moreInfo param to help debug issues reported on GA
            if (error && error.moreInfo) {
                label.moreInfo = error.moreInfo;
            }

            const event = {
                category: categories.errorDrive, // 150 Bytes
                action, // 500 Bytes
                label, // 500 Bytes
                errorCode: errorCode.toString(),
            };

            // Don't log '{}'
            if (Object.keys(detail).length) {
                event.detail = detail;
            }

            // prettier-ignore
            // The following will be removed except when running locally
            // eslint-disable-next-line no-constant-condition
            if ('RUNNING_LOCAL') console.log(`${event.category}: ${event.action}\n${JSON.stringify(event.label, null, 2)}`);

            trackEvent(event);

            if (error && typeof error == 'object') {
                error.logged = true; // Only log once
            }
        }

        return errorCode;
    }
}

// Provide developers a way to decipher error codes...
if (env != 'production') {
    window.decipherError = (codeArg) => {
        // Decipher source, method, urlIndex & status...
        let code, codeStr;
        if (typeof codeArg === 'string') {
            codeStr = codeArg;
            code = parseInt(codeStr, 36);
        } else {
            codeStr = codeArg.toString(36);
            code = codeArg;
        }
        let details = { code: { [codeStr]: code } };
        const source = Math.floor(code / 1000000) * 1000000;
        code -= source;
        const method = Math.floor(code / 100000) * 100000;
        code -= method;
        const urlIndex = Math.floor(code / 1000);
        const response = code - urlIndex * 1000;

        details.source = Object.keys(failureSource).find(
            (key) => failureSource[key] === source
        );
        details.method = Object.keys(fetchMethod).find(
            (key) => fetchMethod[key] === method
        );

        // Find the endpoint
        const service = serviceCalls.find(
            (service, index, array) =>
                urlIndex > service.offset && urlIndex < array[index + 1].offset
        );
        if (service) {
            details.url = service.url[0];
            details.path =
                service.paths[urlIndex - service.offset - 1].toString();
        }
        details.response = response;

        console.log(JSON.stringify(details, null, 2));
    };
}
