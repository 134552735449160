<script>
    import Notification from './notification.svelte';
    import { notificationsStore } from './notifications.store.js';
    import { i18n } from '../../i18n';
    import ClearNotificationIcon from '../../icons/clear.notification.svelte';

    const dataCy = 'notification-panel';
    const clearAllThreshold = 3;
</script>

{#if $notificationsStore.length > 0}
    <div class="notifications-panel is-paddingless">
        {#if $notificationsStore.length > clearAllThreshold}
            <article class="clear-all">
                <button
                    class="button is-size-7"
                    on:click={notificationsStore.clearAll}
                    data-cy="{dataCy}-clear-all"
                >
                    <span>{i18n.t('App.Primary_Buttons.Clear_All_Btn')}</span>
                    <span class="icon delete-icon">
                        <ClearNotificationIcon />
                    </span>
                </button>
            </article>
        {/if}

        {#each $notificationsStore as notification (notification.id)}
            <Notification {...notification} />
        {/each}
    </div>
{/if}
