import 'core-js';
import 'regenerator-runtime/runtime.js';
import 'whatwg-fetch';
import './main.scss';
import 'animate.css';
import config from 'app.config';
import Drive from './pages/drive.svelte';
import Picker from './picker/picker.svelte';

const enableFilePicker = config.misc.enableFilePicker;
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

if (enableFilePicker && urlParams.get('picker') === 'true') {
    new Picker({
        target: document.body,
    });
} else {
    new Drive({
        target: document.body,
    });
}
