<script>
    export let viewable = null;
    export let file = null;
    export let collectionId = null;
    export let password; // Public Share Password

    import config from 'app.config';
    import {
        trackEvent,
        categories,
        actions,
    } from '../../providers/analytics/ga';
    import { URLSafeBase64, safeJSONParse } from '../../providers/utils';
    import {
        getOAuth2ThreeLeggedToken,
        getDriveMs2LeggedToken,
    } from '../../rest.sdks/request';
    import { onMount, onDestroy } from 'svelte';

    const versionUrn = viewable.id;
    const vid = URLSafeBase64.encode(versionUrn);
    let lmvIfr, src;

    // ShareFactory
    if (file.type === 'share') {
        src = `${config.apps.viewer}/drive/hub/${collectionId}/share/${file.id}/version/${vid}?source=drive`;
    } else {
        src = `${config.apps.viewer}/drive/hub/${collectionId}/urn/${file.id}/version/${vid}?source=drive`;
    }

    function sendMessage(msg) {
        if (lmvIfr) {
            lmvIfr.contentWindow.postMessage(msg, config.apps.viewer);
        }
    }

    const _messageListener = async (event) => {
        if (event.origin !== config.apps.viewer) {
            return false;
        }
        let msg = safeJSONParse(event.data);
        if (msg.request === 'getDrive3LeggedToken') {
            const token = await getOAuth2ThreeLeggedToken();
            sendMessage({
                access_token: token,
            });
        } else if (msg.request === 'getDrive2LeggedToken') {
            // Drive_Get_Public_Share_Access_Token
            const token = await getDriveMs2LeggedToken(
                collectionId,
                file.id,
                password
            );
            sendMessage({
                access_token: token.accessToken,
                expires_in: token.validitySeconds,
            });
        } else {
            trackEvent({
                category: categories.viewing,
                action: actions.viewingError,
                label: `Viewer Message not recognized, original message payload: ${event.data}`,
            });
        }
    };

    const _params = ['message', _messageListener];

    onMount(() => {
        lmvIfr = document.getElementById('lmv-ifr');
        window.addEventListener(..._params);
    });

    onDestroy(() => {
        window.removeEventListener(..._params);
    });
</script>

<div class="viewer section has-centered-content">
    <iframe
        id="lmv-ifr"
        class="viewer-iframe has-all-space is-borderless"
        title={viewable.name}
        data-cy="lmv-viewer"
        {src}
    />
</div>
