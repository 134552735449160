import page from 'page';
import urlJoin from 'proper-url-join';
import { categories, actions, trackEventWithDataType } from './analytics/ga';

export const goto = (path) => {
    page(prefixUrl(path));
};

export const goToFile = (file, detail = '') => {
    const newPage = prefixUrl(`/files/${file.id}`);
    window.open(newPage, '_blank');
    trackEventWithDataType({
        category: categories.fileManagement,
        action: actions.fileOpen,
        label: file.fileType,
        value: file.itemInfo.size,
        detail,
    });
};

export const goToShared = () => {
    goto(`/folders/shared`);
};

export const goToHome = () => {
    goto('/home');
};

export const prefixUrl = (path) => {
    return urlJoin('/', path);
};

export default {
    goto,
    goToFile,
    goToShared,
    goToHome,
    prefixUrl,
};
