<script>
    export let fill = '#808080';
    export let height = 16;
    export let width = 16;
</script>

<svg
    {width}
    {height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4001 1.39961L14.6001 0.599609L8.0001 7.29961L1.4001
        0.599609L0.600098 1.39961L7.3001 7.99961L0.600098 14.5996L1.4001
        15.3996L8.0001 8.69961L14.6001 15.3996L15.4001 14.5996L8.7001
        7.99961L15.4001 1.39961Z"
        {fill}
    />
</svg>
