<script>
    export let fill = '#4d4d4d';
    export let height = 33;
    export let width = 33;
</script>

<svg
    {width}
    {height}
    viewBox="0 0 {width}
    {height}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M11.8492 13.8309H21.8492V23.8309C21.8492 24.3832 21.4015 24.8309
        20.8492 24.8309H12.8492C12.297 24.8309 11.8492 24.3832 11.8492
        23.8309V13.8309ZM18.8492 10.8309H14.8492V10.3309C14.8492 10.0548 15.0731
        9.83093 15.3492 9.83093H18.3492C18.6254 9.83093 18.8492 10.0548 18.8492
        10.3309V10.8309ZM11.4492 10.8309C11.1179 10.8309 10.8492 11.0996 10.8492
        11.4309V12.8309H22.8492V11.4309C22.8492 11.0996 22.5806 10.8309 22.2492
        10.8309H19.8492V10.3309C19.8492 9.50251 19.1777 8.83093 18.3492
        8.83093H15.3492C14.5208 8.83093 13.8492 9.50251 13.8492
        10.3309V10.8309H11.4492Z"
        {fill}
    />
</svg>
