import Selection from '@simonwep/selection-js';

const selectionAreaCls = 'selection-area'; // default
const selectedCls = 'is-selected';
const selectionBoxCls = 'selection-box';

export default function SelectionArea(node, options = {}) {
    node.classList.add(selectionAreaCls);

    // What we can select - passed in
    const { selectableCls, canSelect, keepSelectionFor } = options;

    // https://www.npmjs.com/package/@simonwep/selection-js
    const selection = new Selection({
        class: selectionBoxCls,
        selectables: [`.${selectableCls}`],
        boundaries: [`.${selectionAreaCls}`],
    });

    // Provide a way to clear the selection when clicking outside the selectionAreaCls
    const documentListener = (e) => {
        if (
            e.composedPath && // EdgeHTML 18
            !e
                .composedPath()
                .some(
                    (element) =>
                        (element.classList &&
                            element.classList.contains(selectableCls)) ||
                        keepSelectionFor.includes(element.id)
                )
        ) {
            _clearSelection(selection);
            _selectionChanged(selection);
        }
    };
    document.addEventListener('mousedown', documentListener);

    const _selectionChanged = (inst) => {
        // Send new selection back to our container
        node.dispatchEvent(
            new CustomEvent('selection', {
                detail: {
                    selection: inst.getSelection(),
                },
            })
        );
    };

    const _clearSelection = (inst) => {
        for (const el of inst.getSelection()) {
            el.classList.remove(selectedCls);
        }
        inst.clearSelection();
    };

    const beforestart = ({ oe }) => {
        // Skip R-Clicks (Context Menu)
        if (oe.which == 3) {
            return false;
        }

        return canSelect(); // Ask our owner if we can start a selection
    };

    const start = ({ inst, oe }) => {
        // Start fresh if this is not a multi-select
        if (!oe.ctrlKey && !oe.metaKey && !oe.shiftKey) {
            _clearSelection(inst);
        }
    };

    const move = ({ changed: { added, removed } }) => {
        // Add a custom class to the elements that where selected.
        for (const el of added) {
            el.classList.add(selectedCls);
        }

        // Remove the class from elements that where removed
        // since the last selection
        for (const el of removed) {
            el.classList.remove(selectedCls);
        }
    };

    const stop = ({ inst }) => {
        // Remember selection in case the user wants to add more...
        inst.keepSelection();

        // Send new selection back to our container
        _selectionChanged(inst);
    };

    selection.on('beforestart', beforestart);
    selection.on('start', start);
    selection.on('move', move);
    selection.on('stop', stop);

    return {
        destroy() {
            // Cleanup
            document.removeEventListener('mousedown', documentListener);
            selection.destroy();
        },
    };
}
