import { writable, derived } from 'svelte/store';
import touchDevice from 'is-touch-device';

export let isMobile = writable(false);
export let isTablet = writable(false);
export let isDesktop = writable(false);
export let isTouchDevice = writable(touchDevice());

export let isMobileOrTablet = derived(
    [isMobile, isTablet],
    ([$isMobile, $isTablet]) => $isMobile || $isTablet
);

export let isTabletOrDesktop = derived(
    [isDesktop, isTablet],
    ([$isDesktop, $isTablet]) => $isDesktop || $isTablet
);

export default {
    isMobile,
    isTablet,
    isDesktop,
    isTouchDevice,
    isMobileOrTablet,
    isTabletOrDesktop,
};
