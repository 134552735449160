<script>
    import Banner from '../elements/banner.svelte';
    import config from 'app.config';
    import { i18n } from '../../i18n';
    import { showOpenOnDesktopBanner } from '../../providers/state/user';

    const learnMoreLink = config.links.desktopconnectorHelp;
    const getDesktopConnectorLink = config.links.desktopconnectorInstall;
    const dataCy = 'open-on-desktop-banner';
    const type = 'info';
    let show = true;

    const options = {
        showBanner: () => {
            show = true;
        },
        hideBanner: () => {
            showOpenOnDesktopBanner.set(false);
            show = false;
        },
    };
</script>

<Banner {type} {options} {show} {dataCy}>
    <div slot="title" class="is-vertical-center" id={dataCy}>
        {i18n.t(
            'App.Asset_Actions.Open_On_Desktop.Desktop_Connector_Required_Msg'
        )}&nbsp;
        <a href={getDesktopConnectorLink} target="_blank">
            {i18n.t('App.Primary_Buttons.Get_Desktop_Connector_Btn')}.</a
        >
        <a
            id="btn-learn-more"
            href={learnMoreLink}
            target="_blank"
            class="button has-text-grey-dark is-link has-text-weight-semibold"
        >
            {i18n.t('App.EntitlementCheck.Learn_More_Btn')}
        </a>
    </div>
</Banner>
