<script>
    import CSSOverrides from '../components/elements/css.overrides.svelte';
    import Auth from '../components/pkce.auth.svelte';
    import Protected from '../components/protected.svelte';
    import FilePickerSelector from './filepicker.selector.svelte';
    import page from 'page';

    page.start(); // Required if we do any clientside routing
</script>

<CSSOverrides />
<Auth />
<Protected>
    <section class="section">
        <div class="section-head">
            <div
                class="container header-container is-fluid columns
                is-vertical-center"
            >
                <div class="column">
                    <img
                        class="drive-logo"
                        src="DRIVE_ASSETS/images/autodesk-logo-drive.svg"
                        alt="logo"
                    />
                </div>
            </div>
        </div>
        <div class="section-body">
            <FilePickerSelector />
        </div>
    </section>
</Protected>

<style>
    .header-container {
        min-height: 60px;
        margin: 2px 0;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    }
    .drive-logo {
        height: 15px;
        width: auto;
    }
</style>
