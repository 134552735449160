<script>
    import Protected from '../components/protected.svelte';
    import InfoIcon from '../icons/info.svelte';
    import config from 'app.config';
    import ModalComponent from '../components/elements/modal.svelte';
    import { gEntitled } from '../providers/state/user';
    import { i18n } from '../i18n';
    import { gDriveInfo } from '../providers/state/user';

    // const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const dataCy = 'entitlement-expired';
    const learnMoreLink = config.links.subscriptionHelp;

    let status;
    let modalInstance;

    // Disabling the trial flow until the we have refined workflow. Keeping this logic for re-use.
    // const ENTITLEMENT_TYPES = {
    //     FUSION: 'FUSION360',
    //     FREE: 'FREE',
    //     STUDENT: 'STUDENT',
    //     UNLIMITED: 'UNLIMITED',
    //     TRIAL: 'TRIAL',
    //     SUSPENDED: 'SUSPENDED',
    //     INACTIVE: 'INACTIVE',
    //     SUBSCRIPTION: 'SUBSCRIPTION',
    // };

    const ENTITLEMENT_STATUS = {
        SUBSCRIBED: 'SUBSCRIBED',
        SUSPENDED: 'SUSPENDED',
        INACTIVE: 'INACTIVE',
        ACTIVE: 'ACTIVE',
        SETUP: 'SETUP',
    };

    const fillColor = () => {
        let color = '#4394D2';
        if (status) {
            if (status.warn) {
                color = '#F3AA41';
            } else if (status.suspended) {
                color = '#DF546B';
            }
        }
        return color;
    };

    const digestEntitlements = async () => {
        const tempStatus = {
            active: false,
            trial: false,
            suspended: false,
            expiresOn: null,
            autoOpen: false,
            closeToExpiry: false,
            warn: false,
        };
        let entitlementStatus = ENTITLEMENT_STATUS.ACTIVE;

        if (!$gDriveInfo.driveLicenseValid) {
            entitlementStatus = ENTITLEMENT_STATUS.SUSPENDED;
            tempStatus.expiresOn = 0;
        }

        switch (entitlementStatus) {
            // Disabling the trial flow until the we have refined workflow. Keeping this logic for re-use.
            // case ENTITLEMENT_TYPES.TRIAL:
            //     tempStatus.active = true;
            //     tempStatus.trial = true;
            //     tempStatus.expiresOn = entitlements.endDate * 1;
            //     const diffDays = Math.round(
            //         Math.abs((tempStatus.expiresOn - new Date()) / oneDay)
            //     );
            //     if (diffDays <= 5) {
            //         tempStatus.autoOpen = !window.__shownEntitlementMessage;
            //         tempStatus.warn = true;
            //         window.__shownEntitlementMessage = true;
            //     }
            //     break;
            case ENTITLEMENT_STATUS.INACTIVE:
            case ENTITLEMENT_STATUS.SUSPENDED:
                gEntitled.set(false);
                tempStatus.suspended = true;
                tempStatus.autoOpen = !window.__shownEntitlementMessage;
                window.__shownEntitlementMessage = true;
                break;
            default:
                gEntitled.set(true);
                tempStatus.active = true;
        }

        status = tempStatus;
    };

    const openModal = (open) => {
        return () => {
            if (modalInstance) {
                if (open) {
                    modalInstance.open();
                } else {
                    modalInstance.close();
                }
            }
        };
    };

    // Disabling the trial flow until the we have refined workflow. Keeping this logic for re-use.
    // const getExpiresOnDate = (status) => new Date(status.expiresOn);

    const restrictedActions = i18n.t(
        'App.EntitlementCheck.Restricted_Actions',
        {
            returnObjects: true,
        }
    );

    $: if ($gDriveInfo) {
        digestEntitlements();
    }
</script>

<Protected spinner={false}>
    {#if status}
        {#if status.suspended}
            <ModalComponent
                {dataCy}
                headerModifier="is-hidden"
                bind:this={modalInstance}
            >
                <div slot="content">
                    <h1 class="is-size-4 has-text-weight-bold">
                        {i18n.t('App.EntitlementCheck.Modal_Title')}
                    </h1>
                    <div class="is-gap" />
                    <h2 class="is-size-5">
                        {i18n.t('App.EntitlementCheck.Restricted_Actions_Msg')}
                    </h2>
                    <div class="is-gap" />
                    <ul>
                        {#each restrictedActions as action}
                            <li>
                                <span class="icon restricted-action">
                                    <img
                                        alt="Warning"
                                        src="DRIVE_ASSETS/images/warning.svg"
                                    />
                                </span>
                                <span>{action}</span>
                            </li>
                        {/each}
                    </ul>
                    <div class="is-gap" />
                    <p class="is-size-6-7">
                        {i18n.t(
                            'App.EntitlementCheck.Suspended_Access_Expires_Msg'
                        )}
                    </p>
                    <div class="is-gap" />
                    <p class="is-size-6-7">
                        {i18n.t('App.EntitlementCheck.Why_A_Subscription')}
                    </p>
                </div>
                <div
                    slot="footer-right-section"
                    class="level-item is-marginless"
                >
                    <a
                        href={learnMoreLink}
                        target="_blank"
                        class="button is-small"
                    >
                        {i18n.t('App.EntitlementCheck.Learn_More_Btn')}
                    </a>
                    <button
                        class="ga-{dataCy}-close button is-primary"
                        on:click={openModal(false)}
                        data-cy="{dataCy}-close"
                    >
                        {i18n.t('App.Primary_Buttons.Close_Btn')}
                    </button>
                </div>
            </ModalComponent>
        {:else if status.trial}
            <!--Disabling the trial flow until the we have refined workflow. Keeping this logic for re-use.
            <MessageComponent
                {dataCy}
                bind:this={modalInstance}
                open={status.autoOpen}
            >
                <p slot="title">{i18n.t('App.EntitlementCheck.Modal_Title')}</p>
                <div slot="content">
                    <div class="is-size-7 has-text-weight-medium">
                        <!- - XSS Warning! this is unescapped HTML - ->
                        {@html i18n.t(
                            'App.EntitlementCheck.Trial_Access_Expires_Msg',
                            {
                                HTML_START: '<strong>',
                                EXPIRE_DATE: getExpiresOnDate(status),
                                HTML_END: '</strong>',
                            }
                        )}
                    </div>
                    <a href={learnMoreLink} target="_blank" class="button is-small">
                        {i18n.t('App.EntitlementCheck.Learn_More_Btn')}
                    </a>
                </div>
            </MessageComponent>
            -->
        {/if}
        {#if status.suspended}
            <span class="icon has-pointer-cursor" on:click={openModal(true)}>
                <InfoIcon fill={fillColor()} />
            </span>
        {/if}
    {/if}
</Protected>

<style>
    .instruction-line {
        margin: 10px 0px;
    }

    .icon.restricted-action img {
        position: relative;
        top: 6px;
    }
</style>
