import asyncify from 'async/asyncify';
import queue from 'async/queue';
import until from 'async/until';
import { delay } from '../utils';
import analyticsConstants from './ga.constants.json';
import { currentFolder } from '../../providers/state/active.record';
import { DriveError } from '../../rest.sdks';

export { analyticsConstants };

const { categories, labels, actions, pages } = analyticsConstants;

export { categories };
export { labels };
export { actions };
export { pages };

const pageViewName = 'VirtualPageview';
const eventName = 'GAEvent';

let GTMLoaded = false;

const hasGTM = asyncify(async () => {
    // https://developers.google.com/tag-manager/devguide#datalayer
    let DL = window.dataLayer;
    const loaded = DL && Array.isArray(DL);
    const initialized =
        loaded && !/\{\s*\[native code\]\s*\}/.test('' + DL.push.toString());
    return initialized;
});

// This helps us to queue the events until the ga is loaded through tealium.
export const waitForGTM = async () => {
    if (!GTMLoaded) {
        await until(
            hasGTM,
            asyncify(async () => {
                await delay(700);
                return 'foo';
            })
        );
        GTMLoaded = true;
    }
    return true;
};

const tracker = queue(
    asyncify(async (event) => {
        await waitForGTM();
        // Best place to watch all GA events:
        // console.log(JSON.stringify(event));
        event && window.dataLayer.push(event);
    }),
    1
); // Concurrency limit. Avoids duplicate GTM checks.

export const setUserId = async (userId) => {
    if (!userId) {
        userId = '' + Date.now() + Math.random();
    }
    await tracker.push({
        userId,
    });
};

// Error, Object or String
const _toString = (item) => {
    return item instanceof Error
        ? item.toString()
        : typeof item === 'object'
        ? JSON.stringify(item)
        : item;
};

export const trackEvent = async ({
    category = '',
    action = '',
    label = '',
    value = undefined, // not sent if undefined, 0 sent if null
    errorCode = undefined, // Custom Dimension #3
    dataType = undefined, // Custom Dimension #4
    detail = undefined, // Custom Dimension #5
}) => {
    await tracker.push({
        event: eventName,
        eventCategory: category,
        eventAction: action,
        eventLabel: _toString(label),
        eventValue: value,
        errorCode, // Custom Dimension #3
        dataType, // Custom Dimension #4
        detail: _toString(detail), // Custom Dimension #5
    });
};

// Log Folder Source as DataType Custom Dimension
export const trackEventWithDataType = async (props) => {
    const curFolder = currentFolder.getValue();
    const dataType = curFolder
        ? curFolder.isPrivateShared
            ? 'SharedWithMe'
            : 'MyData'
        : 'Missing';

    trackEvent({ ...props, dataType });
};

export const trackPage = async ({ page, title }) => {
    await tracker.push({
        event: pageViewName,
        virtualPageURL: page,
        virtualPageTitle: title || document.title,
    });
};

export const trackDimension = async (dimension) => {
    await tracker.push(dimension);
};

// Utility method to help identify mismatched UploadFileStart
export const trackUploadInfo = (action, label, value) => {
    // Weed out DriveError's as those should be logged as PromiseRejectionEvents
    if (label instanceof DriveError) {
        return;
    }
    trackEventWithDataType({
        category: categories.upload,
        action: `Info_${action}`,
        label,
        value,
    });
};

export default {
    trackEvent,
    trackPage,
    trackDimension,
    trackUploadInfo,
};
