<script>
    export let asset = null;
    export let folderShareInProgress = false;

    import { onDestroy } from 'svelte';
    import SomethingWentWrong from '../../something.wrong.svelte';
    import Spinner from '../../spinner.svelte';
    import UserCardComponent from './folder.share.user.card.svelte';

    const dataCy = 'manage-share-folder';

    let getShareInfo = asset.sharing.info();

    let observers = asset.observe({
        share: () => {
            getShareInfo = asset.sharing.info();
        },
    });

    const getUserInfo = (
        { firstName, lastName, userId, emailId, permissions },
        isOwner = false
    ) => ({
        firstName,
        lastName,
        userId,
        emailId,
        permissions: permissions ? permissions.explicit : [],
        isOwner,
    });

    onDestroy(() => {
        if (observers) {
            observers.cancel();
        }
    });
</script>

{#await getShareInfo}
    <Spinner name={dataCy} />
{:then shareInfo}
    {#if shareInfo.length}
        <div class="manage-share-field">
            <UserCardComponent
                user={getUserInfo(asset.itemInfo.createdBy, true)}
            />
            {#each shareInfo as sharee}
                <UserCardComponent
                    user={getUserInfo(sharee)}
                    {folderShareInProgress}
                    on:changePermission
                />
            {/each}
        </div>
    {/if}
{:catch error}
    <SomethingWentWrong {error} source={dataCy} />
{/await}

<style>
    .manage-share-field {
        max-height: 477px;
        overflow-y: auto;
    }
</style>
