<script>
    export let file;
    export let assetActions = {};

    import FileIcon from '../../../icons/file.svelte';
    import Spinner from '../../spinner.svelte';
    import {
        timeAgo,
        readableFileSize,
        emptyImage,
    } from '../../../providers/utils';
    import AssetActionsComponent from '../asset.actions.svelte';
    import { onDestroy } from 'svelte';
    import { i18n } from '../../../i18n';
    import { gSelection } from '../../elements/components.state';
    import { locateContextMenu } from '../../utils.js';

    let metadata = file.itemInfo;
    let thumbnailSrc = emptyImage; // Smooth transitions

    file.thumbnail().then((src) => (thumbnailSrc = src));

    let observers = file.observe({
        metadata: () => {
            metadata = file.itemInfo;
        },
    });

    let containerElement;

    const id = `${file.idDecoded}-grid-item`;
    const dataCy = 'file-grid-item';

    onDestroy(() => {
        if (observers) {
            observers.cancel();
        }
    });

    let showContextMenu = false;
    const onContextMenu = (event) => {
        $gSelection = [file];
        locateContextMenu(event, containerElement, `${dataCy}: contextmenu`);
        showContextMenu = true;
    };
</script>

<div
    class="grid-container file-grid-parent column has-text-black-bis is-one-fifth is-mobile"
>
    <div
        class="selectable-item file-grid has-all-space can-hover has-border box"
        bind:this={containerElement}
        data-cy={dataCy}
        {id}
        on:dblclick={assetActions.gotoFile(file, `${dataCy}: dblclick`)}
        on:click={assetActions.gotoFile(file, `${dataCy}: click`)}
        on:contextmenu|preventDefault={onContextMenu}
        class:is-selected={$gSelection && $gSelection.includes(file)}
    >
        {#if !metadata}
            <div class="has-centered-content has-all-space">
                <Spinner />
            </div>
        {:else}
            <div
                class="figure has-centered-content thumbnail-box
                        has-ellipsis"
                class:image={thumbnailSrc}
                class:has-padding={!thumbnailSrc}
            >
                {#if thumbnailSrc}
                    <img
                        on:error={() => (thumbnailSrc = null)}
                        alt={file.fileType}
                        src={thumbnailSrc}
                    />
                {:else}
                    <FileIcon width={64} type={file.fileType} />
                {/if}
            </div>
            <div
                class="file-info-grid columns is-mobile is-vertical-center
                        is-marginless has-top-border"
            >
                <div class="column has-ellipsis">
                    <div
                        class="has-text-weight-semibold main-text
                                has-ellipsis"
                        data-cy="item-name"
                    >
                        {metadata.name}
                    </div>
                    <div
                        class="is-size-7 has-ellipsis"
                        title={i18n.displayFullName(metadata.lastModifiedBy)}
                    >
                        {i18n.t('App.Time_Messages.Modified_Time_Ago', {
                            TIME_AGO: timeAgo(metadata.modifiedDate),
                        })}
                    </div>
                    <div
                        class="is-size-7 has-ellipsis"
                        title={i18n.displayFullName(metadata.lastModifiedBy)}
                    >
                        {#if metadata.size}
                            {readableFileSize(metadata.size)}
                        {/if}
                        <span class="tag is-rounded">
                            {i18n.t(
                                'App.Asset_Info_Summary.File_Version_String',
                                {
                                    VERSION: metadata.versionNumber,
                                }
                            )}
                        </span>
                    </div>
                </div>
                <div class="column is-narrow">
                    <AssetActionsComponent
                        asset={file}
                        {assetActions}
                        hideButtons={true}
                        parentDataCy={dataCy}
                        bind:openDropdown={showContextMenu}
                    />
                </div>
            </div>
        {/if}
    </div>
</div>

<style>
    .file-grid-parent {
        min-height: 300px;
    }
    .file-grid {
        border-radius: 5px;
        padding: 0;
    }
    .thumbnail-box {
        height: 180px;
        background-color: #ffffff;
        border-radius: 5px;
    }
    .file-info-grid {
        height: 100px;
    }
</style>
