<script>
    export let onSelect = () => {};

    import { checkFileForErrors, MAX_FILE_SIZE } from './upload.utils';

    const onPick = (files) => {
        files.forEach((file) => {
            checkFileForErrors(file);
        });
        onSelect({
            type: 'dropbox',
            files,
        });
    };

    // Dropbox options https://www.dropbox.com/developers/chooser#triggering
    const options = {
        success: onPick,
        linkType: 'direct',
        cancel: function () {},
        multiselect: true,
        folderselect: false,
        sizeLimit: MAX_FILE_SIZE,
    };

    const pickFiles = () => {
        Dropbox.choose(options);
    };
</script>

<label for="" on:click={pickFiles}>
    <slot name="trigger" />
</label>
