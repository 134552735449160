<script>
    export let asset = {};
    export let type = 'folder';
    export let onSelect = () => {};

    import { randomHex } from '../../providers/utils';
    import Uppie from 'uppie';
    import { onMount } from 'svelte';
    import { checkFileForErrors } from './upload.utils';

    const uppie = new Uppie();
    const id = `${asset.idDecoded}_upload_files_${randomHex()}`;

    const onPick = async (event, formData) => {
        event.preventDefault();
        let files = [];
        for (let [, file] of formData.entries()) {
            files.push(checkFileForErrors(file));
        }
        onSelect({
            files,
            type,
        });
        const input = document.getElementById(id);
        if (input) {
            input.value = null;
        }
    };

    onMount(() => {
        const input = document.getElementById(id);
        uppie(input, onPick);
    });
</script>

<label id="{id}-label" for={id} class="file-label can-hover">
    <slot name="trigger" />
    {#if type === 'files'}
        <input
            class="file-input has-pointer-cursor"
            type="file"
            {id}
            data-cy="upload-input"
            name={id}
            multiple
        />
    {:else if ['folder'].indexOf(type) > -1}
        <input
            type="file"
            {id}
            data-cy="folder-input"
            class="file-input has-pointer-cursor"
            name={id}
            multiple
            directory
            webkitdirectory
            allowdirs
        />
    {:else}
        <input
            type="file"
            {id}
            data-cy="upload-new-version-input"
            class="file-input has-pointer-cursor"
            name={id}
        />
    {/if}
</label>
