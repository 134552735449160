<script>
    export let isFullWidth = false;
    export let isCenterAligned = false;
    export let hasIcon = true;
    import DownloadIcon from '../icons/download.svelte';
    import config from 'app.config';
    import { i18n } from '../i18n';
    const desktopconnectorHelp = config.links.desktopconnectorHelp;
    const dataCy = 'download-desktop-connector';
</script>

<a
    href={desktopconnectorHelp}
    target="_blank"
    data-cy={dataCy}
    class:is-fullwidth={isFullWidth}
    class:is-leftaligned={isFullWidth && !isCenterAligned}
    class="ga-{dataCy} desktop-connector-download button is-primary is-outlined
    has-text-weight-medium"
>
    {#if hasIcon}
        <span class="icon">
            <DownloadIcon fill="#0696D7" />
        </span>
    {/if}
    <span>
        <slot name="title">
            {i18n.t('App.Primary_Buttons.Desktop_Connector_Btn')}
        </slot>
    </span>
</a>
