<script>
    export let upload = null; // Provided when dialog
    export let statusText = null;
    export let group = null;
    export let uploadsMetadata = {};
    export let showDismiss = false;
    export let dismissUploads = null;

    import FileIcon from '../../icons/file.svelte';
    import ExpandIcon from '../../icons/expand.svelte';
    import CloseIcon from '../../icons/close.svelte';
    import { timeAgo, readableFileSize } from '../../providers/utils';
    import { uploadStore } from '../../providers/state/upload.store';
    import { onDestroy } from 'svelte';
    import { i18n } from '../../i18n';

    let observers, metadataArr;

    const dataCy = 'upload-item';

    group = group || Array.isArray(upload);

    // TODO: We need to fix at source of NaN fix it.
    const fixProgress = () => {
        if (!Array.isArray(metadataArr)) return;
        metadataArr.forEach((metadata) => {
            if (metadata && isNaN(metadata.progress)) {
                metadata.progress = 0;
            }
        });
    };

    const setUploadsMetadata = (arr) => {
        metadataArr = arr;
        fixProgress();
    };

    const assignMetadata = () => {
        if (!upload) return;
        if (upload.uploadType === 'dropbox') {
            setUploadsMetadata(upload.info());
        } else {
            setUploadsMetadata([upload.info()]);
        }
    };

    const cancelObservers = () => {
        if (observers) {
            observers.cancel();
        }
    };

    const cancelUploadItem = () => {
        uploadStore.cancelItem(upload);
    };

    $: {
        if (uploadsMetadata) {
            setUploadsMetadata([uploadsMetadata]);
        }
        if (!group && upload && upload.observe) {
            cancelObservers();
            observers = upload.observe({
                progress: assignMetadata,
                cancel: cancelUploadItem,
            });
        }
        assignMetadata();
    }

    const cancelUpload = () => {
        upload.cancel();
    };

    const retryUpload = () => {
        upload.start();
    };

    onDestroy(cancelObservers);
</script>

{#each metadataArr as metadata (metadata.path)}
    <div
        class="upload-item is-flex is-flex-direction-row px-4 py-0 is-vcentered has-all-space box has-border"
        data-cy={dataCy}
    >
        {#if metadata.failedCount > 0 && metadata.progress === 100}
            <div class="column is-narrow px-0 pt-5 is-1-mobile">
                <span class="icon">
                    <img alt="Danger" src="DRIVE_ASSETS/images/danger.svg" />
                </span>
            </div>
        {/if}
        <div
            class="columns is-marginless is-vcentered has-all-space
        is-multiline px-0 pb-0 pt-2 is-8-mobile"
        >
            <div class="column is-flex is-full is-vcentered is-paddingless">
                {#if metadata.fileType}
                    <div class="column is-narrow is-2-mobile pr-0">
                        <span class="icon">
                            <FileIcon type={metadata.fileType} />
                        </span>
                    </div>
                {/if}
                <div class="upload-status column has-ellipsis pb-0">
                    <div
                        class="is-size-6 has-text-black has-text-weight-medium has-ellipsis"
                    >
                        {statusText || metadata.name}
                    </div>
                    <div
                        class="is-size-7 is-flex is-justify-content-space-between"
                    >
                        {#if metadata.failed}
                            <span class="prop has-text-grey-light">
                                {i18n.t(
                                    `App.Upload_Actions.${
                                        metadata.file.errorType || 'Failed'
                                    }_Short`
                                )}
                            </span>
                        {:else}
                            {#if metadata.progress}
                                <span class="prop">
                                    {i18n.t(
                                        'App.Upload_Actions.Uploading_Pct_Complete',
                                        {
                                            PCT_NUMBER: metadata.progress,
                                        }
                                    )}
                                </span>
                            {/if}
                            {#if metadata.size}
                                <span class="prop has-ellipsis">
                                    {i18n.t(
                                        'App.Upload_Actions.Uploading_File_Size',
                                        {
                                            FILE_SIZE: readableFileSize(
                                                metadata.size
                                            ),
                                        }
                                    )}
                                </span>
                            {/if}
                            {#if metadata.startTime}
                                <span class="prop is-hidden-mobile">
                                    {i18n.t(
                                        'App.Upload_Actions.Uploading_Started',
                                        {
                                            TIME_AGO: timeAgo(
                                                metadata.startTime
                                            ),
                                        }
                                    )}
                                </span>
                            {/if}
                            {#if metadata.failedCount}
                                <span class="prop is-hidden-mobile">
                                    {i18n.t(
                                        'App.Upload_Actions.Uploading_Failed',
                                        {
                                            COUNT: metadata.failedCount,
                                        }
                                    )}
                                </span>
                            {/if}
                            {#if metadata.pausedCount}
                                <span class="prop is-hidden-mobile">
                                    {i18n.t(
                                        'App.Upload_Actions.Uploading_Paused',
                                        {
                                            COUNT: metadata.pausedCount,
                                        }
                                    )}
                                </span>
                            {/if}
                            {#if metadata.inprogressCount}
                                <span class="prop is-hidden-mobile">
                                    {i18n.t(
                                        'App.Upload_Actions.Uploading_In_Progress',
                                        {
                                            COUNT: metadata.inprogressCount,
                                        }
                                    )}
                                </span>
                            {/if}
                        {/if}
                    </div>
                    <div class="is-size-7 is-hidden-mobile">
                        {#if metadata.name !== metadata.path}
                            <span class="prop has-text-link is-italic">
                                {metadata.path}
                            </span>
                        {/if}
                    </div>
                </div>
                <div class="upload-actions is-paddingless">
                    {#if !metadata.actionsNotAllowed}
                        {#if metadata.failed}
                            <div
                                class="is-flex is-flex-direction-column is-justify-content-flex-end"
                            >
                                <button
                                    data-cy="{dataCy}-dismiss"
                                    class="button is-outlined is-white is-normal is-borderless ml-4"
                                    on:click={cancelUploadItem}
                                >
                                    <CloseIcon />
                                </button>
                                {#if !metadata.file.errorType}
                                    <button
                                        class="button is-outlined is-white is-small
                            is-borderless"
                                        on:click={retryUpload}
                                    >
                                        <span
                                            class="icon is-size-7 has-text-weight-bold has-text-primary has-tooltip-left has-tooltip-arrow
                                has-tooltip-hidden-mobile"
                                            data-tooltip={i18n.t(
                                                'App.Upload_Actions.Retry_Tooltip'
                                            )}
                                        >
                                            {i18n.t(
                                                'App.Primary_Buttons.Retry_Btn'
                                            )}
                                        </span>
                                    </button>
                                {/if}
                            </div>
                        {:else if metadata.progress !== 100}
                            <button
                                data-cy="{dataCy}-cancel"
                                class="button is-outlined is-white is-normal is-borderless mt-2 mr-3"
                                on:click={cancelUpload}
                            >
                                <span
                                    class="icon is-size-7 has-text-weight-bold has-text-primary has-tooltip-left has-tooltip-arrow
                        has-tooltip-hidden-mobile"
                                    data-tooltip={i18n.t(
                                        'App.Upload_Actions.Cancel_Upload_Tooltip'
                                    )}
                                >
                                    {i18n.t('App.Primary_Buttons.Cancel_Btn')}
                                </span>
                            </button>
                        {/if}
                    {/if}
                    <div
                        class="is-flex is-flex-direction-row is-justify-content-flex-end pr-1"
                    >
                        {#if group}
                            <button
                                class="button is-outlined is-white is-small is-borderless mt-4"
                            >
                                <span
                                    class="icon has-tooltip-left has-tooltip-arrow
                        has-tooltip-hidden-mobile"
                                    data-tooltip={i18n.t(
                                        'App.Upload_Actions.Expand_Tooltip'
                                    )}
                                >
                                    <ExpandIcon height={30} width={30} />
                                </span>
                            </button>
                        {/if}
                    </div>
                </div>
            </div>
            <div class="column is-full is-vcentered pt-2">
                <progress
                    class="progress is-info"
                    value={metadata.failed ? 100 : metadata.progress}
                    max="100"
                    class:is-danger={metadata.pausedCount > 0 ||
                        metadata.failedCount > 0 ||
                        metadata.failed}
                    class:is-primary={metadata.progress > 90}
                    class:is-success={metadata.progress === 100}
                >
                    {metadata.progress}%
                </progress>
            </div>
        </div>
        {#if showDismiss}
            <div class="column is-narrow px-0 pt-2 is-1-mobile">
                <div class="button is-outlined is-white is-small is-borderless">
                    <span
                        class="icon"
                        data-cy="upload-dismiss"
                        on:click={dismissUploads}
                    >
                        <CloseIcon />
                    </span>
                </div>
            </div>
        {/if}
    </div>
{/each}

<style>
    .prop {
        margin-right: 5px;
    }
</style>
