<script>
    import Spinner from '../components/spinner.svelte';
    import SomethingWentWrong from '../components/something.wrong.svelte';
    import config from 'app.config';

    const firstReleaseYear = 2020;
    const links = config.links;
    const dataCy = 'thirdparty';

    const listComponents = async () => {
        const req = await fetch('DRIVE_ASSETS/dependencies.json');
        const components = await req.json();
        return components;
    };
</script>

<div class="section" data-cy={dataCy}>
    <div class="section-body content">
        {#await listComponents()}
            <div class="has-centered-content has-all-space">
                <Spinner />
            </div>
        {:then components}
            <div class="container is-fluid">
                <div class="is-gap" />
                <div class="is-size-6 has-text-weight-bold">
                    {config.desc.title} © {firstReleaseYear} Autodesk, Inc. All rights
                    reserved.
                </div>
                <div class="is-gap" />
                <div class="subtitle is-6">
                    All use of this Software is subject to the Autodesk terms of
                    service accepted accepted upon access or use of this Service
                    or made available on the Autodesk webpage. Autodesk terms of
                    service for Autodesk’s various web services can be found
                    <a href={links.terms} target="_blank">here</a>
                    .
                </div>

                <div class="is-size-6 has-text-weight-bold">Privacy</div>
                <div class="subtitle is-6">
                    To learn more about Autodesk’s online and offline privacy
                    practices, please see the
                    <a href={links.privacy} target="_blank">
                        Autodesk Privacy Statement
                    </a>
                    .
                </div>

                <div class="is-size-6 has-text-weight-bold">
                    Autodesk Trademarks
                </div>
                <div class="is-size-6">
                    The trademarks on the
                    <a href={links.trademarks} target="_blank">
                        Autodesk Trademarks page
                    </a>
                    are registered trademarks or trademarks of Autodesk, Inc., and/or
                    its subsidiaries and/or affiliates in the USA and/or other countries.
                </div>

                <div class="subtitle is-6">
                    All other brand names, product names or trademarks belong to
                    their respective holders.
                </div>

                <div class="is-size-6 has-text-weight-bold">Patents</div>
                <div class="subtitle is-6">
                    This Service is protected by patents listed on the
                    <a href={links.patents} target="_blank">
                        Autodesk Patents page
                    </a>
                    .
                </div>

                <div class="is-size-6 has-text-weight-bold">
                    Autodesk Cloud and Desktop Components
                </div>
                <div class="subtitle is-6">
                    This Service may incorporate or use background Autodesk
                    online and desktop technology components. For information
                    about these components, see
                    <a href={links.cloudComponents} target="_blank">
                        Autodesk Cloud Platform Components
                    </a>
                    and
                    <a href={links.desktopComponents} target="_blank">
                        Autodesk Desktop Platform Components
                    </a>
                    .
                </div>

                <div class="subtitle is-6 has-text-weight-bold">
                    Third-Party Trademarks, Software Credits and Attributions:
                </div>
            </div>
            <div class="container is-fluid">
                {#each components as component}
                    <div class="box component-block">
                        <div class="subtitle is-6 has-text-primary">
                            <a href={component.link} target="_blank">
                                {component.name}@{component.version}
                            </a>
                        </div>
                        <div class="is-size-6 has-text-weight-bold">Author</div>
                        <div class="subtitle is-6">
                            {#if component.author}
                                {`${component.author.name} ${
                                    component.author.email || ''
                                }`}
                            {:else}Author information not Available.{/if}
                        </div>
                        <div class="is-size-6 has-text-weight-bold">
                            Description
                        </div>
                        <div class="subtitle is-6">{component.description}</div>
                        <div class="is-size-6 has-text-weight-bold">
                            Link to homepage or source
                        </div>
                        <div class="subtitle is-6">{component.link}</div>
                        <div class="is-size-6 has-text-weight-bold">
                            License
                        </div>
                        <div class="subtitle is-6">{component.license}</div>
                        <div class="subtitle is-7">
                            {component.licenseText || ''}
                        </div>
                    </div>
                {/each}
            </div>
        {:catch error}
            <SomethingWentWrong {error} source="thirdparty" />
        {/await}
    </div>
</div>

<style>
    .component-block {
        margin: 25px 0;
    }
</style>
