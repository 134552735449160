<script>
    import config from 'app.config';
    import { i18n } from '../i18n';
    import HelpIcon from '../icons/help.svelte';

    const links = config.links;
    const dataCyHelp = 'help';
    const adskBlue = '#0696D7';

    let isHoverOnLink = false;

    const toggleHover = () => {
        isHoverOnLink = !isHoverOnLink;
    };
</script>

<a
    id="help-link"
    on:mouseenter={toggleHover}
    on:mouseleave={toggleHover}
    href={links.help}
    class="is-rightaligned"
    target="_blank"
    data-cy={dataCyHelp}
>
    <span class="icon">
        <HelpIcon fill={isHoverOnLink ? adskBlue : ''} />
    </span>
    <span class="help-link-text is-hidden-mobile"
        >{i18n.t('App.Primary_Buttons.Help_Btn')}</span
    >
</a>

<style>
    #help-link {
        color: inherit;
    }
    #help-link:hover {
        color: #0696d7;
    }
    #help-link span.icon {
        margin-right: 0px;
    }
    #help-link span.help-link-text {
        font-size: 12px;
        margin-top: 3px;
    }
</style>
