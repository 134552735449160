<script>
    export let userProfile = {};
    export let size = '32x32';

    import { profile } from '../providers/state/user';

    const isLoggedInUser = $profile.userId === userProfile.userId;
    const defaultImagePath = '/default/user_X50.png';

    let displayOnlyInitials = false;

    const getAvatarUrlForLoggedInUser = () => {
        try {
            const imageUrl = $profile.profileImages['sizeX50'];
            if (imageUrl.endsWith(defaultImagePath)) {
                displayOnlyInitials = true;
                return;
            }
            return imageUrl;
        } catch (error) {
            // If any error, show initials
            displayOnlyInitials = true;
        }
    };

    const getAvatarUrlForSharees = () => {
        const imageServer = new URL($profile.profileImages['sizeX50']).origin;
        return `${imageServer}/${userProfile.userId}/profilepictures/x50.jpg`;
    };

    const avatarUrl = isLoggedInUser
        ? getAvatarUrlForLoggedInUser()
        : getAvatarUrlForSharees();

    let initials = '';

    if (userProfile.firstName) {
        initials += userProfile.firstName.slice(0, 1);
    }

    if (userProfile.lastName) {
        initials += userProfile.lastName.slice(0, 1);
    }

    const error = () => {
        displayOnlyInitials = true;
    };
</script>

<figure class="image is-{size} is-relative is-rounded is-marginless">
    {#if !displayOnlyInitials}
        <img
            class="avatar-img is-rounded has-pointer-cursor"
            on:error={error}
            src={avatarUrl}
            alt={initials}
        />
    {/if}
    <div class="image initials is-{size} is-size-6 is-rounded tag is-primary">
        <span class="has-pointer-cursor">{initials}</span>
    </div>
</figure>

<style>
    .avatar-img {
        position: absolute;
        z-index: 1;
    }
</style>
