<script>
    export let viewable = null;
    export let type = 'video';

    import 'plyr/dist/plyr.css';
    import Plyr from 'plyr/dist/plyr.polyfilled.min';
    import { onDestroy, onMount } from 'svelte';

    const name = viewable.id;
    const id = `${name}-video`;
    const dataCy = 'video-viewer';

    let videoViewerInstance;
    const initializeVideoViewer = () => {
        const target = document.getElementById(id);
        videoViewerInstance = new Plyr(target);
    };

    onDestroy(() => {
        if (videoViewerInstance) {
            videoViewerInstance.destroy();
        }
    });

    onMount(() => {
        initializeVideoViewer();
    });
</script>

<div
    class="video-viewer has-centered-content has-all-space is-relative"
    data-cy={dataCy}
>
    {#if type === 'audio'}
        <audio {id} class="video-js vjs-fluid has-all-space video-parent">
            <source src={viewable.viewingUrl} type="audio/mp3" />
        </audio>
    {:else}
        <!-- svelte-ignore a11y-media-has-caption -->
        <video {id} class="video-js vjs-fluid video-parent">
            <source src={viewable.viewingUrl} type="video/mp4" />
        </video>
    {/if}
</div>

<style>
    .video-parent {
        margin: 15px;
    }
    :global(.plyr) {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
</style>
