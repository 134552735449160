<script>
    export let sharee = {};
    export let isManage = false;
    export let folderShareInProgress = false;

    import { createEventDispatcher } from 'svelte';
    import DropDownComponent from '../../elements/dropdown.svelte';
    import DownloadIcon from '../../../icons/download.svelte';
    import CheckIcon from '../../../icons/check.svelte';
    import EditIcon from '../../../icons/rename.svelte';
    import RemoveIcon from '../../../icons/close.svelte';
    import RemoveConfirmation from './folder.share.remove.svelte';
    import { i18n } from '../../../i18n';
    import {
        arrayEquals,
        getPermissionsFromRole,
    } from '../../../providers/utils';
    import { isMobile } from '../../../providers/device';

    const dispatch = createEventDispatcher();
    const dataCy = `folder-share-permission-${isManage ? 'manage' : 'new'}`;
    const dataCyDropdown = 'folder-share-dropdown';
    const actions = [
        // Can View has issues - DRIVE-908
        /*{
            name: 'view',
            label: i18n.t('App.Folder_Sharing.Can_View'),
            permissions: getPermissionsFromRole('view'),
            note: i18n.t('App.Folder_Sharing.View_Note'),
            icon: {
                component: ViewIcon,
                props: {},
            },
        },*/
        {
            name: 'download',
            label: i18n.t('App.Folder_Sharing.Can_Download'),
            permissions: getPermissionsFromRole('download'),
            note: i18n.t('App.Folder_Sharing.Download_Note'),
            icon: {
                component: DownloadIcon,
                props: {},
            },
        },
        {
            name: 'edit',
            label: i18n.t('App.Folder_Sharing.Can_Edit'),
            permissions: getPermissionsFromRole('edit'),
            note: i18n.t('App.Folder_Sharing.Edit_Note'),
            icon: {
                component: EditIcon,
                props: {},
            },
        },
    ];
    let selectedPermission = {};
    if (isManage) {
        actions.push({
            name: 'remove',
            label: i18n.t('App.Folder_Sharing.Remove'),
            note: i18n.t('App.Folder_Sharing.Remove_Note'),
            permissions: [],
            icon: {
                component: RemoveIcon,
                props: {
                    fill: '#FF0000',
                },
            },
        });
    }

    if (isManage) {
        selectedPermission = actions.filter((action) =>
            arrayEquals(action.permissions, sharee.permissions)
        )[0] || {
            // If folder permissions are not matching with UI options
            label: i18n.t('App.Folder_Sharing.Unknown_Permission'),
        };
    } else {
        selectedPermission = actions[0];
    }

    const onChangePermission = (actionName) => {
        selectedPermission = actions.filter(
            (action) => action.name === actionName
        )[0];
        dispatch('changePermission', {
            action: selectedPermission.name,
            newPermissions: selectedPermission.permissions.toString(),
            ...sharee,
            isManage,
        });
    };

    let showRemoveConfirmation = false;
    const onRemoveConfirmation = (isRemove) => {
        showRemoveConfirmation = false;
        if (isRemove) {
            onChangePermission('remove');
        }
    };

    let dropdownComponent;
    const onRemove = (event) => {
        dropdownComponent.closeDropdown();
        showRemoveConfirmation = true;
        event.stopPropagation();
    };

    const setDropDownMenuPosition = (event) => {
        const rectObject = event.target.getBoundingClientRect();
        const dropdownMenu = event.target.parentElement.nextElementSibling;
        dropdownMenu.style.top = rectObject.top + rectObject.height + 'px';
        if ($isMobile) {
            dropdownMenu.style.right = 0;
        } else {
            dropdownMenu.style.left = rectObject.left + 'px';
        }
    };
</script>

<DropDownComponent
    position="right"
    dropdownContentOffset="true"
    isDisabled={folderShareInProgress}
    isHiddenOnScroll="true"
    hasCustomWidth="true"
    {dataCyDropdown}
    bind:this={dropdownComponent}
>
    <button
        class="ga-{dataCy} button is-pulled-right btn-permission-select"
        slot="trigger"
        disabled={folderShareInProgress}
        data-cy={dataCy}
        on:click={setDropDownMenuPosition}
    >
        <span>{selectedPermission.label}</span>
        <span class="icon"> <span class="arrow down" /> </span>
    </button>
    <div slot="content">
        <ul class="is-marginless">
            {#each actions as action}
                <li
                    class="ga-{dataCy}-{action.name} dropdown-item action-item"
                    class:has-text-danger={action.name === 'remove'}
                    on:click={(event) =>
                        action.name === 'remove'
                            ? onRemove(event)
                            : onChangePermission(action.name)}
                    data-cy="{dataCy}-item"
                >
                    <div class="is-vertical-center has-text-weight-bold">
                        {#if selectedPermission.name === action.name}
                            <span class="icon selected-action">
                                <CheckIcon fill="#0696D7" />
                            </span>
                        {:else}
                            <span class="icon" />
                        {/if}

                        <span class="icon">
                            <svelte:component
                                this={action.icon.component}
                                {...action.icon.props}
                            />
                        </span>
                        <span>
                            {action.label}
                        </span>
                    </div>
                    <div class="is-size-7 has-leftpadding permission-note">
                        {action.note}
                    </div>
                </li>
            {/each}
        </ul>
    </div>
</DropDownComponent>

{#if showRemoveConfirmation}
    <RemoveConfirmation onClose={onRemoveConfirmation} />
{/if}

<style>
    .action-item {
        color: #0a131c;
        padding: 0.75rem 0.375rem !important;
    }

    .btn-permission-select {
        height: 44px;
    }
    .btn-permission-select span {
        pointer-events: none;
    }

    /* 
      Temporary: Show empty option for existing shares with Can View role.
      Can View has issues - DRIVE-908.
     */
    .btn-permission-select > span:first-child {
        min-width: 50px;
    }

    .selected-action {
        margin-top: 5px !important;
    }

    li.dropdown-item > .permission-note {
        white-space: normal;
    }
</style>
