import { DriveError } from '../rest.sdks';
import { categories, actions, trackEventWithDataType } from './analytics/ga';
import { randomHex } from './utils';

export const trackWait = (options = {}) => {
    const name = options.name || '<unnamed>';
    const min = options.min || 2; // Log threshold in seconds
    const start = Date.now();
    const key = randomHex();

    // Capture the hierarchy...
    const getPath = (node) => {
        let localPath = [name];
        // Check element...
        let nextElement = node;
        while (nextElement && (nextElement = nextElement.parentElement)) {
            if (nextElement.nodeName === 'BODY') {
                localPath.push('|'); // Identify revised format
                break; // Skip top two elements
            }
            if (nextElement.hasAttributes()) {
                var attrs = nextElement.attributes;
                for (let i = 0; i < attrs.length; i++) {
                    if (attrs[i].name === 'data-cy') {
                        localPath.push(attrs[i].value);
                    }
                }
            }
        }

        return localPath;
    };

    const path = getPath(options.instance).reverse().join(' > ');

    // Log the lifespan of this wait element to the proper category
    const lifespan = (category = categories.userStatus) => {
        if (category === categories.userStatus) {
            DriveError.removeWaiter(key);
        }
        const wait = (Date.now() - start) / 1000; // Seconds
        if (wait > min) {
            const event = {
                category,
                action: actions.waiting,
                label: path,
                value: wait,
            };
            trackEventWithDataType(event);
        }
    };

    DriveError.addWaiter(key, lifespan);

    return { lifespan };
};
