<script>
    export let collectionId = '';
    export let shareId = '';

    import FileIcon from '../icons/file.svelte';
    import { i18n } from '../i18n';
    import ModalComponent from '../components/elements/modal.svelte';
    import { getPropValue, ShareFactory } from '../rest.sdks';
    import SomethingWentWrong from '../components/something.wrong.svelte';
    import Spinner from '../components/spinner.svelte';
    import { trackEvent, categories, actions } from '../providers/analytics/ga';
    import AssetDownloadComponent from '../components/folder.content/asset.download.svelte';
    import Viewer from '../components/file.viewer/viewer.svelte';
    import { getExtension } from '../rest.sdks/utils';

    const dataCy = 'shared-file';
    const dataCyDownload = `${dataCy}-viewer-download`;
    const dataCyPasswordButton = `${dataCy}-enter-password-button`;

    const openModal = true;

    let getViewableInfo = false,
        incorrectPassword,
        lastError,
        password = '',
        modalInstance,
        passwordValidationInProgress = false,
        share,
        shareInfo,
        showFileViewer = false,
        showPasswordModal = false,
        viewable;

    const onEnter = (event) => {
        if (event.keyCode == 13) {
            setPassword();
        }
    };

    const setPassword = async () => {
        passwordValidationInProgress = true;
        incorrectPassword = false;
        try {
            viewable = await share.viewable(password);
            showFileViewer = true;
            modalInstance.close();
        } catch (error) {
            if (getPropValue(error, 'response.status') === 403) {
                incorrectPassword = true;
                trackEvent({
                    category: categories.publicFileShare,
                    action: actions.publicShareIncorrectPassword,
                });
            } else {
                lastError = error;
                modalInstance.close();
            }
        }
        passwordValidationInProgress = false;
    };

    share = ShareFactory(shareId, collectionId);

    $: {
        (async () => {
            try {
                shareInfo = share && (await share.info());
            } catch (error) {
                lastError = error;
            }
        })();
    }

    $: {
        if (shareInfo) {
            if (shareInfo.attributes.passwordProtected === true) {
                showPasswordModal = true;
            } else {
                getViewableInfo = true;
            }
        }
    }

    $: {
        if (getViewableInfo) {
            (async () => {
                try {
                    viewable = await share.viewable(password);
                    showFileViewer = true;
                } catch (error) {
                    lastError = error;
                }
            })();
        }
    }
</script>

<div class="section" data-cy={dataCy}>
    {#if lastError}
        <SomethingWentWrong error={lastError} source={dataCy} />
    {:else if showFileViewer}
        <div class="section-head">
            <div
                class="container is-fluid columns is-marginless
                        has-bottom-border is-strong-border is-mobile"
            >
                <div
                    class="column file-info-banner is-vertical-center
                            is-paddingless has-ellipsis"
                >
                    <span class="icon">
                        <FileIcon type={getExtension(viewable.name)} />
                    </span>
                    <span
                        class="title is-6 has-tooltip-right has-tooltip-arrow has-tooltip-hidden-mobile"
                    >
                        {viewable.name}
                    </span>
                </div>
                {#if shareInfo.attributes.downloadEnabled === true}
                    <div class="column is-narrow is-vertical-center">
                        <AssetDownloadComponent
                            assets={[share]}
                            {viewable}
                            open={false}
                        >
                            <button
                                class={`ga-${dataCyDownload} button is-outlined is-primary is-small`}
                                data-cy={dataCyDownload}
                                slot="download-folder-trigger"
                            >
                                {i18n.t('App.Primary_Buttons.Download_Btn')}
                            </button>
                        </AssetDownloadComponent>
                    </div>
                {/if}
            </div>
        </div>
        <div class="is-gap" />
        <div class="section-body">
            <Viewer
                {collectionId}
                file={share}
                {viewable}
                canDownload={shareInfo.attributes.downloadEnabled}
                {password}
            />
        </div>
        <div class="is-gap" />
    {:else}
        <div class="has-centered-content has-all-space">
            <Spinner size="is-large" />
        </div>
    {/if}
    {#if showPasswordModal}
        <ModalComponent
            {openModal}
            dataCy="share-file-password-modal"
            bind:this={modalInstance}
        >
            <div slot="title">
                <div class="is-vertical-center has-ellipsis">
                    {i18n.t('App.Sharing_Actions.Password_Protected')}
                </div>
            </div>
            <div slot="content">
                <!-- <div class="subtitle is-6">
                    {i18n.t('App.Sharing_Actions.Password_Protected')}
                </div> -->
                <div class="is-paddingless">
                    <input
                        class="input is-focused"
                        data-cy="enter-password-input"
                        type="password"
                        placeholder={i18n.t(
                            'App.Sharing_Actions.Password_Placeholder'
                        )}
                        on:keydown={onEnter}
                        bind:value={password}
                    />
                </div>
                {#if incorrectPassword}
                    <SomethingWentWrong
                        error={new Error('App.Errors.Wrong_Password_Msg')}
                        source={dataCyPasswordButton}
                        messageId="App.Errors.Wrong_Password_Msg"
                        inline={true}
                    />
                {/if}
                {#if lastError}
                    <SomethingWentWrong
                        error={lastError}
                        source={dataCyPasswordButton}
                        inline={true}
                    />
                {/if}
            </div>
            <div slot="footer-right-section">
                <button
                    class="ga-{dataCyPasswordButton} button is-primary"
                    data-cy={dataCyPasswordButton}
                    on:click={setPassword}
                    class:is-loading={passwordValidationInProgress}
                    disabled={password.length === 0}
                >
                    {i18n.t('App.Primary_Buttons.Confirm_Btn')}
                </button>
            </div>
        </ModalComponent>
    {/if}
</div>

<style>
    .file-info-banner {
        height: 60px;
    }
</style>
