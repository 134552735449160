<script>
    export let fill = '';
    export let height = 20;
    export let width = 20;
    export let viewBox = '0 0 15 11';
    $: if (!fill) {
        fill = '#666666';
    }
</script>

<svg {width} {height} {viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M1.31337 3.00586C1.31337 2.20586 1.51337 1.50586 2.01337
        0.905859C2.51337 0.305859 3.21337 0.00585938 4.01337 0.00585938C4.81337
        0.00585938 5.51337 0.305859 6.01337 0.905859C6.51337 1.50586 6.71337
        2.20586 6.71337 3.00586C6.71337 4.80586 5.61337 6.10586 4.01337
        6.10586C2.41337 6.10586 1.31337 4.80586 1.31337 3.00586ZM6.91337
        7.90586C6.11337 7.10586 5.11337 6.70586 4.01337 6.60586C2.91337 6.70586
        1.91337 7.10586 1.11337 7.90586C0.613365 8.50586 0.313365 9.20586
        0.113365 9.90586C0.0133652 10.2059 0.313365 10.5059 0.613365
        10.5059H6.01337V10.4059C6.11337 9.50586 6.41337 8.60586 6.91337
        7.90586ZM11.0134 6.10586C12.5134 6.10586 13.7134 4.80586 13.7134
        3.00586C13.8134 2.20586 13.5134 1.50586 13.0134 0.905859C12.5134
        0.305859 11.8134 0.00585938 11.0134 0.00585938C10.2134 0.00585938
        9.51336 0.305859 9.01336 0.905859C8.51336 1.50586 8.31336 2.20586
        8.31336 3.00586C8.31336 4.80586 9.51336 6.10586 11.0134 6.10586ZM13.9134
        7.90586C13.1134 7.10586 12.1134 6.60586 11.0134 6.60586C9.91336 6.70586
        8.91337 7.10586 8.11337 7.90586C7.61337 8.50586 7.31336 9.20586 7.11337
        9.90586C7.01337 10.2059 7.31336 10.5059 7.61337 10.5059H14.4134C14.7134
        10.5059 15.0134 10.2059 14.9134 9.90586C14.8134 9.20586 14.4134 8.50586
        13.9134 7.90586Z"
        {fill}
    />
</svg>
