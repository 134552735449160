<script>
    export let onClose = () => {};

    import { randomHex } from '../../../providers/utils';
    import ModalComponent from '../../elements/modal.svelte';
    import { i18n } from '../../../i18n';

    const id = `remove-folder-share-${randomHex()}`;
    const dataCy = 'remove-folder-share';

    let modalInstance, removeAnswer;
    const options = {
        onCloseEnd: () => {
            onClose(removeAnswer);
        },
    };
    const supplyAnswer = (answer) => {
        return (event) => {
            removeAnswer = answer;
            modalInstance.close(event);
        };
    };
</script>

<ModalComponent {id} {dataCy} bind:this={modalInstance} {options}>
    <span slot="title">
        {i18n.t('App.Folder_Sharing.Remove_Confirm_Title')}
    </span>
    <div slot="content">
        <div class="subtitle is-6" data-cy="{dataCy}-confirm-msg">
            {i18n.t('App.Folder_Sharing.Remove_Confirm_Msg')}
        </div>
    </div>
    <div slot="footer-right-section" class="level-item">
        <button
            class="button is-outlined"
            on:click={supplyAnswer(false)}
            data-cy="{dataCy}-no"
        >
            {i18n.t('App.Folder_Sharing.Remove_No_Btn')}
        </button>
        <button
            class="button is-danger"
            on:click={supplyAnswer(true)}
            data-cy="{dataCy}-yes"
        >
            {i18n.t('App.Folder_Sharing.Remove_Yes_Btn')}
        </button>
    </div>
    <div slot="trigger">
        <slot name="remove-folder-share-trigger" />
    </div>
</ModalComponent>
