<script>
    export let spinner = true;
    import Spinner from '../components/spinner.svelte';
    import { authenticated, checkingSignin } from '../providers/state/user';

    const dataCy = 'protected';
</script>

{#if $authenticated}
    <section class="section">
        <slot />
    </section>
{:else if $checkingSignin && spinner}
    <div class="has-centered-content has-all-space">
        <Spinner name={dataCy} size="is-large" />
    </div>
{/if}
