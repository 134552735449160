import config from 'app.config';
import AuthClient from '@adsk/auth-pkce-sdk';
import store from 'store2';
import {
    authStage,
    authStages,
    userSignedIn,
    userSignedOut,
} from './state/user';
import { getUser } from '../rest.sdks/request';
import { trackEvent, categories, actions } from './analytics/ga';
import { getPropValue } from '../rest.sdks';
import DriveError from '../rest.sdks/drive.error';

const { apps, keys, env } = config;
const clientId = keys.driveWeb;
const scope = 'data:read data:write data:search';
const maxRetries = 1;

let authClient, startTime, endTime;
const initializeAuthClient = async () => {
    if (!authClient) {
        authClient = await AuthClient({
            client_id: clientId,
            // TODO: Ideally it should be last visited URL
            redirect_uri: `${apps.driveUrl}/oauth/callback`, // OAuth2 callback URL
            redirect_uri_iframe: `${apps.driveUrl}/oauth.html`,
            env: env === 'production' ? 'prod' : 'stg',
            useRefreshTokens: true,
            scope,
        });
    }
};

const startTimeByCheckSession = () => {
    startTime = performance.now();
};

const endTimeByCheckSession = (loggedIn = true) => {
    // This also includes time taken by SDK to exchange code to get token (in case logged in)
    endTime = performance.now();
    const timeTaken = endTime - startTime;
    trackEvent({
        category: categories.infoDrive,
        action: actions.waiting,
        label: `Session check time when user is ${
            loggedIn ? 'logged' : 'not logged'
        } in.`,
        value: timeTaken,
    });
};

export const initAuthFlow = async (loginRequired, attempt = 0) => {
    try {
        startTimeByCheckSession();
        // Initialize Auth-PKCE-SDK
        await initializeAuthClient();

        // This initiates silent auth on SDK side
        await getTokenSilently();
        // Once we get the token, set as signedIn
        authStage.set(authStages.signedIn);

        endTimeByCheckSession();

        const userProfile = await getUser();
        userSignedIn(userProfile);
    } catch (error) {
        // If we get any error in SDK initialization retry once
        if (!authClient && attempt < maxRetries) {
            await initAuthFlow(loginRequired, attempt + 1);
        } else {
            const errorMessage = getPropValue(error, 'message') || error;
            // 'Login required' is a legit error
            if (!errorMessage.includes('Login required')) {
                const action = actions.oauth2InitializationFailed;
                // Log TIMEOUT_ERROR as warnings.
                // https://git.autodesk.com/devx/auth-pkce-sdk/blob/master/src/utils.ts#L54-L57
                if (errorMessage === 'TIMEOUT_ERROR') {
                    trackEvent({
                        category: categories.warnDrive,
                        action,
                        label: error,
                    });
                } else {
                    // This should also capture a StackTrace
                    DriveError.logError(action, error);
                }
            }
            endTimeByCheckSession(false);

            // Store return path, as now we navigate to Oxygen for login
            store.set('returnPath', loginRequired ? location.pathname : '');
            userSignedOut(loginRequired);
        }
    }
};

export const loginWithRedirect = async () => {
    if (authClient) {
        await authClient.loginWithRedirect();
    }
};

export const getTokenSilently = async (ignoreCache = false) => {
    const accessToken = await authClient.getTokenSilently({ ignoreCache });
    return accessToken;
};

export const logoutWithRedirect = async () => {
    if (authClient) {
        authClient.logout({
            post_logout_redirect_uri: apps.driveUrl, // Redirect back to base url after logout
        });
    }
};
