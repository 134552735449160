<script>
    export let height = 216;
    export let width = 328;
    export let viewBox = '0 0 328 216';
</script>

<svg {width} {height} {viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="328" height="216" fill="white" />
    <path
        opacity="0.3"
        d="M155.4 168.34L83.04 179L76.71 163.6L142.16 101.65L154.15 124.13L155.4 168.34V168.34Z"
        fill="#5A7D91"
    />
    <path
        opacity="0.3"
        d="M201.16 175.34L172.97 178.5L200.77 104.17L262.6 139.87L201.16 175.34V175.34Z"
        fill="#5A7D91"
    />
    <path
        d="M172.65 49.32L229.72 82.48V98.88L172.65 132V49.32V49.32Z"
        fill="#E7F0F5"
    />
    <path
        d="M115.2 82.27L172.65 49.32V132.25L115.2 98.88V82.27V82.27Z"
        fill="#CCD9E0"
    />
    <path
        d="M172.65 87.96L198.34 102.95L206.36 98.31L172.65 78.68L138.56 98.19L146.61 102.84L172.65 87.96V87.96Z"
        fill="white"
    />
    <path
        d="M156.92 108.07L172.65 99.13L188 108.13L172.65 117.07L156.92 108.07V108.07Z"
        fill="white"
    />
    <path
        d="M110.32 82.7599L114.67 83.7799L181.5 121.75V166L172.65 177.77L110.32 141.96V82.7599V82.7599Z"
        fill="#E7F0F5"
    />
    <path
        d="M154.15 124.13L142.16 101.65L110.32 131.78V141.96L155.38 167.85L154.15 124.13V124.13Z"
        fill="#CCD9E1"
    />
    <path
        d="M234.58 82.98L196.65 102.85L172.65 118.74V177.77L234.58 141.96V82.98V82.98Z"
        fill="#CCD9E1"
    />
    <path
        d="M172.65 118.74V177.77"
        stroke="#8CA9B8"
        stroke-width="0.66"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M177.96 81.81L172.65 78.68L138.56 98.19L144.08 101.37L177.96 81.81V81.81Z"
        fill="#CCD9E0"
    />
    <path
        d="M177.96 81.81L172.65 78.68V49.32L177.96 81.81V81.81Z"
        fill="#CCD9E0"
    />
    <path
        d="M174.55 100.31L172.65 99.1299L156.92 108.07L159.02 109.28L174.55 100.31V100.31Z"
        fill="#CCD9E0"
    />
    <path
        d="M174.55 100.31L172.65 99.13V87.96L174.55 100.31V100.31Z"
        fill="#CCD9E0"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M172.65 87.96L198.34 102.95L206.36 98.31L172.65 78.68L138.56 98.19L146.61 102.84L172.65 87.96V87.96Z"
        stroke="#8CA9B8"
        stroke-width="0.66"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M156.92 108.07L172.65 99.13L188 108.13L172.65 117.07L156.92 108.07V108.07Z"
        stroke="#8CA9B8"
        stroke-width="0.66"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M172.65 78.8V49.32"
        stroke="#8CA9B8"
        stroke-width="0.66"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M172.65 99.3V88.06"
        stroke="#8CA9B8"
        stroke-width="0.66"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M110.32 82.76L172.65 47L234.58 83L172.65 118.76L110.32 82.76ZM116.19 82.27L172.65 114.87L228.74 82.48L172.64 49.89L116.19 82.27Z"
        fill="white"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M172.64 114.85L116.22 82.27L172.64 49.9L228.71 82.48L172.64 114.85Z"
        stroke="#8CA9B8"
        stroke-width="0.66"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M110.32 82.76L172.65 118.76L234.58 83"
        stroke="#8CA9B8"
        stroke-width="0.66"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M172.02 45.92L172.65 45.56V45.54L173.27 45.9L235.21 81.9L235.83 82.26V142.64L235.21 143L173.25 178.86L172.63 179.22L172 178.86L109.7 143.04L109.07 142.68V82.03L109.7 81.67L172.02 45.92ZM234.58 83L172.65 47L110.32 82.76V142L172.65 177.81L234.58 142V83Z"
        fill="#5A7D91"
    />
    <path
        d="M91.29 176.42L144.87 106.03L131.74 93.48L81.21 156.88L79.25 171.02L91.29 176.42V176.42Z"
        fill="#CCD9E0"
    />
    <path
        d="M119.02 54.59L106.98 49.19L114.38 80.18L132.82 100.63L144.87 106.03L119.02 54.59V54.59Z"
        fill="white"
    />
    <path
        d="M53.3999 119.58L106.98 49.19L132.82 100.63L79.2499 171.02L53.3999 119.58V119.58Z"
        fill="white"
    />
    <path
        d="M106.98 49.19L132.82 100.63L79.25 171.02"
        stroke="#8CA9B8"
        stroke-width="0.66"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M132.82 100.63L144.87 106.03"
        stroke="#8CA9B8"
        stroke-width="0.66"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M106 48.43L106.6 47.64L106.62 47.62L107.53 48.03L119.53 53.42L119.94 53.61L120.14 54L145.95 105.47L146.3 106.16L145.82 106.78L92.2499 177.17L91.6499 177.96L90.7399 177.56L78.7399 172.16L78.3299 171.98L78.1299 171.58L52.2799 120.14L51.9299 119.44L52.4099 118.82L106 48.43ZM119 54.59L107 49.19L53.3999 119.58L79.2899 171.02L91.2899 176.42L144.87 106L119 54.59Z"
        fill="#5A7D91"
    />
    <path
        d="M102.92 113L108.67 105.56L91.19 97.34C90.3331 96.9384 89.3135 97.1595 88.7 97.88L82.7 104.94L83.64 104.62C84.1198 104.447 84.6487 104.472 85.11 104.69L102.92 113Z"
        fill="#5A7D91"
    />
    <path
        d="M97.43 112L91.68 128.27C91.2032 129.72 89.7141 130.58 88.22 130.27L84.22 129.34L84.85 129.02C85.2524 128.807 85.596 128.498 85.85 128.12L97.43 112Z"
        fill="#5A7D91"
    />
    <path
        d="M88.6899 122.08C88.6976 122.355 88.6092 122.623 88.4399 122.84L84.5399 127.9C84.3446 128.155 84.0115 128.262 83.7042 128.169C83.3969 128.075 83.1799 127.801 83.1599 127.48L82.3899 107.41C82.3686 106.865 82.6312 106.349 83.0837 106.045C83.5363 105.741 84.114 105.694 84.6099 105.92L88.1899 107.54L88.6899 122.08Z"
        fill="#5A7D91"
    />
</svg>
