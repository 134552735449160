import config from 'app.config';
import urlJoin from 'proper-url-join';
import { arrayBufferToBase64, CONNECT_2LO } from './utils';
import DriveError from './drive.error';
import fetchRetryFcn from 'fetch-retry';
import { actions, categories, trackEvent } from '../providers/analytics/ga';
import { randomHex } from '../providers/utils';
import memoizer from './memoizer';
import { getPropValue } from '.';
import { userSignedOut, signedOut, profile } from '../providers/state/user';
import { getTokenSilently } from '../providers/pkce-auth';
import store from 'store2';
import { get } from 'svelte/store';

const fetchRetry = fetchRetryFcn(fetch);

const { servers, keys } = config;
const apigeeDrive = servers.drive.endsWith('api.autodesk.com/driveapi');

const methods = memoizer.newGroup(CONNECT_2LO);
const oneMinuteCache = { maxAge: 60 * 1000 };
const oneHourCache = { maxAge: 60 * 60 * 1000 };

let retryables = {};

const request = async (
    endpoint,
    path,
    init = {},
    responseType = 'json',
    skipResponse,
    bearerTokenFcn,
    retryAs // If provided, retry upon and log as such
) => {
    const url = path ? urlJoin(endpoint, path) : endpoint;

    if (!init.headers) {
        init.headers = {};
    }

    if (responseType === 'json') {
        init.headers['Accept'] = 'application/json';
        init.headers['Content-Type'] = 'application/json';
    }

    if (bearerTokenFcn) {
        const token = await bearerTokenFcn();
        init.headers['authorization'] = `Bearer ${token}`;
    }

    // Until we can run an Apigee Proxy Locally...
    if (!apigeeDrive && /driveapi/.test(url)) {
        const client_id = keys.driveWeb;
        const xAdsTokenData = { client_id };
        if (bearerTokenFcn === getOAuth2TwoLeggedToken) {
            // If present, DriveMS thinks this is a ServiceToken:
            //  src/main/java/com/autodesk/forge/drive/model/TokenData.java
            xAdsTokenData.access_token = { client_id };
        } else if (bearerTokenFcn === getOAuth2ThreeLeggedToken) {
            const user = get(profile);
            xAdsTokenData.access_token = {
                userid: user.userId,
                oxygenid: user.userId,
                username: user.userName,
                email: user.emailId,
                firstname: user.firstName,
                lastname: user.lastName,
            };
        }
        init.headers['x-ads-token-data'] = JSON.stringify(xAdsTokenData);
    }

    // Judiciously leverage retries - for now, only for a couple of endpoints
    const request = new Request(url, init);
    let response;
    if (retryAs) {
        retryables[retryAs] = (retryables[retryAs] || 0) + 1;
        const retryInfo = { id: randomHex() }; // Correlate log entries
        const retryCount = 1; // Retry once
        let attempts = []; // {error || response.status}
        let retrySuccess = false;
        try {
            // Called upon success or failure, truthy retries
            //  error: fetch threw
            //  response: fetch completed
            const retryOn = async (attempt, error, response) => {
                const resolution = error
                    ? { error: error.toString() }
                    : { response: { status: response.status } };
                resolution.onLine = navigator.onLine;
                attempts[attempt] = resolution;
                // Failed request?
                if (error || (response && response.status >= 500)) {
                    return attempt < retryCount; // 'true' retries
                }
            };

            response = await fetchRetry(request, { retryOn });
            if (response.ok) {
                retrySuccess = true;
            }
        } catch (fetchError) {
            // Exhausted our retries...
            throw new DriveError({ request, fetchError, retryInfo });
        } finally {
            // Log retry success/failure
            if (attempts.length > 1) {
                trackEvent({
                    category: categories.warnDrive,
                    action: actions.fetchRetry,
                    label: { api: retryAs, attempts, retrySuccess },
                    detail: retryInfo,
                });
            }
            // Log total # of retryables - so we know percent failure
            if (retryables[retryAs] >= 10) {
                trackEvent({
                    category: categories.warnDrive,
                    action: actions.fetchRetry,
                    label: retryAs,
                    value: retryables[retryAs], // Avoid race conditions
                });
                retryables[retryAs] = 0;
            }
            if (response && !response.ok) {
                const driveError = new DriveError({
                    request,
                    response,
                    retryInfo,
                });
                await driveError.getBody();
                // eslint-disable-next-line no-unsafe-finally
                throw driveError;
            }
        }
    } else {
        try {
            response = await fetch(request);
        } catch (fetchError) {
            throw new DriveError({ request, fetchError });
        }
    }

    if (!response.ok) {
        const driveError = new DriveError({ request, response });
        await driveError.getBody();
        throw driveError;
    }

    if (!skipResponse) {
        try {
            const body = await response[responseType]();
            return body;
        } catch (bodyError) {
            const driveError = new DriveError({ request, response, bodyError });
            throw driveError;
        }
    } else {
        return response;
    }
};

const getOAuth2TwoLeggedToken = methods.memoize(async () => {
    // Connect_2LO_Bearer_Token
    const body = await request(
        servers.connect,
        'Authentication/GetOAuth2TwoLeggedToken',
        undefined,
        'json',
        false, // skipResponse
        null, // bearerTokenFcn
        'GetOAuth2TwoLeggedToken' // retryAs
    );
    return body.access_token;
}, oneMinuteCache);

export const getDriveMs2LeggedToken = async (
    collectionId,
    shareId,
    password
) => {
    // DMS expects `x-ads-password` if public share is protected
    const options = password ? { headers: { 'x-ads-password': password } } : {};
    // we can safely ignore adding this 2L token once token requirement is removed from getPublicShareAccessToken endpoint
    // Drive_Get_Public_Share_Access_Token
    const body = await drive(
        `collections/${collectionId}/shares/public/${shareId}/access-token`,
        options,
        'json',
        false, // skipResponse
        true // twoLegged
    );
    return body.data.attributes;
};

let expiration = 0; // Beginning of time
let oAuth2ThreeLeggedToken = '';
let tokenRequest; // Only allow one token request at a time
export const getOAuth2ThreeLeggedToken = async () => {
    const clear = () => {
        expiration = 0;
        oAuth2ThreeLeggedToken = '';
    };
    const tokenRequestFcn = async () => {
        // Provide 2 min buffer for time sync issues
        if (Date.now() > expiration - 2 * 60 * 1000) {
            // Authentication_3LO_Bearer_Token
            oAuth2ThreeLeggedToken = await getTokenSilently(!!expiration);

            // Example data payload:
            //   {
            //     "scope": [
            //       "data:read",
            //       "data:write",
            //       "data:search"
            //     ],
            //     "client_id": "1234567890abcdefghij1234567890ab",
            //     "aud": "https://autodesk.com/aud/abcdefg12",
            //     "jti": "1234567890abcdefghij1234567890ab1234567890abcdefghij1234567890ab",
            //     "userid": "1234567890AB",
            //     "exp": 1637871462
            //   }
            const dataPayload = JSON.parse(
                atob(oAuth2ThreeLeggedToken.split('.')[1])
            );
            expiration = dataPayload.exp * 1000; // seconds to milliseconds

            signedOut.subscribe((value) => {
                if (value) {
                    clear();
                }
            });
        }
    };

    try {
        if (!tokenRequest) {
            // auth.session is set by PKCE SDK
            const isAuthenticated = !!store.get('auth.session');
            // Maybe user logged out from another tab
            if (!isAuthenticated) {
                clear();
            }
            tokenRequest = tokenRequestFcn();
        }
        await tokenRequest; // Establish expiration / oAuth2ThreeLeggedToken
        tokenRequest = null;
        return oAuth2ThreeLeggedToken;
    } catch (error) {
        // If we get any error while fetching the token, redirect user to Login
        // The error can be from SDK or Authorization Server
        userSignedOut(true);
    }
};

export const getViewerSupportedFiles = methods.memoize(async () => {
    const body = await request(
        servers.connect,
        'Viewer/GetConfiguration',
        undefined,
        'json',
        false, // skipResponse
        null, // bearerTokenFcn
        false // retryAs
    );

    // Apply manual filtering mimicking the Viewer (minus .rcp) - see:
    // https://git.autodesk.com/A360/Viewer/blob/main/src/app/channels/api/nitrous.channel.ts#L93
    // TODO: Remove the manual filtering once FORCE-3213 is fixed
    const manualFilteredList = [
        'zip',
        'pdf',
        'asm\\.\\d+$',
        'neu\\.\\d+$',
        'prt\\.\\d+$',
    ];

    const supportedFiles = getPropValue(body, 'formats.svf').filter(
        (fileType) => {
            return !manualFilteredList.includes(fileType);
        }
    );

    return supportedFiles;
}, oneHourCache);

// Get_User_Info
export const getUser = async () => {
    const path = 'users/@me';
    const userInfo = await user(path);
    return userInfo;
};

const _downloadBits = async (url, base64Flag) => {
    const buffer = await request(
        url,
        undefined,
        undefined,
        'arrayBuffer',
        false, // skipResponse
        getOAuth2ThreeLeggedToken
    );
    const base64bits = arrayBufferToBase64(buffer);
    return base64Flag + base64bits;
};

export const downloadImage = async (url, imageType = 'data:image/png') => {
    return _downloadBits(url, `${imageType};base64,`);
};

// Not currently used:
// export const downloadPDF = async (url) => {
//     return _downloadBits(url, 'data:application/pdf;base64,');
// };

export const fusionUrl = servers.fusion;

const _drive = async (path, options, responseType, skipResponse, twoLegged) => {
    // Convert twoLegged to bearerTokenFcn - we'll always have one for these endpoints
    const bearerTokenFcn = twoLegged
        ? getOAuth2TwoLeggedToken
        : getOAuth2ThreeLeggedToken;
    return request(
        servers.drive,
        path,
        options,
        responseType,
        skipResponse,
        bearerTokenFcn
    );
};

export const drive = async (path = '', ...rest) => {
    return _drive(`v1/${path}`, ...rest);
};

export const drive2 = async (path = '', ...rest) => {
    return _drive(`v2/${path}`, ...rest);
};

export const user = async (path, options, responseType, skipResponse) => {
    return request(
        servers.userProfile,
        path,
        options,
        responseType,
        skipResponse,
        getOAuth2ThreeLeggedToken
    );
};

export const fusion = async (path, options, responseType, skipResponse) => {
    return request(
        servers.fusion,
        path,
        options,
        responseType,
        skipResponse,
        getOAuth2ThreeLeggedToken
    );
};

export const s3Upload = async (path, options, responseType, skipResponse) => {
    return request(
        '', // Signed URL contains server as well
        path,
        options,
        responseType,
        skipResponse
    );
};

export const forgedm = async (path, options, responseType, skipResponse) => {
    return request(
        servers.forgedm,
        path,
        options,
        responseType,
        skipResponse,
        getOAuth2ThreeLeggedToken
    );
};

export const identity = async (path, options, responseType, skipResponse) => {
    return request(
        servers.identity,
        path,
        options,
        responseType,
        skipResponse,
        getOAuth2ThreeLeggedToken
    );
};
