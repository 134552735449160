<script>
    import { onMount } from 'svelte';
    import { i18n } from '../../i18n';
    import { uploadStore } from '../../providers/state/upload.store';
    import { notificationsStore } from '../elements/notifications.store.js';
    import { groupFiles, addUploadFailedNotification } from './upload.utils';
    import UploadItem from './upload.item.svelte';
    import UploadsView from './upload.info.dialog.svelte';
    import { isMobile } from '../../providers/device';

    let uploads = [],
        uploadsTree,
        uploadItemMetadata,
        statusText;
    let totalUploadSize = 0, // High water size of uploads
        totalCount = 0; // High water count of uploads
    let uploadTimeout = null;
    let showDismiss = false;
    let notification = null;

    const resetStatusText = () => {
        statusText = i18n.t('App.Upload_Actions.Upload_In_Progress');
    };

    const assignUploadTimeout = () => {
        uploadTimeout = setTimeout(() => {
            dismissUploads();
        }, 8000); // Auto dismiss successful uploads after 8 seconds
    };

    const clearUploadTimeout = () => {
        window.clearTimeout(uploadTimeout);
        uploadTimeout = null;
    };

    const dismissFailedNotification = () => {
        if (notification) {
            notificationsStore.close(notification);
        }
    };

    // Clear our timer, fetch uploads info, set another timer
    const assignGetUploads = () => {
        uploadsTree = groupFiles(uploads, 'path');
        let metadata = {
            size: 0,
            failedCount: 0,
            pausedCount: 0,
            inprogressCount: 0,
            progress: 0,
            actionsNotAllowed: true,
        };
        let remainingUploadSize = 0;
        uploads.forEach((upload) => {
            let uploadInfo = upload.info();
            if (!Array.isArray(uploadInfo)) {
                uploadInfo = [uploadInfo];
            }
            uploadInfo.forEach((item) => {
                metadata.size += item.size;
                remainingUploadSize +=
                    item.size - item.size * (item.progress / 100);
                if (item.paused) {
                    metadata.pausedCount += 1;
                }
                if (item.failed) {
                    metadata.failedCount += 1;
                    notification = addUploadFailedNotification(
                        uploads.length,
                        $uploadStore.failed,
                        showUploadTree,
                        item.file.errorType
                    );
                }
            });
        });
        if ($uploadStore.processed === uploads.length) {
            if (metadata.failedCount > 0) {
                statusText = i18n.t('App.Upload_Actions.Upload_Incomplete');
                remainingUploadSize = 0;
            } else {
                statusText = i18n.t('App.Upload_Actions.Upload_Complete');
                // check for uploadTimeout to avoid multiple timeouts
                if (!uploadTimeout) {
                    assignUploadTimeout();
                }
            }
            if (uploads.length > 0) {
                showDismiss = true;
            }
        }
        if (uploads.length > 0) {
            // See if we have new high-water marks...
            totalUploadSize = Math.max(totalUploadSize, metadata.size);
            totalCount = Math.max(totalCount, uploads.length);

            metadata.inprogressCount = uploads.length;

            const percentRemaining =
                totalUploadSize === 0
                    ? 0
                    : remainingUploadSize / totalUploadSize;

            metadata.progress = Math.floor((1 - percentRemaining) * 100);
        } else {
            totalUploadSize = totalCount = 0; // reset
            dismissFailedNotification();
        }

        uploadItemMetadata = metadata;
    };

    $: if ($uploadStore.uploads) {
        uploads = $uploadStore.uploads;
        // If new uploads get added to a successful batch that has not dismissed yet, clear existing timeout
        if (uploads.length > 0 && uploadTimeout) {
            clearUploadTimeout();
            resetStatusText();
        }
        assignGetUploads();
    }

    let showTree = false;
    const showUploadTree = () => {
        showTree = true;
    };

    const onCancel = () => {
        showTree = false;
    };

    onMount(() => {
        resetStatusText();
    });

    const dismissUploads = (event) => {
        uploads = [];
        uploadStore.reset();
        showDismiss = false;
        showTree = false;
        clearUploadTimeout();
        resetStatusText();
        if (event) {
            event.stopPropagation(); // do not bubble up to showUploadTree
        }
        dismissFailedNotification();
    };
</script>

{#if uploads.length > 0 && !showTree}
    <div
        data-cy="upload-tree-view"
        class="upload-info-container has-ellipsis has-pointer-cursor is-horizontal-center"
        class:mobile={$isMobile}
        class:not-mobile={!$isMobile}
        on:click={showUploadTree}
    >
        <UploadItem
            {statusText}
            uploadsMetadata={uploadItemMetadata}
            group={true}
            {showDismiss}
            {dismissUploads}
        />
    </div>
{/if}

{#if showTree}
    <UploadsView uploadObject={uploadsTree} {uploads} {onCancel} />
{/if}

<style>
    /* bulma footer class does not fix the mobile scroll issue */
    .upload-info-container {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .not-mobile {
        /* 300px fits the width of the folder container */
        max-width: calc(100% - 300px);
    }
    .mobile {
        /* 85px fits the width to the center of the mobile screens */
        max-width: calc(100% - 85px);
    }
</style>
