<script>
    export let fill;
    export let height = 24;
    export let width = 24;

    $: if (!fill) {
        fill = '#666666';
    }
</script>

<svg
    {width}
    {height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0L0 10L10 20L20 10L10 0ZM1.41 10L10 1.41L18.59 10L10 18.59L1.41 10ZM9 13C9 13.5523 9.44771 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12C9.44771 12 9 12.4477 9 13ZM10.4859 10.8536C10.3862 10.9459 10.2559 10.9981 10.12 11H9.88C9.74412 10.9981 9.61376 10.9459 9.51405 10.8536C9.41434 10.7613 9.35234 10.6353 9.34 10.5L9 6C9 5.73478 9.10536 5.48043 9.29289 5.29289C9.48043 5.10536 9.73478 5 10 5C10.2652 5 10.5196 5.10536 10.7071 5.29289C10.8946 5.48043 11 5.73478 11 6L10.66 10.5C10.6477 10.6353 10.5857 10.7613 10.4859 10.8536Z"
        {fill}
    />
</svg>
