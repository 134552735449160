<script>
    import { i18n } from '../i18n';

    let title = i18n.t('App.Maintenance_Page.Title');
    let duration = i18n.t('App.Maintenance_Page.Duration');
    let thanks = i18n.t('App.Maintenance_Page.Thanks');
    let link = i18n.t('App.Maintenance_Page.Link');

    const handleClick = () => {
        window.location.href = 'https://autodesk.com';
    };
</script>

<div class="maintenance container has-text-centered">
    <div class="column is-vcentered">
        <div id="maintenance_title" class="subtitle is-size-2-mobile is-2">
            {title}
        </div>
        <div class="maintenance-svg-image">
            <svg
                width="260"
                height="138"
                viewBox="0 0 260 138"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M175.942 84.22C145.742 101.52 96.6418 101.43 66.2618 84.0201C51.0018 75.2801 43.4018 63.83 43.3718 52.41C43.3718 49.41 43.3718 49.1001 43.3718 49.1001C43.3718 49.1001 59.5318 24.8401 65.8718 21.1901C96.0718 3.89005 145.172 3.98005 175.552 21.3801C181.242 24.6401 198.442 49.1001 198.442 49.1001V53.0101C198.482 64.3201 190.962 75.61 175.942 84.22Z"
                    fill="#E7F0F4"
                />
                <path
                    d="M193.122 47.02C192.372 60.52 175.622 88.27 124.872 90.27V97.09C143.462 96.62 161.782 92.33 175.942 84.22C190.942 75.61 198.482 64.32 198.482 53.01V49.1C196.761 48.2484 194.967 47.5523 193.122 47.02V47.02Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M66.2618 80.82C96.6418 98.23 145.742 98.31 175.942 81.02C176.942 80.46 177.842 79.9 178.742 79.32C177.222 72.14 171.632 65.18 161.992 59.65C139.172 46.58 102.282 46.51 79.6118 59.51C70.1218 64.94 64.6118 71.8 63.1118 78.9C64.1251 79.5533 65.1752 80.1933 66.2618 80.82Z"
                    fill="#6F8FA1"
                />
                <path
                    d="M176.992 74.3C174.362 68.94 169.362 63.88 161.992 59.65C139.172 46.58 102.282 46.51 79.6119 59.51C72.8719 63.38 68.1119 67.97 65.4019 72.86"
                    stroke="#8CA9B8"
                    stroke-width="0.66"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M65.7919 78.5601L70.1519 74.3801C58.0519 61.7701 61.2619 45.6401 79.8719 35.0001C102.432 22.0801 139.122 22.1501 161.872 35.1501C180.502 45.8201 183.872 61.8901 172.072 74.4601L177.312 77.8901C192.312 63.5201 188.882 44.5701 167.162 32.1201C141.522 17.4301 100.082 17.3601 74.6019 31.9601C52.4819 44.5801 49.5919 64.0301 65.7919 78.5601Z"
                    fill="white"
                />
                <path
                    d="M65.7919 78.5601L70.1519 74.3801C58.0519 61.7701 61.2619 45.6401 79.8719 35.0001C102.432 22.0801 139.122 22.1501 161.872 35.1501C180.502 45.8201 183.872 61.8901 172.072 74.4601L177.312 77.8901C192.312 63.5201 188.882 44.5701 167.162 32.1201C141.522 17.4301 100.082 17.3601 74.6019 31.9601C52.4819 44.5801 49.5919 64.0301 65.7919 78.5601Z"
                    stroke="#8CA9B8"
                    stroke-width="0.66"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M175.592 18.1801C145.212 0.770096 96.1117 0.690094 65.8717 17.9801C35.6317 35.2701 35.8717 63.4101 66.2217 80.8201C96.5717 98.2301 145.702 98.3101 175.902 81.0201C206.102 63.7301 205.972 35.5901 175.592 18.1801ZM167.592 76.2301C141.982 90.9101 100.322 90.8301 74.5917 76.0701C48.8617 61.3101 48.6517 37.4401 74.2617 22.7701C99.8717 8.1001 141.532 8.1701 167.262 22.9301C192.992 37.6901 193.202 61.5601 167.592 76.2301Z"
                    fill="white"
                />
                <path
                    d="M120.442 5.07003C140.372 5.07003 160.342 9.44003 175.592 18.18C205.972 35.59 206.132 63.72 175.942 81.02C160.942 89.63 141.182 93.93 121.412 93.93C101.482 93.93 81.5119 89.56 66.2619 80.82C35.8719 63.41 35.7219 35.28 65.8719 17.98C80.8719 9.37003 100.632 5.07003 120.402 5.07003H120.442ZM121.302 87.19C138.062 87.19 154.802 83.54 167.552 76.19C193.162 61.52 193.032 37.66 167.262 22.89C154.362 15.52 137.422 11.77 120.512 11.77C103.752 11.77 87.0119 15.42 74.2619 22.77C48.6519 37.44 48.7819 61.3 74.5519 76.07C87.4919 83.48 104.432 87.19 121.342 87.19H121.302ZM120.442 4.41003C99.7019 4.41003 80.2219 9.03003 65.5919 17.41C50.9619 25.79 42.7119 37.16 42.7119 49.22C42.7119 61.28 50.9619 72.77 65.9319 81.39C80.9019 90.01 100.492 94.59 121.412 94.59C142.152 94.59 161.632 89.97 176.262 81.59C190.892 73.21 199.142 61.84 199.142 49.77C199.142 37.7 190.872 26.19 175.922 17.61C160.972 9.03003 141.362 4.41003 120.442 4.41003V4.41003ZM121.342 86.53C103.802 86.53 87.3419 82.61 74.8819 75.53C62.4219 68.45 55.8019 59.13 55.8019 49.26C55.8019 39.39 62.4719 30.26 74.5919 23.34C86.7119 16.42 103.132 12.47 120.512 12.47C138.052 12.47 154.512 16.39 166.972 23.47C179.432 30.55 186.052 39.87 186.052 49.77C186.052 59.67 179.382 68.77 167.262 75.69C155.142 82.61 138.722 86.56 121.342 86.56V86.53Z"
                    fill="#8CA9B8"
                />
                <path
                    d="M120.442 5.07006C140.372 5.07006 160.342 9.44006 175.592 18.1801C190.542 26.7401 198.162 37.9001 198.462 49.0801C198.462 49.3101 198.462 50.3301 198.462 53.0101C198.462 64.3201 190.942 75.6101 175.922 84.2201C160.902 92.8301 141.162 97.1301 121.392 97.1301C101.462 97.1301 81.4918 92.7601 66.2418 84.0201C50.9918 75.2801 43.4018 63.8301 43.3718 52.4101C43.3718 49.4101 43.3718 49.1001 43.3718 49.1001C43.4318 37.7701 50.9318 26.5701 65.8718 17.9801C80.8118 9.39006 100.672 5.07006 120.442 5.07006ZM120.442 3.82006C99.6018 3.82006 80.0218 8.46006 65.2918 16.9001C50.5618 25.3401 42.2918 36.7301 42.1318 48.9001V49.0901C42.1318 49.0901 42.1318 49.4801 42.1318 52.4201C42.1318 64.8301 50.5118 76.4201 65.6518 85.1201C80.5918 93.6801 100.402 98.3901 121.422 98.3901C142.262 98.3901 161.842 93.7501 176.572 85.3101C191.512 76.7501 199.742 65.3101 199.742 53.0201C199.742 50.9401 199.742 49.8501 199.742 49.3601V49.0901C199.412 36.9501 191.072 25.6001 176.242 17.0901C161.412 8.58006 141.492 3.81006 120.472 3.81006L120.442 3.82006Z"
                    fill="#5A7D91"
                />
                <path
                    opacity="0.3"
                    d="M87.872 82.1499C87.872 82.1499 81.492 84.0199 70.872 86.7699C59.0892 86.6692 47.4167 84.4891 36.392 80.3299C35.392 78.1899 34.252 75.6899 34.252 75.6899L87.872 79.0199V82.1499Z"
                    fill="#5A7D91"
                />
                <path
                    d="M118.122 73.98C93.0118 88.48 52.1718 88.41 26.8718 73.82C14.1618 66.48 7.87183 56.82 7.87183 47.27V39.77H136.872V47.77C136.872 57.27 130.642 66.77 118.122 73.98Z"
                    fill="#E7F0F4"
                />
                <path
                    d="M75.8718 84.77C91.2618 84.35 106.402 80.77 118.122 73.99C130.642 66.77 136.872 57.27 136.872 47.77V39.77H75.8718V84.77Z"
                    fill="#CBD9DF"
                />
                <path
                    d="M136.87 39.355C136.981 18.7882 108.194 1.95929 72.5719 1.76656C36.9501 1.57382 7.98269 18.0902 7.87141 38.657C7.76013 59.2238 36.5471 76.0527 72.169 76.2455C107.791 76.4382 136.758 59.9218 136.87 39.355Z"
                    fill="white"
                />
                <path
                    d="M71.9719 1.76999C88.5419 1.76999 105.152 5.43999 117.872 12.77C143.132 27.36 143.262 50.94 118.152 65.43C105.652 72.65 89.2419 76.26 72.8119 76.26C56.3819 76.26 39.5919 72.58 26.8719 65.26C1.64191 50.67 1.51191 27.09 26.6219 12.59C39.1219 5.37998 55.5319 1.76999 71.9719 1.76999ZM71.9719 1.10999C54.7019 1.10999 38.4819 4.98998 26.2919 12.02C14.1019 19.05 7.21191 28.63 7.21191 38.77C7.21191 48.91 14.0919 58.62 26.5719 65.83C39.0519 73.04 55.3619 76.91 72.7819 76.91C90.0419 76.91 106.262 73.03 118.452 66C130.642 58.97 137.532 49.39 137.532 39.24C137.532 29.09 130.652 19.4 118.172 12.24C105.692 5.07998 89.3919 1.10999 71.9719 1.10999Z"
                    fill="#8CA9B8"
                />
                <path
                    d="M71.9717 1.76998C88.5417 1.76998 105.152 5.43998 117.872 12.77C130.782 20.23 137.132 30.04 136.872 39.77C136.872 39.77 136.872 45.27 136.872 47.77C136.872 57.27 130.642 66.77 118.122 73.98C105.602 81.19 89.2217 84.77 72.7817 84.77C56.3417 84.77 39.5917 81.14 26.8717 73.82C14.1517 66.5 7.87172 56.82 7.87172 47.27C7.87172 44.77 7.87172 39.77 7.87172 39.77C7.42172 29.95 13.6617 20.07 26.6017 12.59C39.1217 5.37998 55.5217 1.76998 71.9717 1.76998ZM71.9717 0.51998C54.6017 0.51998 38.2717 4.41998 25.9717 11.52C13.0317 18.99 6.16172 29.04 6.64172 39.77C6.64172 40.19 6.64172 44.85 6.64172 47.24C6.64172 57.74 13.6417 67.55 26.3017 74.87C38.7617 82.07 55.3017 86.03 72.8017 86.03C90.1617 86.03 106.492 82.13 118.802 75.03C131.292 67.81 138.172 58.12 138.172 47.73C138.172 45.23 138.172 39.73 138.172 39.73C138.432 29.09 131.452 19.1 118.522 11.64C106.062 4.43998 89.5217 0.47998 72.0317 0.47998L71.9717 0.51998Z"
                    fill="#5A7D91"
                />
                <path
                    d="M92.432 15.77L90.842 14.85L85.002 18.23L86.582 19.14L92.432 15.77Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M88.1319 13.28L86.5419 12.37L80.7019 15.74L82.2819 16.66L88.1319 13.28Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M74.572 10.39L73.002 11.3L78.892 14.7L80.462 13.79L74.572 10.39Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M111.192 22.7001L109.622 23.6101L103.732 20.2101L105.312 19.3101L111.192 22.7001Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M107.822 24.65L106.232 23.74L100.392 27.11L101.972 28.03L107.822 24.65Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M103.522 22.17L101.932 21.25L96.0918 24.63L97.6718 25.54L103.522 22.17Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M94.2419 16.8101L92.6719 17.7201L98.5519 21.1201L100.122 20.2101L94.2419 16.8101Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M89.9718 19.28L88.3918 20.19L94.2819 23.58L95.8519 22.68L89.9718 19.28Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M118.912 31.05L117.332 30.14L111.482 33.52L113.062 34.43L118.912 31.05Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M109.632 25.7L108.062 26.61L113.942 30L115.512 29.09L109.632 25.7Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M105.362 28.16L103.782 29.07L109.672 32.47L111.242 31.56L105.362 28.16Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M65.1219 13.76L63.5419 14.67L57.6619 11.27L59.2319 10.36L65.1219 13.76Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M61.7418 15.71L60.1618 14.79L54.3118 18.17L55.8918 19.09L61.7418 15.71Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M57.4417 13.2301L55.8617 12.3101L50.0117 15.6901L51.5917 16.6001L57.4417 13.2301Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M71.2018 12.3401L72.7918 13.2501L66.9418 16.6301L65.3518 15.7201L71.2018 12.3401Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M75.5019 14.8201L77.0919 15.7401L71.2419 19.1101L69.6519 18.2001L75.5019 14.8201Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M84.7818 20.18L83.2118 21.09L77.3218 17.69L78.9018 16.78L84.7818 20.18Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M80.5118 22.65L78.9318 23.56L73.0518 20.16L74.6218 19.25L80.5118 22.65Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M77.1319 24.5901L75.5519 23.6801L69.7019 27.0601L71.2919 27.9701L77.1319 24.5901Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M72.8319 22.11L71.2519 21.2L65.4019 24.57L66.9819 25.49L72.8319 22.11Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M63.5519 16.75L61.9819 17.66L67.8619 21.06L69.4419 20.15L63.5519 16.75Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M59.2819 19.22L57.7119 20.13L63.5919 23.53L65.1619 22.62L59.2819 19.22Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M86.5917 21.22L88.1817 22.14L82.3317 25.52L80.7417 24.6L86.5917 21.22Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M90.8917 23.71L92.4818 24.62L86.6318 28L85.0417 27.08L90.8917 23.71Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M100.172 29.07L98.6019 29.97L92.7119 26.58L94.2919 25.67L100.172 29.07Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M95.9019 31.53L94.3219 32.44L88.4419 29.04L90.0119 28.14L95.9019 31.53Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M92.5318 33.4801L90.9418 32.5701L85.0918 35.9401L86.6818 36.8601L92.5318 33.4801Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M88.2217 31.0001L86.6417 30.0801L80.7917 33.4601L82.3818 34.3701L88.2217 31.0001Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M78.9418 25.64L77.3718 26.55L83.2518 29.95L84.8318 29.04L78.9418 25.64Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M74.6718 28.11L73.1018 29.02L78.9818 32.42L80.5618 31.51L74.6718 28.11Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M101.982 30.11L103.572 31.03L97.7218 34.4L96.1418 33.49L101.982 30.11Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M106.282 32.5901L107.872 33.5101L102.022 36.8901L100.432 35.9701L106.282 32.5901Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M115.562 37.9501L113.992 38.8601L108.102 35.46L109.682 34.55L115.562 37.9501Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M111.292 40.42L109.712 41.33L103.832 37.93L105.402 37.02L111.292 40.42Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M107.922 42.3699L106.332 41.45L100.482 44.83L102.072 45.74L107.922 42.3699Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M103.622 39.89L102.032 38.97L96.1819 42.35L97.7719 43.26L103.622 39.89Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M94.3417 34.53L92.7617 35.44L98.6517 38.83L100.222 37.93L94.3417 34.53Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M90.0617 36.99L88.4917 37.9L94.3717 41.3L95.9517 40.39L90.0617 36.99Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M117.372 39L118.962 39.91L113.112 43.29L111.532 42.37L117.372 39Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M109.732 43.41L108.152 44.32L114.042 47.72L115.612 46.81L109.732 43.41Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M105.452 45.88L103.882 46.79L109.762 50.19L111.342 49.28L105.452 45.88Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M54.0918 20.12L52.5218 21.03L46.6418 17.64L48.2118 16.73L54.0918 20.12Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M49.8218 22.5901L48.2518 23.5001L42.3618 20.1001L43.9418 19.1901L49.8218 22.5901Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M46.4517 24.54L44.8617 23.63L39.0117 27L40.6017 27.92L46.4517 24.54Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M42.1519 22.06L40.5619 21.14L34.7119 24.52L36.3019 25.43L42.1519 22.06Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M55.9018 21.17L57.4918 22.09L51.6418 25.46L50.0618 24.55L55.9018 21.17Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M60.2118 23.65L61.7918 24.57L55.9418 27.94L54.3618 27.03L60.2118 23.65Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M69.4917 29.01L67.9117 29.92L62.0317 26.52L63.6017 25.61L69.4917 29.01Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M65.2119 31.4801L63.6419 32.3901L57.752 28.9901L59.332 28.0801L65.2119 31.4801Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M61.8419 33.43L60.2519 32.51L54.4119 35.89L55.9919 36.8L61.8419 33.43Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M57.5418 30.94L55.9518 30.03L50.1018 33.4L51.6918 34.32L57.5418 30.94Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M48.2619 25.5901L46.6819 26.4901L52.5719 29.8901L54.1419 28.9801L48.2619 25.5901Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M43.9819 28.05L42.4119 28.96L48.2919 32.3601L49.8719 31.4501L43.9819 28.05Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M71.3019 30.0601L72.8819 30.9701L67.0319 34.3501L65.4519 33.4301L71.3019 30.0601Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M75.602 32.54L77.182 33.45L71.332 36.83L69.752 35.92L75.602 32.54Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M84.8819 37.9L83.3019 38.81L77.4219 35.41L78.9919 34.5L84.8819 37.9Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M80.6019 40.36L79.0319 41.27L73.1418 37.88L74.7218 36.97L80.6019 40.36Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M77.2318 42.31L75.6518 41.4L69.8018 44.77L71.3818 45.69L77.2318 42.31Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M72.932 39.83L71.342 38.92L65.502 42.29L67.082 43.21L72.932 39.83Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M63.6518 34.47L62.0718 35.38L67.9618 38.78L69.5318 37.87L63.6518 34.47Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M59.3718 36.9399L57.8018 37.8499L63.6918 41.2499L65.2618 40.3399L59.3718 36.9399Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M86.6918 38.9399L88.2718 39.8599L82.4218 43.2299L80.8418 42.3199L86.6918 38.9399Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M90.9919 41.4301L92.5718 42.3401L86.7218 45.7201L85.1418 44.8001L90.9919 41.4301Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M100.272 46.78L98.6918 47.69L92.8118 44.29L94.3818 43.39L100.272 46.78Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M96.0017 49.2501L94.4218 50.1601L88.5417 46.7601L90.1117 45.8501L96.0017 49.2501Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M92.6219 51.2L91.0419 50.28L85.1919 53.66L86.7719 54.57L92.6219 51.2Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M88.3218 48.7201L86.7419 47.8L80.8918 51.18L82.4718 52.09L88.3218 48.7201Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M79.0417 43.36L77.4717 44.27L83.3517 47.66L84.9317 46.75L79.0417 43.36Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M74.7719 45.8201L73.1919 46.7301L79.0819 50.1301L80.6519 49.2201L74.7719 45.8201Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M102.082 47.8301L103.662 48.7401L97.8119 52.1201L96.2319 51.2101L102.082 47.8301Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M106.382 50.3101L107.962 51.2301L102.122 54.6001L100.532 53.6901L106.382 50.3101Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M94.4318 52.24L92.8618 53.15L98.7418 56.55L100.322 55.64L94.4318 52.24Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M90.1618 54.71L88.5818 55.62L94.4718 59.02L96.0418 58.11L90.1618 54.71Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M38.8018 28.9601L37.2218 29.8601L31.3418 26.4701L32.9118 25.5601L38.8018 28.9601Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M34.5318 31.42L32.9518 32.33L27.0718 28.93L28.6418 28.03L34.5318 31.42Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M31.1517 33.37L29.5717 32.46L23.7217 35.83L25.3017 36.75L31.1517 33.37Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M40.6117 30L42.1917 30.92L36.3517 34.29L34.7617 33.38L40.6117 30Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M44.9118 32.48L46.5018 33.4L40.6518 36.78L39.0618 35.86L44.9118 32.48Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M54.1919 37.8399L52.6219 38.7499L46.7319 35.3499L48.3119 34.4399L54.1919 37.8399Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M49.9219 40.31L48.3419 41.22L42.4619 37.82L44.0319 36.91L49.9219 40.31Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M46.5418 42.2601L44.9618 41.3401L39.1118 44.7201L40.7018 45.6301L46.5418 42.2601Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M42.2418 39.77L40.6618 38.86L34.8118 42.24L36.3918 43.15L42.2418 39.77Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M32.9618 34.42L31.3918 35.32L37.2718 38.72L38.8518 37.81L32.9618 34.42Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M28.6918 36.88L27.1118 37.79L33.0018 41.19L34.5718 40.28L28.6918 36.88Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M56.0019 38.89L57.5919 39.8L51.7419 43.18L50.1519 42.26L56.0019 38.89Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M60.3019 41.37L61.8919 42.28L56.0419 45.66L54.4519 44.75L60.3019 41.37Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M69.5818 46.7301L68.0118 47.6401L62.1218 44.2401L63.7018 43.3301L69.5818 46.7301Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M65.3118 49.19L63.7318 50.1001L57.8518 46.71L59.4218 45.8L65.3118 49.19Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M61.9319 51.14L60.352 50.23L54.502 53.61L56.092 54.52L61.9319 51.14Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M57.6319 48.66L56.0519 47.75L50.2019 51.12L51.7919 52.04L57.6319 48.66Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M48.3517 43.3L46.7817 44.21L52.6617 47.6101L54.2417 46.7001L48.3517 43.3Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M44.082 45.77L42.502 46.68L48.392 50.08L49.962 49.17L44.082 45.77Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M71.3917 47.77L72.9818 48.69L67.1318 52.06L65.5417 51.15L71.3917 47.77Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M75.6918 50.26L77.2818 51.17L71.4318 54.55L69.8418 53.63L75.6918 50.26Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M84.9717 55.61L83.4017 56.52L77.5117 53.12L79.0917 52.22L84.9717 55.61Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M80.7017 58.0801L79.1217 58.9901L73.2417 55.5901L74.8117 54.6801L80.7017 58.0801Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M77.3218 60.03L75.7419 59.11L69.8918 62.49L71.4818 63.41L77.3218 60.03Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M73.0318 57.55L71.4418 56.63L65.5918 60.01L67.1818 60.92L73.0318 57.55Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M63.7519 52.1899L62.1719 53.0999L68.0619 56.4999L69.6319 55.5899L63.7519 52.1899Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M59.4719 54.66L57.9019 55.57L63.7819 58.96L65.3619 58.05L59.4719 54.66Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M86.7819 56.66L88.3719 57.57L82.5219 60.95L80.9419 60.04L86.7819 56.66Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M91.0817 59.14L92.6717 60.06L86.8217 63.43L85.2417 62.52L91.0817 59.14Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M79.1418 61.0701L77.5618 61.9801L83.4518 65.3801L85.0218 64.4701L79.1418 61.0701Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M29.612 41.3101L31.202 42.2301L25.352 45.6101L23.772 44.6901L29.612 41.3101Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M38.8919 46.67L37.3219 47.58L31.4419 44.18L33.0119 43.27L38.8919 46.67Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M34.6219 49.14L33.0519 50.05L27.1619 46.65L28.7419 45.74L34.6219 49.14Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M40.7118 47.72L42.2918 48.63L36.4418 52.01L34.8618 51.09L40.7118 47.72Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M45.0119 50.2L46.5919 51.11L40.7419 54.49L39.1619 53.58L45.0119 50.2Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M54.2918 55.56L52.7118 56.47L46.8318 53.07L48.4018 52.16L54.2918 55.56Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M50.0118 58.03L48.4418 58.94L42.5518 55.54L44.1318 54.63L50.0118 58.03Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M33.0619 52.13L31.4819 53.04L37.3719 56.44L38.9419 55.53L33.0619 52.13Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M56.102 56.6001L57.682 57.5201L51.832 60.9001L50.252 59.9801L56.102 56.6001Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M60.4018 59.0901L61.9818 60.0001L56.1318 63.3801L54.5518 62.4601L60.4018 59.0901Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M69.6817 64.44L68.1017 65.3501L62.2217 61.9601L63.7917 61.05L69.6817 64.44Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M71.1918 10.25L73.9818 8.64001C72.9818 8.64001 71.9818 8.64001 70.9018 8.64001L69.6018 9.39001L71.1918 10.25Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M71.1918 10.25L73.9818 8.64001C72.9818 8.64001 71.9818 8.64001 70.9018 8.64001L69.6018 9.39001L71.1918 10.25Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M86.9217 10.06L85.7817 9.84998L86.5317 10.28L86.9217 10.06Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M86.9217 10.06L85.7817 9.84998L86.5317 10.28L86.9217 10.06Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M94.2318 14.77L95.8018 13.86L90.7118 10.92L89.5818 10.65L88.3418 11.37L94.2318 14.77Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M94.2318 14.77L95.8018 13.86L90.7118 10.92L89.5818 10.65L88.3418 11.37L94.2318 14.77Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M83.1618 12.2301L84.7318 11.3201L80.9918 9.16006C79.7718 9.02006 78.5518 8.90006 77.3118 8.81006L83.1618 12.2301Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M83.1618 12.2301L84.7318 11.3201L80.9918 9.16006C79.7718 9.02006 78.5518 8.90006 77.3118 8.81006L83.1618 12.2301Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M97.6217 16.6901L101.512 14.4401C100.902 14.1801 100.292 13.9201 99.6618 13.6801L96.0417 15.7701L97.6217 16.6901Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M97.6217 16.6901L101.512 14.4401C100.902 14.1801 100.292 13.9201 99.6618 13.6801L96.0417 15.7701L97.6217 16.6901Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M101.922 19.1701L106.182 16.7101C105.632 16.4201 105.082 16.1201 104.522 15.8401L100.342 18.2501L101.922 19.1701Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M101.922 19.1701L106.182 16.7101C105.632 16.4201 105.082 16.1201 104.522 15.8401L100.342 18.2501L101.922 19.1701Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M113.012 25.57L115.872 23.95C115.502 23.56 115.112 23.17 114.712 22.79L111.482 24.66L113.012 25.57Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M113.012 25.57L115.872 23.95C115.502 23.56 115.112 23.17 114.712 22.79L111.482 24.66L113.012 25.57Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M117.312 28.05L118.562 27.3301C118.272 26.9001 117.962 26.47 117.632 26.05L115.732 27.14L117.312 28.05Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M117.312 28.05L118.562 27.3301C118.272 26.9001 117.962 26.47 117.632 26.05L115.732 27.14L117.312 28.05Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M119.872 29.55C119.752 29.32 119.622 29.1 119.492 28.87L119.102 29.1L119.872 29.55Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M119.872 29.55C119.752 29.32 119.622 29.1 119.492 28.87L119.102 29.1L119.872 29.55Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M117.362 36.9101L121.692 34.4201C121.572 33.8801 121.432 33.3601 121.262 32.8301L115.782 36.0001L117.362 36.9101Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M117.362 36.9101L121.692 34.4201C121.572 33.8801 121.432 33.3601 121.262 32.8301L115.782 36.0001L117.362 36.9101Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M119.172 37.96L122.012 39.6001C122.067 39.0084 122.087 38.414 122.072 37.82L120.752 37.05L119.172 37.96Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M119.172 37.96L122.012 39.6001C122.067 39.0084 122.087 38.414 122.072 37.82L120.752 37.05L119.172 37.96Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M56.5719 9.77002L55.4519 9.96002L55.8519 10.19L56.5719 9.77002Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M56.5719 9.77002L55.4519 9.96002L55.8519 10.19L56.5719 9.77002Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M69.3918 11.29L65.0618 8.77002C64.1418 8.83002 63.2318 8.90002 62.3118 9.00002L67.8718 12.2L69.3918 11.29Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M69.3918 11.29L65.0618 8.77002C64.1418 8.83002 63.2318 8.90002 62.3118 9.00002L67.8718 12.2L69.3918 11.29Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M54.0518 11.27L52.8018 10.55C52.0518 10.71 51.3118 10.89 50.5818 11.08L52.4718 12.18L54.0518 11.27Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M54.0518 11.27L52.8018 10.55C52.0518 10.71 51.3118 10.89 50.5818 11.08L52.4718 12.18L54.0518 11.27Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M115.872 44.86L117.452 45.77L121.212 43.6C121.438 42.9154 121.622 42.2174 121.762 41.51L115.872 44.86Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M115.872 44.86L117.452 45.77L121.212 43.6C121.438 42.9154 121.622 42.2174 121.762 41.51L115.872 44.86Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M119.642 47.06C119.762 46.83 119.882 46.6 119.992 46.37L119.222 46.82L119.642 47.06Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M119.642 47.06C119.762 46.83 119.882 46.6 119.992 46.37L119.222 46.82L119.642 47.06Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M111.572 51.2301L113.162 52.1501L118.272 49.1501C118.418 48.9501 118.558 48.7501 118.692 48.5501L117.422 47.8201L111.572 51.2301Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M111.572 51.2301L113.162 52.1501L118.272 49.1501C118.418 48.9501 118.558 48.7501 118.692 48.5501L117.422 47.8201L111.572 51.2301Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M108.202 53.18L111.202 54.91C111.742 54.91 112.282 54.91 112.832 54.91L113.442 54.39L109.772 52.27L108.202 53.18Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M108.202 53.18L111.202 54.91C111.742 54.91 112.282 54.91 112.832 54.91L113.442 54.39L109.772 52.27L108.202 53.18Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M103.932 55.65L105.012 56.27C105.637 55.9888 106.282 55.7548 106.942 55.57L105.502 54.77L103.932 55.65Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M103.932 55.65L105.012 56.27C105.637 55.9888 106.282 55.7548 106.942 55.57L105.502 54.77L103.932 55.65Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M96.2817 60.06L97.8617 60.98L101.732 58.77C102.149 58.2172 102.648 57.7316 103.212 57.33L102.132 56.71L96.2817 60.06Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M96.2817 60.06L97.8617 60.98L101.732 58.77C102.149 58.2172 102.648 57.7316 103.212 57.33L102.132 56.71L96.2817 60.06Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M92.8718 62.0101L95.7218 63.6501C96.3918 63.4401 97.0618 63.2201 97.7218 62.9901L94.4518 61.1001L92.8718 62.0101Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M92.8718 62.0101L95.7218 63.6501C96.3918 63.4401 97.0618 63.2201 97.7218 62.9901L94.4518 61.1001L92.8718 62.0101Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M88.6318 64.4801L89.9018 65.2101C90.6518 65.0401 91.3918 64.8701 92.1318 64.6801L90.2118 63.5701L88.6318 64.4801Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M88.6318 64.4801L89.9018 65.2101C90.6518 65.0401 91.3918 64.8701 92.1318 64.6801L90.2118 63.5701L88.6318 64.4801Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M86.0618 65.96L87.2518 65.76L86.8718 65.52L86.0618 65.96Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M86.0618 65.96L87.2518 65.76L86.8718 65.52L86.0618 65.96Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M73.2917 64.45L77.6417 66.96C78.5617 66.9 79.4817 66.82 80.3917 66.73L74.8717 63.54L73.2917 64.45Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M73.2917 64.45L77.6417 66.96C78.5617 66.9 79.4817 66.82 80.3917 66.73L74.8717 63.54L73.2917 64.45Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M57.9419 64.42L61.7119 66.6C62.9219 66.74 64.1319 66.85 65.3519 66.94L59.4719 63.54L57.9419 64.42Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M71.7519 67.17L73.0719 66.4L71.4919 65.49L68.6719 67.12C69.7019 67.15 70.7319 67.17 71.7519 67.17Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M46.8718 61.9301L51.9818 64.9301L53.0818 65.1901L54.3218 64.4701L48.4418 61.0701L46.8718 61.9301Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M41.1619 61.3101L43.0119 62.0701L46.6419 59.9701L45.0519 59.0601L41.1619 61.3101Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M36.502 59.0301C37.042 59.3301 37.602 59.6201 38.162 59.9001L42.342 57.4901L40.752 56.5801L36.502 59.0301Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M26.8718 51.77C27.2418 52.16 27.6318 52.55 28.0318 52.93L31.2618 51.07L29.6718 50.15L26.8718 51.77Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M24.1318 48.3999C24.4185 48.8399 24.7285 49.2699 25.0618 49.6899L26.9518 48.5999L25.3618 47.6899L24.1318 48.3999Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M23.1718 46.87L23.5518 46.64L22.8018 46.21L23.1718 46.87Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M21.0117 41.3201C21.1217 41.8501 21.2717 42.3801 21.4317 42.9001L26.8717 39.7701L25.2817 38.8501L21.0117 41.3201Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M20.6318 37.94L21.9318 38.69L23.5018 37.79L20.6918 36.16C20.6368 36.7517 20.6168 37.346 20.6318 37.94Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M21.0019 34.26L26.8719 30.89L25.2719 29.97L21.5519 32.12C21.313 32.816 21.1225 33.5277 20.9819 34.25L21.0019 34.26Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M24.4617 26.52C24.3017 26.74 24.1617 26.95 24.0117 27.17L25.2617 27.89L31.1017 24.51L29.5217 23.6L24.4617 26.52Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M29.8117 20.9L29.2617 21.37L32.8717 23.47L34.4517 22.56L31.5917 20.91C30.9987 20.9406 30.4044 20.9372 29.8117 20.9Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M37.1818 21.01L38.7518 20.1L37.7518 19.55C37.1305 19.8346 36.4887 20.0719 35.8318 20.26L37.1818 21.01Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M41.2818 16.77C40.8303 17.4345 40.2609 18.0107 39.6018 18.47L40.6018 19.02L46.4518 15.64L44.8118 14.77L41.2818 16.77Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M48.2019 14.64L49.7719 13.77L46.9519 12.15C46.2819 12.36 45.6119 12.58 44.9519 12.81L48.2019 14.64Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M31.5419 60.86C21.7619 55.22 15.8719 47.77 14.9919 39.77C14.1119 31.77 18.1619 24.18 26.4019 17.97L27.4819 17.15L29.2019 17.47C31.0619 17.8435 32.9937 17.5756 34.6819 16.71C36.1219 15.88 36.6219 14.66 36.0019 13.54L35.4519 12.54L36.8719 11.92C47.6119 7.17001 61.0419 4.83001 74.6719 5.34001C88.3019 5.85001 101.382 9.25001 111.162 14.89C120.942 20.53 126.872 28.04 127.712 35.98C128.552 43.92 124.522 51.62 116.252 57.83L115.192 58.63L113.512 58.34C111.683 57.9942 109.791 58.265 108.132 59.11C106.732 59.92 106.212 61.11 106.782 62.22L107.292 63.22L105.872 63.77C95.1219 68.55 81.6919 70.9 68.0419 70.39C54.3919 69.88 41.3319 66.52 31.5419 60.86ZM28.4319 18.86C20.5619 24.77 16.6919 32.2 17.5419 39.71C18.3919 47.22 24.0019 54.43 33.3419 59.83C42.6819 65.23 55.0719 68.46 68.2219 68.95C81.3719 69.44 94.0819 67.19 104.342 62.63C103.502 61.01 104.262 59.26 106.342 58.07C108.779 56.8234 111.561 56.419 114.252 56.92C122.142 51 126.022 43.59 125.192 36.07C124.362 28.55 118.732 21.33 109.382 15.93C100.032 10.53 87.6519 7.30001 74.5219 6.77001C61.3919 6.24001 48.6919 8.52001 38.4319 13.06C39.3519 14.71 38.6119 16.49 36.4919 17.71C34.0106 18.9885 31.1678 19.3836 28.4319 18.83V18.86Z"
                    fill="#CCD9E0"
                />
                <path
                    d="M33.342 59.83C24.132 54.51 18.532 47.47 17.572 40.01C16.612 32.55 20.252 25.28 27.782 19.37L28.382 18.9L29.382 19.01C31.823 19.3322 34.3041 18.8995 36.492 17.77C38.362 16.7 39.172 15.16 38.672 13.66L38.482 13.09L39.282 12.75C49.532 8.39003 62.282 6.30003 75.032 6.85003C87.782 7.40003 100.162 10.64 109.362 15.95C118.562 21.26 124.182 28.3 125.132 35.77C126.082 43.24 122.422 50.52 114.872 56.44L114.282 56.9L113.282 56.8C110.874 56.5105 108.435 56.953 106.282 58.07C104.462 59.07 103.642 60.63 104.082 62.07L104.252 62.63L103.462 62.97C93.212 67.34 80.462 69.44 67.682 68.9C54.902 68.36 42.562 65.15 33.342 59.83ZM29.812 20.9C23.092 26.39 19.882 33.11 20.752 39.9C21.662 46.9 26.932 53.55 35.592 58.55C44.252 63.55 55.752 66.55 67.932 67.11C79.692 67.61 91.342 65.74 100.852 61.85C100.582 59.97 101.752 58.12 104.062 56.78C106.77 55.3563 109.819 54.7091 112.872 54.91C119.622 49.42 122.872 42.69 121.992 35.91C121.102 28.91 115.832 22.25 107.152 17.24C98.472 12.23 86.982 9.19003 74.872 8.67003C63.142 8.16003 51.512 10.02 42.002 13.89C42.332 15.8 41.172 17.7 38.812 19.06C36.0464 20.5124 32.9256 21.1505 29.812 20.9Z"
                    fill="white"
                />
                <path
                    opacity="0.3"
                    d="M214.532 134.51L182.612 116.05C182.157 115.786 181.78 115.408 181.518 114.952C181.255 114.497 181.117 113.981 181.117 113.455C181.117 112.929 181.255 112.413 181.518 111.958C181.78 111.502 182.157 111.124 182.612 110.86L214.262 92.56C216.083 91.4989 218.154 90.9398 220.262 90.9398C222.37 90.9398 224.44 91.4989 226.262 92.56L258.182 111.02C258.634 111.284 259.009 111.663 259.27 112.117C259.531 112.571 259.668 113.086 259.668 113.61C259.668 114.134 259.531 114.649 259.27 115.103C259.009 115.557 258.634 115.936 258.182 116.2L226.532 134.51C224.708 135.563 222.638 136.118 220.532 136.118C218.425 136.118 216.356 135.563 214.532 134.51Z"
                    fill="#5A7D91"
                />
                <path
                    d="M211.782 134.17L179.872 115.71C179.433 115.467 179.065 115.115 178.804 114.687C178.543 114.259 178.397 113.771 178.382 113.27V108.77L256.882 108.96V113.27C256.881 113.794 256.744 114.309 256.482 114.764C256.221 115.218 255.845 115.596 255.392 115.86L223.732 134.17C221.914 135.214 219.853 135.763 217.757 135.763C215.66 135.763 213.6 135.214 211.782 134.17Z"
                    fill="#D0DFE5"
                />
                <path
                    d="M217.872 135.77C219.93 135.748 221.948 135.197 223.732 134.17L255.382 115.86C255.835 115.596 256.211 115.218 256.472 114.764C256.734 114.309 256.871 113.794 256.872 113.27V108.96L217.872 108.86V135.77Z"
                    fill="#B8C9D3"
                />
                <path
                    d="M211.782 129.82L179.872 111.36C179.42 111.096 179.044 110.717 178.784 110.263C178.523 109.809 178.386 109.294 178.386 108.77C178.386 108.246 178.523 107.731 178.784 107.277C179.044 106.823 179.42 106.444 179.872 106.18L211.512 87.87C213.336 86.8168 215.405 86.2623 217.512 86.2623C219.618 86.2623 221.688 86.8168 223.512 87.87L255.432 106.33C255.886 106.594 256.264 106.972 256.526 107.428C256.788 107.883 256.927 108.399 256.927 108.925C256.927 109.451 256.788 109.967 256.526 110.422C256.264 110.878 255.886 111.256 255.432 111.52L223.782 129.82C221.96 130.881 219.89 131.44 217.782 131.44C215.674 131.44 213.603 130.881 211.782 129.82Z"
                    fill="#E6EDF0"
                />
                <path
                    d="M217.492 86.27C219.598 86.2619 221.669 86.8141 223.492 87.87L255.412 106.33C255.866 106.594 256.244 106.972 256.506 107.428C256.768 107.883 256.906 108.399 256.906 108.925C256.906 109.451 256.768 109.967 256.506 110.422C256.244 110.878 255.866 111.256 255.412 111.52L223.762 129.82C221.938 130.873 219.868 131.428 217.762 131.428C215.655 131.428 213.586 130.873 211.762 129.82L179.872 111.36C179.42 111.096 179.044 110.717 178.784 110.263C178.523 109.809 178.386 109.294 178.386 108.77C178.386 108.246 178.523 107.731 178.784 107.277C179.044 106.823 179.42 106.444 179.872 106.18L211.512 87.87C213.335 86.8159 215.406 86.2639 217.512 86.27H217.492ZM217.512 85.61C215.297 85.6144 213.122 86.197 211.202 87.3L179.552 105.61C178.997 105.93 178.537 106.391 178.217 106.946C177.897 107.501 177.729 108.13 177.729 108.77C177.729 109.41 177.897 110.039 178.217 110.594C178.537 111.149 178.997 111.61 179.552 111.93L211.462 130.39C213.38 131.498 215.557 132.081 217.772 132.081C219.987 132.081 222.163 131.498 224.082 130.39L255.732 112.09C256.286 111.768 256.746 111.306 257.066 110.751C257.385 110.196 257.554 109.566 257.554 108.925C257.554 108.284 257.385 107.654 257.066 107.099C256.746 106.544 256.286 106.082 255.732 105.76L223.802 87.3C221.881 86.197 219.706 85.6144 217.492 85.61H217.512Z"
                    fill="#8CA9B8"
                />
                <path
                    opacity="0.3"
                    d="M247.952 102.04L233.372 99.4L228.872 118.9L255.042 106.13L247.952 102.04Z"
                    fill="#5A7D91"
                />
                <path
                    d="M232.412 117.29C224.332 121.95 211.192 121.93 203.062 117.23C198.262 114.46 196.282 110.69 197.132 107.1C197.712 104.61 213.422 38.88 213.422 38.88L221.832 38.94C221.832 38.94 237.622 104.67 238.232 107.17C239.122 110.77 237.182 114.53 232.412 117.29Z"
                    fill="#E6EDF0"
                />
                <path
                    d="M206.652 93.5101C212.772 97.0401 222.652 97.0601 228.742 93.5101C231.532 91.9001 233.242 89.8201 233.562 87.7101C232.232 82.2101 230.762 76.0901 229.322 70.0901C229.012 71.5701 227.722 73.0201 225.762 74.1601C221.312 76.7301 214.072 76.7101 209.602 74.1601C207.919 73.3358 206.609 71.9075 205.932 70.1601L201.872 87.3501C202.112 89.5701 203.792 91.8601 206.652 93.5101Z"
                    fill="white"
                />
                <path
                    d="M229.322 70.13C230.762 76.13 232.232 82.25 233.562 87.75C233.242 89.86 231.562 91.94 228.742 93.55C225.359 95.3484 221.572 96.2504 217.742 96.17C213.872 96.2423 210.049 95.3268 206.632 93.51C203.772 91.86 202.092 89.57 201.822 87.35L205.912 70.22C206.588 71.9675 207.899 73.3957 209.582 74.22C212.085 75.4918 214.854 76.1547 217.662 76.1547C220.47 76.1547 223.238 75.4918 225.742 74.22C227.742 73.08 228.992 71.63 229.302 70.15L229.322 70.13ZM229.302 69.49C229.153 69.4916 229.009 69.5441 228.895 69.6387C228.78 69.7333 228.701 69.8644 228.672 70.01C228.392 71.3 227.242 72.6 225.422 73.65C223.018 74.863 220.364 75.495 217.672 75.495C214.98 75.495 212.325 74.863 209.922 73.65C208.397 72.9205 207.197 71.6523 206.552 70.09C206.513 69.9526 206.43 69.8316 206.317 69.745C206.203 69.6585 206.065 69.6111 205.922 69.61C205.777 69.615 205.637 69.6677 205.525 69.7599C205.413 69.8521 205.335 69.9787 205.302 70.12L201.212 87.25C201.196 87.3259 201.196 87.4041 201.212 87.48C201.522 90 203.392 92.42 206.352 94.13C209.867 96.0115 213.806 96.9585 217.792 96.88C221.732 96.9632 225.627 96.0299 229.102 94.17C232.102 92.46 233.892 90.23 234.242 87.89C234.257 87.8074 234.257 87.7227 234.242 87.64L229.962 69.97C229.928 69.8264 229.846 69.6986 229.729 69.6078C229.613 69.5169 229.469 69.4683 229.322 69.47L229.302 69.49Z"
                    fill="#8CA9B8"
                />
                <path
                    opacity="0.15"
                    d="M215.992 38.9L207.562 119.21C215.482 121.8 225.692 121.21 232.412 117.29C237.182 114.53 239.122 110.76 238.232 107.17C237.622 104.67 221.832 38.94 221.832 38.94L215.992 38.9Z"
                    fill="#5A7D91"
                />
                <path
                    opacity="0.25"
                    d="M219.792 38.92L229.072 118.85C230.226 118.423 231.344 117.902 232.412 117.29C237.182 114.53 239.122 110.76 238.232 107.17C237.622 104.67 221.832 38.94 221.832 38.94L219.792 38.92Z"
                    fill="#5A7D91"
                />
                <path
                    d="M213.422 38.88L221.832 38.94C221.832 38.94 237.622 104.67 238.232 107.17C239.122 110.76 237.182 114.53 232.412 117.29C227.86 119.588 222.829 120.781 217.73 120.77C212.63 120.76 207.605 119.547 203.062 117.23C198.262 114.46 196.282 110.69 197.132 107.1C197.712 104.61 213.422 38.88 213.422 38.88ZM213.422 38.22C213.272 38.2184 213.125 38.268 213.007 38.3607C212.889 38.4535 212.806 38.5837 212.772 38.73C212.622 39.39 197.062 104.48 196.482 106.95C195.552 110.95 197.882 115.01 202.732 117.81C207.385 120.295 212.598 121.541 217.872 121.43C223.069 121.536 228.206 120.307 232.792 117.86C237.622 115.07 239.912 111.02 238.922 107.01C238.312 104.55 222.682 39.44 222.522 38.78C222.488 38.6381 222.408 38.5116 222.294 38.4209C222.179 38.3302 222.038 38.2806 221.892 38.28L213.472 38.22H213.422Z"
                    fill="#8CA9B8"
                />
                <path
                    d="M217.592 36.77C218.636 36.7547 219.668 37.0023 220.592 37.49C222.252 38.49 222.262 40.01 220.592 40.96C219.671 41.4539 218.637 41.6987 217.592 41.67C216.547 41.6853 215.516 41.4378 214.592 40.95C212.932 39.95 212.922 38.43 214.592 37.48C215.513 36.9861 216.547 36.7414 217.592 36.77ZM217.592 36.11C216.438 36.0858 215.297 36.3615 214.282 36.91C213.816 37.0894 213.416 37.4056 213.133 37.8171C212.851 38.2286 212.699 38.7159 212.699 39.215C212.699 39.7141 212.851 40.2015 213.133 40.613C213.416 41.0244 213.816 41.3407 214.282 41.52C215.313 42.0766 216.471 42.3557 217.642 42.33C218.796 42.3542 219.936 42.0786 220.952 41.53C221.418 41.3507 221.818 41.0344 222.101 40.623C222.383 40.2115 222.534 39.7241 222.534 39.225C222.534 38.7259 222.383 38.2386 222.101 37.8271C221.818 37.4156 221.418 37.0994 220.952 36.92C219.921 36.3634 218.763 36.0843 217.592 36.11Z"
                    fill="#8CA9B8"
                />
                <path
                    d="M221.866 39.2723C221.873 37.9192 219.977 36.812 217.629 36.7993C215.282 36.7866 213.374 37.8732 213.366 39.2263C213.359 40.5794 215.256 41.6866 217.603 41.6993C219.95 41.712 221.859 40.6254 221.866 39.2723Z"
                    fill="#E6EDF0"
                />
                <path
                    d="M217.592 36.77C218.636 36.7547 219.668 37.0023 220.592 37.49C220.896 37.6265 221.168 37.8265 221.389 38.0766C221.609 38.3267 221.774 38.6211 221.872 38.94L235.262 94.67L255.422 106.33C255.881 106.597 256.26 106.982 256.522 107.444C256.784 107.906 256.918 108.429 256.912 108.96V113.27C256.911 113.794 256.774 114.309 256.512 114.764C256.251 115.218 255.875 115.596 255.422 115.86L223.732 134.17C221.907 135.223 219.838 135.778 217.732 135.778C215.625 135.778 213.556 135.223 211.732 134.17L179.872 115.71C179.433 115.467 179.065 115.115 178.804 114.687C178.543 114.259 178.397 113.771 178.382 113.27V108.77C178.382 108.246 178.52 107.731 178.781 107.276C179.043 106.822 179.419 106.444 179.872 106.18L200.152 94.45L213.432 38.88C213.528 38.5834 213.683 38.3092 213.887 38.0738C214.092 37.8385 214.341 37.6467 214.622 37.51C215.543 37.0161 216.577 36.7714 217.622 36.8L217.592 36.77ZM217.622 35.55C216.363 35.5275 215.119 35.8307 214.012 36.43C213.585 36.6472 213.207 36.9503 212.902 37.3203C212.598 37.6902 212.373 38.1191 212.242 38.58C212.242 38.58 203.792 73.77 199.052 93.64L179.242 105.1C178.593 105.467 178.055 106.001 177.682 106.646C177.309 107.292 177.116 108.025 177.122 108.77V113.27C177.139 113.99 177.344 114.694 177.715 115.311C178.087 115.928 178.613 116.438 179.242 116.79L211.152 135.25C213.16 136.409 215.438 137.02 217.757 137.02C220.076 137.02 222.354 136.409 224.362 135.25L256.002 116.94C256.65 116.573 257.189 116.039 257.561 115.394C257.934 114.748 258.128 114.015 258.122 113.27V108.95C258.131 108.2 257.939 107.462 257.566 106.811C257.193 106.16 256.653 105.621 256.002 105.25L236.312 93.86C231.542 74.03 223.042 38.64 223.042 38.64C222.914 38.167 222.688 37.7264 222.377 37.3473C222.067 36.9681 221.68 36.6589 221.242 36.44C220.123 35.8326 218.865 35.5259 217.592 35.55H217.622Z"
                    fill="#5A7D91"
                />
                <path
                    opacity="0.3"
                    d="M74.2217 111.26L75.4217 109.15C75.6706 108.73 75.8134 108.256 75.8377 107.769C75.862 107.282 75.7671 106.796 75.5612 106.354C75.3553 105.911 75.0447 105.526 74.6562 105.231C74.2677 104.936 73.8131 104.74 73.3317 104.66L26.7017 95.99C25.0502 95.6842 23.3443 95.9061 21.8259 96.624C20.3074 97.3419 19.0535 98.5196 18.2417 99.99L5.08174 123.77C4.83927 124.189 4.70198 124.661 4.68161 125.145C4.66124 125.629 4.7584 126.111 4.96478 126.549C5.17115 126.988 5.48061 127.37 5.86669 127.662C6.25277 127.955 6.70398 128.15 7.18174 128.23L53.4317 136.91C55.0727 137.218 56.7688 137.004 58.2823 136.299C59.7958 135.594 61.0509 134.434 61.8717 132.98L70.8717 117.16C72.2617 116.27 74.1517 115.06 74.9617 114.6C76.2317 113.89 76.1317 111.77 74.2217 111.26Z"
                    fill="#5A7D91"
                />
                <path
                    d="M58.6918 132.23L72.2618 108.4C72.5215 107.943 72.6592 107.426 72.6618 106.9V103.01L1.53184 120.56V124.47C1.52105 125.183 1.76477 125.877 2.21927 126.427C2.67376 126.977 3.30929 127.346 4.01184 127.47L50.2618 136.14C51.8992 136.447 53.5915 136.235 55.1028 135.534C56.614 134.834 57.8687 133.678 58.6918 132.23Z"
                    fill="#D0DFE5"
                />
                <path
                    d="M52.5317 136.23C53.8033 136.104 55.0262 135.675 56.0979 134.979C57.1696 134.283 58.0591 133.34 58.6917 132.23L72.2617 108.4C72.5214 107.943 72.6591 107.426 72.6617 106.9V103.01L71.0517 103.4L52.5317 127.55V136.23Z"
                    fill="#B8C9D3"
                />
                <path
                    d="M58.6919 128.31L72.2619 104.48C72.5108 104.06 72.6536 103.586 72.6779 103.099C72.7022 102.612 72.6073 102.126 72.4014 101.684C72.1955 101.241 71.8849 100.856 71.4964 100.561C71.1079 100.266 70.6533 100.07 70.1719 99.99L23.5419 91.32C21.8904 91.0142 20.1845 91.236 18.6661 91.9539C17.1476 92.6719 15.8937 93.8495 15.0819 95.32L1.87194 119.08C1.62732 119.5 1.48829 119.973 1.4668 120.458C1.44532 120.943 1.54201 121.426 1.74859 121.866C1.95516 122.306 2.26542 122.689 2.65271 122.982C3.03999 123.275 3.49271 123.47 3.97194 123.55L50.2219 132.22C51.8653 132.536 53.5665 132.329 55.0859 131.627C56.6053 130.926 57.8665 129.766 58.6919 128.31Z"
                    fill="#E6EDF0"
                />
                <path
                    d="M22.0717 91.19C22.5646 91.1886 23.0567 91.2321 23.5417 91.32L70.1717 99.99C70.653 100.07 71.1076 100.266 71.4961 100.561C71.8846 100.856 72.1953 101.241 72.4012 101.684C72.6071 102.126 72.702 102.612 72.6777 103.099C72.6534 103.586 72.5106 104.06 72.2617 104.48L58.6917 128.31C57.9899 129.546 56.9715 130.573 55.7411 131.285C54.5108 131.997 53.1131 132.368 51.6917 132.36C51.1952 132.358 50.6999 132.311 50.2117 132.22L4.01171 123.55C3.529 123.476 3.07168 123.285 2.67947 122.994C2.28726 122.703 1.97203 122.321 1.76114 121.88C1.55026 121.44 1.4501 120.954 1.46937 120.466C1.48864 119.978 1.62675 119.503 1.87171 119.08L15.0417 95.31C15.7345 94.0609 16.749 93.0201 17.9799 92.2956C19.2109 91.5711 20.6134 91.1893 22.0417 91.19H22.0717ZM22.0417 90.53C20.497 90.5317 18.9808 90.946 17.6499 91.7301C16.319 92.5142 15.2218 93.6397 14.4717 94.99L1.33171 118.77C1.05001 119.282 0.893204 119.853 0.874062 120.437C0.85492 121.021 0.973984 121.601 1.22156 122.131C1.46913 122.66 1.83821 123.123 2.29874 123.483C2.75926 123.842 3.29822 124.088 3.87171 124.2L50.1217 132.87C51.8932 133.205 53.7249 132.977 55.3603 132.218C56.9957 131.46 58.3529 130.208 59.2417 128.64L72.8717 104.77C73.164 104.257 73.3293 103.682 73.3539 103.092C73.3785 102.502 73.2616 101.915 73.0129 101.38C72.7643 100.844 72.3912 100.376 71.9248 100.014C71.4584 99.6526 70.9121 99.4077 70.3317 99.3L23.6617 90.67C23.1366 90.5784 22.6047 90.5315 22.0717 90.53H22.0417Z"
                    fill="#8CA9B8"
                />
                <path
                    opacity="0.3"
                    d="M71.1818 106.37L52.7018 101.32L48.3218 121.78L67.7618 112.38L71.1818 106.37Z"
                    fill="#5A7D91"
                />
                <path
                    d="M51.8719 119.77C43.7919 124.43 30.6519 124.41 22.5219 119.71C17.7219 116.94 15.7419 113.17 16.5919 109.58C17.1719 107.09 32.8819 41.36 32.8819 41.36L41.2919 41.42C41.2919 41.42 57.0819 107.15 57.6919 109.65C58.6219 113.26 56.6819 117.03 51.8719 119.77Z"
                    fill="#E6EDF0"
                />
                <path
                    d="M26.1518 96.01C32.2718 99.54 42.1518 99.56 48.2418 96.01C51.0318 94.4 52.7418 92.32 53.0618 90.21C51.7318 84.77 50.2618 78.63 48.8718 72.63C48.5618 74.11 47.2718 75.56 45.3118 76.7C40.8618 79.27 33.6218 79.25 29.1518 76.7C27.4689 75.8757 26.1585 74.4474 25.4818 72.7L21.3418 89.85C21.6118 92.07 23.2918 94.36 26.1518 96.01Z"
                    fill="white"
                />
                <path
                    opacity="0.15"
                    d="M35.4918 41.4L27.0618 121.71C34.9818 124.3 45.1918 123.66 51.9118 119.79C56.6818 117.03 58.6218 113.26 57.7318 109.67C57.1218 107.17 41.3318 41.44 41.3318 41.44L35.4918 41.4Z"
                    fill="#5A7D91"
                />
                <path
                    opacity="0.25"
                    d="M39.2917 41.42L48.5718 121.35C49.7133 120.915 50.8171 120.387 51.8717 119.77C56.6418 117.01 58.5817 113.24 57.6917 109.65C57.0817 107.15 41.2917 41.42 41.2917 41.42H39.2917Z"
                    fill="#5A7D91"
                />
                <path
                    d="M48.8719 72.63C50.3119 78.63 51.7819 84.75 53.1119 90.25C52.7919 92.36 51.1119 94.44 48.2919 96.05C44.9092 97.8484 41.1222 98.7504 37.2919 98.67C33.4227 98.7423 29.5989 97.8268 26.1819 96.01C23.3219 94.36 21.6419 92.07 21.3719 89.85L25.4319 72.77C26.1087 74.5175 27.4191 75.9457 29.1019 76.77C31.5981 78.1018 34.3933 78.773 37.2219 78.72C40.0083 78.7688 42.7613 78.1081 45.2219 76.8C47.2219 75.66 48.4719 74.21 48.7819 72.73L48.8719 72.63ZM48.7819 72.07C48.6333 72.0716 48.4897 72.1241 48.3751 72.2187C48.2604 72.3133 48.1817 72.4444 48.1519 72.59C47.8719 73.77 46.7519 75.08 44.9319 76.13C42.5607 77.3892 39.9061 78.0193 37.2219 77.96C34.5086 78.0194 31.8258 77.3788 29.4319 76.1C27.9074 75.3705 26.7069 74.1023 26.0619 72.54C26.023 72.4026 25.9405 72.2816 25.8269 72.195C25.7134 72.1085 25.5747 72.0611 25.4319 72.06C25.2869 72.065 25.1476 72.1177 25.0355 72.2099C24.9234 72.3021 24.8448 72.4287 24.8119 72.57L20.7019 89.7C20.6866 89.7759 20.6866 89.8541 20.7019 89.93C21.0119 92.45 22.8819 94.87 25.8419 96.58C29.3567 98.4615 33.296 99.4085 37.2819 99.33C41.2221 99.4132 45.1173 98.4799 48.5919 96.62C51.5919 94.91 53.3819 92.68 53.7319 90.34C53.7469 90.2574 53.7469 90.1727 53.7319 90.09L49.4619 72.47C49.4279 72.3264 49.346 72.1986 49.2296 72.1078C49.1133 72.0169 48.9695 71.9683 48.8219 71.97L48.7819 72.07Z"
                    fill="#8CA9B8"
                />
                <path
                    d="M32.9219 41.38L41.3319 41.44C41.3319 41.44 57.1219 107.17 57.7319 109.67C58.6219 113.26 56.7319 117.03 51.9119 119.79C47.4239 122.173 42.4022 123.37 37.3219 123.27C32.1841 123.38 27.1033 122.176 22.5619 119.77C17.7619 117 15.7819 113.23 16.6319 109.64C17.2119 107.15 32.9219 41.42 32.9219 41.42V41.38ZM32.9219 40.76C32.7717 40.7584 32.6255 40.808 32.5073 40.9008C32.3891 40.9935 32.3061 41.1238 32.2719 41.27C32.1219 41.93 16.5619 107.02 15.9819 109.49C15.0519 113.49 17.3819 117.55 22.2319 120.35C26.8699 122.827 32.0652 124.073 37.3219 123.97C42.5188 124.076 47.6561 122.847 52.2419 120.4C57.0719 117.61 59.3619 113.55 58.3719 109.55C57.7619 107.09 42.1319 41.98 41.9719 41.32C41.9383 41.1781 41.8578 41.0516 41.7436 40.9609C41.6293 40.8702 41.4878 40.8206 41.3419 40.82L32.9219 40.77V40.76Z"
                    fill="#8CA9B8"
                />
                <path
                    d="M37.0918 39.3001C38.1364 39.2847 39.168 39.5323 40.0918 40.0201C41.7518 41.0201 41.7618 42.5401 40.0918 43.4901C39.1707 43.984 38.1366 44.2287 37.0918 44.2001C36.0473 44.2154 35.0157 43.9678 34.0918 43.4801C32.4318 42.4801 32.4218 40.9601 34.0918 40.0101C35.0129 39.5161 36.0471 39.2714 37.0918 39.3001ZM37.0918 38.6401C35.9379 38.6158 34.7974 38.8915 33.7818 39.4401C33.3161 39.6194 32.9156 39.9357 32.6331 40.3471C32.3507 40.7586 32.1995 41.246 32.1995 41.7451C32.1995 42.2441 32.3507 42.7315 32.6331 43.143C32.9156 43.5545 33.3161 43.8707 33.7818 44.0501C34.8127 44.6066 35.9706 44.8858 37.1418 44.8601C38.2958 44.8843 39.4363 44.6086 40.4518 44.0601C40.9176 43.8807 41.3181 43.5645 41.6006 43.153C41.883 42.7415 42.0342 42.2541 42.0342 41.7551C42.0342 41.256 41.883 40.7686 41.6006 40.3571C41.3181 39.9457 40.9176 39.6294 40.4518 39.4501C39.421 38.8935 38.2631 38.6143 37.0918 38.6401Z"
                    fill="#8CA9B8"
                />
                <path
                    d="M41.3696 41.7733C41.3769 40.4202 39.4801 39.313 37.1329 39.3003C34.7857 39.2876 32.877 40.3742 32.8697 41.7273C32.8624 43.0804 34.7592 44.1875 37.1064 44.2002C39.4536 44.2129 41.3623 43.1263 41.3696 41.7733Z"
                    fill="#E6EDF0"
                />
                <path
                    d="M37.0918 39.3C38.1363 39.2847 39.168 39.5323 40.0918 40.02C40.3801 40.1611 40.6363 40.3599 40.8444 40.6042C41.0526 40.8485 41.2083 41.133 41.3018 41.44C41.3018 41.44 49.9318 77.38 54.6818 97.12L70.1418 99.99C70.8443 100.114 71.4799 100.483 71.9344 101.033C72.3889 101.583 72.6326 102.277 72.6218 102.99C72.6218 102.99 72.6218 105.48 72.6218 106.88C72.6192 107.406 72.4814 107.923 72.2218 108.38L58.6918 132.23C57.9836 133.457 56.9624 134.474 55.7325 135.176C54.5026 135.879 53.1082 136.243 51.6918 136.23C51.195 136.233 50.6993 136.186 50.2118 136.09L4.01179 127.46C3.30924 127.336 2.67372 126.967 2.21922 126.417C1.76472 125.867 1.52101 125.173 1.53179 124.46V120.55C1.5215 120.039 1.63833 119.534 1.87179 119.08L15.0418 95.31C15.6453 94.2208 16.4947 93.2875 17.5225 92.5844C18.5502 91.8813 19.7279 91.4278 20.9618 91.26L32.9218 41.38C33.0179 41.0834 33.1728 40.8092 33.3773 40.5738C33.5817 40.3385 33.8316 40.1467 34.1118 40.01C35.0329 39.5161 36.067 39.2714 37.1118 39.3H37.0918ZM37.1118 38.05C35.8527 38.0275 34.6092 38.3307 33.5018 38.93C33.0746 39.1472 32.6969 39.4503 32.3924 39.8203C32.0878 40.1902 31.8629 40.6191 31.7318 41.08C31.7318 41.08 24.8218 69.98 19.9918 90.18C18.7317 90.4765 17.5473 91.0325 16.5142 91.8125C15.4811 92.5924 14.622 93.5793 13.9918 94.71L0.87179 118.48C0.51865 119.116 0.332848 119.832 0.33179 120.56V124.46C0.330607 125.463 0.679738 126.434 1.31883 127.206C1.95791 127.979 2.84679 128.504 3.83179 128.69L50.0818 137.36C50.6454 137.468 51.218 137.521 51.7918 137.52C53.4177 137.512 55.0128 137.076 56.4169 136.256C57.8211 135.436 58.985 134.262 59.7918 132.85L73.3518 109.02C73.7224 108.375 73.9189 107.644 73.9218 106.9V103.01C73.9316 102.003 73.586 101.025 72.9459 100.248C72.3057 99.4702 71.4119 98.9435 70.4218 98.76L55.7518 96.04L42.5418 41.14C42.4141 40.667 42.1876 40.2264 41.8774 39.8473C41.5672 39.4681 41.1801 39.1589 40.7418 38.94C39.6226 38.3326 38.365 38.0259 37.0918 38.05H37.1118Z"
                    fill="#5A7D91"
                />
            </svg>
        </div>
        <div
            id="maintenance_duration"
            class="subtitle is-3 is-size-5-mobile is-centered pt-6"
        >
            {duration}
        </div>
        <div
            id="maintenance_thanks"
            class="subtitle is-5 is-size-6-mobile is-centered"
        >
            {thanks}
        </div>
        <button
            class="button drive-blue is-centered maintenance-button"
            on:click={handleClick}
        >
            <span>{link}</span>
        </button>
    </div>
</div>

<style>
    .maintenance {
        display: flex;
        align-items: center;
        align-content: center;
        height: 100%;
    }
    svg {
        margin: 0 auto;
        display: block;
        box-sizing: unset;
    }
    .drive-blue {
        background-color: #0696d7;
        color: white;
    }
</style>
