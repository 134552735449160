<svelte:head>
    <style>
        /* Button overrides */
        .btn {
            background-color: #0696d7;
            border: 1px solid rgba(6, 150, 215, 1);
            color: white;
            cursor: pointer;
            display: flex;
            border-radius: 2px;
            height: 38px;
            line-height: 38px;
            font-size: 14px;
            font-weight: 600;
            align-items: center;
            text-transform: none;
            box-shadow: none;
            justify-content: left;
        }

        .btn.disabled {
            border: 1px solid #dcdcdc;
        }

        .btn-flat {
            background-color: transparent;
            color: #0696d7;
        }

        .btn-flat:not(.disabled) {
            border: 1px solid rgba(6, 150, 215, 1);
        }

        .btn-flat.btn.disabled {
            background-color: transparent !important;
            color: #b3b2b2 !important;
            cursor: default;
            opacity: 0.3;
        }

        .btn-large {
            height: 43px;
            line-height: 43px;
        }

        .btn:hover {
            background-color: #0696d7;
            box-shadow: 0px 0px 0px 2px #dcdcdc;
        }

        .btn-flat:hover {
            background-color: transparent;
            border: 1px solid #0696d7;
            color: #0696d7;
        }

        /* Button enhancements */
        .btn-flat.btn-link:hover {
            background-color: rgba(6, 150, 215, 0.08);
        }

        .btn-inline {
            display: inline-flex;
        }

        .btn-red {
            background-color: rgb(241, 183, 179);
            border: 1px solid #f44336;
            color: white;
        }

        .btn-red:hover {
            border: 1px solid #f44336;
            background-color: transparent;
            color: #3c3c3c;
        }

        .btn-red.btn-flat {
            background-color: transparent;
            color: #3c3c3c;
            border: 1px solid #dcdcdc;
        }

        .btn-red.btn-flat:hover {
            background-color: transparent;
            color: #3c3c3c;
            border: 1px solid #f44336;
            box-shadow: 0px 0px 0px 1px #f44336;
        }

        .btn-center {
            justify-content: center;
        }

        .btn-icon {
            padding: 4px;
            border: 0 !important;
        }

        .btn-flat.btn-gray {
            background-color: #ffffff;
            border: 1px solid #f2f2f2;
            color: #0a131c;
        }

        .btn-flat.btn-gray:hover {
            background-color: transparent;
            border: 1px solid #0696d7;
        }

        /* Progress overrides */

        .progress .indeterminate {
            background-color: #0696d7;
        }

        /* Modal overrides */
        .modal.open {
            display: flex !important;
        }

        .modal-content {
            width: 100%;
        }

        /* chip overrides */

        .chip {
            background-color: #dcdcdc;
        }

        /* Filler */

        .filler {
            flex-grow: 1;
        }

        /* Arrows */

        .arrow {
            display: inline-block;
            border-style: solid;
            border-width: 2px 2px 0 0;
            height: 6px;
            width: 8px;
            color: #666666;
            padding: 3px;
            transition: all 0.2s ease;
        }

        .arrow.right {
            transform: rotate(45deg);
        }

        .arrow.down {
            transform: rotate(135deg);
        }

        .arrow.up {
            transform: rotate(-45deg);
        }

        .arrow.left {
            transform: rotate(-135deg);
        }

        /* Switch */

        .switch label input[type='checkbox']:checked + .lever {
            background-color: #e8f4fa;
        }

        .switch label .lever:before {
            background-color: #0696d7;
        }

        .switch label input[type='checkbox']:checked + .lever:after {
            background-color: #0696d7;
        }

        /* Others */

        .noselect {
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
        }

        /* Drag & Drop Helpers */

        .droppable-area {
            position: relative;
        }

        .drop-overlay {
            position: absolute;
            z-index: 10;
            background-color: #f0f7fb;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 2px solid #0696d7;
            opacity: 0.7;
            pointer-events: none;
        }

        /* Tealium - Hide default button */

        #adsk-eprivacy-privacy-settings-footer-container {
            display: none;
        }
    </style>
</svelte:head>
