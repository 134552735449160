<script>
    import config from 'app.config';
    import { i18n } from '../i18n';
    import { signIn, signedOut } from '../providers/state/user';
    import Footer from './footer.svelte';

    const dataCy = 'landing';
    const dataCyGetStarted = 'get-started';
</script>

<div data-cy={dataCy}>
    <div id="landing-sec1" class="landing-section columns">
        <div class="column hero-column">
            <section class="hero">
                <div class="hero-body">
                    <div class="container">
                        <h1 class="landing-title">
                            {i18n.t('App.Landing_Page.P1_Title')}
                        </h1>
                        <h2 class="landing-subtitle">
                            {i18n.t('App.Landing_Page.P1_Subtitle')}
                        </h2>
                        <button
                            class="button is-info get-started"
                            data-cy={dataCyGetStarted}
                            on:click={signIn}
                            disabled={!$signedOut}
                            >{i18n.t(
                                'App.New_Experience.Get_Started_Btn'
                            )}</button
                        >
                    </div>
                </div>
            </section>
        </div>
        <div class="column hero-img">
            <picture class="sec1-img">
                <source
                    type="image/webp"
                    srcset="DRIVE_ASSETS/images/landing/sec1-img.webp"
                />
                <source
                    type="image/jpeg"
                    srcset="DRIVE_ASSETS/images/landing/sec1-img.jpg"
                />
                <img
                    id="sec1-img"
                    src="DRIVE_ASSETS/images/landing/sec1-img.jpg"
                    alt={i18n.t('App.Landing_Page.P1_Title')}
                />
            </picture>
        </div>
    </div>

    <div id="landing-sec2" class="landing-section columns is-flex">
        <div class="column hero-img">
            <picture>
                <source
                    type="image/webp"
                    srcset="DRIVE_ASSETS/images/landing/sec2-img.webp"
                />
                <source
                    type="image/jpeg"
                    srcset="DRIVE_ASSETS/images/landing/sec2-img.jpg"
                />
                <img
                    id="sec2-img"
                    src="DRIVE_ASSETS/images/landing/sec2-img.jpg"
                    alt={i18n.t('App.Landing_Page.P2_Title')}
                />
            </picture>
        </div>
        <div class="column hero-column">
            <section class="hero">
                <div class="hero-body">
                    <div class="container">
                        <h1 class="landing-title">
                            {i18n.t('App.Landing_Page.P2_Title')}
                        </h1>
                        <h2 class="landing-subtitle">
                            {i18n.t('App.Landing_Page.P2_Subtitle')}
                        </h2>
                    </div>
                </div>
            </section>
        </div>
    </div>

    <div id="landing-sec3" class="landing-section columns">
        <div class="column hero-column">
            <section class="hero">
                <div class="hero-body">
                    <div class="container">
                        <h1 class="landing-title">
                            {i18n.t('App.Landing_Page.P3_Title')}
                        </h1>
                        <h2 class="landing-subtitle">
                            {i18n.t('App.Landing_Page.P3_Subtitle')}
                        </h2>
                    </div>
                </div>
            </section>
        </div>
        <div class="column hero-img">
            <picture>
                <source
                    type="image/webp"
                    srcset="DRIVE_ASSETS/images/landing/sec3-img.webp"
                />
                <source
                    type="image/jpeg"
                    srcset="DRIVE_ASSETS/images/landing/sec3-img.jpg"
                />
                <img
                    id="sec3-img"
                    src="DRIVE_ASSETS/images/landing/sec3-img.jpg"
                    alt={i18n.t('App.Landing_Page.P3_Title')}
                />
            </picture>
        </div>
    </div>

    <div id="landing-sec4" class="landing-section columns is-flex">
        <div class="column hero-img">
            <picture>
                <source
                    type="image/webp"
                    srcset="DRIVE_ASSETS/images/landing/sec4-img.webp"
                />
                <source
                    type="image/jpeg"
                    srcset="DRIVE_ASSETS/images/landing/sec4-img.jpg"
                />
                <img
                    id="sec4-img"
                    src="DRIVE_ASSETS/images/landing/sec4-img.jpg"
                    alt={i18n.t('App.Landing_Page.P4_Title')}
                />
            </picture>
        </div>
        <div class="column hero-column">
            <section class="hero">
                <div class="hero-body">
                    <div class="container">
                        <h1 class="landing-title">
                            {i18n.t('App.Landing_Page.P4_Title')}
                        </h1>
                        <h2 class="landing-subtitle">
                            {@html i18n.t('App.Landing_Page.P4_Subtitle', {
                                HTML_START:
                                    '<a href="' +
                                    config.links.desktopconnectorInstall +
                                    '" target="_blank">',
                                HTML_END: '</a>',
                            })}
                        </h2>
                    </div>
                </div>
            </section>
        </div>
    </div>

    <div id="landing-sec5" class="landing-section columns is-centered">
        <section class="hero">
            <div class="hero-body">
                <div class="container">
                    <h1 class="landing-title">
                        {i18n.t('App.Landing_Page.P5_Title')}
                    </h1>
                    <h2 class="landing-subtitle">
                        {i18n.t('App.Landing_Page.P5_Subtitle')}
                    </h2>
                    <button
                        class="button is-info get-started"
                        on:click={signIn}
                        disabled={!$signedOut}
                        >{i18n.t('App.New_Experience.Get_Started_Btn')}</button
                    >
                </div>
            </div>
        </section>
    </div>
</div>

<Footer />

<style>
    .landing-section {
        padding: 1% 10%;
        align-items: center !important;
        align-content: center !important;
    }
    #landing-sec2,
    #landing-sec4 {
        background-color: #f4f5f7;
        flex-wrap: wrap-reverse;
    }
    .landing-section .hero-column {
        min-width: 320px;
    }
    #landing-sec5 {
        text-align: center;
    }
    .column.hero-img {
        min-width: 320px;
    }
    .hero-body {
        padding: 1rem 0rem;
    }
    h1.landing-title,
    h2.landing-subtitle {
        letter-spacing: -0.03em;
    }
    h1.landing-title {
        font-weight: 600;
        font-size: 45px;
        line-height: 50px;
    }
    #landing-sec2 h1,
    #landing-sec3 h1,
    #landing-sec4 h1,
    #landing-sec5 h1 {
        font-size: 32px;
    }
    h2.landing-subtitle {
        font-size: 22px;
        font-weight: 200;
        padding: 28px 0 28px 0;
    }
    .get-started {
        width: 220px;
        height: 56px;
        font-size: 22px !important;
        font-weight: normal !important;
        background-color: #06a7df;
    }
</style>
