<script>
    export let fill = '#808080';
    export let height = 33;
    export let width = 33;
    export let mode = 'vertical';
</script>

<svg
    class={mode}
    {width}
    {height}
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1811 12.4879C17.2857 12.4879 18.1811 11.5925 18.1811
        10.4879C18.1811 9.38335 17.2857 8.48791 16.1811 8.48791C15.0765 8.48791
        14.1811 9.38335 14.1811 10.4879C14.1811 11.5925 15.0765 12.4879 16.1811
        12.4879Z"
        {fill}
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1811 18.4879C17.2857 18.4879 18.1811 17.5925 18.1811
        16.4879C18.1811 15.3833 17.2857 14.4879 16.1811 14.4879C15.0765 14.4879
        14.1811 15.3833 14.1811 16.4879C14.1811 17.5925 15.0765 18.4879 16.1811
        18.4879Z"
        {fill}
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1811 24.4879C17.2857 24.4879 18.1811 23.5925 18.1811
        22.4879C18.1811 21.3833 17.2857 20.4879 16.1811 20.4879C15.0765 20.4879
        14.1811 21.3833 14.1811 22.4879C14.1811 23.5925 15.0765 24.4879 16.1811
        24.4879Z"
        {fill}
    />
</svg>

<style>
    .horizontal {
        transform: rotate(90deg);
    }
</style>
