<script>
    export let parentFolderId = null;

    import {
        getPropValue,
        randomHex,
        invalidItemName,
    } from '../providers/utils';
    import ModalComponent from './elements/modal.svelte';
    import SomethingWentWrong from '../components/something.wrong.svelte';
    import { i18n } from '../i18n';
    import {
        actions,
        categories,
        trackEventWithDataType,
    } from '../providers/analytics/ga';
    import { FolderFactory } from '../rest.sdks';
    import FolderNewIcon from '../icons/folder.new.svelte';
    import CloseIcon from '../icons/close.svelte';
    import { gDriveInfo } from '../providers/state/user';

    const id = `new-folder-${randomHex()}`;
    const inputId = `folder_name_${id}`;
    const labelId = `folder_name_${id}_label`;
    const dataCy = 'new-folder';
    const dataCyDismiss = `${dataCy}-dismiss`;
    const dataCyLabel = `${dataCy}-label`;
    const dataCyInput = `${dataCy}-input`;
    const dataCyConfirm = `${dataCy}-confirm`;
    const dataCyError = `${dataCy}-error`;
    const assetType = i18n.t('App.Asset_Info_Summary.Folder_Asset_Type');
    const assetTypeLC = assetType.toLowerCase();

    let parentFolder = null;

    $: if (parentFolderId && $gDriveInfo) {
        parentFolder = FolderFactory(parentFolderId);
    }

    let folderName = null; // Non-error, initial state
    let modalInstance;
    let saveInProgress = false;
    let someError;

    $: namingIssue = invalidItemName(folderName);
    $: disabled = namingIssue || !folderName || saveInProgress;

    const createFolder = async () => {
        someError = null;
        saveInProgress = true;
        try {
            await parentFolder.new.folder(folderName.trim());
            modalInstance.close();
            trackEventWithDataType({
                category: categories.fileManagement,
                action: actions.folderCreateNew,
            });
            folderName = null; // Non-error, initial state
        } catch (err) {
            if (getPropValue(err, 'response.status') === 400) {
                namingIssue = 'InvalidOrReserved'; // Drive_Create_Folder - DriveError
            } else if (getPropValue(err, 'response.status') === 409) {
                namingIssue = 'Duplicate'; // Drive_Create_Folder - DriveError
            } else {
                someError = err;
            }
        }
        saveInProgress = false;
    };

    const options = {
        onCloseStart: () => {
            someError = null;
            saveInProgress = false;
            folderName = null; // Non-error, initial state
        },
    };

    const onEnter = (event) => {
        if (event.keyCode === 13 && !disabled) {
            createFolder(); // Enter
        }
    };
</script>

<ModalComponent
    {id}
    {options}
    {dataCy}
    bind:this={modalInstance}
    openModal={false}
>
    <div slot="trigger">
        <slot name="new-folder-trigger" />
        <slot name="trigger" />
    </div>
    <div
        class="columns has-text-weight-semibold has-all-space is-vertical-center is-marginless"
        slot="title"
    >
        <div class="column is-vertical-center is-paddingless">
            <span class="icon">
                <FolderNewIcon fill="#0696D7" />
            </span>
            <span class="has-ellipsis"
                >{i18n.t('App.Folder_Actions.Add_Folder_Title')}</span
            >
        </div>
        <div class="column is-narrow is-rightaligned is-paddingless">
            <span
                class="icon delete-icon is-pulled-right"
                data-cy={dataCyDismiss}
                on:click={modalInstance.close}
            >
                <CloseIcon />
            </span>
        </div>
    </div>
    <div slot="content">
        <label class="field" id={labelId} for={inputId} data-cy={dataCyLabel}>
            <span class="is-size-6">
                {i18n.t('App.Folder_Actions.New_Folder_Name_Label')}
            </span>
            <div class="is-gap" />
            <div class="control" class:is-loading={saveInProgress}>
                <input
                    class:is-danger={namingIssue}
                    class="input is-shadowless is-focus"
                    class:has-text-danger={namingIssue}
                    id={inputId}
                    data-cy={dataCyInput}
                    type="text"
                    on:keydown={onEnter}
                    bind:value={folderName}
                    placeholder={namingIssue
                        ? ''
                        : i18n.t('App.Folder_Actions.New_Folder_Placeholder')}
                />
            </div>
        </label>
        {#if namingIssue}
            <div class="is-gap" />
            <div class="has-text-grey-light" data-cy={dataCyError}>
                {i18n.t(`App.Naming_Issues.${namingIssue}`, {
                    assetType,
                    assetTypeLC,
                })}
            </div>
        {/if}
        {#if someError}
            <div class="is-gap" />
            <SomethingWentWrong
                error={someError}
                source={dataCy}
                inline={true}
            />
        {/if}
    </div>
    <span slot="footer-right-section">
        <button
            class="button"
            on:click={modalInstance.close}
            data-cy={`${dataCy}-cancel`}
        >
            {i18n.t('App.Primary_Buttons.Cancel_Btn')}
        </button>
        <button
            class="ga-{dataCyConfirm} button is-primary"
            on:click|stopPropagation={createFolder}
            class:is-loading={saveInProgress}
            data-cy={dataCyConfirm}
            {disabled}
        >
            {i18n.t('App.Primary_Buttons.Save_Btn')}
        </button>
    </span>
</ModalComponent>
