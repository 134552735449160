<script>
    export let assets = [];
    export let onClose = () => {};
    export let move = false;

    import FolderSelector from './folder.selector.svelte';
    import { notificationsStore } from '../../components/elements/notifications.store.js';
    import { i18n } from '../../i18n';
    import {
        actions,
        categories,
        trackEventWithDataType,
    } from '../../providers/analytics/ga';

    let sharedFolders = [];

    const strPrefix = `App.Asset_Actions.${move ? 'Move' : 'Copy'}`;

    const startNotification = (ITEM_COUNT) => {
        notificationsStore.add({
            type: 'info',
            title: `${strPrefix}_Notification_Title`,
            body:
                ITEM_COUNT === 1
                    ? `${strPrefix}_Single_Notification_Body`
                    : `${strPrefix}_Multiple_Notification_Body`,
            bodyParams: { ITEM_COUNT },
            timeout: 4000,
        });
    };

    const readyNotification = (SUCCESS_COUNT) => {
        notificationsStore.add({
            type: 'success',
            title: `${strPrefix}_Ready_Notification_Title`,
            body:
                SUCCESS_COUNT === 1
                    ? `${strPrefix}_Ready_Single_Notification_Body`
                    : `${strPrefix}_Ready_Multiple_Notification_Body`,
            bodyParams: { SUCCESS_COUNT },
            timeout: 6000,
        });
    };

    const errorNotification = (ITEM_COUNT, ERROR_COUNT) => {
        notificationsStore.add({
            type: 'danger',
            title: `${strPrefix}_Error_Notification_Title`,
            body:
                ERROR_COUNT === 1
                    ? `${strPrefix}_Error_Single_Notification_Body`
                    : `${strPrefix}_Error_Multiple_Notification_Body`,
            bodyParams: { ITEM_COUNT, ERROR_COUNT },
        });
    };

    const copy = async (folder) => {
        if (move) {
            sharedFolders = await getSharedFolders();

            if (sharedFolders.length > 0) {
                // Filter out shared folders
                assets = assets.filter((asset) => {
                    let index = sharedFolders.findIndex(
                        (sharedFolder) => sharedFolder.id === asset.id
                    );
                    return index === -1 ? true : false;
                });

                // Show error notification for shared folders
                notificationsStore.add({
                    type: 'danger',
                    body: 'App.Bulk_Actions.Items_Not_Moved',
                    bodyParams: { count: sharedFolders.length },
                });
            }
        }

        if (assets.length <= 0) {
            return;
        }

        startNotification(assets.length, move);

        await Promise.allSettled(
            assets.map(async (asset) => {
                return folder.copy(asset, move);
            })
        ).then((results) => {
            let errorCount = 0;
            results.forEach((result) => {
                if (result.status === 'rejected') {
                    errorCount += 1;
                }
            });
            if (assets.length - errorCount > 0) {
                readyNotification(assets.length - errorCount, move);
            }
            if (errorCount > 0) {
                errorNotification(assets.length, errorCount, move);
            }

            // Send GA Events
            if (move) {
                if (assets.length === 1) {
                    trackEventWithDataType({
                        category: categories.fileManagement,
                        action: assets[0].isFile
                            ? actions.fileMove
                            : actions.folderMove,
                    });
                } else {
                    trackEventWithDataType({
                        category: categories.fileManagement,
                        action: actions.bulkMove,
                        value: assets.length, // # of top-level items only
                    });
                }

                if (errorCount > 0) {
                    trackEventWithDataType({
                        category: categories.errorDrive,
                        action: actions.moveError,
                        value: errorCount,
                    });
                }
            } else {
                if (assets.length === 1) {
                    trackEventWithDataType({
                        category: categories.fileManagement,
                        action: assets[0].isFile
                            ? actions.fileCopy
                            : actions.folderCopy,
                    });
                } else {
                    trackEventWithDataType({
                        category: categories.fileManagement,
                        action: actions.bulkCopy,
                        value: assets.length, // # of top-level items only
                    });
                }

                if (errorCount > 0) {
                    trackEventWithDataType({
                        category: categories.errorDrive,
                        action: actions.copyError,
                        value: errorCount,
                    });
                }
            }
        });
    };

    const getSharedFolders = async () => {
        let sharedFolders = [];
        const folders = assets.filter((asset) => asset.type === 'folder');
        const shareInfoResponses = (
            await Promise.all(folders.map((folder) => folder.sharing.info()))
        ).map((shareInfo) => shareInfo.length > 0);
        sharedFolders = folders.filter(
            (_, index) => shareInfoResponses[index] === true
        );
        return sharedFolders;
    };
</script>

<FolderSelector
    doNotAllow={assets}
    {onClose}
    operation={copy}
    executeType={move ? 'move' : 'copy'}
>
    <span slot="action-name">
        {i18n.t(
            move
                ? 'App.Asset_Actions.Move_Action'
                : 'App.Asset_Actions.Copy_Action'
        )}
    </span>
</FolderSelector>
