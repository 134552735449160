import { writable, derived } from 'svelte/store';

export const SHARED_WITH_ME_PATH = 'shared';

export const currentFolder = (() => {
    let _value = null;
    const { set: _set, subscribe } = writable(_value);

    return {
        set: (value) => {
            _value = value;
            _set(value);
        },
        subscribe, // must expose
        getValue: () => _value,
    };
})();

export const isDisplayingSharedFolders = derived(
    currentFolder,
    ($currentFolder) =>
        $currentFolder && $currentFolder.id === SHARED_WITH_ME_PATH
);

export const clearState = () => {
    currentFolder.set(null);
};

export default {
    currentFolder,
    clearState,
};
