<script>
    import Banner from '../elements/banner.svelte';
    import { i18n } from '../../i18n';

    const dataCy = 'system-outage-banner';
    const type = 'danger';
    let show = true;

    const options = {
        showBanner: () => {
            show = true;
        },
        hideBanner: () => {
            show = false;
        },
    };
</script>

<Banner {type} {options} {show} {dataCy}>
    <div slot="title" class="is-vertical-center">
        {i18n.t('App.Misc.System_Outage_Banner_Msg')}
    </div>
</Banner>
