import moment from 'moment';
import { resetCreateFolderCache } from './s3.upload';

export { resetCreateFolderCache }; // Hide s3.upload from consumers

export const chunkSize = 5242880; // Bytes
export const defaultS3LinkExpiry = 300000; // 5 minutes
export const maxUploadChunkConcurrency = 6; // Limiting total memory usage at any time to around 30 MB (uploadChunk * 6);

export const slice = (file, start, end) => {
    if (file.slice) {
        return file.slice(start, end);
    } else if (file.webkitSlice) {
        return file.webkitSlice(start, end);
    } else if ('mozSlice' in file) {
        return file.mozSlice(start, end);
    }
};

export const split = (path, separator = '.', escape = '\\') => {
    if (!path) return [];
    if (Array.isArray(path)) return path;
    const keys = [];
    let key = '';
    for (let i = 0, l = path.length; i < l; ++i) {
        const charKey = path[i];
        if (charKey === separator && path[i - 1] !== escape) {
            if (key.length > 0) {
                keys.push(key);
            }
            key = '';
        } else {
            if (charKey !== escape) {
                key += path[i];
            }
        }
    }
    if (key.length > 0) {
        keys.push(key);
    }
    return keys;
};

export const getSliceRanges = (size) => {
    const range = [];
    let start = 0;
    let uploadPart = 0;
    while (start < size) {
        const end = start + chunkSize;
        uploadPart++;
        range.push({
            start,
            end: Math.min(end, size),
            uploadPart, // Used for direct S3 upload
        });
        start = end;
    }
    return range;
};

export const fileName = (file) => {
    let path = split(file.name, '/').reverse();
    const name = path[0];
    return name;
};

export const isS3SignedUrlExpired = (url) => {
    const _url = new URL(url);
    const queryString = _url.search;
    const queryParams = new URLSearchParams(queryString);
    const amzDate = moment(
        queryParams.get('X-Amz-Date'),
        'YYYYMMDDHHmmssZ'
    ).valueOf(); // ms
    const amzExpiresIn = Number(queryParams.get('X-Amz-Expires')) * 1000; // ms
    const expiry = amzDate + amzExpiresIn;
    return expiry < Date.now();
};

export const getExpiryInMinutes = (ms) => ms / 1000 / 60;

export default {
    chunkSize,
    slice,
    getSliceRanges,
};
