<script>
    export let collectionId;
    export let file;
    export let viewable;
    export let canDownload;
    export let password;

    import AutodeskRecapComponent from './recap.viewer.svelte';
    import AutodeskViewerComponent from './lmv.viewer.svelte';
    import ImageViewer from './image.viewer.svelte';
    import NotSupportedFileComponent from './not.supported.svelte';
    import PDFViewer from './pdf.viewer.svelte';
    import TextViewer from './text.viewer.svelte';
    import VideoViewer from './video.viewer.svelte';

    const viewerOptions = {
        audio: { component: VideoViewer, props: { viewable } },
        image: { component: ImageViewer, props: { viewable, file } },
        lmv: {
            component: AutodeskViewerComponent,
            props: { viewable, file, collectionId, password },
        },
        // Disable office support before DMS provides a PDF wrapper similar to ConnectAPI
        // Or until we can use Office 365 SDK
        // office: { component: PDFViewer, props: { viewable } },
        pdf: { component: PDFViewer, props: { viewable } },
        recap: { component: AutodeskRecapComponent, props: { viewable, file } },
        text: { component: TextViewer, props: { viewable } },
        unknown: {
            component: NotSupportedFileComponent,
            props: { viewable, file, canDownload },
        },
        video: { component: VideoViewer, props: { viewable } },
    };

    let selectedViewer;

    $: {
        if (viewable) {
            selectedViewer = viewerOptions[viewable.viewableType];
        }
    }
</script>

{#if selectedViewer}
    <svelte:component
        this={selectedViewer.component}
        {...selectedViewer.props}
    />
{/if}
