import { drive2 } from '../request';
import S3Upload from './s3.upload';
import { fileName } from './upload.utils';
import { getPropValue, randomHex } from '../../providers/utils';
import {
    actions,
    categories,
    trackEventWithDataType,
    trackUploadInfo,
} from '../../providers/analytics/ga';
import { getExtension } from '../utils.js';
import asyncify from 'async/asyncify';
import queue from 'async/queue';

// File uploads occur in 4 steps:
// -- -------------------------------
// 1) Drive_Get_Create_Folder_by_Path
// 2) Drive2_Upload_File_Start
// 3.1) Drive2_Get_Signed_Upload_URLs
// 3.2) S3_Put_Upload(s)
// 4) Drive2_Upload_File_Complete
//
// See also:
//  https://wiki.autodesk.com/pages/viewpage.action?spaceKey=FCPA&title=Autodesk+Drive+Microservice+-+Milestone+1
//  https://stg.forge.autodesk.com/en/docs/data/v2/tutorials/upload-file/
//

// Let's create a Queue with a concurrency of 6
// and process maximum of six files at a time
const uploadQueue = queue(
    asyncify(async (task) => {
        await task();
    }),
    6
);

const UploadFactory = (file, parentFolder, collectionId, parentFile) => {
    const onSuccess = {};
    const onFailed = {};
    const onProgress = {};
    const onCancelled = {};
    const onStarted = {};
    const onPaused = {};

    const state = {
        file,
        uploadType: 'file',
        paused: false,
        cancelled: false,
        failed: false,
        error: null, // nested exception if failed
        progress: 0,
        result: null,
        success: false,
        saving: false,
        uploading: false,
        tokenObj: null,
        isVersionUpload: !!parentFile,
        startTime: Date.now(),
        startTimeUpload: null,
        endTimeUpload: null,
        started: false, // Detect unexpected duplicate starts
        get inprogress() {
            return this.uploading || this.saving;
        },
        set inprogress(val) {
            this.uploading = val;
            this.failed = false;
            this.paused = !val;
            this.cancelled = !val;
        },
        collectionId,
        parentFile,
        parentFolder,
    };

    const triggerSuccess = () => {
        for (let k in onSuccess) {
            onSuccess[k](state.result);
        }
        triggerProgress();
    };

    const triggerFailed = () => {
        for (let k in onFailed) {
            onFailed[k](state);
        }
        triggerProgress();
    };

    const triggerProgress = () => {
        for (let k in onProgress) {
            onProgress[k](state);
        }
    };

    const triggerCancelled = () => {
        for (let k in onCancelled) {
            onCancelled[k](state);
        }
        triggerProgress();
    };

    const triggerStarted = () => {
        state.inprogress = true;
        for (let k in onStarted) {
            onStarted[k](state);
        }
        triggerProgress();
    };

    const triggerPaused = () => {
        for (let k in onPaused) {
            onPaused[k](state);
        }
        triggerProgress();
    };

    const markSuccess = () => {
        state.success = true;
        triggerSuccess();
    };

    const markFailed = () => {
        state.failed = true;
        triggerFailed();
    };

    // Upload Step (V2) 4/4
    const uploadFileComplete2 = async () => {
        try {
            if (state.cancelled) {
                throw new Error('Upload was cancelled');
            }
            if (state.failed) {
                throw new Error('Upload failed');
            }
            const uploadKey = getPropValue(
                state,
                'tokenObj.data.attributes.uploadKey'
            );

            const payload = {
                data: {
                    type: 'FileUploadCompleteV2RequestInfo',
                    attributes: {
                        folderId: state.parentFolder.id,
                        fileName: fileName(state.file),
                        fileSize: state.file.size,
                        uploadKey,
                    },
                },
            };
            const options = {
                method: 'post',
                body: JSON.stringify(payload),
            };
            const storageId = getPropValue(state, 'tokenObj.data.id');
            const url = `collections/${state.collectionId}/files/${storageId}/upload:complete`;

            // Drive2_Upload_File_Complete
            const response = await drive2(url, options);

            const newFileId = getPropValue(response, 'data.id');
            const attributes = getPropValue(response, 'data.attributes');
            if (!newFileId || !attributes) {
                throw new Error(
                    `Unexpected response from Drive2_Upload_File_Complete`
                );
            }

            response.lineageUrn = newFileId;
            // Pluck out the extension, do not use the mimeType
            response.fileType = getExtension(attributes.name);
            response.fileSize = attributes.size; // string for Fusion
            response.parents = attributes.path
                .map((parent) => parent.id)
                .reverse();

            state.result = response;
            return state.result;
        } catch (error) {
            trackUploadInfo('uploadFileComplete', error);

            state.failed = true;
            state.error = error; // Provide exception outside of this queue
            return false;
        }
    };

    const s3Upload = S3Upload(file, state.parentFolder, state.collectionId, {
        onProgress: (percent) => {
            state.progress = Math.floor(percent);
            triggerProgress();
        },
        // TODO: Decide to use this pattern or just exceptions
        // TODO: Never called
        onFailed: () => {
            state.failed = true;
            triggerFailed();
        },
    });

    const _upload = async () => {
        try {
            state.inprogress = true;
            let newState;
            triggerStarted(); // Sends FileUploadStart GA Event

            newState = await s3Upload.start(); // Returns new state

            state.tokenObj = newState.tokenObj;
            state.parentFolder = newState.parentFolder; // May have changed
            state.uploading = false;
            state.saving = true;
            // Start of first chunk upload, for empty files it might be null
            state.startTimeUpload = s3Upload.info().startTime || Date.now();

            await uploadFileComplete2();

            state.endTimeUpload = Date.now();
            const timeTaken = state.endTimeUpload - state.startTimeUpload;
            // log time taken by Upload
            trackEventWithDataType({
                category: categories.upload,
                action: actions.fileUploadTimeTaken,
                label: {
                    type: getExtension(state.file.name),
                    size: state.file.size,
                    uploadType: s3Upload.uploadType,
                },
                value: timeTaken,
            });

            triggerProgress();
            if (state.failed) {
                // TODO: How is this handled???
                throw state.error
                    ? state.error
                    : new Error('UploadFactory.save() failed');
            } else {
                markSuccess();
            }
            return state.result;
        } catch (error) {
            // Extra forensics logging
            trackUploadInfo('_upload', error);

            // Save the exception for outside this queue
            state.error = error;
        }
    };

    const api = {
        uploadType: 'file',
        path: s3Upload.info().path,
        info() {
            const metadata = s3Upload.info();
            Object.assign(metadata, state); // Copy over
            metadata.uploadType = 'file';
            return metadata;
        },
        markStarted() {
            state.started = true; // Detect unexpected duplicate starts
        },
        start: async () => {
            // Check for errors before s3Upload.start()
            if (state.file.errorType) {
                markFailed();
                return;
            }
            await uploadQueue.push(_upload);
            // Check for error condition
            // If it's cancelled by user, don't mark it as failed.
            if (state.error && !state.cancelled) {
                markFailed();
                throw state.error;
            }
        },
        pause: () => {
            if (state.paused) return;
            state.paused = true;

            s3Upload.pause();

            triggerPaused();
        },
        resume: () => {
            if (!state.paused) return;
            state.paused = false;
            api.start();
        },
        cancel: () => {
            state.cancelled = true;
            s3Upload.cancel();

            triggerCancelled();
        },
        observe({ success, failed, progress, cancel, start, paused }) {
            const key = randomHex();
            if (success) {
                onSuccess[key] = success;
            }
            if (failed) {
                onFailed[key] = failed;
            }
            if (progress) {
                onProgress[key] = progress;
                triggerProgress();
            }
            if (cancel) {
                onCancelled[key] = cancel;
            }
            if (start) {
                onStarted[key] = start;
            }
            if (paused) {
                onPaused[key] = paused;
            }
            return {
                cancel() {
                    delete onSuccess[key]; // undefined now
                    delete onFailed[key];
                    delete onProgress[key];
                    delete onCancelled[key];
                    delete onStarted[key];
                    delete onPaused[key];
                },
            };
        },
    };
    return api;
};

export default UploadFactory;
