<script>
    export let viewable = null;

    const name = viewable.id;
    const id = `${name}-text`;
    const dataCy = 'text-viewer';
</script>

<div class="office-viewer has-all-space" {id} data-cy={dataCy}>
    <object
        title={viewable.name}
        data={viewable.viewingUrl}
        type="text/plain"
        style="width: 100%; height: 100%;"
    />
</div>
