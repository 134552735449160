<script>
    export let viewable = null;

    import PDFObject from 'pdfobject';
    import { onDestroy, onMount } from 'svelte';

    const name = viewable.id;
    const id = `${name}-office`;
    const dataCy = 'pdf-viewer';

    let officeViewerInstance;

    const initializeOfficeViewer = (url) => {
        const target = document.getElementById(id);
        officeViewerInstance = PDFObject.embed(url, target);
    };

    onDestroy(() => {
        // if (officeViewerInstance) {
        //     officeViewerInstance.destroy();
        // }
    });

    onMount(() => {
        initializeOfficeViewer(viewable.viewingUrl);
    });
</script>

<div class="office-viewer has-all-space" {id} data-cy={dataCy}>
    <span />
</div>
