<script>
    export let fill = '#4d4d4d';
    export let height = 17;
    export let width = 17;
</script>

<svg
    {width}
    {height}
    viewBox="0 0 {width}
    {height}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5593 1.54093L16.1393 3.12093C16.3286 3.3087 16.4351 3.56429
        16.4351 3.83093C16.4351 4.09757 16.3286 4.35316 16.1393 4.54093L14.6993
        5.98093L11.6993 2.98093L13.1493 1.54093C13.5393 1.15321 14.1692 1.15321
        14.5593 1.54093ZM2.84928 11.8309L10.9993 3.68093L13.9993 6.68093L5.84928
        14.8309L1.38928 16.6109C1.29813 16.6438 1.19619 16.6211 1.12767
        16.5525C1.05916 16.484 1.0364 16.3821 1.06928 16.2909L2.84928 11.8309Z"
        {fill}
    />
    <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="16"
        height="16"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.5593 1.54093L16.1393 3.12093C16.3286 3.3087 16.4351 3.56429
            16.4351 3.83093C16.4351 4.09757 16.3286 4.35316 16.1393
            4.54093L14.6993 5.98093L11.6993 2.98093L13.1493 1.54093C13.5393
            1.15321 14.1692 1.15321 14.5593 1.54093ZM2.84928 11.8309L10.9993
            3.68093L13.9993 6.68093L5.84928 14.8309L1.38928 16.6109C1.29813
            16.6438 1.19619 16.6211 1.12767 16.5525C1.05916 16.484 1.0364
            16.3821 1.06928 16.2909L2.84928 11.8309Z"
            fill="white"
        />
    </mask>
</svg>
