import Uppie from 'uppie';
import { groupFiles, checkFileForErrors } from './upload.utils';

const uppie = new Uppie();

let bodyEventRegistered;

const stopPropagation = (event) => {
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }
};

export function droparea(element, options = {}) {
    const node = options.target || element;
    node.classList.add('droppable-area');

    const overlay = document.createElement('div');
    overlay.classList.add('drop-overlay');

    // dragleave event is fired when hovering over children. Counter is a guard.
    let counter = 0;
    const addDragOverlay = (event) => {
        counter++;
        if (overlay && !overlay.parentNode) {
            node.appendChild(overlay);
        }
        stopPropagation(event);
    };

    const removeOverlay = (event) => {
        counter--;
        if (overlay && overlay.parentNode && counter === 0) {
            node.removeChild(overlay);
        }
        stopPropagation(event);
    };

    node.addEventListener('dragenter', addDragOverlay);
    node.addEventListener('dragleave', removeOverlay);
    node.addEventListener('drop', removeOverlay);

    // If drop listener already registered on body avoid calling it again
    // as no way to clean up listeners created by uppie.
    if (!(element === document.body && bodyEventRegistered)) {
        uppie(node, function (event, formData) {
            stopPropagation(event);

            let files = [];
            for (let [, file] of formData.entries()) {
                files.push(checkFileForErrors(file));
            }

            const groups = groupFiles(files);
            const individualFiles = [];
            for (let id in groups) {
                if (Array.isArray(groups[id])) {
                    element.dispatchEvent(
                        new CustomEvent('files', {
                            detail: {
                                files: groups[id],
                                type: 'folder',
                            },
                        })
                    );
                } else {
                    individualFiles.push(groups[id]);
                }
            }

            if (individualFiles.length > 0) {
                element.dispatchEvent(
                    new CustomEvent('files', {
                        detail: {
                            files: individualFiles,
                            type: 'files',
                        },
                    })
                );
            }
        });

        // Set up a flag after uppie registered 'drop' event on body
        if (element === document.body) {
            bodyEventRegistered = true;
        }
    }

    return {
        destroy() {
            node.removeEventListener('dragenter', addDragOverlay, false);
            node.removeEventListener('dragleave', removeOverlay, false);
            node.removeEventListener('drop', removeOverlay, false);

            node.classList.remove('droppable-area');
            removeOverlay();
            // Clean up not required for uppie.
        },
    };
}

export default {
    droparea,
};
