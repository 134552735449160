import i18next from 'i18next';
import queryString from 'query-string';
import locale from 'browser-locale';

import { writable } from 'svelte/store';

const I18nService = (config, i18nStrings, gLocation = location) => {
    const language = writable(config.i18n.defaultLanguage);

    // Fallback language
    const defaultLanguage = config.i18n.defaultLanguage;
    const supportedLanguages = Object.keys(i18nStrings);
    let detectedLanguage = '';
    let detectedLanguageOrig = '';

    const storeLanguage = (lang) => {
        language.set(lang || detectedLanguage);
    };

    const processLanguage = (language) => {
        if (!language) {
            return language;
        }
        language = language.toLowerCase();
        // Relying on just the first two letters as general languages
        const isChineseRoot = language.indexOf('zh') === 0;
        const isPseudo = language.indexOf('pseudo') === 0;
        if (!isChineseRoot && !isPseudo && language.length > 2) {
            language = language.substring(2, 0);
        }
        return language;
    };

    const detectLanguage = () => {
        let language = '';
        var sources = config.i18n.languageSources || [];
        sources.some((source) => {
            if (source == 'querystring') {
                language = queryString.parse(gLocation.search)[
                    config.i18n.queryStringKey
                ];
            } else if (source == 'navigator') {
                language = locale();
            }
            return language;
        });
        detectedLanguageOrig = language;
        if (!language) {
            return defaultLanguage;
        }
        language = processLanguage(language);
        return language;
    };

    detectedLanguage = detectLanguage();
    storeLanguage(detectedLanguage);

    i18next.init({
        resources: i18nStrings,
        fallbackLng: config.i18n.fallbackLng,
        whitelist: supportedLanguages,
        lng: detectedLanguage.replace('-', '_'),
        keySeparator: '.',
        ns: ['translations'],
        defaultNS: 'translations',
    });

    // Used by UserVoice
    const currentLanguage = () => {
        return {
            processed: detectedLanguage,
            original: detectedLanguageOrig,
        };
    };

    let i18n = i18next; // Simpiler alias, add helper

    // i18n helper:
    i18n.displayFullName = (user) => {
        if (user) {
            const firstName = user.firstName;
            const lastName = user.lastName;

            return i18n.t('App.User_Content.User_Full_Name_Display', {
                FIRST_NAME: firstName,
                LAST_NAME: lastName,
            });
        }
    };

    // Return translation if it exists, else default
    i18n.find = (id, defaultId, ...params) => {
        const translation = i18n.t(id, ...params);
        if (translation === id) {
            // Not found, use default
            return { id: defaultId, translation: i18n.t(defaultId, ...params) };
        } else {
            return { id, translation };
        }
    };

    return [currentLanguage, i18n];
};

export { I18nService };
