<script>
    export let uploadObject = {};
    export let uploads = [];
    export let onCancel = () => {};

    import { randomHex } from '../../providers/utils';
    import ModalComponent from '../elements/modal.svelte';
    import UploadItem from './upload.item.svelte';
    import { i18n } from '../../i18n';

    const id = `uploads-view-${randomHex()}`;
    const dataCy = 'uploads-view';

    let modalInstance;

    const cancel = () => {
        onCancel();
        modalInstance.close();
    };

    $: {
        if (modalInstance && Object.keys(uploadObject).length === 0) {
            cancel();
        }
    }
    const options = {
        // only toggle visibility when modal is dismissed by an outside click
        onCloseEnd: () => {
            onCancel();
        },
    };
</script>

<ModalComponent {id} {dataCy} {options} bind:this={modalInstance}>
    <span slot="title">{i18n.t('App.Upload_Actions.Upload_Dialog_Title')}</span>
    <div slot="content">
        {#each uploads as upload}
            <div class="is-gap" />
            <UploadItem {upload} />
        {/each}
    </div>
    <button
        slot="footer-right-section"
        class="button is-fullwidth is-primary"
        data-cy="{dataCy}-close"
        on:click={cancel}
    >
        {i18n.t('App.Primary_Buttons.Close_Btn')}
    </button>
</ModalComponent>
