<script>
    import ModalComponent from './elements/modal.svelte';
    import { gEntitled, profile } from '../providers/state/user';
    import store from 'store2';
    import { i18n } from '../i18n';
    import CloseIcon from '../icons/close.svelte';

    const dataCy = 'first-timer';
    const dataCyDismiss = `${dataCy}-dismiss`;

    let modalInstance;
    let userStorage;

    const turnOff = () => {
        store.add($profile.userId, { notFirstTimer: true });

        // We may not want to show PFS intro modal ever for users who sign up after the PFS rollout
        // i.e. Once they dismiss the Welcome modal, don't show PFS intro on their subsequent login
        store.add($profile.userId, { showFolderShareIntro: false });

        modalInstance.close();
    };

    $: if ($profile) {
        userStorage = store($profile.userId) || {};
    }

    const messages = i18n.t('App.New_Experience.Welcome_Summary', {
        returnObjects: true,
    });
</script>

{#if userStorage && !userStorage.notFirstTimer && $gEntitled}
    <ModalComponent {dataCy} hasMinHeight={true} bind:this={modalInstance}>
        <div
            class="columns has-all-space is-size-6 is-vertical-center is-four-fifths is-marginless"
            slot="title"
        >
            <div class="is-vertical-center is-paddingless">
                {i18n.t('App.New_Experience.Welcome_Msg')}
            </div>
            <div class="column is-paddingless">
                <span
                    class="icon delete-icon is-pulled-right ml-2 mr-0"
                    data-cy={dataCyDismiss}
                    on:click={turnOff}
                >
                    <CloseIcon />
                </span>
            </div>
        </div>
        <div slot="content">
            <p>
                {messages[0]}
            </p>
            <div class="is-gap is-medium" />
            <p>
                {messages[1]}
            </p>
        </div>
        <div slot="footer-right-section" class="level-item">
            <button
                class="button is-primary"
                on:click={turnOff}
                data-cy={dataCyDismiss}
            >
                {i18n.t('App.Primary_Buttons.OK_Btn')}
            </button>
        </div>
    </ModalComponent>
{/if}
