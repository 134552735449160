<script>
    export let user = null;
    export let folderShareInProgress = false;

    import UserAvatar from '../../user.avatar.svelte';
    import SharePermissionsComponent from './folder.share.permissions.svelte';
    import { i18n } from '../../../i18n';
    import { profile } from '../../../providers/state/user';
    const dataCyUserCardShare = 'folder-share-user-card';
</script>

<div
    class="columns is-mobile is-vcentered is-marginless"
    data-cy={dataCyUserCardShare}
>
    <div class="column is-narrow is-paddingless">
        <UserAvatar userProfile={user} />
    </div>
    <div class="column has-ellipsis">
        <div class="is-size-6 has-ellipsis">
            {i18n.displayFullName(user)}
            {$profile.userId === user.userId
                ? i18n.t('App.Folder_Sharing.Owner_Me')
                : ''}
        </div>
        <div class="is-size-7 has-ellipsis">{user.emailId}</div>
    </div>
    <div class="column is-narrow is-paddingless">
        {#if user.isOwner}
            <span class="is-size-6">{i18n.t('App.Folder_Sharing.Owner')}</span>
        {:else}
            <SharePermissionsComponent
                sharee={user}
                isManage="true"
                {folderShareInProgress}
                on:changePermission
            />
        {/if}
    </div>
</div>
