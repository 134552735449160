<script>
    export let loginRequired = true;

    import config from 'app.config';
    import { onMount } from 'svelte';
    import { initAuthFlow } from '../providers/pkce-auth';

    onMount(async () => {
        // window.top also works but Cypress tests fails as Cypress loads our app inside the iframe
        const topWindow = window.parent;
        const iframeSelector = topWindow.document.body.querySelector('iframe');
        const oAuthIframeExists = iframeSelector
            ? iframeSelector.src.includes(config.servers.authentication)
            : false;

        if (!oAuthIframeExists) {
            await initAuthFlow(loginRequired);
        }
    });
</script>
