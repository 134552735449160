<script>
    export let asset;
    export let createdBy = {};

    import UserAvatar from '../../user.avatar.svelte';
    import FolderShareComponent from '../folder.share.svelte';
    import SomethingWentWrong from '../../something.wrong.svelte';
    import { i18n } from '../../../i18n';
    import Spinner from '../../spinner.svelte';
    import { onDestroy } from 'svelte';
    import { gEntitled } from '../../../providers/state/user';

    const dataCy = 'folder-shared-with';

    const getUserInfo = ({ firstName, lastName, userId }) => ({
        firstName,
        lastName,
        userId,
    });

    let canShare = asset.canShare,
        getShareInfo = Promise.resolve([]);

    let observers = asset.observe({
        share: () => {
            getShareInfo = asset.sharing.info();
        },
    });

    let enableShare = false;
    const onShareDialogClose = () => {
        enableShare = false;
    };

    onDestroy(() => {
        if (observers) {
            observers.cancel();
        }
    });

    $: {
        canShare = asset.canShare;
        // If folder is not shared, avoid call to Drive_Get_Private_Share
        getShareInfo = asset.isFolderShared
            ? asset.sharing.info()
            : Promise.resolve([]);
    }
</script>

<div class="content">
    {#await getShareInfo}
        <Spinner name={dataCy} />
    {:then shareInfo}
        {#if canShare}
            <div class="shared-with-section" data-cy="info-shared-with">
                <div class="is-size-7 has-text-weight-semibold">
                    {i18n.t('App.Folder_Sharing.Shared_With_Heading')}
                </div>
                <div class="is-gap" />
                {#if shareInfo.length > 0}
                    <div class="columns is-mobile">
                        <div class="column is-two-thirds">
                            <div class="field is-grouped" data-cy={dataCy}>
                                <p class="control">
                                    <UserAvatar
                                        userProfile={getUserInfo(createdBy)}
                                    />
                                </p>

                                <p class="control has-left-border second-user">
                                    <UserAvatar
                                        userProfile={getUserInfo(shareInfo[0])}
                                    />
                                </p>
                                {#if shareInfo.length > 1}
                                    <p
                                        class="control is-vertical-center remaining-users"
                                    >
                                        <span
                                            class="tag is-dark is-rounded
                    has-text-weight-semibold"
                                        >
                                            {shareInfo.length - 1}
                                        </span>
                                    </p>
                                {/if}
                            </div>
                        </div>
                        <div class="column is-paddingless is-rightaligned">
                            {#if $gEntitled}
                                <button
                                    class="is-rightaligned is-size-6-7 has-text-weight-semibold button is-ghost"
                                    on:click={() =>
                                        (enableShare = !enableShare)}
                                    data-cy="{dataCy}-manage"
                                    >{i18n.t(
                                        'App.Folder_Sharing.Manage_Shared_With_Btn'
                                    )}</button
                                >
                            {/if}
                        </div>
                    </div>
                {:else}
                    <div class="columns is-mobile">
                        <div class="column is-two-thirds">
                            <div class="subtitle is-7">
                                {i18n.t(
                                    'App.Folder_Sharing.Folder_Not_Shared_Text'
                                )}
                            </div>
                        </div>
                        <div class="column is-paddingless is-rightaligned">
                            {#if $gEntitled}
                                <button
                                    class="is-rightaligned is-size-6-7 has-text-weight-semibold button is-ghost"
                                    on:click={() =>
                                        (enableShare = !enableShare)}
                                    data-cy="{dataCy}-share"
                                    >{i18n.t(
                                        'App.Folder_Content_List.Share_Folder_Action'
                                    )}</button
                                >
                            {/if}
                        </div>
                    </div>
                {/if}
            </div>
        {/if}
    {:catch error}
        <SomethingWentWrong {error} source={dataCy} />
    {/await}
    {#if enableShare}
        <FolderShareComponent {asset} onClose={onShareDialogClose} />
    {/if}
</div>

<style>
    .second-user {
        padding-left: 5px;
    }

    .field.is-grouped > .control:not(:last-child) {
        margin-right: 0.25rem;
    }

    .content {
        min-width: 40px;
    }

    .remaining-users span {
        height: 32px;
        width: 32px;
    }
    .shared-with-section {
        padding: 10px;
    }
</style>
