<script>
    import page from 'page';
    import config from 'app.config';
    import Header from './header.svelte';
    import Protected from '../components/protected.svelte';
    import CSSOverrides from '../components/elements/css.overrides.svelte';
    import ScreenSizeDetector from '../components/responsiveness.helpers/screen.size.detector.svelte';
    import Landing from './landing.svelte';
    import NotFound from '../components/something.wrong.svelte';
    import SystemOutageBanner from '../components/banners/system-outage.banner.svelte';
    import Home from './home.svelte';
    import FileViewer from './file.viewer.svelte';
    import SharedFileViewer from './shared.file.viewer.svelte';
    import ThirdpartyComponent from './thirdparty.svelte';
    import Auth from '../components/pkce.auth.svelte';
    import UservoiceWidget from '../components/uservoice.widget.svelte';
    import { trackPage } from '../providers/analytics/ga';
    import { DriveError, URLSafeBase64 } from '../rest.sdks';
    import { initializeLaunchDarkly } from '../providers/launchdarkly';
    import { exposeCypressHooks } from '../providers/cypress.hooks';
    import { gHasSystemOutage } from '../providers/state/user';
    import Maintenance from './maintenance.svelte';

    // One time initialization...
    DriveError.addEventsListeners(); // Add global error event listeners

    let maintenanceMode = false;

    initializeLaunchDarkly().then((mode) => {
        maintenanceMode = mode;
    });

    exposeCypressHooks(); // For Cypress Test Access

    let state = {
        component: null,
        props: {},
        loginRequired: false,
    };

    const releaseConfig = config.release;
    const thirdpartyPath = `/${releaseConfig.thirdpartyLicensesLoc}`;

    const landingPage = (props) => {
        state = {
            component: Landing,
            loginRequired: false,
            props,
        };
    };

    const notFoundPage = (props) => {
        // Custom config for NotFoundPage
        props = {
            ...props,
            error: new Error(`Invalid Page: ${props[0] || ''}`),
            source: 'page-not-found',
        };

        state = {
            component: NotFound,
            loginRequired: true,
            props,
        };
    };

    const homePage = (props) => {
        state = {
            component: Home,
            loginRequired: true,
            props,
        };
    };

    const thirdpartyPage = (props) => {
        state = {
            component: ThirdpartyComponent,
            loginRequired: false,
            props,
        };
    };

    const publicSharePage = (props) => {
        state = {
            component: SharedFileViewer,
            loginRequired: false,
            props,
        };
    };

    const fileViewingPage = (props) => {
        state = {
            component: FileViewer,
            loginRequired: true,
            props,
        };
    };

    const extractParams = (fn) => {
        return (ctx) => {
            const params = ctx && ctx.params ? ctx.params : {};
            try {
                if (params.folderId || params.fileId) {
                    URLSafeBase64.decode(params.folderId || params.fileId);
                }
                fn(params);
            } catch (error) {
                // If invalid base64 encoding for folderId/fileId, render not-found page.
                notFoundPage(params);
            }
        };
    };

    const trackVirtualPageview = () => {
        return (ctx, next) => {
            if (ctx.params[0]) {
                trackPage({
                    page: ctx.params[0],
                });
            }
            next();
        };
    };

    page('*', trackVirtualPageview());
    page('/', extractParams(landingPage));
    page('/login', extractParams(landingPage));

    page(thirdpartyPath, extractParams(thirdpartyPage));

    // Redirect from /new or /old to /
    // This should be removed when https://jira.autodesk.com/browse/DRIVE-1126 is completed
    page(/^\/(new|old)\/?(.*)/, ({ params }) => {
        page.redirect(`/${params[1]}`);
    });

    // Redirect Legacy Endpoints
    page(/^\/g(|\/.*)$/, () => page('/'));
    page('/:collectionId/g', () => {
        page('/');
    });

    // Redirect Legacy Public Share Links
    page('/:collectionId/g/shares/:shareId', ({ params }) => {
        page(`/${params.collectionId}/shares/${params.shareId}`);
    });
    page('/:collectionId/s/:shareId', ({ params }) => {
        page(`/${params.collectionId}/shares/${params.shareId}`);
    });
    // Public Share Link
    page('/:collectionId/shares/:shareId', extractParams(publicSharePage));

    // Redirect Legacy Desktop Connector (via Fusion Team) Links
    page('/:collectionId/g/data/:itemId', ({ params }) => {
        const decodedUrn = atob(params.itemId);
        if (decodedUrn.includes('dm.lineage')) {
            page(`/files/${params.itemId}`);
        } else if (decodedUrn.includes('fs.folder')) {
            page(`/folders/${params.itemId}`);
        } else {
            console.warn(decodedUrn); // Help us identify unexpected links
            page('/');
        }
    });

    // Redirect Legacy File Links (with optional version)
    page(
        '/:collectionId/g/projects/:spaceId/data/:folderId/:fileId/:versionNumber?',
        ({ params }) => page(`/files/${params.fileId}`)
    );
    page('/:collectionId/i/:fileId/:versionNumber?', ({ params }) =>
        page(`/files/${params.fileId}`)
    );

    // Redirect old File link with collection ID
    page('/:collectionId/files/:fileId', ({ params }) =>
        page(`/files/${params.fileId}`)
    );

    // File Link
    page('/files/:fileId', extractParams(fileViewingPage));

    // Redirect Legacy Folder Links
    page('/:collectionId/g/projects/:spaceId/data/:folderId', ({ params }) =>
        page(`/folders/${params.folderId}`)
    );
    page('(/preview)?/:collectionId/f/:folderId', ({ params }) =>
        page(`/folders/${params.folderId}`)
    );

    // Redirect old Folder link with collection ID
    page('/:collectionId/folders/:folderId', ({ params }) =>
        page(`/folders/${params.folderId}`)
    );

    // Folder Link
    page('/folders/:folderId', extractParams(homePage));

    page('/:collectionId', extractParams(homePage));

    // OAuth2 redirect_uri after successful authentication
    page('/oauth/callback', extractParams(homePage));

    page('*', extractParams(notFoundPage));

    page.start();
</script>

<CSSOverrides />
<ScreenSizeDetector />
{#if maintenanceMode}
    <Maintenance />
{:else}
    <section class="section">
        <div class="section-head" data-cy="root-header">
            <Header loginRequired={state.loginRequired} />
        </div>
        <div class="section-body" data-cy="root-body">
            <div class="has-all-space is-clipped-x">
                {#if state && state.component}
                    <Auth loginRequired={state.loginRequired} />
                    {#if state.loginRequired}
                        {#if $gHasSystemOutage}
                            <SystemOutageBanner />
                        {/if}
                        <Protected>
                            <svelte:component
                                this={state.component}
                                {...state.props}
                            />
                        </Protected>
                    {:else}
                        <svelte:component
                            this={state.component}
                            {...state.props}
                        />
                    {/if}
                {/if}
            </div>
        </div>
        <div class="section-footer" data-cy="root-footer">
            {#if config.misc.showUserVoice}
                <UservoiceWidget />
            {/if}
        </div>
    </section>
{/if}
