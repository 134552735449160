<script>
    export let fill;

    $: if (!fill) {
        fill = '#4D4D4D';
    }
</script>

<svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 16V5H4V16H10V18H6V19H18V18H14V16H20ZM5 15V6H19V15H5ZM18 7H6V14H18V7Z"
        {fill}
    />
</svg>
