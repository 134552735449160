<script>
    export let fill = '';
    export let height = 16;
    export let width = 16;
    $: if (!fill) {
        fill = '#4d4d4d';
    }
</script>

<svg
    {width}
    {height}
    viewBox="-8 -8 {width}
    {height}"
    style="background-color:#ffffff00"
    x="-8"
    y="-8"
>
    <path
        d="M5 3L3.5 4.5l1 1L8 2 4.5-1.5l-1 1L5 1H1v2h4zM-1-7v4h4l-4-4zm-7
        0V7H3V5l-.5-.5L3 4H0V0h3l-.5-.5L3-1v-1h-5v-5h-6z"
        {fill}
    />
</svg>
