<script>
    export let fill = '#666666';
    export let size = 26;
    const width = size;
    const height = size;
</script>

<svg
    {width}
    {height}
    viewBox="7 7 {width}
    {height}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 12H22C22.5523 12 23 12.4477 23 13V23C23 23.5523 22.5523 24 22
        24H13C12.4477 24 12 23.5523 12 23V13C12 12.4477 12.4477 12 13 12ZM24
        16H27C27.5523 16 28 16.4477 28 17V27C28 27.5523 27.5523 28 27
        28H18C17.4477 28 17 27.5523 17 27V25H24V16Z"
        {fill}
    />
</svg>
