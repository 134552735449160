import { waitForGTM } from './ga';
import { writable } from 'svelte/store';
import { i18n, currentLanguage } from '../../i18n';

// UserVoice Documentation: https://www.uservoice.com/o/javascript-sdk
// No need to initialize UV as it is injected by Tealium

export const uservoiceLoaded = writable(false);

// https://developer.uservoice.com/docs/widgets/identify/
export const identifyUserVoice = (userProfile) => {
    uservoiceSettings([
        'identify',
        {
            email: userProfile.emailId,
            name: [userProfile.firstName, userProfile.lastName].join(' '),
            created_at: Date.now(),
            id: userProfile.userId,
            cf_version: 'preview',
            account: {
                cf_version: 'preview',
            },
        },
    ]);
};

export const setLocale = (language) => {
    // Store user language
    uservoiceSettings(['set', 'locale', language]);
};

export const setTrigger = (selector) => {
    uservoiceSettings([
        'addTrigger',
        selector,
        {
            mode: 'satisfaction',
            trigger_position: 'bottom-right',
        },
    ]);
};

export const uservoiceSettings = (command) => {
    window.UserVoice.push(command);
};

if (!window.UserVoice) {
    window.UserVoice = [];
}

(async () => {
    await waitForGTM();
    uservoiceLoaded.set(true);
})();

// Set appearance
uservoiceSettings([
    'set',
    {
        accent_color: '#5d96db',
        trigger_color: '#fff',
        trigger_background_color: '#303549',
        smartvote_enabled: false,
        post_suggestion_enabled: false,
        menu_enabled: true,
        strings: {
            satisfaction_success_title: i18n.t('App.UserVoice.Success_Title'),
            satisfaction_success_body: i18n.t('App.UserVoice.Success_Body'),
        },
    },
]);

setLocale(currentLanguage().original);

// Autoprompt for NPS® Rating and SmartVote (only displayed under certain conditions)
uservoiceSettings(['autoprompt', {}]);
