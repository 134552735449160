<script>
    export let asset = {};

    import DropDownComponent from '../elements/dropdown.svelte';
    import UploadIcon from '../../icons/upload.svelte';
    import FileIcon from '../../icons/file.svelte';
    import AddIcon from '../../icons/add.svelte';

    import UploadComponent from './upload.svelte';
    import DropboxUploadComponent from './upload.dropbox.svelte';
    import { i18n } from '../../i18n';

    const directoryUploadSupported =
        window.DataTransferItem && DataTransferItem.prototype.webkitGetAsEntry;

    const dataCyDropdown = 'upload-dropdown';

    let isFile;

    $: {
        isFile = asset.isFile;
    }

    let selectedData;

    const triggerUpload = () => {
        asset.uploads.upload(selectedData);
    };

    let onSelect = (data) => {
        selectedData = data;
        triggerUpload();
    };
</script>

<DropDownComponent {dataCyDropdown}>
    <div slot="trigger">
        <slot name="trigger" />
    </div>
    <div class="section" slot="content">
        {#if isFile}
            <UploadComponent {onSelect} {asset} type="file">
                <div
                    slot="trigger"
                    class="dropdown-item is-vertical-center"
                    data-cy="upload-new-version"
                >
                    <span class="icon">
                        <UploadIcon fill="#4d4d4d" />
                    </span>
                    <span>
                        {i18n.t('App.Upload_Actions.Upload_New_Version_Action')}
                    </span>
                </div>
            </UploadComponent>
        {/if}
        <UploadComponent {onSelect} {asset} type="files">
            <div
                slot="trigger"
                class="dropdown-item is-vertical-center"
                data-cy="upload-files"
            >
                <span class="icon">
                    <UploadIcon fill="#4d4d4d" />
                </span>
                <span>{i18n.t('App.Upload_Actions.Upload_Files_Action')}</span>
            </div>
        </UploadComponent>
        {#if directoryUploadSupported}
            <UploadComponent {onSelect} {asset} type="folder">
                <div
                    slot="trigger"
                    class="dropdown-item is-vertical-center"
                    data-cy="upload-folder"
                >
                    <span class="icon">
                        <AddIcon />
                    </span>
                    <span>
                        {i18n.t('App.Upload_Actions.Upload_Folder_Action')}
                    </span>
                </div>
            </UploadComponent>
        {/if}
        <DropboxUploadComponent {onSelect}>
            <div
                slot="trigger"
                class="dropdown-item is-vertical-center can-hover"
                data-cy="upload-dropbox"
            >
                <span class="icon">
                    <FileIcon type="dropbox" height={18} width={18} />
                </span>
                <span>
                    {i18n.t('App.Upload_Actions.Dropbox_Upload_Action')}
                </span>
            </div>
        </DropboxUploadComponent>
        <hr class="dropdown-divider" />
        <div class="dropdown-item has-normal-cursor">
            <p>
                <!-- XSS Warning! this is unescapped HTML -->
                {@html i18n.t(
                    'App.Upload_Actions.Desktop_Connector_Required_Msg',
                    {
                        HTML_START: '<strong>',
                        HTML_END: '</strong>',
                    }
                )}
            </p>
        </div>
    </div>
</DropDownComponent>
