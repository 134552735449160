<script>
    import Protected from './protected.svelte';
    import { search } from '../rest.sdks/collection';
    import ModalComponent from './elements/modal.svelte';
    import FileIcon from '../icons/file.svelte';
    import EmptyFolder from './folder.content/empty.folder.svelte';
    import { prefixUrl } from '../providers/navigation';
    import { i18n } from '../i18n';
    import SomethingWentWrong from '../components/something.wrong.svelte';

    const dataCy = 'file-search';

    let searching, results, showResults;
    let searchText = '';
    let modalInstance;
    let error;

    const gotoFileUrl = (file) => {
        return prefixUrl(`/files/${file.id}`);
    };

    const runSearch = async () => {
        if (!searchText || searchText.length < 2) {
            return; // Avoid 2183400 & endless spinner
        }
        searching = true;
        try {
            results = await search(searchText);
        } catch (searchError) {
            error = searchError;
        } finally {
            searching = false;
            showResults = true;
        }
    };

    const onEnter = (event) => {
        if (event.keyCode == 13) {
            runSearch();
        }
    };

    const options = {
        onCloseEnd: () => {
            results = null;
            showResults = false;
            showResults = false;
        },
    };
</script>

<Protected spinner={false}>
    <div class="field is-vertical-center is-rightaligned is-marginless">
        <p class="control has-icons-right" class:is-loading={searching}>
            <input
                data-cy={`${dataCy}-input`}
                class="input is-small search-input has-transitions"
                on:keydown={onEnter}
                type="search"
                bind:value={searchText}
                placeholder={i18n.t('App.Search.Search_Files_Placeholder')}
            />
            {#if !searching}
                <span class="icon is-small is-right">
                    <div class="search-icon is-clickable" on:click={runSearch}>
                        <img
                            id="search-icon"
                            class="search-icon"
                            src="DRIVE_ASSETS/images/search.svg"
                            alt={i18n.t('App.Search.Search')}
                        />
                    </div>
                </span>
            {/if}
        </p>
    </div>
    {#if showResults}
        <ModalComponent {dataCy} bind:this={modalInstance} {options}>
            <span slot="title">
                {i18n.t('App.Search.Search_Results_Header', {
                    SEARCH_TEXT: searchText,
                    interpolation: { escapeValue: false },
                })}
            </span>
            <div slot="content" data-cy={`${dataCy}-results`}>
                {#if results && results.length}
                    {#each results as result, i}
                        <a
                            class="search-result is-vertical-center can-hover
                            has-text-black-ter"
                            target="_blank"
                            href={gotoFileUrl(result)}
                            data-cy={`${dataCy}-result-item`}
                            class:has-top-border={i !== 0}
                        >
                            <span class="icon">
                                <FileIcon type={result.attributes.type} />
                            </span>
                            <span class="has-ellipsis"
                                >{result.attributes.name}</span
                            >
                        </a>
                    {/each}
                {:else if error}
                    <SomethingWentWrong {error} source={dataCy} inline={true} />
                {:else}
                    <EmptyFolder
                        message={i18n.t('App.Search.No_Results_Msg')}
                    />
                {/if}
            </div>
            <div slot="footer-right-section">
                <button
                    class="button is-primary is-focus"
                    on:click={modalInstance.close}
                    data-cy={`${dataCy}-results-done`}
                >
                    {i18n.t('App.Search.Done_Btn')}
                </button>
            </div>
        </ModalComponent>
    {/if}
</Protected>

<style>
    .search-icon {
        height: 16px;
        width: 16px;
    }
    .search-result {
        height: 47px;
        padding-left: 12px;
    }
</style>
